define('frontend/components/toggle-switch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      guidFor = Ember.guidFor,
      Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    id: computed(function () {
      return 'toggle-switch__switch-' + guidFor(this);
    })
  });
});