define('frontend/components/qms-integrations/jira', ['exports', 'frontend/components/qms-integrations/webhook'], function (exports, _webhook) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _webhook.default.extend({
    provider: 'jira',

    webhook: Ember.computed.alias('product.jiraWebhook'),
    jiraWebhookUrl: Ember.computed.alias('product.jiraWebhookUrl')
  });
});