define('frontend/components/formatted-document-path', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    store: service(),
    fullPath: computed('fullPath', function () {
      return this.get('fullPath');
    }),
    fullPathArray: computed('fullPath', function () {
      return this.get('fullPath').split('ENZYMESUBFOLDERBREAK');
    }),
    firstFolder: computed('fullPathArray', function () {
      return this.get('fullPathArray')[0];
    }),
    subFoldersExist: computed('fullPathArray', function () {
      return this.get('fullPathArray').length > 1;
    }),
    subFolders: computed('fullPathArray', function () {
      var temp = this.get('fullPathArray').slice();
      temp.shift();
      return temp;
    })
  });
});