define('frontend/routes/app/products/product/document-control/document-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var qms_document_id = _ref.qms_document_id;

      return this.get('store').findRecord('qms-document', qms_document_id, { reload: true });
    }
  });
});