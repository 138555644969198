define('frontend/models/nc-product-risklevel', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    severity: (0, _relationships.belongsTo)(),
    probability: (0, _relationships.belongsTo)(),
    risk: (0, _attr.default)('string', { defaultValue: 'low' })
  });
});