define('frontend/components/change-request/question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['craq-question'],
    classNameBindings: ['isDisabled', 'errors.length:is-error'],

    commentIsRequired: Ember.computed('question.options.@each.comment_required', 'selectedOptionIndex', function () {
      var optionIndex = this.get('selectedOptionIndex');

      if (optionIndex !== undefined) {
        var selectedOption = this.get('question.options').objectAt(optionIndex);
        return Ember.get(selectedOption, 'comment_required');
      }
    }),

    isDisabled: Ember.computed('index', 'completeIndex', 'allDisabled', function () {
      if (this.get('allDisabled')) {
        return true;
      }
      return this.get('index') > this.get('completeIndex');
    }),

    humanIndex: Ember.computed('index', function () {
      return this.get('index') + 1;
    }),

    actions: {
      selectOption: function selectOption(question, option) {
        var optionIndex = Ember.get(question, 'options').indexOf(option);
        this.set('selectedOptionIndex', optionIndex);
        this.get('selectOption')(question, option);
      }
    }
  });
});