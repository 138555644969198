define('frontend/models/supplier', ['exports', 'ember-data', 'ember-data/relationships', 'ember-data/attr', 'frontend/mixins/approvable', 'frontend/initializers/add-model-async-methods', 'frontend/helpers/humanize'], function (exports, _emberData, _relationships, _attr, _approvable, _addModelAsyncMethods, _humanize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var conditionallyApprove = { action: 'conditionally_approve', icon: 'check-circle-o', confirmText: "conditionally approve this supplier?" };
  var approve = { action: 'approve_supplier', icon: 'check-circle', confirmText: "approve this supplier?" };
  var audit = { action: 'audit', icon: 'eye', confirmText: "audit this supplier?" };
  var disqualify = { action: 'disqualify', icon: 'times', confirmText: "disqualify this supplier?" };
  var discontinue = { action: 'discontinue', icon: 'times-circle', confirmText: "discontinue this supplier?" };
  var setToUnapproved = { action: 'set_to_unapproved', icon: 'pencil-square-o', confirmText: "set this supplier to unapproved?" };

  var actionMap = {
    new_supplier: [conditionallyApprove, approve, audit, disqualify, discontinue],
    conditionally_approved: [approve, disqualify, discontinue, setToUnapproved],
    approved_supplier: [conditionallyApprove, audit, disqualify, discontinue],
    auditing: [conditionallyApprove, approve, disqualify, discontinue, setToUnapproved],
    disqualified: [conditionallyApprove, approve, discontinue, setToUnapproved],
    discontinued: [setToUnapproved]
  };

  exports.default = _emberData.default.Model.extend(_approvable.default, {
    company: (0, _relationships.belongsTo)('company'),
    supplierRisk: (0, _relationships.belongsTo)('supplier-risk'),
    supplierRating: (0, _relationships.belongsTo)('supplier-rating'),
    supplierType: (0, _relationships.belongsTo)('supplier-type'),

    supplierSurveyFileUploads: (0, _relationships.hasMany)('file-upload'),
    supplierQualityAuditFileUploads: (0, _relationships.hasMany)('file-upload'),
    supplierRiskJustificationFileUploads: (0, _relationships.hasMany)('file-upload'),
    miscellaneousFileUploads: (0, _relationships.hasMany)('file-upload'),
    qmsDocuments: (0, _relationships.hasMany)('qms-document'),

    numberOfAttachments: Ember.computed('supplierSurveyFileUploads.length', 'supplierQualityAuditFileUploads.length', 'supplierRiskJustificationFileUploads', 'miscellaneousFileUploads', function () {
      return this.get('supplierSurveyFileUploads.length') + this.get('supplierQualityAuditFileUploads.length') + this.get('supplierRiskJustificationFileUploads.length') + this.get('miscellaneousFileUploads.length');
    }),

    stateActions: Ember.computed('state', function () {
      return actionMap[this.get('state')];
    }),

    conditionallyApprove: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'conditionally_approve', type: 'post' }),
    approveSupplier: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'approve_supplier', type: 'post' }),
    audit: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'audit', type: 'post' }),
    disqualify: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'disqualify', type: 'post' }),
    discontinue: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'discontinue', type: 'post' }),
    setToUnapproved: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'set_to_unapproved', type: 'post' }),

    shortName: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    address: (0, _attr.default)('string'),
    postCode: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    phoneNumber: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    riskJustification: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),

    routeForApproval: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'route_for_approval', type: 'post' }),

    humanizedApprovalState: Ember.computed('approvalState', function () {
      var map = {
        draft: 'draft',
        create_requested: 'pending_approval',
        update_requested: 'pending_approval',
        destroy_requested: 'pending_approval',
        approved: 'released'
      };
      var approvalState = this.get('approvalState');
      var stateName = map[approvalState] || approvalState;
      return (0, _humanize.humanize)(stateName);
    }),

    humanizedSupplierState: Ember.computed('state', function () {
      var map = {
        new_supplier: 'unapproved',
        conditionally_approved: 'conditionally_approved',
        approved_supplier: 'approved',
        auditing: 'audit_in_progress',
        disqualified: 'disqualified',
        discontinued: 'discontinued'
      };
      var state = this.get('state');
      var stateName = map[state] || state;
      return (0, _humanize.humanize)(stateName);
    }),

    effectiveState: Ember.computed('state', 'hasBeenApproved', 'isApproved', 'sortedVersions', function () {
      if (this.get('isApproved')) {
        return this.get('state'); // if approved return the state
      } else if (!this.get('hasBeenApproved')) {
        // always unapproved if never been approved
        if (this.get('state') == 'auditing') {
          return 'auditing';
        } else {
          return 'new_supplier';
        }
      } else {
        var lastApprovedVersion = null;
        lastApprovedVersion = this.get('sortedVersions').findBy('approvalState', 'approved');
        if (lastApprovedVersion) {
          return lastApprovedVersion.get('versionData.state');
        } else {
          // can't find an approved version == unapproved
          if (this.get('state') == 'auditing') {
            return 'auditing';
          } else {
            return 'new_supplier';
          }
        }
      }
    }),

    humanizedEffectiveState: Ember.computed('effectiveState', function () {
      var map = {
        new_supplier: 'unapproved',
        conditionally_approved: 'conditionally_approved',
        approved_supplier: 'approved',
        auditing: 'audit_in_progress',
        disqualified: 'disqualified',
        discontinued: 'discontinued'
      };
      var state = this.get('effectiveState');
      var stateName = map[state] || state;
      return (0, _humanize.humanize)(stateName);
    }),

    noStateChange: Ember.computed('effectiveState', 'state', function () {
      return String(this.get('effectiveState')) === String(this.get('state'));
    }),
    stateChange: Ember.computed.not('noStateChange'),

    reviewInProgress: Ember.computed.reads('isPendingApproval'),

    routableTarget: 'app.products.product.supplier-quality.supplier'
  });
});