define('frontend/components/qms-document-folder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentUser: Ember.computed.alias('session.currentUser'),

    tagName: 'li',
    active: true,
    classNameBindings: ['active'],
    sortProperties: ['createdAt'],
    sortedQmsDocuments: Ember.computed.sort('qmsDocumentFolder.notObsoleteQmsDocuments', 'sortProperties'),

    canDeleteQmsDocumentFolder: Ember.computed.and('currentUser.canEdit', 'qmsDocumentFolder.isEmpty'),

    actions: {
      toggleActive: function toggleActive() {
        this.toggleProperty('active');
      },
      destroyQmsDocumentFolder: function destroyQmsDocumentFolder() {
        this.sendAction('destroyQmsDocumentFolder', this.get('qmsDocumentFolder'));
      }
    }
  });
});