define('frontend/components/notification-cards/approval-card-form/training', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['approval-card-form', 'training'],

    assignment: null
  });
});