define('frontend/components/modal-dialog', ['exports', 'ember-modal-dialog/components/modal-dialog'], function (exports, _modalDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _modalDialog.default.extend({
    translucentOverlay: true,
    targetAttachment: 'none',
    onClickOverlay: function onClickOverlay() {},
    animatable: false,

    containerClassNames: ['centered-scrolling-container'],
    overlayClassNames: ['centered-scrolling-overlay'],
    wrapperClassNames: ['centered-scrolling-wrapper'],

    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        _this._getModalAndOverlay().forEach(function (el) {
          return el.classList.add('active');
        });
      });
      Ember.$('body').on('keyup.modal-dialog', this.get('keyUpHandler'));

      document.body.classList.add('modal-dialog-open');

      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      if (document.querySelectorAll('.ember-modal-dialog').length === 1) {
        // only this modal is open, then when it's removed there will be no modals
        // open

        document.body.classList.remove('modal-dialog-open');
      }

      var _getModalAndOverlay = this._getModalAndOverlay(),
          _getModalAndOverlay2 = _slicedToArray(_getModalAndOverlay, 2),
          overlay = _getModalAndOverlay2[1];

      var clone = Ember.$(overlay).clone();
      clone.one('transitionend', function () {
        return clone.remove();
      });
      var modalOverlays = Ember.$(overlay).parents('#modal-overlays');
      modalOverlays.append(clone);
      Ember.run.next(function () {
        clone.removeClass('active');
        Ember.$('.ember-modal-dialog', clone).removeClass('active');
      });
      Ember.$('body').off('keyup.modal-dialog', this.get('keyUpHandler'));
      this._super.apply(this, arguments);
    },


    keyUpHandler: Ember.computed(function () {
      var _this2 = this;

      return function (e) {
        if (e.keyCode === 27 && !_this2.get('isDestroying') && !_this2.get('isDestroyed')) {
          _this2.sendAction('close');
        }
      };
    }),

    // Methods
    _getModalAndOverlay: function _getModalAndOverlay() {
      if (this._result) {
        return this._result;
      }
      var modals = document.querySelectorAll('.ember-modal-dialog:not(.existing-modal-dialog)');
      var modal = modals[modals.length - 1];
      var result = [];
      if (modal) {
        modal.classList.add('existing-modal-dialog');
        result.push(modal);
        var overlay = modal.parentElement;
        if (overlay) {
          result.push(overlay);
        }
      }
      this._result = result;
      return result;
    }
  });
});