define('frontend/services/authorize-action', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    confirm: false,

    showConfirmation: function showConfirmation() {
      this.set('confirm', true);
      this.defer = Ember.RSVP.defer();
      return this.defer.promise;
    },
    hideConfirmation: function hideConfirmation(result) {
      this.set('confirm', false);
      this.defer.resolve(result);
      this.defer = null;
    }
  });
});