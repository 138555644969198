define('frontend/components/current-and-previous-values', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  var readOnly = computed.readOnly;
  exports.default = Component.extend({
    store: service(),

    active: true,
    showDiff: computed.and('active', 'currentIsDifferentToPrevious'),

    modelListChanges: null,

    current: defaultValue('current'),
    previous: defaultValue('previous'),

    /**
     * Rending `previous` in the template fails, possibly because of an addon
     * we're using?
     */
    previousValue: readOnly('previous'),

    currentIsDifferentToPrevious: computed('current', 'previous', function () {
      return JSON.stringify(this.get('current')) !== JSON.stringify(this.get('previous'));
    }),

    decoratedPrevious: decoratedValue('previous'),
    decoratedCurrent: decoratedValue('current')
  });


  function defaultValue(key) {
    return computed('modelListChanges.' + key + '.[]', 'modelType', 'modelIds.' + key, function () {
      if (this.get('modelListChanges')) {
        return this.get('modelListChanges.' + key);
      } else if (this.get('modelType')) {
        return this.get('modelIds.' + key);
      }
    });
  }

  function decoratedValue(key) {
    return computed(key, 'modelListChanges.' + key, 'modelType', 'modelIds.' + key, function () {
      var _this = this;

      if (this.get('modelListChanges')) {
        return (this.get('modelListChanges.' + key) || []).map(function (data) {
          return _this.get('store').findRecord(data.type, data.id);
        });
      } else if (this.get('modelIds.' + key) && this.get('modelType')) {
        return this.get('store').findRecord(this.get('modelType'), this.get('modelIds.' + key));
      } else {
        return this.get(key);
      }
    });
  }
});