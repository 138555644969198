define('frontend/models/requirement-verification-dependency', ['exports', 'ember-data', 'frontend/mixins/model-meta-data'], function (exports, _emberData, _modelMetaData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend(_modelMetaData.default, {
    state: attr('string'),
    requirement: belongsTo('requirement'),
    verification: belongsTo('verification')
  });
});