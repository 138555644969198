define('frontend/controllers/onboarding/product/subsystem', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed;
  exports.default = Controller.extend({
    persistedSubsystems: computed.filterBy('model.subsystems', 'isNew', false),

    createNewSubsystem: function createNewSubsystem() {
      this.set('newSubsystem', this.get('model.subsystems').createRecord());
    },


    actions: {
      save: function save() {
        var _this = this;

        this.get('newSubsystem').saveWithNotifications().then(function () {
          _this.createNewSubsystem();
        });
      }
    }
  });
});