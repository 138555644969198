define('frontend/models/change-request', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    craqAnswers: (0, _attr.default)(),
    comment: (0, _attr.default)('string'),
    pendingJustification: (0, _attr.default)('string'),
    unlinkedJustification: (0, _attr.default)('string'),
    isAdminCr: (0, _attr.default)('boolean', { defaultValue: false }),
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),

    /**
     * Set this to true before submitting so the new approval set transitions
     * to `pending` state (as opposed to remaining in `draft` state).
     *
     * @type {Boolean}
     */
    isSubmitted: (0, _attr.default)('boolean', { defaultValue: false }),

    /**
     * When editing an existing change request, set this approval set before
     * saving.
     *
     * @type {PromiseObject<ApprovalSet>}
     */
    approvalSet: (0, _relationships.belongsTo)('approval-set'),
    approverBlacklist: (0, _attr.default)(),
    approvables: (0, _relationships.hasMany)('approvable'),
    qmsDocuments: (0, _relationships.hasMany)('qms-document')
  });
});