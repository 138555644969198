define('frontend/components/design-controls-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['container', 'design-controls-container'],
    hoveredCard: null,

    showOverlay: Ember.computed('tracingModeOn', 'tracingReportOn', 'hoveredCard', function () {
      return this.get('tracingReportOn') || this.get('tracingModeOn') && this.get('hoveredCard');
    }),

    tracingCardsWithoutSelf: Ember.computed('hoveredCard.dc.tracingDependencyObjects.[]', 'cardComponents.[]', function () {
      var _this = this;

      if (!this.get('hoveredCard')) {
        return [];
      }

      var tracingDependencyObjects = this.get('hoveredCard.dc.tracingDependencyObjects');

      var findCardsFromDependencyName = function findCardsFromDependencyName(dependencyName) {
        return _this.get('hoveredCard.dc.' + dependencyName).map(function (dependencyRelation) {
          return _this.get('cardComponents').findBy('dc.shortName', dependencyRelation.get(tracingDependencyObjects[dependencyName] + '.shortName'));
        });
      };

      return this.get('hoveredCard.dc.tracingDependencies').map(function (dependency) {
        return findCardsFromDependencyName(dependency);
      }).flatten().compact();
    }),

    designControls: Ember.computed.mapBy('cardComponents', 'dc'),

    tracingCards: Ember.computed('tracingCardsWithoutSelf.[]', 'hoveredCard', function () {
      return this.get('tracingCardsWithoutSelf').concat(this.get('hoveredCard'));
    }),

    cardComponents: Ember.computed(function () {
      return [];
    }),

    registerCard: function registerCard(cardComponent) {
      this.get('cardComponents').pushObject(cardComponent);
    },
    unregisterCard: function unregisterCard(cardComponent) {
      this.get('cardComponents').removeObject(cardComponent);
      this.unhoverCard();
    },
    hoverCard: function hoverCard(cardComponent) {
      this.set('hoveredCard', cardComponent);
    },
    unhoverCard: function unhoverCard() {
      this.set('hoveredCard', null);
    }
  });
});