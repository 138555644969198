define('frontend/models/qms-document-folder', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    company: (0, _relationships.belongsTo)(),

    parentFolder: (0, _relationships.belongsTo)('qms-document-folder', { inverse: 'subfolders' }),

    qmsDocuments: (0, _relationships.hasMany)('qms-document'),
    subfolders: (0, _relationships.hasMany)('qms-document-folder', { inverse: 'parentFolder' }),

    isTopLevel: Ember.computed.not('parentFolder.content'),
    isOldGlobalFolder: Ember.computed('name', function () {
      return this.get('name') == "Global documents" || this.get('name') == 'Global Documents';
    }),
    sortedSubfolders: Ember.computed('subfolders.[]', function () {
      return this.get('subfolders').naturalSortBy('name');
    }),

    // Props
    canHaveSubfolders: true,

    // CPs
    liveQmsDocuments: Ember.computed.filterBy('qmsDocuments', 'isLive'),
    notObsoleteQmsDocuments: Ember.computed.filterBy('liveQmsDocuments', 'isObsolete', false),
    isEmpty: Ember.computed.empty('notObsoleteQmsDocuments'),
    isNotEmpty: Ember.computed.not('isEmpty'),
    title: Ember.computed.alias('name'),

    allQmsDocuments: Ember.computed('qmsDocuments.[]', 'subfolders.@each.allQmsDocuments', function () {
      return this.get('subfolders').reduce(function (allDocuments, nextFolder) {
        return allDocuments.concat(nextFolder.get('allQmsDocuments'));
      }, this.get('qmsDocuments').rejectBy('isObsolete', true).toArray());
    }),

    fullPath: Ember.computed('parentFolder.fullPath', function () {
      return [this.get('parentFolder.fullPath'), this.get('name')].compact().join('/');
    }),

    fullPathBreakable: Ember.computed('parentFolder.fullPathBreakable', function () {
      return [this.get('parentFolder.fullPathBreakable'), this.get('name')].compact().join('ENZYMESUBFOLDERBREAK');
    }),

    canBeDestroyed: Ember.computed('parentFolder.id', 'isEmpty', function () {
      return !!(this.get('parentFolder.id') || this.get('isEmpty'));
    })
  });
});