define('frontend/controllers/app/products/product/complaints', ['exports', 'frontend/utils/date-formatter'], function (exports, _dateFormatter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    productController: Ember.inject.controller('app.products.product'),

    searchText: Ember.computed.reads('productController.searchText'),

    columns: Ember.computed(function () {
      var _this = this;

      return [{
        title: "ID",
        key: "id",
        sortBy: "id",
        filter: { type: 'string' }
      }, { key: 'createdAt', title: "Created On", formatter: _dateFormatter.default, filter: { type: 'string' } }, { key: 'eventDate', formatter: _dateFormatter.default, filter: { type: 'string' } }, { key: 'awareDate', formatter: _dateFormatter.default, filter: { type: 'string' } }, { key: 'asReportedCodes', component: 'complaints/column-as-reported-codes' }, { key: 'sourceName', filter: { type: 'string' } }, { key: 'sourceType', filter: { type: 'string' } }, { key: 'hospital', filter: { type: 'string' } }, { key: 'stage', component: 'complaints/column-stage', title: 'Status' }, { key: 'daysOpen', filter: { type: 'string' } }, { key: 'daysAware', filter: { type: 'string' } }, { key: 'triageAssessment', title: 'Triage', component: 'complaints/column-triage-assessment' }, { key: 'approvalState', component: 'complaints/column-approval-state' }, {
        type: 'actions',
        actions: [{
          name: "View",
          action: function action(complaint) {
            return _this.transitionToRoute('app.products.product.complaints.complaint', complaint);
          }
        }]
      }];
    }),

    persistedComplaints: Ember.computed.filterBy('model', 'isNew', false),

    filteredComplaints: Ember.computed('persistedComplaints.@each.{region,sourceName,sourceType,hospital,stage}', 'searchText', function () {
      var q = this.get('searchText');
      var complaints = this.get('persistedComplaints');

      if (Ember.isBlank(q)) {
        return complaints;
      }

      q = q.toLowerCase();

      return complaints.filter(function (complaint) {
        return "region.name sourceName sourceType hospital stage".w().some(function (field) {
          return (complaint.get(field) || "").toLowerCase().includes(q);
        });
      });
    })
  });
});