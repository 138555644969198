define('frontend/controllers/app/products/product/document-control/document-type/list', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_emberValidations.default, {
    session: Ember.inject.service('session'),
    qmsDocumentTypes: Ember.computed.alias('model'),
    user: Ember.computed.alias('session.currentUser'),
    isQualityOwner: Ember.computed.alias('session.currentUser.isQualityOwner'),

    errors: [],
    displayErrors: false,

    changedTypes: Ember.computed('qmsDocumentTypes.@each.{description,requireTrainingPlan}', function () {
      return this.get('qmsDocumentTypes').filter(function (type) {
        return type.get('hasDirtyAttributes') === true;
      });
    }),

    actions: {
      closeListDocumentTypeModal: function closeListDocumentTypeModal() {
        if (history.length > 2) {
          history.back();
        } else {
          this.transitionToRoute('app.products.product.document-control');
        }
      },
      updateTypes: function updateTypes() {
        this.get('changedTypes').forEach(function (type) {
          type.saveWithNotifications();
        });
        this.transitionToRoute('app.products.product.document-control');
      }
    }
  });
});