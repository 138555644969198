define('frontend/models/as-reported-code', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    company: belongsTo(),
    code: attr('string'),
    description: attr('string'),
    searchText: computed('code', 'description', function () {
      return this.get('code') + ': ' + this.get('description');
    })
  });
});