define('frontend/routes/app/products/product/non-conformances/non-conformance', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    resetController: function resetController(controller) {
      controller.set('isCancelling', false);
      controller.set('isReopened', false);
    }
  });
});