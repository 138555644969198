define('frontend/routes/app/products/product/document-control/new', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('store').createRecord('qms-document');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.setProperties({
        effectiveAt: (0, _moment.default)().format('YYYY-MM-DD'),
        qmsDocumentFileUpload: null,
        qmsDocumentType: null,
        qmsDocumentFolder: null,
        training: null,
        title: '',
        tagList: [],
        documentNumber: null,
        attachmentsMarkedForAddition: [],
        fileUpload: null,
        productionVersioning: null,
        productionVersioningSelected: null,
        products: [],
        formNumber: null,
        parentQmsDocument: null,
        isForm: false
      });
    }
  });
});