define('frontend/components/version-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['row'],
    approverNames: computed('version.approverNames.[]', function () {
      return this.get('version.approverNames').join(', ');
    })
  });
});