define('frontend/routes/app/products/product/document-control/document-type/delete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    resetController: function resetController() {
      this.get('controller').setProperties({
        displayErrors: false,
        qmsDocumentTypeToRemove: null,
        qmsDocumentTypeToReplaceWith: null
      });
    }
  });
});