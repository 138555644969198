define('frontend/components/nc-capa-settings/product-risk-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ncProductRisklevel: Ember.computed('{severity,probability}.@each.ncProductRisklevels', function () {
      var risklevel = this.get('severity.ncProductRisklevels').findBy('probability.id', this.get('probability.id'));

      if (!risklevel) {
        risklevel = this.get('severity.ncProductRisklevels').createRecord({ probability: this.get('probability') });
      }

      return risklevel;
    }),

    actions: {
      updateRisk: function updateRisk(risk) {
        this.set('ncProductRisklevel.risk', risk);
        this.get('ncProductRisklevel').saveWithNotifications();
      }
    }
  });
});