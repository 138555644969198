define('frontend/components/non-conformances/description-column', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    nonConformancesProducts: Ember.computed('row.ncType', 'row.nonConformancesProductsSummary.@each.{product_id,software_version}', function () {
      var _this = this;

      if (this.get('row.ncType') == 'product') {
        return this.get('row.nonConformancesProductsSummary').map(function (spec) {
          return {
            product: spec.product_id && _this.get('store').findRecord('product', spec.product_id),
            softwareVersion: spec.software_version
          };
        });
      } else {
        return [];
      }
    })
  });
});