define('frontend/lib/qms-document-version', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    /**
     * @type {Version}
     */
    model: null,

    /**
     * @type {Service}
     */
    store: null,

    /**
     * Evaluates to `true` when minor version changed of when the version belongs to
     * the `create` event.
     *
     * @type {Boolean}
     */
    didChangeVersion: Ember.computed.or('model.versionChangeset.minorVersionNumber', 'model.versionChangeset.majorVersionNumber', 'model.isCreateEvent'),

    /**
     * @type {Boolean}
     */
    isApproved: Ember.computed.readOnly('model.isApproved'),

    /**
     * @type {Array<String>}
     */
    approverNames: Ember.computed.readOnly('model.approverNames'),

    requesterName: Ember.computed.alias('model.requesterName'),

    editorName: Ember.computed.alias('model.editorName'),

    /**
     * @type {String}
     */
    data: Ember.computed.readOnly('model.completeVersionData'),

    effectiveAt: Ember.computed('data.effectiveAt', function () {
      var date = this.get('data.effectiveAt');
      if (date) {
        return (0, _moment.default)(date);
      }
    }),

    qmsDocumentVersionString: Ember.computed.readOnly('model.qmsDocumentVersionString'),

    /**
     * Concatenates the major and minor version number.
     *
     * @type {String}
     */
    versionNumber: Ember.computed('data.{minorVersionNumber,majorVersionNumber,obsolete}', function () {
      if (this.get('model.isCreateEvent')) {
        return '0.1';
      }
      if (this.get('data.obsolete')) {
        return 'X.X';
      }
      return this.get('data.majorVersionNumber') + '.' + this.get('data.minorVersionNumber');
    }),

    /**
     * @type {FileUpload}
     */
    fileUpload: Ember.computed('data.fileUploadId', function () {
      if (this.get('data.fileUploadId')) {
        return this.get('store').find('file-upload', this.get('data.fileUploadId'));
      }
      return null;
    }),

    changesFileUpload: Ember.computed('data.changesFileUploadId', function () {
      if (this.get('data.changesFileUploadId')) {
        return this.get('store').find('file-upload', this.get('data.changesFileUploadId'));
      }
      return null;
    }),

    changesPdfFileUpload: Ember.computed('data.changesPdfFileUploadId', function () {
      if (this.get('data.changesPdfFileUploadId')) {
        return this.get('store').find('file-upload', this.get('data.changesPdfFileUploadId'));
      }
      return null;
    }),

    historyPdfFileUpload: Ember.computed('data.historyPdfFileUploadId', function () {
      if (this.get('data.historyPdfFileUploadId')) {
        return this.get('store').find('file-upload', this.get('data.historyPdfFileUploadId'));
      }
      return null;
    }),

    auditPdfFileUpload: Ember.computed('data.auditPdfFileUploadId', function () {
      if (this.get('data.auditPdfFileUploadId')) {
        return this.get('store').find('file-upload', this.get('data.auditPdfFileUploadId'));
      }
      return null;
    }),

    processedFileUpload: Ember.computed('data.processedFileUploadId', function () {
      if (this.get('data.processedFileUploadId')) {
        return this.get('store').find('file-upload', this.get('data.processedFileUploadId'));
      }
      return null;
    }),

    /**
     * @type {Date}
     */
    updatedAt: Ember.computed('data.updatedAt', function () {
      return (0, _moment.default)(this.get('data.updatedAt')).toDate();
    }),

    createdAt: Ember.computed('data.createdAt', function () {
      return (0, _moment.default)(this.get('data.createdAt')).toDate();
    }),

    /**
    * @type {String}
    */
    title: Ember.computed('data.title', function () {
      return this.get('data.title');
    }),

    approvalSetId: Ember.computed.reads('model.approvalSetId')
  });
});