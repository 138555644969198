define('frontend/services/list', ['exports', 'frontend/macros/natural-sort'], function (exports, _naturalSort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    warmup: function warmup() {
      this.get('qmsDocuments');
      this.get('qmsFolders');
      this.get('qmsDocumentTypes');
    },


    qmsDocuments: Ember.computed(function () {
      return this.get('store').findAll('qms-document', { reload: false });
    }),

    nonObsoleteQmsDocuments: Ember.computed('qmsDocuments.@each.{obsolete,docIdentifier}', function () {
      return this.get('qmsDocuments').filterBy('obsolete', false).naturalSortBy('docIdentifier');
    }),

    qmsFolders: Ember.computed(function () {
      return this.get('store').findAll('qms-document-folder', { reload: false });
    }),

    qmsDocumentTypes: Ember.computed(function () {
      return this.get('store').findAll('qms-document-type', { reload: false });
    }),

    qmsDocumentTypesSorting: ['title'],
    sortedQmsDocumentTypes: (0, _naturalSort.default)('qmsDocumentTypes', 'qmsDocumentTypesSorting'),
    liveQmsDocumentTypes: Ember.computed.filterBy('sortedQmsDocumentTypes', 'deletedAt', null),

    foldersByLevel: Ember.computed('qmsFolders.[]', function () {
      var folders = this.get('qmsFolders');

      if (!folders.get('length')) {
        return folders;
      }

      var result = [];

      folders = folders.filterBy('isTopLevel');

      function walk(folders, level) {
        folders.naturalSortBy('name').forEach(function (folder) {
          result.push({ level: level, folder: folder });
          var subfolders = folder.get('subfolders').toArray();
          if (subfolders.length > 0) {
            walk(subfolders, level + 1);
          }
        });
      }
      walk(folders, 0);
      return result;
    })
  });
});