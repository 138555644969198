define('frontend/utils/documents-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.removeDocuments = removeDocuments;
  exports.generateOptions = generateOptions;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function removeDocuments(arr, documentIds) {
    return arr && arr.filter(function (document) {
      return documentIds.indexOf(document.id) === -1;
    });
  }

  function generateOptions(folder, documentIds) {
    var subfolders = Ember.get(folder, 'subfolders').toArray();
    var documents = Ember.get(folder, 'qmsDocuments').toArray();

    documents = removeDocuments(documents, documentIds);

    var options = [];
    var documentsCount = 0;

    if (documents.length > 0) {
      options.push.apply(options, _toConsumableArray(documents));
      documentsCount += documents.length;
    }

    if (subfolders.length > 0) {
      for (var i = 0; i < subfolders.length; i++) {
        var subfolder = subfolders[i];
        var subfolderData = generateOptions(subfolder, documentIds);

        if (subfolderData.documentsCount > 0) {
          documentsCount += subfolderData.documentsCount;
          options.push({ groupName: Ember.get(subfolder, 'name'), options: subfolderData.options });
        }
      }
    }

    return { documentsCount: documentsCount, options: options };
  }
});