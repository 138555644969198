define('frontend/tours/quality-owner-functions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = {
    name: 'Quality Owner Functions',

    setup: function setup() {
      var allproducts = this.store.peekAll('product');
      this.realproduct = allproducts.get('firstObject');
      var allcompanies = this.store.peekAll('company');
      this.company = allcompanies.get('firstObject');

      this.negligible = this.company.get('severities').createRecord({ name: "Negligible", description: "Inconvenience or temporary discomfort", id: 0.1 });
      this.minor = this.company.get('severities').createRecord({ name: "Minor", description: "Results in temporary injury or impairment not requiring professional medical intervention", id: 0.2 });
      this.serious = this.company.get('severities').createRecord({ name: "Serious", description: "Results in injury or impairment requiring professional medical intervention", id: 0.3 });
      this.critical = this.company.get('severities').createRecord({ name: "Critical", description: "Results in permanent impairment or life-threatening injury", id: 0.4 });
      this.catastrophic = this.company.get('severities').createRecord({ name: "Catastrophic", description: "Results in patient death", id: 0.5 });
      this.severities = [this.negligible, this.minor, this.serious, this.critical, this.catastrophic];

      this.remote = this.company.get('probabilities').createRecord({ name: "Remote", description: "< 10⁻⁶ (Less than 1 in 1,000,000). Extremely unlikely. No history of occurrence.", id: 0.1 });
      this.improbable = this.company.get('probabilities').createRecord({ name: "Improbable", description: "≤ 10⁻⁵ and ≥ 10⁻⁶ (Between 1 in 100,000 and 1 in 1,000,000). Could occur at some time. Isolated incidents observed.", id: 0.2 });
      this.occasional = this.company.get('probabilities').createRecord({ name: "Occasional", description: "≤ 10⁻⁴ and ≥ 10⁻⁵ (Between 1 in 10,000 and 1 in 100,000). Probable at some time and has been observed.", id: 0.3 });
      this.probable = this.company.get('probabilities').createRecord({ name: "Probable", description: "≤ 10⁻³ and ≥ 10⁻⁴ (Between 1 and 1,000 and 1 in 10,000). Likely and will occur in most circumstances. Repeated occurrences observed.", id: 0.4 });
      this.frequent = this.company.get('probabilities').createRecord({ name: "Frequent", description: "> 10⁻³ (More than 1 in 1,000). Almost inevitable.", id: 0.5 });
      this.probabilities = [this.remote, this.improbable, this.occasional, this.probable, this.frequent];

      this.rl_nf = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.negligible, probability: this.frequent, id: 0.1 });
      this.rl_np = this.company.get('risklevels').createRecord({ description: "Low", severity: this.negligible, probability: this.probable, id: 0.2 });
      this.rl_no = this.company.get('risklevels').createRecord({ description: "Low", severity: this.negligible, probability: this.occasional, id: 0.3 });
      this.rl_ni = this.company.get('risklevels').createRecord({ description: "Low", severity: this.negligible, probability: this.improbable, id: 0.4 });
      this.rl_nr = this.company.get('risklevels').createRecord({ description: "Low", severity: this.negligible, probability: this.remote, id: 0.5 });
      this.rl_mf = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.minor, probability: this.frequent, id: 0.6 });
      this.rl_mp = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.minor, probability: this.probable, id: 0.7 });
      this.rl_mo = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.minor, probability: this.occasional, id: 0.8 });
      this.rl_mi = this.company.get('risklevels').createRecord({ description: "Low", severity: this.minor, probability: this.improbable, id: 0.9 });
      this.rl_mr = this.company.get('risklevels').createRecord({ description: "Low", severity: this.minor, probability: this.remote, id: '0.10' });
      this.rl_sf = this.company.get('risklevels').createRecord({ description: "High", severity: this.serious, probability: this.frequent, id: 0.11 });
      this.rl_sp = this.company.get('risklevels').createRecord({ description: "High", severity: this.serious, probability: this.probable, id: 0.12 });
      this.rl_so = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.serious, probability: this.occasional, id: 0.13 });
      this.rl_si = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.serious, probability: this.improbable, id: 0.14 });
      this.rl_sr = this.company.get('risklevels').createRecord({ description: "Low", severity: this.serious, probability: this.remote, id: 0.15 });
      this.rl_cf = this.company.get('risklevels').createRecord({ description: "High", severity: this.critical, probability: this.frequent, id: 0.16 });
      this.rl_cp = this.company.get('risklevels').createRecord({ description: "High", severity: this.critical, probability: this.probable, id: 0.17 });
      this.rl_co = this.company.get('risklevels').createRecord({ description: "High", severity: this.critical, probability: this.occasional, id: 0.18 });
      this.rl_ci = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.critical, probability: this.improbable, id: 0.19 });
      this.rl_cr = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.critical, probability: this.remote, id: '0.20' });
      this.rl_caf = this.company.get('risklevels').createRecord({ description: "High", severity: this.catastrophic, probability: this.frequent, id: 0.21 });
      this.rl_cap = this.company.get('risklevels').createRecord({ description: "High", severity: this.catastrophic, probability: this.probable, id: 0.22 });
      this.rl_cao = this.company.get('risklevels').createRecord({ description: "High", severity: this.catastrophic, probability: this.occasional, id: 0.23 });
      this.rl_cai = this.company.get('risklevels').createRecord({ description: "High", severity: this.catastrophic, probability: this.improbable, id: 0.24 });
      this.rl_car = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.catastrophic, probability: this.remote, id: 0.25 });
      this.risklevels = [this.rl_nf, this.rl_np, this.rl_no, this.rl_ni, this.rl_nr, this.rl_mf, this.rl_mp, this.rl_mo, this.rl_mi, this.rl_mr, this.rl_sf, this.rl_sp, this.rl_so, this.rl_si, this.rl_sr, this.rl_cf, this.rl_cp, this.rl_co, this.rl_ci, this.rl_cr, this.rl_caf, this.rl_cap, this.rl_cao, this.rl_cai, this.rl_car];

      this.product = this.store.createRecord('product', { name: "Example product", id: 'example', phase: 'product_definition', company: this.company, severities: this.severities, probabilities: this.probabilities, risklevels: this.risklevels });

      this.riskManagement = [this.negligible, this.minor, this.serious, this.critical, this.catastrophic, this.remote, this.improbable, this.occasional, this.probable, this.frequent, this.rl_nf, this.rl_np, this.rl_no, this.rl_ni, this.rl_nr, this.rl_mf, this.rl_mp, this.rl_mo, this.rl_mi, this.rl_mr, this.rl_sf, this.rl_sp, this.rl_so, this.rl_si, this.rl_sr, this.rl_cf, this.rl_cp, this.rl_co, this.rl_ci, this.rl_cr, this.rl_caf, this.rl_cap, this.rl_cao, this.rl_cai, this.rl_car];
    },
    teardown: function teardown() {
      $('#products-dropdown-wormhole .dropdown').removeClass('active');
      $('.user-dropdown').removeClass('active');
      this.riskManagement.invoke('unloadRecord');
      this.product.unloadRecord();
    },


    steps: [{
      prepare: function prepare() {
        this.transitionTo("app.products.product.dashboard", this.product);
        $($('.tabs-nav a')[0]).click();
        return this.waitFor('.tab-nav-wrapper');
      },

      text: "Hello, Quality Owners. As administrators of the QMS, you have special abilities within the Enzyme QMS application that are unavailable to all other users.<br><br>We start here in the <b>Dashboard</b> with <u>Company Status</u>, where you can see the total number of: <ul><li>Pending Change Requests</li><li>Outstanding Training Assignments</li><li>and Missing Signatures</li></ul><br>We'll look at each of these in order.",
      selector: '.dashboard--status',
      animationRoot: '.product-liquid-outlet'
    }, {
      prepare: function prepare() {
        $($('.tabs-nav a')[3]).click();
        return this.waitFor('.training-section');
      },

      text: "Clicking on <b>Total Outstanding Training</b> in the status page or <b>All Training</b> above in the tab bar brings you here.<br><br>This page shows you the status of every Training Assignment ever issued in your company. Because that will become a large number very quickly, you can filter by User and by Status at the top.<br><br>The three statuses to filter by are: <ul><li>Completed</li><li>Outstanding (not complete, not yet overdue)</li><li>and Overdue</li></ul><br>You can also manually <u>mark any training as complete</u>. This is useful if you have in-person or instructor-led training, for instance.",
      selector: '.dashboard-tabs',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        if ($('.popup-header .fa-close').length > 0) {
          $('.popup-header .fa-close').click();
        }
      },

      text: "When you click on <b>Assign</b> you are able to manually assign training to any arbitrary set of users.",
      selector: '.filter-container button',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        $($('.tabs-nav a')[3]).click();
        $('.filter-container button').click();
        return this.waitFor('.ember-modal-dialog');
      },

      text: "Any user who requests a new document to be approved can assign training when they upload a new document revision.<br><br>You can also set up training for documents here (for instance, if you have a new hire.<br>Additionally, <u>this is the only place to assign <b>In-App Training</b>, such as the one you are currently taking.</u><br><br>In addition to choosing <b>Due Date</b> and <b>Selecting Users</b> you can also <b>Add Questions</b> to each training assignment, which will create a quiz that users must pass with a score of > 80% to complete their training assignment.",
      selector: '.ember-modal-dialog',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        if ($('.popup-header .fa-close').length > 0) {
          $('.popup-header .fa-close').click();
        }
        this.transitionTo("app.products.product.design-control", this.product);
        $('#products-dropdown-wormhole .dropdown').removeClass('active');
        return this.waitFor(function () {
          return $('#products-dropdown-wormhole .dropdown').css('visibility') === "hidden";
        });
      },

      text: "From here, we will explore the Product menu.<br><br>As Quality Owners, you have the sole ability to: <ul><li>Create new Products</li><li>Change Product names</li><li>Move the product phase backward (for example, in case you've failed design verification, or design transfer)<li>Clone Products (useful for line extensions)</li><li>Delete Products</li></ul>",
      selector: '#products-dropdown-wormhole'
    }, {
      prepare: function prepare() {
        $('#products-dropdown-wormhole .dropdown').addClass('active');
        return this.waitFor(function () {
          return $('#products-dropdown-wormhole .dropdown').css('visibility') === "visible";
        });
      },

      text: "After clicking on the Product menu you will see <b>Product Controls</b>. <br><ul><li>Cloning is accomplished by clicking the 'two documents' icon</li><li>Addition via the plus icon</li><li>Editing via the pencil icon</li><li>Deletion by the trash icon</li>",
      selector: '#products-dropdown-wormhole .dropdown'
    }, {
      prepare: function prepare() {
        $('#products-dropdown-wormhole .dropdown').removeClass('active');
        $('.user-dropdown').removeClass('active');
        return this.waitFor(function () {
          return $('#products-dropdown-wormhole .dropdown').css('visibility') === "hidden" && $('.user-dropdown').css('visibility') === "hidden";
        });
      },

      text: "Next, to the User menu, where you'll find the other functions available only to Quality Owners.",
      selector: '.ph-zone.f-right'
    }, {
      prepare: function prepare() {
        $('.user-dropdown').addClass('active');
        return this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "visible";
        });
      },

      text: "These functions are: <br><ul><li>User Administration</li><li>Company Details</li><li>Severity/Probabilility Levels</li><li>Risk Levels</li><li>Approval Settings</li><li>Billing Portal Access</li></ul>",
      selector: '.user-dropdown',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        $('.user-dropdown').addClass('active');
        return this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "visible";
        });
      },

      text: "User Administation allows you to create users, set permission levels, reset passwords, and inactivate users.",
      selector: '.user-dropdown .clear-ul li.user-admin'
    }, {
      prepare: function prepare() {
        $('.user-dropdown').removeClass('active');
        this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "hidden";
        });
        return this.transitionTo("app.products.product.users", this.product);
      },

      text: "You create new users with <b>Create User</b>, and each individual user can be modified by clicking <b>Edit</b>. <br><br>This page will display not only Active Users but also Deleted Users. <br><br>To maintain the audit trail, the information of every user who performs an action in the QMS is retained indefinitely, even though their accounts are disabled and cannot be used.",
      selector: '.user-management'
    }, {
      prepare: function prepare() {
        if ($('.popup-header .fa-close').length > 0) {
          $('.popup-header .fa-close').click();
        }
        $('.user-dropdown').addClass('active');
        return this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "visible";
        });
      },

      text: "Next is Company Admin, which allows you to set the company logo",
      selector: '.user-dropdown .clear-ul li.company-admin',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        $($('.user-dropdown .clear-ul a')[2]).click();
        return this.waitFor('.ember-modal-dialog');
      },

      text: "Set and view the current logo here",
      selector: '.ember-modal-dialog',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        if ($('.popup-header .fa-close').length > 0) {
          $('.popup-header .fa-close').click();
        }
        $('.user-dropdown').addClass('active');
        return this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "visible";
        });
      },

      text: "Next is setting your company's <b>Severity and Probability Levels</b>.<br><br>Generally this is a task you do only a handful of times, and Enzyme QMS comes with a default set of severities and probabilities corresponding to international standard ISO 14971.<br><br>However, you are free to set your own levels at your discretion.",
      selector: '.user-dropdown .clear-ul li.severity-probability',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        this.transitionTo("app.products.product.risk-management.harms.levels", this.product);
        return this.waitFor('.ember-modal-dialog');
      },

      text: "You can change the content of each of the 5 Severity and Probability values here.",
      selector: '.card-info',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        $('.popup-header .fa-close').click();
        $('.user-dropdown').addClass('active');
        return this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "visible";
        });
      },

      text: "After you've set your Severity and Probability levels, you need to assign Risk Levels to each combination of Severity and Probability.<br><br>You do that in the Risk Matrix, accessed here.",
      selector: '.user-dropdown .clear-ul li.risk-matrix',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        this.transitionTo("app.products.product.risk-management.harms.matrix", this.product);
        return this.waitFor('.ember-modal-dialog');
      },

      text: "In the Risk Matrix you can select a <b>Risk Level</b> of Low, Moderate or High, for each combination of Severity/Probability. <br><br>Like values for Severity and Probability, Enzyme QMS comes with a default set of risk values, derived from international standard ISO 14971.",
      selector: '.ember-modal-dialog',
      scrollTop: '0px',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        $('.popup-header .fa-close').click();
        $('.user-dropdown').addClass('active');
        return this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "visible";
        });
      },

      text: "Next in the User dropdown is <b>Approval Settings</b>.<br>Here is where you set permissions for who can and cannot approve changes to your design.",
      selector: '.user-dropdown .clear-ul li.approval-settings',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        $('.user-dropdown').removeClass('active');
        return this.transitionTo("app.products.product.approvers", this.product);
      },

      text: "Here, in <b>Approval Settings</b>, you will notice two sections: one titled 'Approval Settings' and the other 'Approval Matrix'.<br><br><u>Approval Settings</u> is where you make changes, <u>Approval Matrix</u> is where you see their impact.<br><br>Enzyme QMS approvals are based on a three-tiered system: <ul><li>Required</li><li>Optional</li><li>Can't Approve</li></ul><br>When a change request is routed for approval, it is not approved until: <ul><li><u>EVERY</u> one of the <u>Required</u> approvers have approved AND</li><li>AT LEAST ONE of the <u>Optional</u> approvers have approved.</li><br>By mixing and matching Required and Optional approvers you can give a class of data any set of approval conditions you want.",
      selector: '.container',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        $('.user-dropdown').removeClass('active');
      },

      text: "The matrix, here, will show you how the changes you make above impact Approval Settings.<br>Users listed in bold are <u>Required</u> approvers, all other users are <u>Optional</u> approvers.",
      selector: '.approval-matrix-table',
      scrollTop: '500px'
    }, {
      prepare: function prepare() {
        $('.user-dropdown').addClass('active');
        return this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "visible";
        });
      },

      text: "This link will allow you to access the billing portal for your company.",
      selector: '.user-dropdown .clear-ul li.billing',
      scrollTop: '0px',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        $($('.user-dropdown .clear-ul a')[10]).click();
        $('.user-dropdown').removeClass('active');
        return this.waitFor('.ember-modal-dialog');
      },

      text: "The link here will take you away from the QMS toward the Enzyme Billing Portal, which is a separate application. <br><br>You can change your payment settings and view past and present invoices at the Billing Portal.",
      selector: '.ember-modal-dialog .inner-space',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        var _this = this;

        $('.popup-header .fa-close').click();
        return this.transitionTo("app.products.product.dashboard", this.product).then(function () {
          $($('.tabs-nav a')[0]).click();
          return _this.waitFor(function () {
            return $('.animated-tab:last').css('visibility') === "visible";
          });
        });
      },

      text: "This concludes Quality Owner training!",
      selector: 'body',
      animationDelay: 300
    }]
  };
});