define('frontend/models/risklevel', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    company: belongsTo('company'),
    description: attr('string'),
    severity: belongsTo('severity'),
    probability: belongsTo('probability')
  });
});