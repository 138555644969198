define('frontend/components/file-uploader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fileUpload: null,

    label: 'Upload New File',
    for: 'qms-document-file-upload-new',

    onFileChange: function onFileChange() {}
  });
});