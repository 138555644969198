define('frontend/components/changeset-form-for/mobiledoc-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MobiledocField = Ember.Component.extend({
    tagName: '',
    changeset: Ember.computed.reads('f.changeset'),
    allowFileUploads: true
  });

  MobiledocField.reopenClass({ positionalParams: ['field'] });

  exports.default = MobiledocField;
});