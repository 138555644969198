define('frontend/components/qms-integrations/link-to-pull-request', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LinkToPullRequest = Ember.Component.extend({
    store: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('repos', []);

      this.get('fetchTask').perform();
    },


    fetchTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.t0 = this;
              _context.next = 3;
              return this.get('store').findAll(this.get('integrationName') + '-repo');

            case 3:
              _context.t1 = _context.sent;

              _context.t0.set.call(_context.t0, 'repos', _context.t1);

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    reposForProduct: Ember.computed('repos.[]', 'webhooks.@each.repoId', function () {
      if (!this.get('repos.length') || !this.get('webhooks.length')) {
        return [];
      }

      var productRepoIds = this.get('webhooks').mapBy('repoId');

      return this.get('repos').filter(function (repo) {
        return productRepoIds.includes(repo.get('id'));
      });
    }),

    reposByLogin: Ember.computed('reposForProduct.[]', function () {
      return this.get('reposForProduct').reduce(function (memo, repo) {
        var login = repo.get('ownerLogin');
        var object = memo.findBy('login', login);

        if (!object) {
          object = { login: login, repos: [], avatarUrl: repo.get('ownerAvatarUrl') };
          memo.push(object);
        }
        object.repos.push(repo);
        return memo;
      }, []);
    }),

    selectedRepoPullRequests: Ember.computed('selectedRepo', function () {
      return this.get('selectedRepo.' + this.get('integrationName') + 'PullRequests');
    }),

    linkPullRequest: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(pullRequest) {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              this.set('designControl.' + this.get('integrationName') + 'PullRequestUrl', pullRequest.get('htmlUrl'));

              _context2.next = 3;
              return this.get('designControl').saveWithNotifications('The pull request was linked', 'The pull request could not be linked');

            case 3:

              this.get('closeModal')();

            case 4:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    }))
  });

  LinkToPullRequest.reopenClass({
    positionalParams: ['integrationName']
  });

  exports.default = LinkToPullRequest;
});