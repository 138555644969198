define('frontend/components/qms-integrations/zendesk', ['exports', 'frontend/components/qms-integrations/webhook'], function (exports, _webhook) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _webhook.default.extend({
    provider: 'zendesk',

    webhook: Ember.computed.alias('product.zendeskWebhook')
  });
});