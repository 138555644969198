define('frontend/mixins/tracable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      computed = Ember.computed;
  exports.default = Mixin.create({
    hasMissingDependencies: computed('tracingDependencies.[]', function () {
      var _this = this;

      if (this.get('approvalState') === "draft") {
        return true;
      }

      return this.get('tracingDependencies').any(function (tracingDependency) {
        return !_this.get(tracingDependency).get('length') || _this.get(tracingDependency).any(function (dependency) {
          return dependency.get(_this.get('tracingDependencyObjects')[tracingDependency]).get('approvalState') !== "approved";
        });
      });
    })
  });
});