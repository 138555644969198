define('frontend/components/complaints/column-triage-assessment', ['exports', 'frontend/models/complaint'], function (exports, _complaint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['column-label'],
    classNameBindings: ['row.triageAssessment', 'row.triageAssessment::not-triaged'],
    triageAssessmentLabel: computed('row.triageAssessment', function () {
      var i = Object.keys(_complaint.triageAssessments).indexOf(this.get('row.triageAssessment'));
      if (i >= 0) {
        return i + 1;
      } else {
        return 'Not Triaged';
      }
    }),

    triageAssessmentText: computed('row.triageAssessment', function () {
      var defaultText = "This complaint has not been triaged yet";
      return _complaint.triageAssessments[this.get('row.triageAssessment')] || defaultText;
    })
  });
});