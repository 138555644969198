define('frontend/components/enzyme-datepicker', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['enzyme-datepicker'],
    /**
     * The class name applied to the input field.
     *
     * @type {String}
     */
    fieldClass: 'form-field--control date-chooser',

    /**
     * The class name applied to the "clear" button (if `allowClear` is true).
     *
     * @type {String}
     */
    clearClass: 'enzyme-datepacker-clear',

    /**
     * Adds a "clear" button to the datepicker. Make sure to pass `clearClass`
     * to style the button.
     *
     * @type {Boolean}
     */
    allowClear: false,

    dateFormat: "YYYY-MM-DD",
    placeholder: "Enter Date",

    actions: {
      showPicker: function showPicker(dropdown) {
        dropdown.actions.open();
      },
      inputDate: function inputDate(event) {
        var value = event.target.value;
        var parsed = (0, _moment.default)(value, this.get('dateFormat'));
        if (parsed.format(this.get('dateFormat')) !== value) {
          this.set('error', "Invalid date format");
        } else {
          this.set('error', null);
          this.get('onSelect')(parsed.toDate());
          this.set('center', parsed);
        }
      },
      onSelect: function onSelect(date) {
        this.get('onSelect')(date);
        this.set('error', null);
      }
    }
  });
});