define("frontend/components/capa-entry-form", ["exports", "frontend/utils/full-array-mutate"], function (exports, _fullArrayMutate) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['capa-entry-form'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.computed.reads('session.currentUser'),
    company: Ember.computed.reads('user.company'),
    changeset: Ember.computed.alias('f.changeset'),

    approversById: Ember.computed('company.approvers.[]', function () {
      var arr = [];
      this.get('company.approvers').forEach(function (approver) {
        arr[approver.id] = approver;
      });
      return arr;
    }),

    changeCapaElementsSummary: (0, _fullArrayMutate.default)('capaElementsSummary'),
    changeCapaSolutionsSummary: (0, _fullArrayMutate.default)('capaSolutionsSummary'),
    changeCapaElementResultsSummary: (0, _fullArrayMutate.default)('capaElementResultsSummary'),

    actions: {
      editCapaElement: function editCapaElement(i, field, value) {
        this.changeCapaElementsSummary(function (array) {
          var element = array[i];
          Ember.set(element, field, value);
        });
      },
      editCapaSolution: function editCapaSolution(i, field, value) {
        this.changeCapaSolutionsSummary(function (array) {
          var element = array[i];
          Ember.set(element, field, value);
        });
      },
      editCapaElementResult: function editCapaElementResult(i, field, value) {
        this.changeCapaElementResultsSummary(function (array) {
          var element = array[i];
          Ember.set(element, field, value);
        });
      },
      editCapaElementId: function editCapaElementId(i, field, value) {
        this.changeCapaElementsSummary(function (array) {
          var element = array[i];
          Ember.set(element, field, value.id);
        });
      },
      updateAttachments: function updateAttachments(attachments) {
        this.set('newAttachments', attachments);
      },
      newElement: function newElement() {
        var elementNumber = this.get('changeset').get('capaElementsSummary.length') + 1;
        var newElement = { step: elementNumber };
        this.changeCapaElementsSummary(function (array) {
          return array.pushObject(newElement);
        });
      },
      deleteElement: function deleteElement(i) {
        this.changeCapaElementsSummary(function (array) {
          return array.removeAt(i);
        });
      },
      newSolution: function newSolution() {
        var solutionNumber = this.get('changeset').get('capaSolutionsSummary.length') + 1;
        var newSolution = { step: solutionNumber };
        this.changeCapaSolutionsSummary(function (array) {
          return array.pushObject(newSolution);
        });
      },
      deleteSolution: function deleteSolution(i) {
        this.changeCapaSolutionsSummary(function (array) {
          return array.removeAt(i);
        });
      }
    }
  });
});