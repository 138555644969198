define('frontend/components/form-controls/reset', ['exports', 'ember-form-for/components/form-controls/reset'], function (exports, _reset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _reset.default;
    }
  });
});