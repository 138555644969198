define('frontend/components/qms-integrations/github', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    githubRepos: Ember.computed(function () {
      return this.get('store').findAll('github-repo');
    }),

    actions: {
      save: function save() {
        this.get('company').saveWithNotifications();
      }
    }
  });
});