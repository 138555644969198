define('frontend/components/show-approval-set-approver-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Status = Ember.Component.extend({});

  Status.reopenClass({ positionalParams: ['approvalSet'] });

  exports.default = Status;
});