define('frontend/components/tab-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['tab-nav'],
    currentTab: computed.reads('tabs.firstObject'),

    actions: {
      setTab: function setTab(tab) {
        if ((this.get('disabledTabs') || []).includes(tab)) {
          return;
        }
        if (this.get('onchange')) {
          this.sendAction('onchange', tab);
        } else {
          this.set('currentTab', tab);
        }
      }
    }
  });
});