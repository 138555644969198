define('frontend/routes/app/products/product/risk-management/hazards/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    viewOptions: Ember.inject.service(),

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var product = this.modelFor('app.products.product');
      var subsystem = this.get('viewOptions.selectedSubsystem');

      controller.setProperties({
        product: product,
        subsystem: subsystem,
        newHazardDescription: null,
        displayErrors: false
      });
    }
  });
});