define('frontend/models/qms-document-type', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    company: (0, _relationships.belongsTo)('company'),
    product: (0, _relationships.belongsTo)('product'),
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    lastDocumentNumber: (0, _attr.default)('number'),
    requireTrainingPlan: (0, _attr.default)('boolean'),
    assignedDocumentNumbers: (0, _attr.default)(),
    unassignableDocumentNumbers: (0, _attr.default)(),

    qmsDocuments: (0, _relationships.hasMany)('qms-documents'),
    qmsDocumentTypeApprovers: (0, _relationships.hasMany)('qms-document-type-approvers'),
    trainingPlan: (0, _relationships.belongsTo)('training-plan', { inverse: 'qmsDocumentType' }),

    replaceWith: (0, _relationships.belongsTo)('qms-document-type'),
    deletedAt: (0, _attr.default)('date'),
    shortName: Ember.computed.reads('title')
  });
});