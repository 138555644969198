define('frontend/components/file-upload', ['exports', 'ember-uploader'], function (exports, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({
    filesDidChange: function filesDidChange(files) {
      if (!Ember.isEmpty(files)) {
        this.sendAction('action', files[0]);
      }
    }
  });
});