define('frontend/helpers/notification-card-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.notificationCardType = notificationCardType;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var DEFAULT_PREFIX = 'notification-cards/';

  function notificationCardType(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        item = _ref2[0],
        _ref2$ = _ref2[1],
        prefix = _ref2$ === undefined ? true : _ref2$;

    if (item) {
      var modelName = Ember.get(item, 'constructor.modelName');
      var cardType = '' + DEFAULT_PREFIX + modelName + '-card';
      var owner = Ember.getOwner(item);

      if (!owner || !owner.factoryFor('component:' + cardType)) {
        cardType = DEFAULT_PREFIX + 'default-approval-card';
      }

      if (!prefix) {
        return cardType.replace(DEFAULT_PREFIX, '');
      }

      return cardType;
    }
  }

  exports.default = Ember.Helper.helper(notificationCardType);
});