define('frontend/helpers/merge-query-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mergeQueryParams = mergeQueryParams;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // If you have a params array that you are passing to a link-to, and the last
  // element of that array may or may not be query params, and you want to merge
  // in some specific query param into that last element or create the last element
  // if there are no query params already, well this is the helper for you!

  function mergeQueryParams(_ref, newQP) {
    var _ref2 = _slicedToArray(_ref, 1),
        params = _ref2[0];

    params = params.slice();

    var existingQP = Ember.get(params, 'lastObject.isQueryParams') ? params.pop() : {};

    params.push({
      isQueryParams: true,
      values: Ember.assign({}, existingQP.values, newQP)
    });

    return params;
  }

  exports.default = Ember.Helper.helper(mergeQueryParams);
});