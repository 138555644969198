define('frontend/utils/find-selected-repos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (provider) {
    return Ember.computed(provider + 'Repos.@each.id', provider + 'Webhooks.@each.repoId', function () {
      var repos = this.get(provider + 'Repos');
      var webhooks = this.get(provider + 'Webhooks');
      return Ember.RSVP.hash({ repos: repos, webhooks: webhooks }).then(function (_ref) {
        var repos = _ref.repos,
            webhooks = _ref.webhooks;

        var repoIds = webhooks.mapBy('repoId');
        if (repoIds.length > 0) {
          return repos.filter(function (repo) {
            return repoIds.includes(Ember.get(repo, 'id'));
          }).naturalSortBy('name');
        }
      });
    });
  };
});