define('frontend/components/pop-up', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['pop-up'],

    popper: null,

    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.createPopper();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('popper').destroy();
    },
    createPopper: function createPopper() {
      var _getSources = this.getSources(),
          reference = _getSources.reference,
          popper = _getSources.popper;

      var options = this.getOptions();

      (false && !(reference && popper) && Ember.assert('A reference and popup source must be specified', reference && popper));


      var popperInstance = new Popper(reference, popper, options);

      this.set('popper', popperInstance);

      return popper;
    },
    getSources: function getSources() {
      var elementId = '#' + this.elementId;

      var referenceSelector = elementId + ' .reference';
      var reference = this.element.querySelector(referenceSelector);

      var popperSelector = elementId + ' .popper';
      var popper = this.element.querySelector(popperSelector);

      return { reference: reference, popper: popper };
    },
    getOptions: function getOptions() {
      var placement = 'bottom';
      var offset = { offset: '0,10' };
      var modifiers = { offset: offset };
      var options = { placement: placement, modifiers: modifiers };

      return options;
    }
  });
});