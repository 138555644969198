define('frontend/models/training', ['exports', 'ember-data', 'frontend/tours/design-control-module', 'frontend/tours/viewer-design-control-module', 'frontend/tours/document-control-module', 'frontend/tours/viewer-document-control-module', 'frontend/tours/introduction-dashboard-training', 'frontend/tours/quality-owner-functions', 'frontend/tours/risk-management-module'], function (exports, _emberData, _designControlModule, _viewerDesignControlModule, _documentControlModule, _viewerDocumentControlModule, _introductionDashboardTraining, _qualityOwnerFunctions, _riskManagementModule) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tours = undefined;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var tours = exports.tours = [_designControlModule.default, _viewerDesignControlModule.default, _documentControlModule.default, _viewerDocumentControlModule.default, _introductionDashboardTraining.default, _qualityOwnerFunctions.default, _riskManagementModule.default];

  exports.default = Model.extend({
    dueDate: attr('date-no-time'),
    createdAt: attr('string'),
    tourName: attr('string'),
    qmsDocument: belongsTo('qms-document', { inverse: 'trainings' }),
    company: belongsTo('company'),
    trainingQuestions: hasMany('training-question'),
    trainingAssignments: hasMany('training-assignment'),
    approvalRole: belongsTo('approval-role'),
    documentVersionType: attr('string'),
    documentVersionString: attr('string'),
    taskDescription: attr('string'),
    documentVersion: belongsTo('version'),
    attachments: hasMany('attachment', { inverse: 'attachable' }),
    requester: belongsTo('user'),
    source: attr('string', { defaultValue: 'non_plan' }),

    isInApp: Ember.computed.bool('tourName'),
    isDoc: Ember.computed.bool('qmsDocument.id'),
    isTask: Ember.computed.bool('taskDescription'),

    tourData: Ember.computed('tourName', function () {
      return tours.findBy('name', this.get('tourName'));
    }),

    description: Ember.computed('qmsDocument.title', 'tourName', 'taskDescription', function () {
      if (this.get('qmsDocument.title')) {
        var effectiveDocTitle = this.get('qmsDocument.effectiveAttributes.title') || this.get('qmsDocument.title');
        return this.get('qmsDocument.docIdentifier') + ' ' + effectiveDocTitle;
      } else if (this.get('taskDescription')) {
        return this.get('taskDescription');
      } else {
        return 'In-App: ' + this.get('tourName');
      }
    })
  });
});