define('frontend/components/pill-creator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    didInsertElement: function didInsertElement() {
      this.$("input").autocomplete({
        source: this.get('options') || []
      });
    },

    actions: {
      save: function save() {
        var _this = this;

        this.get('create')(this.get('tagText')).then(function () {
          return _this.set('tagText', '');
        });
      }
    }
  });
});