define('frontend/router', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('register');
    this.route('login');

    this.route('onboarding', function () {
      this.route('invite');
      this.route('product', function () {
        this.route('subsystem', {
          path: ':product_id/subsystem'
        });
        this.route('new');
      });
    });

    this.route('app', { path: '/app', resetNamespace: true }, function () {
      this.route('products', function () {
        this.route('product', { path: '/:product_id' }, function () {
          this.route('edit');
          this.route('delete');

          this.route('design-review', function () {
            this.route('index', { path: '/' });
            this.route('edit', { path: '/:design_review_id' });
          });

          this.route('dashboard', { path: '/' });

          this.route('training');

          this.route('design-control', function () {
            this.route('show', { path: '/:item_type/:item_id' }, function () {
              this.route('link-circleci-build', { path: 'linkCircleCiBuild' });
              this.route('link-travisci-build', { path: 'linkTravisCiBuild' });
              this.route('link-jenkins-build', { path: 'linkJenkinsBuild' });
            });
          });

          this.route('document-control', { path: '/documents' }, function () {
            this.route('document-item', { path: '/:qms_document_id' }, function () {
              this.route('edit');
              this.route('revise');
              this.route('obsolete');
              this.route('delete');
            });
            this.route('document-type', function () {
              this.route('delete');
              this.route('list');
            });
            this.route('new');
          });

          this.route('risk-management', {}, function () {
            this.route('hazards', function () {
              this.route('new');
              this.route('edit', { path: '/:hazard_id/edit' });
            });

            this.route('hazardous-situations', function () {
              this.route('new');
              this.route('edit', { path: '/:hazardous_situation_id/edit' });
            });

            this.route('harms', function () {
              this.route('new');
              this.route('update');
              this.route('levels');
              this.route('matrix');
              this.route('edit', { path: '/:harm_instance_id/edit' });
            });
          });
          this.route('approvers');
          this.route('craq');

          this.route('integrations');

          this.route('users', function () {
            this.route('new');
            this.route('edit', { path: "/:user_id/edit" });
            this.route('permissions');
          });

          this.route('subsystems', function () {
            this.route('new');
            this.route('edit', { path: "/:subsystem_id/edit" });
          });
          this.route('complaints', function () {
            this.route('new');
            this.route('complaint', { path: '/:complaint_id' }, function () {
              this.route('link-zendesk');
            });
          });
          this.route('change-requests', function () {
            this.route('change-request', { path: '/:approval_set_id' });
          });
          this.route('supplier-quality', function () {
            this.route('new');
            this.route('supplier', { path: '/:supplier_id' });
          });
          this.route('audits', function () {
            this.route('new');
            this.route('audit', { path: '/:audit_id' });
          });
          this.route('non-conformances', function () {
            this.route('new');
            this.route('non-conformance', { path: '/:non_conformance_id' });
          });
          this.route('capas', function () {
            this.route('capa', { path: '/:capa_id' });
          });
        });
      });
    });
    this.route('forgot_password');
    this.route('reset_password');

    this.route('complaint', { path: "/products/:product_id/complaint" });

    this.route('oauth', { path: "/oauth" });

    this.route('not_found', { path: '/*wildcard', resetNamespace: true });

    if (_environment.default.environment === 'development' || _environment.default.environment === 'test') {
      this.route('styleguide');

      this.route("test", function () {
        this.route('approvable-display');
      });
    }
  });

  exports.default = Router;
});