define("frontend/components/changeset-form-for/buttons", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    canEdit: Ember.computed.reads('session.currentUser.canEdit'),
    classNames: ['changeset-form-buttons'],

    saveText: "Save Changes",
    savingText: "Saving…",
    cancelText: "Cancel Changes",

    showCancelButton: Ember.computed('changeset.isDirty', 'model.isNew', 'canEdit', 'alwaysShowButtons', function () {
      // Only show the cancel button if the model is dirty, and also it doesn't
      // make much sense for object creation because it'll just clear everything
      return (this.get('changeset.isDirty') || this.get('alwaysShowButtons')) && !this.get('model.isNew') && this.get('canEdit');
    }),

    // Always show the save button for creating a record, because it might not be
    // clear what to do after completeing the form otherwise. For existing records,
    // you don't want to show it unless it's dirty because you might just want to
    // look at the record without changing anything
    showSaveButton: Ember.computed('changeset.isDirty', 'model.isNew', 'canEdit', 'alwaysShowButtons', function () {
      return (this.get('changeset.isDirty') || this.get('alwaysShowButtons') || this.get('model.isNew')) && this.get('canEdit');
    }),

    disableButtons: Ember.computed('changeset.isDirty', 'model.isSaving', function () {
      return !this.get('changeset.isDirty') || this.get('model.isSaving');
    })
  });
});