define('frontend/routes/app/products/product/risk-management', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.modelFor('app.products.product').get('liveHazards');
    },


    actions: {
      closeModal: function closeModal() {
        this.transitionTo('app.products.product.risk-management');
      }
    }
  });
});