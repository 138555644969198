define('frontend/controllers/app/products/product/capas', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    productController: Ember.inject.controller('app.products.product'),
    currentCompany: Ember.computed.reads('session.currentUser.company'),
    exportSupplierDataButtonIsDisabled: Ember.computed.alias('qmsExportsPoller.qmsExportInProgress'),
    queryParams: ['showCancelled'],
    showCancelled: false,

    searchText: Ember.computed.reads('productController.searchText'),

    columns: Ember.computed(function () {
      return [{
        component: "capas/status-and-number-column",
        title: 'Status',
        sortBy: 'id',
        key: 'humanizedApprovalState',
        filter: {
          type: 'list',
          options: ['Open', 'Closed', 'Cancelled']
        }

      }, {
        component: "capas/description-column",
        title: 'Description and Owner',
        sortBy: 'owner.fullName',
        grow: true
      }, {
        component: "capas/phase-column",
        title: 'Phases',
        grow: true
      }, {
        component: "capas/metadata-column",
        title: 'Opened On',
        sortBy: 'createdAt'
      }, {
        component: "capas/links-column",
        title: 'NC',
        sortBy: 'nonConformance.id'
      }];
    }),

    persistedCapas: Ember.computed('model.@each.{isNew,cancelledAt}', 'showCancelled', function () {
      var _this = this;

      return this.get('model').filter(function (capa) {
        return !capa.get('isNew') && (_this.get('showCancelled') || !capa.get('cancelledAt'));
      });
    }),

    actions: {
      viewCapa: function viewCapa(capa) {
        this.transitionToRoute('app.products.product.capas.capa', capa);
      }
    }

  });
});