define('frontend/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'frontend/mixins/loading-slider'], function (exports, _applicationRouteMixin, _loadingSlider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _loadingSlider.default, {
    tour: Ember.inject.service(),
    bubbleLoadingSlider: true,

    actions: {
      goBack: function goBack() {
        (false && !(false) && Ember.deprecate("The `goBack` action has been deprecated. In almost all cases, it's better to use an explicit route to navigate to instead", false, { id: 'go-back-action', until: "It's gone" }));

        history.back();
      },
      willTransition: function willTransition(transition) {
        // cancel transitions if there's an active tour, but allow the tour to transition
        if (this.get('tour.currentStep') && !this.get('tour.isTourTransitioning')) {
          transition.abort();
        }
      },
      recordDeletedViaPush: function recordDeletedViaPush() /* record */{
        // Do nothing here - if this gets called, it means no active route cares
        // about this action and it has bubbled
      },
      changeRequestSaved: function changeRequestSaved() /* change request*/{
        // Do nothing here - if this gets called, it means no active route cares
        // about this action and it has bubbled

      }
    }
  });
});