define('frontend/components/integration-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),

    classNames: ['integration-link__wrapper'],

    unlink: function unlink() {}
  });
});