define('frontend/routes/app/products/product/risk-management/harms/levels', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Rollsback the model if it exists.
   * 
   * @param {DS.Model} model
   */
  function rollback(model) {
    if (!model) {
      return;
    }

    var id = model.get('id');
    var hasId = id >= 1;

    if (hasId) {
      model.rollbackAttributes();
    }
  }

  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      var product = this.modelFor('app.products.product');
      var riskLevels = product.get('riskLevels');
      var severities = product.get('company.productSeverities');
      var probabilities = product.get('probabilities');

      controller.setProperties({
        product: product,
        riskLevels: riskLevels,
        severities: severities,
        probabilities: probabilities,
        changedSeverities: [],
        changedProbabilities: []
      });
    },
    resetController: function resetController() {
      var product = this.modelFor('app.products.product');
      var severities = product.get('company.productSeverities').toArray();
      var probabilities = product.get('probabilities').toArray();
      var models = severities.concat(probabilities);

      models.forEach(rollback);
    }
  });
});