define("frontend/controllers/app/products/product/training", ["exports", "frontend/macros/natural-sort"], function (exports, _naturalSort) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    user: Ember.computed.alias('session.currentUser'),
    list: Ember.inject.service(),
    showTrainingMatrix: true,
    showTrainingPlanMatrix: true,
    isAssigningTraining: false,
    isAssigningTrainingPlan: false,

    assignmentId: null,
    queryParams: ['assignmentId', 'trainingTab'],

    trainingTab: 'My Training Record',

    filteredTrainings: Ember.computed.filterBy('model', 'isObsoleted', false),
    sortedTrainings: Ember.computed.sort('filteredTrainings', 'modelSorting'),
    modelSorting: ['createdAt:desc'],

    overdueTrainingAssignments: Ember.computed.filterBy('sortedTrainings', 'isOverdue'),
    completedTrainingAssignments: Ember.computed.filterBy('sortedTrainings', 'isCompleted'),
    outstandingTrainingAssignments: Ember.computed.filterBy('sortedTrainings', 'isOutstanding'),
    failedTrainingAssignments: Ember.computed.filterBy('sortedTrainings', 'isFailed'),

    sortedQmsDocuments: (0, _naturalSort.default)('list.nonObsoleteQmsDocuments', 'documentSorting'),
    documentSorting: ['docIdentifier'],
    sortedQmsDocumentDescriptions: Ember.computed.mapBy('sortedQmsDocuments', 'docIdentifier'),

    tabList: Ember.computed('user.isOwner', 'user.manages', function () {
      if (this.get('user.isOwner')) {
        if (this.get('user.manages')) {
          return ["My Training Record", "My Team's Training Status", "Company Training Status", "Recurring Training"];
        } else {
          return ["My Training Record", "Company Training Status", "Recurring Training"];
        }
      } else if (this.get('user.manages')) {
        return ["My Training Record", "My Team's Training Status"];
      } else {
        return ["My Training Record"];
      }
    }),

    allTrainingAssignments: Ember.computed(function () {
      return this.get('store').findAll('training-assignment');
    }),

    allTrainingAssignmentUsers: Ember.computed.mapBy('allTrainingAssignments', 'user'),

    filteredTrainingAssignments: Ember.computed('selectedTrainingStatus', 'selectedUser', 'user', 'trainingTab', 'allTrainingAssignmentUsers.@each.isActive', 'allTrainingAssignments.@each.status', function () {

      // The next line is required to make sure the computed property is consumed,
      // otherwise it won't trigger DK invalidation, so don't remove it unless
      // the CP is consumed elsewhere
      this.get('allTrainingAssignmentUsers');

      var userId = this.get('selectedUser.id'),
          currentUserId = this.get('user.id'),
          status = this.get('selectedTrainingStatus'),
          onAllTraining = this.get('trainingTab') == "Company Training Status";

      return this.get('allTrainingAssignments').filter(function (assignment) {
        return (!userId || assignment.get('user.id') === userId) && (!status || assignment.get('filterStatus') === status) && assignment.get('status') !== 'obsoleted' && assignment.get('status') !== 'pending' && assignment.get('user.isActive') && (onAllTraining || assignment.get('user.manager.id') === currentUserId);
      });
    }),

    trainingStatuses: ['completed', 'outstanding', 'overdue'],

    allTrainingPlans: Ember.computed(function () {
      return this.get('store').findAll('training-plan');
    }),

    filteredTrainingPlans: Ember.computed('allTrainingPlans.[]', 'selectedQmsDocument', 'selectedRole', function () {

      var roleId = this.get('selectedRole.id'),
          docId = this.get('selectedQmsDocument.id');

      return this.get('allTrainingPlans').filter(function (plan) {
        return (!roleId || plan.get('approvalRoleIds').includes(roleId)) && (!docId || plan.get('qmsDocument.id') === docId);
      });
    }),

    users: Ember.computed(function () {
      return this.get('store').peekAll('user');
    }),

    approvalRoles: Ember.computed(function () {
      return this.get('store').peekAll('approval-role').filterBy('isInactive', false).naturalSortBy('name');
    }),

    actions: {
      onCurrentAssignmentChanged: function onCurrentAssignmentChanged(newCurrentAssignmentId) {
        this.set('assignmentId', newCurrentAssignmentId);
      },
      closeTrainingModal: function closeTrainingModal() {
        this.set('isAssigningTraining', false);
      },
      closeTrainingPlanModal: function closeTrainingPlanModal() {
        this.set('isAssigningTrainingPlan', false);
      }
    }
  });
});