define('frontend/helpers/file-format', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fileFormat = fileFormat;
  function fileFormat(params /*, hash*/) {
    if (params[0]) {
      return params[0].split('?')[0].split('.').pop();
    }
  }

  exports.default = Ember.Helper.helper(fileFormat);
});