define('frontend/components/approver-select', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var PromiseArray = _emberData.default.PromiseArray;
  exports.default = Ember.Component.extend({
    classNames: ['approver-select'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    isAdminCr: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.sendChangeAction();
    },


    approvers: Ember.computed('approvables.[]', 'isAdminCr', function () {
      var _this = this;

      if (Array.isArray(this.get('approvables')) && !this.get('approvables')[0]) {
        return [];
      }

      if (!this.get('approvables.length')) {
        return [];
      }

      var approvables = this.get('approvables').map(function (approvable) {
        // Could be either a string spec, or a reified approvable
        if (typeof approvable === 'string') {
          var _approvable$split = approvable.split(":"),
              _approvable$split2 = _slicedToArray(_approvable$split, 2),
              type = _approvable$split2[0],
              id = _approvable$split2[1];

          return { type: type, id: id };
        } else {
          return {
            id: approvable.get('id'),
            type: approvable.get('constructor.modelName')
          };
        }
      });

      var data = {
        is_admin_cr: this.get('isAdminCr'),
        approvables: approvables
      };

      this.sendAction('startLoad');

      var promise = this.get('session').authorizedAjaxRequest('/api/change_requests/approvers', { data: data }).then(function (result) {
        return result.approvers.map(function (spec) {
          return {
            approver: _this.get('store').peekRecord(spec.type, spec.id),
            conjunctive: spec.conjunctive
          };
        });
      }).catch(function (error) {
        _this.sendAction('loadErrorsChanged', error.responseJSON.errors);
      });

      promise.finally(function () {
        return _this.sendAction('endLoad');
      });
      promise.finally(function () {
        return Ember.run.next(_this, 'sendChangeAction');
      });

      return PromiseArray.create({ promise: promise });
    }),

    approverBlacklist: Ember.computed('existingBlacklist.[]', 'groupedApprovers.[]', function () {
      if (this.get('existingBlacklist.length') && this.get('groupedApprovers.length')) {
        var allSpecs = this.get('groupedApprovers').mapBy('options').flatten();
        return this.get('existingBlacklist').map(function (existing) {
          return allSpecs.find(function (spec) {
            return spec.approver.id == existing.userId && spec.roleId == existing.roleId;
          });
        });
      } else {
        return [];
      }
    }),

    groupedApprovers: Ember.computed('approvers.[]', function () {
      var approvers = this.get('approvers');

      var individualApprovers = approvers.filterBy('approver.isRole', false);

      var roleGroups = approvers.filterBy('approver.isRole').map(function (spec) {
        var options = Ember.get(spec, 'approver.approvers').toArray().map(function (user) {
          return {
            approver: user,
            conjunctive: spec.conjunctive,
            roleName: Ember.get(spec, 'approver.name'),
            roleId: Ember.get(spec, 'approver.id')
          };
        }).naturalSortBy('roleName', 'approver.fullName');

        return {
          groupName: Ember.get(spec, 'approver.name'),
          conjunctive: spec.conjunctive,
          options: options
        };
      });

      if (individualApprovers.length) {
        return [].concat(_toConsumableArray(roleGroups), [{
          groupName: "Individual Approvers",
          options: individualApprovers.naturalSortBy('approver.fullName')
        }]);
      } else {
        return [].concat(_toConsumableArray(roleGroups));
      }
    }),

    selectedApprovers: Ember.computed('groupedApprovers.[]', 'approverBlacklist.[]', function () {
      var _this2 = this;

      var specs = [];

      this.get('groupedApprovers').forEach(function (group) {
        group.options.forEach(function (spec) {
          if (!_this2.get('approverBlacklist').includes(spec)) {
            specs.push(spec);
          }
        });
      });

      this.sendAction('blacklistChanged', this.get('approverBlacklist'), specs);

      return specs;
    }),

    sendChangeAction: function sendChangeAction() {
      this.sendAction('blacklistChanged', this.get('approverBlacklist'), this.get('selectedApprovers'));
    },


    actions: {
      addApproverToBlacklist: function addApproverToBlacklist(spec) {
        this.get('approverBlacklist').addObject(spec);
        this.sendChangeAction();
      },
      removeApproverFromBlacklist: function removeApproverFromBlacklist(spec) {
        this.get('approverBlacklist').removeObject(spec);
        this.sendChangeAction();
      },
      emptySelectedApprovers: function emptySelectedApprovers() {
        var _this3 = this;

        this.get('selectedApprovers').slice().forEach(function (approver) {
          _this3.get('approverBlacklist').addObject(approver);
        });
        this.sendChangeAction();
      }
    }

  });
});