define('frontend/components/training-plan-matrix', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isAssigningTrainingPlan: false,
    chosenPlan: null,

    classNames: ['training-matrix-wrapper'],

    approvalRoleArrrays: Ember.computed.mapBy('trainingPlans', 'approvalRoles'),

    flattenedApprovalRoles: Ember.computed('approvalRoleArrrays.@each.[]', function () {
      return this.get('approvalRoleArrrays').flatten();
    }),

    roles: Ember.computed('flattenedApprovalRoles.@each.{id,name}', function () {
      return this.get('flattenedApprovalRoles').uniqBy('id').naturalSortBy('name');
    }),

    rolesAndPlans: Ember.computed('roles.[]', 'headers.[]', function () {
      var _this = this;

      return this.get('roles').map(function (role) {
        return {
          role: role,
          plans: _this.findPlans(role)
        };
      });
    }),

    findPlans: function findPlans(role) {
      var _this2 = this;

      return this.get('headers.all').map(function (trainingName) {
        return _this2.get('trainingPlans').find(function (plan) {
          return plan.get('approvalRoleIds').includes(role.get('id')) && plan.get('description') === trainingName;
        });
      });
    },


    headers: Ember.computed('trainingPlans.@each.{isDoc,isTask,description,docIdentifier}', function () {
      var documentHeaders = this.get('trainingPlans').filterBy('isDoc').uniqBy('qmsDocument.id').naturalSortBy('qmsDocument.docIdentifier').mapBy('description');

      var taskHeaders = this.get('trainingPlans').filterBy('isTask').mapBy('description').uniq().naturalSort();

      return {
        document: documentHeaders,
        task: taskHeaders,
        all: documentHeaders.concat(taskHeaders)
      };
    }),

    actions: {
      updatePlan: function updatePlan(plan) {
        this.set('chosenPlan', plan);
        this.set('isAssigningTrainingPlan', true);
      },
      removePlan: function removePlan(plan) {
        var check = confirm('You are about to delete this recurring training. Are you sure?');
        if (check) {
          plan.destroyWithNotifications();
        }
      },
      closeTrainingPlanModal: function closeTrainingPlanModal() {
        this.set('isAssigningTrainingPlan', false);
        this.set('chosenPlan', null);
      }
    }
  });
});