define('frontend/components/enzyme-metrics', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      this.set('metrics', {});
      this.set('startDate', (0, _moment.default)().subtract(1, 'year').toDate());
      this.set('endDate', (0, _moment.default)().toDate());
    },


    itemMeasured: '',
    metric: "Average Age of Change Request (days)",
    filter: "All",
    interval: "Month",

    metricChoices: Ember.computed('metric', 'filter', 'interval', 'startDate', 'endDate', function () {
      return {
        metric: this.get('metric'),
        filter: this.get('filter'),
        interval: this.get('interval'),
        startDate: this.get('startDate'),
        endDate: this.get('endDate')
      };
    }),

    filterOptions: Ember.computed('metric', function () {
      return ["All"]; // only all currently working
    }),

    metricOptions: ["Average Age of Change Request (days)", "Number of Complaints Opened", "Average Open Age of Complaints (days)"],
    intervals: ["Month"],

    ajax: Ember.inject.service(),

    chartLabels: Ember.computed('metrics', function () {
      return Object.keys(this.get('metrics')).reverse();
    }),

    chartType: 'bar',
    chartOptions: {
      responsive: true,
      title: {
        display: true,
        text: 'Click Calculate to generate Metrics'
      },
      tooltips: {
        mode: 'index',
        intersect: true
      },
      scales: {
        yAxes: [{
          type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
          display: true,
          position: 'left',
          id: 'y-axis-1',
          ticks: {
            beginAtZero: true
          }
        }]
      },
      legend: {
        display: false
      }
    },

    chartWidth: '500',
    chartHeight: '500',
    chartData: Ember.computed('metrics', 'chartLabels', function () {
      var labels = this.get('chartLabels');
      var metrics = this.get('metrics');
      var colorPalette = ['rgb(141, 182, 199)', 'rgb(193, 179, 142)', 'rgb(209, 198, 191)', 'rgb(202, 159, 146)', 'rgb(249, 205, 151)', 'rgb(227, 217, 176)', 'rgb(177, 194, 122)', 'rgb(178, 226, 137)', 'rgb(81, 192, 191)', 'rgb(89, 173, 208)', 'rgb(112, 149, 225)', 'rgb(159, 163, 227)', 'rgb(201, 147, 212)', 'rgb(209, 141, 178)'];
      while (labels.length > colorPalette.length) {
        var clonedColorPalette = colorPalette.slice(0);
        colorPalette = colorPalette.concat(clonedColorPalette);
      }
      var yAxisID = 'y-axis-1';
      var data = labels.map(function (x) {
        return metrics[x];
      });
      var datasets_1 = {
        label: this.get('itemMeasured'),
        backgroundColor: colorPalette,
        yAxisID: yAxisID,
        data: data
      };
      var datasets = [datasets_1];
      return { 'labels': labels, 'datasets': datasets };
    }),

    actions: {
      generateMetrics: function generateMetrics() {
        var componentScope = this;
        this.get('ajax').request('/api/metrics', {
          type: 'POST',
          data: { metricChoices: this.get('metricChoices') }
        }).then(function (response) {
          componentScope.set('metrics', response['metrics']);
          componentScope.set('itemMeasured', response['item_measured']);
          componentScope.get('childViews')[1].get('chart').options.title.text = componentScope.get('metricChoices')['metric'];
          componentScope.get('childViews')[1].get('chart').data.datasets[0].label = response['item_measured'];
          componentScope.get('childViews')[1].get('chart').update();
        });
      },
      downloadImage: function downloadImage() {
        var canvas = this.get('element').querySelector('canvas');
        var filename = "metrics_download.png";
        var lnk = document.createElement('a'),
            e;
        /// the key here is to set the download attribute of the a tag
        lnk.download = filename;

        /// convert canvas content to data-uri for link. When download
        /// attribute is set the content pointed to by link will be
        /// pushed as "download" in HTML5 capable browsers
        lnk.href = canvas.toDataURL("image/png;base64");

        /// create a "fake" click-event to trigger the download
        if (document.createEvent) {
          e = document.createEvent("MouseEvents");
          e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);

          lnk.dispatchEvent(e);
        } else if (lnk.fireEvent) {
          lnk.fireEvent("onclick");
        }
      }
    }
  });
});