define('frontend/macros/filter-by-subsystem', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (arrayKey) {
    return Ember.computed(arrayKey + '.@each.{subsystem,isApproved,name,description}', 'selectedSubsystem', 'searchText', 'viewOptions.approvedModeOn', 'focusedCard', function () {
      var _this = this;

      var arr;
      if (!this.get('selectedSubsystem')) {
        arr = this.get(arrayKey);
      } else {
        arr = this.get(arrayKey).filterBy('subsystem.id', this.get('selectedSubsystem.id'));
      }

      if (this.get('focusedCard')) {
        arr = arr.filter(function (dc) {
          return dc.isRelatedTo(_this.get('focusedCard')) || dc === _this.get('focusedCard');
        });
      }

      if (this.get('viewOptions.approvedModeOn')) {
        arr = arr.filterBy('hasBeenApproved');
      }

      if (Ember.isBlank(this.get('searchText'))) {
        return arr;
      }

      var retArray = [];

      var searchText = this.get('searchText');

      var compareSearchTextToLowerCase = function compareSearchTextToLowerCase(string) {
        if (string && searchText) {
          return string.toLowerCase().includes(searchText.toLowerCase());
        }
        return false;
      };

      arr.forEach(function (model) {
        var desc = model.get('description');
        if (typeof desc !== 'string') {
          desc = model.get('descriptionText') || '';
        }
        if (compareSearchTextToLowerCase(model.get('idDescon')) || compareSearchTextToLowerCase(model.get('name')) || compareSearchTextToLowerCase(desc)) {
          retArray.push(model);
        } else if (model.get('taggings.length') > 0) {
          var tags = model.get('taggings').toArray();
          for (var i = 0; i < tags.length; ++i) {
            if (compareSearchTextToLowerCase(tags[i].get('tag.name'))) {
              retArray.push(model);
              break;
            }
          }
        }
      });

      return retArray;
    });
  };
});