define("frontend/models/training-request", ["exports", "frontend/models/training", "ember-data/model", "ember-data/attr", "ember-data/relationships", "frontend/mixins/attachable"], function (exports, _training, _model, _attr, _relationships, _attachable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_attachable.default, {
    dueDate: (0, _attr.default)('date-no-time'),
    tourName: (0, _attr.default)('string'),
    qmsDocument: (0, _relationships.belongsTo)('qms-document', { inverse: 'trainingRequests' }),
    trainingQuestions: (0, _relationships.hasMany)('training-question'),
    trainingAssignments: (0, _relationships.hasMany)('training-assignment'),
    users: (0, _relationships.hasMany)('user'),
    approvalRole: (0, _relationships.belongsTo)('approval-role'),
    documentVersionType: (0, _attr.default)('string', { defaultValue: 'effective' }),
    taskDescription: (0, _attr.default)('string'),
    dontEditTraining: (0, _attr.default)('boolean'),
    requester: (0, _relationships.belongsTo)('user'),

    tourData: Ember.computed('tourName', function () {
      return _training.tours.findBy('name', this.get('tourName'));
    })
  });
});