define('frontend/models/capa-element', ['exports', 'ember-data', 'ember-data/relationships', 'ember-data/attr'], function (exports, _emberData, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    assignee: (0, _relationships.belongsTo)('user'),
    company: (0, _relationships.belongsTo)(),
    capaPhase: (0, _relationships.belongsTo)(),
    step: (0, _attr.default)('number'),
    description: (0, _attr.default)('mobiledoc'),
    results: (0, _attr.default)('mobiledoc'),
    targetDate: (0, _attr.default)('date-no-time'),
    completionDate: (0, _attr.default)('date-no-time'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),

    shortName: Ember.computed('step', function () {
      return 'Element: ' + this.get('step');
    }),

    paddedId: Ember.computed('id', function () {
      if (this.get('id')) {
        return this.get('id').padStart(4, '0');
      }
    })
  });
});