define('frontend/components/trello-board-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    colorStyle: Ember.computed('color', function () {
      var color = this.get('color');
      if (color) {
        return Ember.String.htmlSafe('color: ' + this.get('color') + ';');
      }
    })
  });
});