define('frontend/routes/app/products/product/document-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    list: Ember.inject.service(),

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('model', this.get('list.qmsFolders'));
    }
  });
});