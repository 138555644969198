define('frontend/components/attachments-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),

    model: null,

    init: function init() {
      this._super();
      this.set('newAttachments', []);
    },
    updateAttachments: function updateAttachments() {},


    attachments: Ember.computed('model', 'model.attachments.[]', 'newAttachments.[]', function () {
      var currentAttachments = [];
      if (this.get('model.attachments')) {
        currentAttachments = this.get('model.attachments').toArray();
      }
      var newAttachments = this.get('newAttachments');
      var attachments = currentAttachments.concat(newAttachments).uniq();

      return attachments;
    }),

    removeAddedAttachment: function removeAddedAttachment(attachment) {
      if (attachment.get('isNew')) {
        attachment.deleteRecord();
      } else {
        attachment.destroyRecord();
      }

      var newAttachments = this.get('newAttachments');
      newAttachments.removeObject(attachment);
      this.get('updateAttachments')(newAttachments);
    },
    createAttachment: function createAttachment(file) {
      var attachment = this.get('store').createRecord('attachment', { file: file });

      return attachment;
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var newAttachments = this.get('newAttachments');
      newAttachments.clear();
      this.get('updateAttachments')(newAttachments);
    },


    actions: {
      addAttachment: function addAttachment(file) {
        var attachment = this.createAttachment(file);
        var newAttachments = this.get('newAttachments');
        newAttachments.pushObject(attachment);
        this.get('updateAttachments')(newAttachments);
      },
      removeAttachment: function removeAttachment(attachment) {
        this.removeAddedAttachment(attachment);
      }
    }
  });
});