define('frontend/components/file-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    type: 'file',
    change: function change(e) {
      if (e.target.files.length < 1) {
        return;
      }
      this.sendAction('fileChanged', e.target.files[0]);
    }
  });
});