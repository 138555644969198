define('frontend/components/tr-qms-document-version', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['qms-document-version'],

    model: null,

    filePresent: Ember.computed.or('model.processedFileUpload.fileUrl', 'model.fileUpload.fileUrl'),

    onViewAttachments: function onViewAttachments() {}
  });
});