define('frontend/controllers/app/products/product/risk-management/harms/levels', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function computedModelIsDirty(key) {
    return Ember.computed(key, {
      get: function get() {
        var modelName = key.split('.').get('firstObject');
        var model = this.get(modelName);

        return model.filter(this._hasDirtyAttributes);
      },
      set: function set(_key, value) {
        return value;
      }
    });
  }

  exports.default = Ember.Controller.extend(_emberValidations.default, {
    session: Ember.inject.service(),

    displayErrors: false,

    isQualityOwner: Ember.computed.alias('session.currentUser.isQualityOwner'),
    changedSeverities: computedModelIsDirty('severities.@each.{name,description}'),
    changedProbabilities: computedModelIsDirty('probabilities.@each.{name,description}'),

    actions: {
      updateLevels: function updateLevels() {
        var isQualityOwner = this.get('isQualityOwner');

        if (isQualityOwner) {
          var changedSeverities = this.get('changedSeverities');
          var changedProbabilities = this.get('changedProbabilities');
          var modelsToSave = changedSeverities.concat(changedProbabilities);

          modelsToSave.forEach(this._saveWithNotifications);

          this.transitionToRoute('app.products.product.risk-management');
        }
      }
    },

    _hasDirtyAttributes: function _hasDirtyAttributes(model) {
      return model.get('hasDirtyAttributes') === true;
    },
    _saveWithNotifications: function _saveWithNotifications(model) {
      model.saveWithNotifications();
    }
  });
});