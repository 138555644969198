define('frontend/utils/native-click', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (selector) {
    var el = document.querySelector(selector);

    run(function () {
      return triggerMouseEvent(el, 'mousedown');
    });

    focus(el);

    run(function () {
      return triggerMouseEvent(el, 'mouseup');
    });
    run(function () {
      return triggerMouseEvent(el, 'click');
    });
  };

  var run = Ember.run;


  function triggerMouseEvent(node, eventType) {
    var clickEvent = document.createEvent('MouseEvents');
    clickEvent.initEvent(eventType, true, true);
    node.dispatchEvent(clickEvent);
  }
});