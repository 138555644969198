define('frontend/models/complaint', ['exports', 'ember-data', 'frontend/mixins/approvable', 'frontend/initializers/add-model-async-methods', 'frontend/mixins/model-meta-data', 'moment', 'frontend/mixins/nc-capa-source'], function (exports, _emberData, _approvable, _addModelAsyncMethods, _modelMetaData, _moment, _ncCapaSource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.triageAssessments = exports.rootCauseCategories = exports.genders = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var genders = exports.genders = ['male', 'female'];

  var rootCauseCategories = exports.rootCauseCategories = ['Design', 'Labeling', 'Manufacturing', 'Not Confirmed', 'User / Use Error', 'User Preference Issue', 'Supplier Design', 'Supplier Manufacture', 'Supplier Labeling', 'Wear and Tear', 'Caused by Other', 'Handling Damage', 'Operational Context', 'Undeterminable', 'Anticipated Procedural Complication', 'Other'];

  var triageAssessments = exports.triageAssessments = {
    not_complaint: "Level 1 - Does not meet the definition of a complaint",
    no_device_malfunction_injury_or_dsi: "Level 2 - Complaint with unlikely device malfunction (device, labeling or packaging failure to meet specification), injury or DSI",
    non_serious_injury_no_malfunction: "Level 3 - Complaint with non-serious injury; unlikely device malfunction or DSI",
    possible_malfunction_non_serious_injury: "Level 4 - Complaint with possible device malfunction, with or without non-serious injury, without DSI (Investigate or provide rationale)",
    dsi: "Level 5 - Complaint with or without possible device malfunction, with DSI (Investigation Required)"
  };

  var actionMap = {
    reported: [{ action: 'open_investigation', icon: 'folder-open-o', confirmText: "open an investigation into this complaint?" }],
    complaint_closed: [{ action: 'reopen_complaint', icon: 'folder-open-o', confirmText: "reopen this complaint?" }]
  };

  exports.default = _emberData.default.Model.extend(_approvable.default, _ncCapaSource.default, _modelMetaData.default, {
    disableManualChangeRequest: true,
    reportedByPublic: attr('boolean'),
    closedAt: attr('date'),

    // public complaint fields
    eventDate: attr('date-no-time'),
    awareDate: attr('date-no-time'),
    procedureDate: attr('date-no-time'),
    sourceName: attr('string'),
    sourceType: attr('string'),
    otherSourceType: attr('string'),
    hospital: attr('string'),
    address: attr('string'),
    state: attr('string'),
    postCode: attr('string'),
    country: attr('string'),
    phoneNumber: attr('string'),
    email: attr('string'),
    eventDescription: attr('string'),
    eventDescriptionFileUploads: hasMany('file-uploads'),
    additionalInformation: attr('string'),
    additionalInformationFileUploads: hasMany('file-upload'),
    isProductFeedback: attr('boolean'),
    isMedicalFeedback: attr('boolean'),
    isConnectedToDeath: attr('boolean'),
    isConnectedToInjury: attr('boolean'),
    wasInterventionPerformed: attr('boolean'),
    clinicalConditionFollowup: attr('string'),
    patientGender: attr('string'),
    patientAge: attr('number'),
    medicalHistory: attr('string'),
    lotOrBatch: attr('string'),
    productUpn: attr('string'),

    quantity: attr('number'),
    createdAt: attr('date'),

    wasPatientOrUserInjured: attr('boolean'),
    patientOrUserInjuryExplanation: attr('string'),
    // internal complaint record fields
    isProductComplaint: attr('boolean'),
    nonComplaintRationale: attr('string'),
    isMedicalDeviceReportable: attr('boolean'),
    willInvestigate: attr('boolean', { defaultValue: false }),
    noInvestigationRationale: attr('string'),
    stage: attr('string'),
    triageAssessment: attr('string'),
    reportabilityDecision: attr('string'),
    relatedComplaints: attr('string'),
    asReportedCodes: hasMany(),
    asAnalyzedCodes: hasMany(),

    // complaint investigation fields
    productAnalysisResults: attr('string'),
    productReturned: attr('boolean'),
    productReturnDescription: attr('string'),
    productReturnDescriptionFileUploads: hasMany('file-uploads'),
    complaintConfirmed: attr('boolean'),
    historiesReview: attr('string'),
    similarComplaintsReview: attr('string'),
    productLabelingReview: attr('string'),
    rootCause: attr('string'),
    rootCauseCategory: attr({ defaultValue: function defaultValue() {
        return [];
      } }),
    rootCauseAddressed: attr('boolean'),
    riskAnalysisReview: attr('string'),
    requiresEscalation: attr('boolean'),
    escalationStatement: attr('string'),
    correctiveActionComplete: attr('boolean'),
    returnedProductLotOrBatch: attr('string'),
    correctiveActionExplanation: attr('string'),
    productAnalysisFileUploads: hasMany('file-uploads'),
    riskAnalysisQmsDocuments: hasMany('qms-document'),
    productAnalysisQmsDocuments: hasMany('qms-document'),

    initialProductReturnRequestDate: attr('date-no-time'),
    firstFollowUpRequestDate: attr('date-no-time'),
    secondFollowUpRequestDate: attr('date-no-time'),
    productReturnKitShippingDate: attr('date-no-time'),
    productReturnKitTrackingNumber: attr('string'),
    productReturnKitAddressee: attr('string'),
    productReturnKitShippingAddress: attr('string'),
    productReturnDate: attr('date-no-time'),
    productReturnTrackingNumber: attr('string'),
    returnedProductInspection: attr('string'),

    session: Ember.inject.service('session'),

    // Relationships
    product: belongsTo(),
    company: belongsTo(),
    zendeskTicket: belongsTo(),
    returnedProductInspectionFileUploads: hasMany('file-uploads'),

    openInvestigation: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'open_investigation', type: 'post' }),
    submitForApprovalAndClosure: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'close_complaint', type: 'post' }),
    reopenComplaint: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'reopen_complaint', type: 'post' }),
    markAsFeedbackOnly: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'mark_as_feedback_only', type: 'post' }),

    // CPs
    shortName: Ember.computed('id', function () {
      return 'Complaint #' + this.get('id');
    }),

    stageActions: Ember.computed('stage', function () {
      return actionMap[this.get('stage')];
    }),

    canBeApproved: Ember.computed('stage', function () {
      return this.get('stage') == 'reported' || this.get('stage') == 'investigation_open';
    }),

    sourceDescription: Ember.computed('shortName', 'sourceName', function () {
      if (this.get('sourceName')) {
        return this.get('shortName') + ' (from #' + this.get('sourceName') + ')';
      } else {
        return this.get('shortName');
      }
    }),

    daysOpen: Ember.computed('stage', 'closedAt', 'awareDate', 'createdAt', function () {
      var compareDate = void 0,
          awareDate = void 0;

      if (this.get('stage') === 'complaint_closed' && this.get('closedAt')) {
        compareDate = (0, _moment.default)(this.get('closedAt'));
      } else {
        compareDate = (0, _moment.default)();
      }
      awareDate = this.get('createdAt');

      return compareDate.diff(awareDate, 'days');
    }),

    daysAware: Ember.computed('stage', 'closedAt', 'awareDate', 'createdAt', function () {
      var compareDate = void 0,
          awareDate = void 0;

      if (this.get('stage') === 'complaint_closed' && this.get('closedAt')) {
        compareDate = (0, _moment.default)(this.get('closedAt'));
      } else {
        compareDate = (0, _moment.default)();
      }
      awareDate = this.get('awareDate') || this.get('createdAt');

      return compareDate.diff(awareDate, 'days');
    }),

    stageColor: Ember.computed('stage', function () {
      return {
        reported: 'red',
        feedback_only: 'green',
        investigation_open: 'orange',
        complaint_closed: 'green'
      }[this.get('stage')];
    }),

    humanizedApprovalState: Ember.computed('approvalState', function () {
      return {
        draft: "Draft",
        create_requested: "Pending Approval",
        destroy_requested: "Pending Approval",
        update_requested: "Pending Approval",
        approved: "Approved"
      }[this.get('approvalState')];
    }),

    canEdit: Ember.computed('isPendingApprovalOrDestroyRequested', 'reportedByPublic', 'hasNeverBeenApproved', function () {
      if (this.get('isPendingApprovalOrDestroyRequested')) {
        return false;
      }

      if (this.get('reportedByPublic') && this.get('hasNeverBeenApproved')) {
        return false;
      }

      if (this.get('session.currentUser.isViewer')) {
        return false;
      }

      return true;
    }),

    routableTarget: 'app.products.product.complaints.complaint'

  });
});