define('frontend/models/subsystem-approver', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var not = Ember.computed.not;
  exports.default = _model.default.extend({
    conjunctive: (0, _attr.default)('boolean'),
    approver: (0, _relationships.belongsTo)('approver', { polymorphic: true }),
    subsystem: (0, _relationships.belongsTo)('subsystem'),
    // CPs
    disjunctive: not('conjunctive')
  });
});