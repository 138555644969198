define('frontend/components/billing-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Component.extend({
    metrics: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var hostname = location.hostname;
      var portalReq = md5(hostname);
      var theComponent = this;
      Ember.$.getJSON("https://billingcreds.enzyme.io/portal/" + portalReq).done(function (data) {
        theComponent.set('notCalledYet', false);
        theComponent.set('portalId', data.id);
        if (data.id === "") {
          theComponent.set('noBillingInfo', true);
        }
      });
    },


    session: inject.service('session'),
    user: computed.alias('session.currentUser'),
    company: computed.alias('user.company'),

    notCalledYet: true,

    noBillingInfo: false,

    portalId: "",

    errors: [],
    displayErrors: false,
    closeBillingSettingsModalAction: 'closeBillingSettingsModal',
    actions: {
      closeBillingSettingsModal: function closeBillingSettingsModal() {
        this.sendAction('closeBillingSettingsModalAction');
      },
      openBillingPortal: function openBillingPortal() {
        var portalId = this.get('portalId');
        window.open('https://billing.enzyme.io/client/dashboard/' + portalId);
        this.get('metrics').trackEvent({ event: 'opened-billing_portal', portalId: portalId });
      }
    }
  });
});