define("frontend/components/approvable-select", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var sortKeys = ['docIdentifier', 'title', 'shortName', 'descriptionText', 'description'];

  var filterByModelNames = function filterByModelNames(modelNames) {
    return Ember.computed('approvables.[]', function () {
      var _get$filter;

      return this.get('approvables') && (_get$filter = this.get('approvables').filter(function (approvable) {
        return modelNames.includes(approvable.constructor.modelName);
      })).naturalSortBy.apply(_get$filter, sortKeys);
    });
  };

  exports.default = Ember.Component.extend({
    placeholder: "Click to select",
    selectedItemComponent: 'approvable-select/selected-item',

    triggerId: Ember.computed('elementId', function () {
      return this.get('elementId') + "-power-select";
    }),

    groupedApprovables: Ember.computed('designApprovables.[]', 'documentApprovables.[]', 'riskApprovables.[]', 'otherApprovables.[]', function () {

      var grouped = [];

      if (this.get('designApprovables.length')) {
        grouped.push({
          groupName: 'Design',
          options: this.get('designApprovables')
        });
      }

      if (this.get('documentApprovables.length')) {
        grouped.push({
          groupName: 'Documents',
          options: this.get('documentApprovables')
        });
      }

      if (this.get('riskApprovables.length')) {
        grouped.push({
          groupName: 'Risk Management',
          options: this.get('riskApprovables')
        });
      }

      if (this.get('otherApprovables.length')) {
        grouped.push({
          groupName: 'Other',
          options: this.get('otherApprovables')
        });
      }

      return grouped;
    }),

    sortedSelected: Ember.computed('selected.[]', function () {
      var _this = this,
          _ref;

      var groups = ['designApprovables', 'documentApprovables', 'riskApprovables', 'otherApprovables'];
      var grouped = groups.map(function (group) {
        var _get$filter2;

        return (_get$filter2 = _this.get('selected').filter(function (item) {
          return _this.get(group).includes(item);
        })).naturalSortBy.apply(_get$filter2, sortKeys);
      });
      return (_ref = []).concat.apply(_ref, _toConsumableArray(grouped));
    }),

    designApprovables: filterByModelNames(['user-need', 'requirement', 'design-output', 'verification', 'validation']),

    documentApprovables: filterByModelNames(['qms-document']),

    riskApprovables: filterByModelNames(['hazard', 'harm', 'harm-instance', 'hazardous-situation']),

    otherApprovables: Ember.computed('approvables.[]', 'designApprovables.[]', 'documentApprovables.[]', 'riskApprovables.[]', function () {
      var _get$filter3,
          _this2 = this;

      return this.get('approvables') && (_get$filter3 = this.get('approvables').filter(function (approvable) {
        return !_this2.get('designApprovables').includes(approvable) && !_this2.get('documentApprovables').includes(approvable) && !_this2.get('riskApprovables').includes(approvable) && !approvable.get('disableManualChangeRequest');
      })).naturalSortBy.apply(_get$filter3, sortKeys);
    }),

    actions: {
      searchApprovables: function searchApprovables(query) {
        return this.get('groupedApprovables').map(function (group) {
          var filtered = group.options.filter(function (approvable) {
            if (matches(approvable.get('shortName'), query)) {
              return true;
            }

            // allow "un1" to match "UN-1"
            if (matches(approvable.get('shortName').replace(/-/g, ""), query)) {
              return true;
            }

            if (matches(approvable.get('name'), query)) {
              return true;
            }

            if (matches(approvable.get('title'), query)) {
              return true;
            }

            var description = approvable.get('hasDescriptionText') ? approvable.get('descriptionText') : approvable.get('description');
            if (matches(description, query)) {
              return true;
            }

            if (matches(approvable.get('docIdentifier'), query)) {
              return true;
            }
          });
          return { groupName: group.groupName, options: filtered };
        }).filter(function (group) {
          return group.options.length;
        });
      },
      itemCheckboxChecked: function itemCheckboxChecked(event) {
        event.preventDefault(); // checkbox checking handled by bindings
      }
    }
  });


  function matches(value, query) {
    return (value || "").toLowerCase().includes(query.toLowerCase());
  }
});