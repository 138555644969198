define('frontend/routes/app/products/product/design-review/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model() {
      return this.get('store').createRecord('design-review');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('model', model);

      if (this.get('session.currentUser.isViewer')) {
        controller.set('tab', 'View Prior');
      } else {
        controller.set('tab', 'Create New');
      }

      model.set('product', this.modelFor('app.products.product'));
      model.get('users').addObject(this.get('session.currentUser'));
    },
    willTransition: function willTransition() {
      this._super.apply(this, arguments);

      var designReviews = this.get('store').peekAll('design-review');

      if (designReviews) {
        var newDesignReviews = designReviews.filterBy('isNew');
        newDesignReviews.invoke('deleteRecord');
      }
    },


    actions: {
      closeModal: function closeModal() {
        this.transitionTo('app.products.product.design-control');
      }
    }
  });
});