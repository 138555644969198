define('frontend/components/training-matrix', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['training-matrix-wrapper'],

    users: computed('trainingAssignments.@each.user', function () {
      return this.get('trainingAssignments').mapBy('user').uniqBy('id').naturalSortBy('fullName');
    }),

    usersAndAssignments: computed('users.[]', 'headers.all.[]', function () {
      var _this = this;

      return this.get('users').map(function (user) {
        return {
          user: user,
          assignments: _this.findAssignments(user)
        };
      });
    }),

    findAssignments: function findAssignments(user) {
      var _this2 = this;

      return this.get('headers.all').map(function (trainingName) {
        return _this2.get('trainingAssignments').find(function (assignment) {
          return assignment.get('user.id') === user.get('id') && (assignment.get('training.tourName') === trainingName || assignment.get('training.description') === trainingName) && !assignment.get('isObsoleted');
        });
      });
    },


    headers: computed('trainingAssignments.@each.{isInApp,isDoc,isTask,description,tourName,documentType,documentNumber}', function () {
      var inAppHeaders = this.get('trainingAssignments').filterBy('isInApp').mapBy('tourName').uniq().naturalSort();

      var documentHeaders = this.get('trainingAssignments').filterBy('isDoc').uniqBy('qmsDocument.id').naturalSortBy('documentType', 'documentNumber').mapBy('description');

      var taskHeaders = this.get('trainingAssignments').filterBy('isTask').mapBy('description').uniq().naturalSort();

      return {
        inApp: inAppHeaders,
        document: documentHeaders,
        task: taskHeaders,
        all: inAppHeaders.concat(documentHeaders).concat(taskHeaders)
      };
    }),

    actions: {
      markAsIncomplete: function markAsIncomplete(assignment) {
        assignment.markAsIncomplete();
      },
      markAsReset: function markAsReset(assignment) {
        assignment.markAsReset();
      },
      markAsComplete: function markAsComplete(assignment) {
        assignment.markAsComplete();
      },
      removeAssignment: function removeAssignment(assignment) {
        assignment.removeAssignment();
      }
    }
  });
});