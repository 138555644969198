define('frontend/controllers/app/products/product/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PHASES = ['product_definition', 'verification_and_validation', 'design_transfer'];

  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    product: Ember.computed.alias('model'),
    killSwitchStates: ['active', 'disabled', 'force_upgrade', 'recall'],

    selectableProductPhases: Ember.computed('product.phase', function () {
      var currentIndex = PHASES.indexOf(this.get('product.phase'));

      return PHASES.slice(0, currentIndex + 1);
    }),

    actions: {
      save: function save() {
        var _this = this;

        var product = this.get('product');

        product.saveWithNotifications("Changes saved ready for change request").then(function () {
          return _this.transitionToRoute('app.products.product.design-control');
        });
      }
    }
  });
});