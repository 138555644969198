define('frontend/components/sort-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var sortPropertyOptions = [{ display: 'Sort by: Title', value: 'title' }, { display: 'Sort by: Document Identifier', value: 'docIdentifier' }];

  exports.default = Ember.Component.extend({
    sortDirection: 'asc',

    sortPropertyOptions: sortPropertyOptions,

    'on-sort': function onSort() {},
    init: function init() {
      this._super.apply(this, arguments);

      var defaultSortProperty = this.get('sortPropertyOptions.firstObject');
      this.set('sortProperty', defaultSortProperty);

      var sortDefinition = this.get('sortDefinition');
      this.get('on-sort')(sortDefinition);
    },


    sortDefinition: Ember.computed('sortProperty.value', 'sortDirection', function () {
      var sortProperty = this.get('sortProperty.value');
      var sortDirection = this.get('sortDirection');

      return [sortProperty + ':' + sortDirection];
    }),

    actions: {
      changeSortDirection: function changeSortDirection() {
        var newSortDirection = this.get('sortDirection') === 'asc' ? 'desc' : 'asc';
        this.set('sortDirection', newSortDirection);

        var sortDefinition = this.get('sortDefinition');
        this.get('on-sort')(sortDefinition);
      },
      sortItems: function sortItems() {
        var sortDefinition = this.get('sortDefinition');
        this.get('on-sort')(sortDefinition);
      }
    }
  });
});