define('frontend/components/hazards-table/group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    classNames: 'hazard-group table-3-body clearfix'.w(),
    classNameBindings: ['filteredRows.length::d-none', 'expanded:expanded'],
    expanded: false,

    rows: computed('hazard', 'hazard.liveHazardousSituations.@each.sortedLiveHarmInstances', function () {
      var hazard = this.get('hazard');

      var rows = [];

      eachOrOnce(hazard, 'liveHazardousSituations', function (hazardousSituation) {
        return eachOrOnce(hazardousSituation, 'sortedLiveHarmInstances', function (harmInstance) {
          return rows.push({
            hazard: hazard,
            hazardousSituation: hazardousSituation,
            harmInstance: harmInstance
          });
        });
      });

      return rows;
    }),

    filteredRows: computed('rows.[]', 'approvedModeOn', 'searchText', function () {
      var _this = this;

      var rows = this.get('rows');

      if (this.get('approvedModeOn')) {
        rows = rows.filter(function (row) {
          return allMatchPredicateOrAreNull(row, function (approvable) {
            return approvable.get('isApproved');
          });
        });
      }

      if (this.get('searchText.length')) {
        rows = rows.filter(function (row) {
          return anyMatchPredicate(row, function (approvable) {
            return anySearchTermsMatch(approvable, _this.get('searchText').toLowerCase());
          });
        });
      }

      return rows;
    }),

    rowsAfterFirstRow: computed('filteredRows.[]', function () {
      return this.get('filteredRows').slice(1);
    }),

    actions: {
      expand: function expand() {
        this.toggleProperty('expanded');
      }
    }
  });


  function eachOrOnce(object, arrayKey, callback) {
    var array = object && get(object, arrayKey);

    if (array && get(array, 'length')) {
      array.forEach(callback);
    } else {
      callback();
    }
  }

  function allMatchPredicateOrAreNull(row, predicate) {
    return 'hazard hazardousSituation harmInstance'.w().every(function (key) {
      return !get(row, key) || predicate(get(row, key));
    });
  }
  function anyMatchPredicate(row, predicate) {
    return 'hazard hazardousSituation harmInstance'.w().any(function (key) {
      return get(row, key) && predicate(get(row, key));
    });
  }

  function anySearchTermsMatch(approvable, searchText) {
    return "description severityLabel initialRiskLabel mitigatedRiskLabel initialProbabilityLabel mitigatedProbabilityLabel".w().any(function (termKey) {
      return approvable.get(termKey) && approvable.get(termKey).toLowerCase().includes(searchText);
    });
  }
});