define("frontend/components/changeset-form-for", ["exports", "ember-changeset", "ember-concurrency"], function (exports, _emberChangeset, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Form = Ember.Component.extend({
    classNames: ['changeset-form'],
    classNameBindings: ['disabled'],

    beforeUnload: Ember.inject.service(),

    changeset: Ember.computed('model', function () {
      return new _emberChangeset.default(this.get('model'));
    }),

    didInsertElement: function didInsertElement() {
      this.get('beforeUnload').addObject(this.get('changeset'));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('beforeUnload').removeObject(this.get('changeset'));
    },


    formId: Ember.computed('elementId', function () {
      return this.get('elementId') + "-form";
    }),

    executeChangeset: function executeChangeset() {
      // changeset errors have to be cleared here to allow it to execute
      this.set('changeset._errors', {});
      this.get('changeset').execute();
    },
    handleSave: function handleSave() {
      var _this = this;

      this.executeChangeset();

      return this.get('model').saveWithNotifications().then(function () {
        _this.get('changeset').rollback();
      }).catch(function () {
        _this.handleSaveErrors();
        return Ember.RSVP.reject("Saving changeset failed");
      });
    },
    handleSaveErrors: function handleSaveErrors() {
      var _this2 = this;

      this.get('model.errors').forEach(function (_ref) {
        var attribute = _ref.attribute,
            message = _ref.message;

        try {
          _this2.get('changeset').pushErrors(attribute, message);
        } catch (e) {
          // sometimes the server responds with errors we can't show on the client
          // in addition to ones we can, for example, "capa_phases.due_date"
          // this is fine because we show the error in a different way, however
          // trying to push an error with a . in the attribute name usually results
          // in an error unless the model has special handling for that. So the
          // simplest thing to do here is just try to set the error and catch any
          // failure
        }
      });
      // The errors have to be cleared here from the model because they are
      // copied onto the changeset. If they are not, it causes an intermittent
      // problem on saving. Annoying use of private api
      this.get('model._internalModel').clearErrorMessages();
    },


    // If you have something like `saveThenMemberAction` in a model, you'll want
    // to use this to apply the changeset first, then show errors correctly after
    handleExternalSave: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(callback) {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              this.executeChangeset();

              _context.prev = 1;
              _context.next = 4;
              return Ember.RSVP.resolve(callback());

            case 4:
              this.get('changeset').rollback();
              _context.next = 10;
              break;

            case 7:
              _context.prev = 7;
              _context.t0 = _context["catch"](1);

              this.handleSaveErrors();

            case 10:
            case "end":
              return _context.stop();
          }
        }
      }, _callee, this, [[1, 7]]);
    })).restartable(),

    actions: {
      save: function save(event) {
        var _this3 = this;

        event.stopPropagation();
        event.preventDefault();

        this.handleSave().then(function () {
          return _this3.sendAction('done');
        }).catch(function () {});
      },
      rollback: function rollback() {
        if (confirm("Are you sure you want to discard your changes?")) {
          this.get('changeset').rollback();
        }
      },
      closeForm: function closeForm() {
        var message = "Are you sure you want to leave without saving your changes?";
        if (this.get('changeset.isDirty') && !confirm(message)) {
          return;
        }
        this.get('changeset').rollback();
        if (!this.get('model.isNew')) {
          this.get('model').rollbackAttributes();
        }
        this.sendAction('done');
        this.sendAction('closeForm');
      }
    }
  });

  Form.reopenClass({
    positionalParams: ['model']
  });

  exports.default = Form;
});