define("frontend/tours/viewer-design-control-module", ["exports", "frontend/utils/build-mobile-doc", "frontend/utils/native-click"], function (exports, _buildMobileDoc, _nativeClick) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = {
    name: 'Design Control Module (Viewer)',

    setup: function setup() {
      var allproducts = this.store.peekAll('product');
      this.realproduct = allproducts.get('firstObject');
      var allcompanies = this.store.peekAll('company');
      this.company = allcompanies.get('firstObject');
      this.product = this.store.createRecord('product', { name: "Example product", id: 'example', phase: 'product_definition', company: this.company });
      // ids are decimals to both (a) not conflict with existing records and (b) be > 0, which is
      // required to be considered live and show on the dashboard

      this.designControls = [this.product.get('userNeeds').createRecord({ name: "First user need", sequentialId: 1, id: 0.1, description: (0, _buildMobileDoc.default)("This is an example of a user need card.") }), this.product.get('requirements').createRecord({ name: "First requirement", sequentialId: 1, id: 0.1, description: (0, _buildMobileDoc.default)("This is an example of a requirement card.") }), this.product.get('designOutputs').createRecord({ name: "First design output", sequentialId: 1, id: 0.1, description: (0, _buildMobileDoc.default)("This is an example of a design output card.") }), this.product.get('verifications').createRecord({ name: "First requirement", sequentialId: 1, id: 0.1, description: (0, _buildMobileDoc.default)("This is an example of a verification card.") }), this.product.get('validations').createRecord({ name: "First requirement", sequentialId: 1, id: 0.1, description: (0, _buildMobileDoc.default)("This is an example of a validation card.") })];
    },
    teardown: function teardown() {
      $('#undoApprovalTraining').click();
      this.designControls.invoke('unloadRecord');
      this.product.unloadRecord();
    },


    steps: [{
      prepare: function prepare() {
        return this.transitionTo("app.products.product.design-control", this.product);
      },

      text: "This is the <b>Design Control</b> module. <br><br>Design control is used to keep track of design data about your products.",
      selector: '.design-controls-container',
      animationRoot: '.product-liquid-outlet'
    }, {
      text: "You access the Design Control module by clicking <br>'Design Control' in the main navbar.",
      selector: '.main-nav'
    }, {
      prepare: function prepare() {
        $('#products-dropdown-wormhole .dropdown').removeClass('active');
        return this.waitFor(function () {
          return $('#products-dropdown-wormhole .dropdown').css('visibility') === "hidden";
        });
      },

      text: "Unlike the rest of the QMS, in Design Control you work within the scope of a single product. If you want to switch products...",
      selector: '#products-dropdown-wormhole'
    }, {
      prepare: function prepare() {
        $('#products-dropdown-wormhole .dropdown').addClass('active');
        return this.waitFor(function () {
          return $('#products-dropdown-wormhole .dropdown').css('visibility') === "visible";
        });
      },

      text: "After clicking on the Product menu you will be able to switch contexts to a different product by clicking on that product name.",
      selector: '#products-dropdown-wormhole .dropdown'
    }, {
      prepare: function prepare() {
        $('#products-dropdown-wormhole .dropdown').removeClass('active');
        return this.waitFor(function () {
          return $('#products-dropdown-wormhole .dropdown').css('visibility') === "hidden";
        });
      },

      text: "Design data is subdivided into <u>five classes</u> that correspond to different stages of your design",
      selector: '.main-column-header'
    }, {
      text: "<b>User Needs</b> and <b>Requirements</b> are “Design Inputs”.<br><br>“Design Inputs” are what you define to be definition of the design, what the design has to do and who it is for.<br><br><b>User Needs</b> are <u>voice-of-customer</u> and usually fairly abstract. There are usually a number of different ways to accomodate any given user need.<br><br><b>Requirements</b> are <u>instructions to the engineering team</u>: 'what does this product have to do in order to be considered ready for market?'",
      selector: '.design-control-column:nth-child(1) .main-column-header, .design-control-column:nth-child(2) .main-column-header'
    }, {
      text: "<b>Design Outputs</b> are the components of your design. If you were going to hand off your design to a contract manufacturer and say “build this” what you would hand off are design outputs. <br><br>Code, manufacturing specifications, drawings, diagrams, are all <b>Design Outputs</b>.",
      selector: '.design-control-column:nth-child(3) .main-column-header'
    }, {
      text: "<b>Verifications</b> and <b>Validations</b> are the evidence that your <u>Design Outputs</u> (your design) meet your <u>Design Inputs</u> (your specification). In other words, <u>proof that you built what you spec’d</u>.<br><br><u>Verifications tie to requirements</u>, they are generally tests on the product.<br><br><u>Validations tie to user needs</u>, and are either clinical trials or simulated use 'human factors' tests. <u>Validations are always conducted with people.</u>",
      selector: '.design-control-column:nth-child(4) .main-column-header'
    }, {
      prepare: function prepare() {
        return this.transitionTo("app.products.product.design-control", this.product);
      },

      text: "You access elements of your design data through these “cards” by clicking on them",
      selector: '.main-column .card',
      advanceOnClickTarget: true
    }, {
      prepare: function prepare() {
        return this.transitionTo('app.products.product.design-control.show', this.product.get('userNeeds.firstObject'));
      },

      text: "The top of each card gives:<ul><li>The identifier of this piece of data</li><li>The title<li>The description</li><li>The status (approved, draft, pending)</li></ul>",
      selector: '.card-txt',
      animationDelay: 250
    }, {
      text: "Certain information is <b>controlled</b> - meaning any changes to the data have to be approved.<br><br>Other data is <b>metadata</b> and not controlled.<br><br><u>Title, description and attachments</u> are three pieces of controlled design data.",
      selector: '.card-txt, .popup-header .previous-approved-version-button'
    }, {
      prepare: function prepare() {
        $('.ember-modal-overlay').scrollTop(500);
      },

      text: "One key part of US and Int'l compliance is to demonstrate <b>tracing</b> between <u>Design Inputs</u> and <u>Design Outputs</u> .<ul><li>Each User Need must be traced to a Validation</li><li>Each Requirement must be traced to a Verification.</li><li>Each Design Output must be traced to a Requirement.</li><li>Each User Need must be traced to at least one Requirement.</li></ul>",
      selector: '#tracing-section'
    }, {
      text: "As your company grows, it is likely that certain teams within the company will be working on aspects of the product independent from one another.<br><br>In this case, it can be useful to have <b>Impact Groups</b>.<br><br>Impact Groups are cues to everyone in the company that say “Hey, this piece of the design impacts this other piece, so don't change one without evaluating the other.” You have to change items in an Impact Group simulataneously, or provide a justification why the change doesn't impact other members of the group.",
      selector: '#impact-section'
    }, {
      text: "As your product grows in complexity, it can be useful to subdivide design data into <b>subsystems</b>.<br><br>You can filter the main Design Control module screen based on subsystem, and you can set approval settings based on subsystem (for example, you may have a Hardware lead and a Software lead, and they each should be approving things from Hardware and Software, respectively.)",
      selector: '#subsystem-section'
    }, {
      prepare: function prepare() {
        $('.ember-modal-overlay').scrollTop(-500);
        $('.tabs-nav a:eq(1)').click();
      },

      text: "Clicking on <b>Files</b> will allow you to access the attachments to this design control element.",
      selector: '#files-tab',
      animationDelay: 400
    }, {
      prepare: function prepare() {
        if (!document.getElementById('comments-tab')) {
          return this.transitionTo('app.products.product.design-control', this.product.get('userNeeds.firstObject'));
        }
        $('.tabs-nav a:eq(2)').click();
      },

      text: "Clicking on <b>Comments</b> will allow you to add Comments for any design control item, or to view the approval history of any particular item.",
      selector: '#comments-tab',
      animationDelay: 400
    }, {
      prepare: function prepare() {
        $('.popup-header .fa-close').click();
      },

      text: "For each type of Design Control data (User Needs, Requirements, etc), this column will allow you to perform the following:<ul><li>The circular arrow will show history of this type of design control data </li><li>The box with the diagonal arrow will give an export of only this type of data.</li></ul>",
      selector: '.main-column-header'
    }, {
      prepare: function prepare() {
        (0, _nativeClick.default)('.view-options-button');
      },

      text: "<b>View Options</b> gives you a number of ways to filter your view of Design data.<br /><br />The <b>Tracing</b> toggle, shown here, will show tracing elements between design control items. When you press this toggle, and hover over any item, you can see its connection to other items.",
      selector: '.tracing-toggle',
      animationDelay: 175
    }, {
      prepare: function prepare() {
        (0, _nativeClick.default)('.view-options-button');
        if ($('.search-bar').val() === "New") {
          $('.search-bar').val('');
          this.product.set('searchText', '');
          return this.transitionTo("app.products.product.design-control", this.product, { queryParams: { searchText: '' } });
        }
      },

      text: "The <b>Approved</b> toggle, shown here, will filter out all items that are in draft state. <br><br>You can see the screen is now empty, because the test items we've created are all in draft.",
      selector: '.approved-toggle'
    }, {
      prepare: function prepare() {
        $('.search-bar').val('New');
        return this.transitionTo("app.products.product.design-control", this.product, { queryParams: { searchText: 'New' } });
      },

      text: "The <b>Search</b> bar allows you to filter design control items by any keyword in the name, description, or tags.<br><br>We've put in 'New' here which is filtering out all items except the first User Need.",
      selector: '#search-form'
    }, {
      prepare: function prepare() {
        $('#undoNotificationsTraining').click();
        if ($('.search-bar').val() === "New") {
          $('.search-bar').val('');
          this.product.set('searchText', '');
          return this.transitionTo("app.products.product.design-control", this.product, { queryParams: { searchText: '' } }).then(function () {
            (0, _nativeClick.default)('.view-options-button');
          });
        }
      },

      text: "This is the <b>Subsystem</b> filter, which allows you to filter design control items by subsystems. <br><br>Items are assigned a subsystem in each 'card', as we covered earlier. You can create a new subsystem with the '+' button to the right.",
      selector: '.subsystem-select'
    }, {
      text: "This is the <b>Notification Icon</b>. When bordered in red with a red dot, it indicates you have approval requests, training requests, or design review requests that are awaiting your review. <br><br>Click the icon to view the requests.<br><br>You will receive regular email reminders of items that require your review if you enable the feature in your User Profile.",
      selector: '#notificationsDropdown'
    }, {
      text: "This button, when clicked, will show the complete product history. Product History can also be accessed via the Dashboard.",
      selector: '#productHistoryDropdown'
    }, {
      text: "This button, when clicked, will generate the elements of your product's <u>Design History File</u> that are contained within the Design Control Module of the Enzyme QMS system.<br><br> You generally only need this function during audits or submissions to regulators.",
      selector: '#exportButton'
    }, {
      prepare: function prepare() {
        return this.transitionTo({ queryParams: { changeRequestId: null } });
      },

      text: "This concludes <b>Design Control</b> module training!",
      selector: 'body'
    }]
  };
});