define('frontend/controllers/app/products/product', ['exports', 'frontend/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_permissions.default, {
    productDropdown: Ember.inject.service(),
    session: Ember.inject.service(),
    qmsExportsPoller: Ember.inject.service(),
    viewOptions: Ember.inject.service(),
    router: Ember.inject.service(),
    appController: Ember.inject.controller('application'),

    queryParams: ['searchText', { changeRequestId: { as: 'change' } }],
    searchText: null,
    product: Ember.computed.alias('model'),

    isPear: Ember.computed.alias('product.company.isPear'),
    notQualityOwner: Ember.computed.not('isQualityOwner'),

    /**
     * When set, this query param opens the Change Request modal.
     * It can either contain the string `new` (new change request),
     * or an approval set id when editing a draft change request.
     *
     * @type {String}
     */
    changeRequestId: null,

    exportProductDataButtonIsDisabled: Ember.computed.alias('qmsExportsPoller.qmsExportInProgress'),

    /**
     * Determines whether to show the search field in the top bar. To hide the search field in a child route,
     * set this value to false in the child route's `activate` hook.
     *
     * @type {Boolean}
     */
    isSearchFieldVisible: Ember.computed('router.currentRouteName', function () {
      var _this = this;

      var noSearchRoutes = ['app.products.product.non-conformances', 'app.products.product.change-requests'];
      return !noSearchRoutes.any(function (r) {
        return _this.get('router').isActive(r);
      });
    }),

    actions: {
      createProductExport: function createProductExport() {
        if (!this.get('qmsExportsPoller.qmsExportInProgress')) {
          this.store.createRecord('qms-export', {
            productId: this.get('product.id'),
            qmsExportableType: 'Product',
            qmsExportableId: this.get('product.id')
          }).saveAndNotify();
        }
      }
    }
  });
});