define('frontend/serializers/training-request', ['exports', 'ember-data', 'frontend/serializers/application', 'frontend/mixins/embedded-jsonapi-records'], function (exports, _emberData, _application, _embeddedJsonapiRecords) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, _embeddedJsonapiRecords.default, {
    attrs: {
      trainingQuestions: { serialize: 'records' },
      users: { serialize: 'ids-and-type' },
      attachments: { serialize: 'ids-and-type' }
    }
  });
});