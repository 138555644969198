define('frontend/components/craq-editor/question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['craq-editor-question'],
    classNameBindings: ['errors.length:error'],
    isExpanded: false,

    atTop: Ember.computed.equal('index', 0),
    atBottom: Ember.computed('index', 'questions.length', function () {
      return this.get('index') === this.get('questions.length') - 1;
    }),

    humanIndex: Ember.computed('index', function () {
      return this.get('index') + 1;
    })
  });
});