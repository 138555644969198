define('frontend/routes/app/products/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('product');
    },
    afterModel: function afterModel(model) {
      var product = model.get('firstObject');

      if (product) {
        this.replaceWith('app.products.product.dashboard', product.get('id'));
      }
    }
  });
});