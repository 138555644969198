define("frontend/helpers/days-ago", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.daysAgo = daysAgo;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function daysAgo(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        date = _ref2[0];

    if (!date) {
      return "Unknown date";
    }

    var days = (0, _moment.default)().diff((0, _moment.default)(date), 'days');

    if (days === 0) {
      return "Today";
    } else if (days === 1) {
      return "Yesterday";
    } else {
      return days + " Days Ago";
    }
  }

  exports.default = Ember.Helper.helper(daysAgo);
});