define('frontend/routes/onboarding/product', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      var _this = this;

      // don't create a new product if one already exists
      return this.store.findAll('product').then(function (products) {
        if (products.get('firstObject')) {
          return products.get('firstObject').reload();
        } else {
          return _this.store.createRecord('product');
        }
      });
    }
  });
});