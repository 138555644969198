define('frontend/controllers/app/products/product/approvers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    list: Ember.inject.service(),
    approvalSettingsTab: 'Design Controls',
    queryParams: ['approvalSettingsTab'],

    errorClasses: ['form-field--errors'],
    company: Ember.computed.alias('model.company'),

    designControlTypes: ["User Needs", "Requirements", "Design Outputs", "Verifications", "Validations"],

    otherTypes: ["Risk Management", "Products", "Complaints", "Suppliers", "Audits", "Non Conformances", "Capas"],

    sortedQmsDocumentTypes: Ember.computed.readOnly('list.liveQmsDocumentTypes'),
    sortedQmsDocumentTypeDescriptions: Ember.computed.mapBy('sortedQmsDocumentTypes', 'description'),

    actions: {
      roleDeleted: function roleDeleted(role) {
        if (this.get('newApprover') === role) {
          this.setNewApprover(this.get('product.approvers.firstObject'));
        }
      }
    }
  });
});