define('frontend/components/training-assignment/training-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['training-assignment__training-details'],
    qmsDocument: null,
    training: null,
    assignment: null,
    questions: null,

    doTour: function doTour() {
      (false && !('doTour needs to be provided') && Ember.assert(false, 'doTour needs to be provided'));
    },
    cancelCompleteTraining: function cancelCompleteTraining() {
      (false && !('cancelCompleteTraining needs to be provided') && Ember.assert(false, 'cancelCompleteTraining needs to be provided'));
    },
    confirmTrainingCompleted: function confirmTrainingCompleted() {
      (false && !('confirmTrainingCompleted needs to be provided') && Ember.assert(false, 'confirmTrainingCompleted needs to be provided'));
    },


    actions: {
      updateAttachments: function updateAttachments(attachments) {
        this.set('newAttachments', attachments);
      }
    }
  });
});