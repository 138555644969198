define('frontend/components/get-training-questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    trainingPlan: Ember.computed('trainingPlanId', function () {
      return this.get('store').findRecord('training-plan', this.get('trainingPlanId'));
    })
  });
});