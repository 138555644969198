define('frontend/validations/approval-role', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    users: (0, _validators.validateLength)({ min: 1 }),
    name: (0, _validators.validatePresence)(true)
  };
});