define('frontend/models/approval-set-approver', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    approved: (0, _attr.default)('boolean'),
    pendingApproval: (0, _attr.default)('boolean'),
    conjunctive: (0, _attr.default)('boolean'),
    approvalSet: (0, _relationships.belongsTo)('approval-set'),
    approver: (0, _relationships.belongsTo)('approver'),
    approvalSetApproverSigners: (0, _relationships.hasMany)('approval-set-approver-signers'),
    updatedAt: (0, _attr.default)('date'),
    denied: Ember.computed.equal('approved', false),

    company: Ember.computed.reads('approvalSet.company'),

    roleRepresentative: (0, _relationships.belongsTo)('user'),

    // CPs
    isConjunctive: Ember.computed.alias('conjunctive'),
    isLive: Ember.computed.notEmpty('id'),
    isDraft: Ember.computed.equal('status', 'draft'),
    isPending: Ember.computed.equal('status', 'pending'),
    isApproved: Ember.computed.reads('approved'),
    isDenied: Ember.computed.equal('status', 'denied'),

    /**
     * @type {String}
     */
    status: Ember.computed('approvalSet.isStateDraft', 'pendingApproval', 'approved', function () {
      if (this.get('approvalSet.isStateDraft')) {
        return 'draft';
      }
      if (this.get('pendingApproval')) {
        return 'pending';
      }
      if (this.get('approved')) {
        return 'approved';
      }
      return 'denied';
    })
  });
});