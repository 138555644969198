define("frontend/transitions/quick-fade", ["exports", "liquid-fire"], function (exports, _liquidFire) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    (0, _liquidFire.stop)(this.oldElement);
    (0, _liquidFire.stop)(this.newElement);

    var duration = 150;

    return Ember.RSVP.all([(0, _liquidFire.animate)(this.oldElement, { opacity: [0, 1] }, { duration: duration }), (0, _liquidFire.animate)(this.newElement, { opacity: [1, 0] }, { duration: duration })]);
  };
});