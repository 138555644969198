define('frontend/controllers/app/products/product/audits', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    productController: Ember.inject.controller('app.products.product'),
    currentCompany: Ember.computed.reads('session.currentUser.company'),
    exportSupplierDataButtonIsDisabled: Ember.computed.alias('qmsExportsPoller.qmsExportInProgress'),

    searchText: Ember.computed.reads('productController.searchText'),

    columns: Ember.computed(function () {
      return [{
        title: 'ID',
        key: 'paddedId',
        filter: { type: 'number' }
      }, {
        title: "Type",
        key: 'auditType',
        filter: { type: 'string' }
      }, {
        title: "Started",
        component: "audits/start-date-column",
        sortBy: 'startDate',
        key: 'startDateString',
        filter: { type: 'string' }
      }, {
        title: "Closed",
        component: "audits/close-date-column",
        sortBy: 'closureDate',
        key: 'closureDateString',
        filter: { type: 'string' }
      }, {
        title: "Title",
        key: 'title',
        grow: true,
        filter: { type: 'string' }
      }, {
        title: "Comments",
        key: 'comments',
        grow: true,
        filter: { type: 'string' }
      }, {
        title: "Status",
        component: 'audits/column-approval-state',
        sortBy: 'humanizedApprovalState',
        key: 'humanizedApprovalState',
        filter: { type: 'string' }
      }, {
        title: "",
        component: "audits/nc-column"
      }, {
        title: "",
        component: "audits/attachments-column"
      }];
    }),

    persistedAudits: Ember.computed.filterBy('model', 'isNew', false),

    actions: {
      viewAudit: function viewAudit(audit) {
        this.transitionToRoute('app.products.product.audits.audit', audit);
      }
    }

  });
});