define('frontend/components/infinite-scroller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['infinite-scroller'],
    items: Ember.computed(function () {
      return [];
    }),
    page: 1,
    isLoading: false,
    isAllLoaded: false,

    scroll: function scroll() {
      Ember.run.debounce(this, this.handleScrollEvent, 100);
    },
    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', this, 'triggerLoad');
      this.$().on('scroll', this.scroll.bind(this));
      this.$().on('mousewheel', this.mousewheel.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('scroll');
      this.$().off('mousewheel');
    },
    mousewheel: function mousewheel(event) {
      if (this.isAtBottom() && event.originalEvent.wheelDelta < 0) {
        // prevent scroll event from scrolling whole page in background
        // whilst waiting for infinite scroll load
        event.preventDefault();
      }
    },
    triggerLoad: function triggerLoad() {
      var _this = this;

      this.set('isLoading', true);

      this.get('loadPage')(this.get('page')).then(function (items) {
        if (items.get('length') && _this.get('items')) {
          _this.get('items').pushObjects(items.toArray());
        } else {
          _this.trySet('isAllLoaded', true);
        }
      }).finally(function () {
        return _this.trySet('isLoading', false);
      });
    },


    // don't try to set values on destroyed objects
    trySet: function trySet(key, value) {
      if (!this.isDestroyed) {
        this.set(key, value);
      }
    },
    isAtBottom: function isAtBottom() {
      return this.get('element').scrollHeight - this.$().scrollTop() - this.$().outerHeight() < 1;
    },
    handleScrollEvent: function handleScrollEvent() {
      if (this.get('isLoading') || this.get('isAllLoaded')) {
        return;
      }

      if (this.isAtBottom()) {
        this.incrementProperty('page');

        this.triggerLoad();

        Ember.run.schedule('afterRender', this, function () {
          // scroll to bottom after loading message is rendered
          this.get('element').scrollTop = this.get('element').scrollHeight;
        });
      }
    },


    // If the loadPage closure changes, we need to reload as there's now a different
    // method of loading data
    reset: Ember.observer('loadPage', function () {
      this.set('items', []);
      this.set('page', 1);
      this.triggerLoad();
    })
  });
});