define('frontend/components/comments-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Component.extend({
    commentsActive: true,

    comments: computed.alias('designControl.comments'),

    qmsExportsPoller: inject.service(),
    exportChangeHistoryButtonIsDisabled: computed.alias('qmsExportsPoller.qmsExportInProgress'),

    actions: {
      saveComment: function saveComment() {
        this.sendAction();
      },
      exportChangeHistory: function exportChangeHistory() {
        this.sendAction('exportChangeHistory');
      }
    }
  });
});