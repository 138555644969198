define('frontend/routes/app/products/product/subsystems/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('app.products.product').get('subsystems').then(function (relation) {
        return relation.createRecord();
      });
    },
    resetController: function resetController() {
      this.modelFor(this.routeName).rollbackAttributes();
    }
  });
});