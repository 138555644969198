define('frontend/models/severity', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      not = _Ember$computed.not;
  exports.default = Model.extend({
    company: belongsTo('company'),
    name: attr('string'),
    description: attr('string'),
    ncCapaRisk: attr('string'),
    ncProductRisklevels: hasMany(),
    combinedDescription: Ember.computed('name', 'description', function () {
      return this.get('name') + ': ' + this.get('description');
    }),
    shortName: alias('combinedDescription'),
    processRisk: attr('boolean'),
    productRisk: not('processRisk')
  });
});