define('frontend/models/user-company-view-model', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    email: null,
    firstName: null,
    lastName: null,
    password: null,
    passwordConfirmation: null,
    name: null,
    errors: null
  });
});