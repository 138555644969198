define("frontend/controllers/app/products/product/craq", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    company: Ember.computed.reads('session.currentUser.company')
  });
});