define('frontend/components/approval-item-card/link-to-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LinkToItem = Ember.Component.extend({
    tagName: 'span',

    renderPopup: false,
    usePopper: false,

    isPendingUpdate: false,
    isDesignReview: false,

    constraints: [{ to: 'scrollParent', attachment: 'together', pin: true }, { to: 'window', pin: true }]
  });

  LinkToItem.reopenClass({
    positionalParams: ['item']
  });

  exports.default = LinkToItem;
});