define('frontend/components/light-table/loading-spinner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    /**
     * @type {Array<String>}
     */
    classNames: ['light-table-spinner'],

    /**
     * @type {Array<String>}
     */
    classNameBindings: ['isFullPage:light-table-spinner_is-full-page'],

    /**
     * It's full page when the table is empty. The other case is when it's
     * at the bottom of the table loading the "next page".
     *
     * @type {Boolean}
     */
    isFullPage: false
  });
});