define('frontend/routes/app/products/product/supplier-quality/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var product = this.modelFor('app.products.product');
      return this.store.createRecord('supplier', { product: product });
    }
  });
});