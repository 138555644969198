define('frontend/components/form-field-wrapper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['form-field', 'classic-input'],
    classNameBindings: ["isError:is-error"],
    stringErrors: false,
    showErrors: true,
    required: false,

    isError: Ember.computed.and('errors.length', 'showErrors'),

    fieldId: Ember.computed(function () {
      return 'form-field-wrapper-' + Ember.guidFor(this);
    })
  });
});