define('frontend/services/view-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    approvedModeOn: false,
    router: Ember.inject.service(),

    canTrace: Ember.computed('wideColumns', 'focusedColumn', 'isDesignControlRoute', function () {
      return this.get('isDesignControlRoute') && !this.get('wideColumns') && !this.get('focusedColumn');
    }),

    isDesignControlRoute: Ember.computed('router.currentRouteName', function () {
      return this.get('router').isActive('app.products.product.design-control');
    }),

    isRiskManagementRoute: Ember.computed('router.currentRouteName', function () {
      return this.get('router').isActive('app.products.product.risk-management');
    }),

    showWideColumnsToggle: Ember.computed('focusedColumn', 'isDesignControlRoute', function () {
      return this.get('isDesignControlRoute') && !this.get('focusedColumn');
    }),

    visible: Ember.computed.or('isRiskManagementRoute', 'isDesignControlRoute'),

    isFiltering: Ember.computed('focusedColumn', 'approvedModeOn', 'selectedSubsystem', 'isDesignControlRoute', 'tracingToggled', function () {
      if (this.get('approvedModeOn') || this.get('selectedSubsystem')) {
        return true;
      }

      if (this.get('isDesignControlRoute') && (this.get('focusedColumn') || this.get('tracingToggled'))) {
        return true;
      }

      return false;
    }),

    focusColumn: function focusColumn(designControlType) {
      this.set('wideColumns', false);
      if (this.get('focusedColumn') === designControlType) {
        this.set('focusedColumn', null);
      } else {
        this.set('focusedColumn', designControlType);
      }
    },


    actions: {
      toggleApprovedMode: function toggleApprovedMode() {
        this.toggleProperty('approvedModeOn');
      },
      toggleTracing: function toggleTracing() {
        if (this.get('tracingToggled')) {
          this.set('tracingModeOn', false);
          this.set('tracingReportOn', false);
          this.set('tracingToggled', false);
        } else {
          this.set('tracingToggled', true);
          this.set('tracingModeOn', true);
        }
      },
      toggleTracingReport: function toggleTracingReport() {
        if (this.get('tracingModeOn')) {
          this.set('tracingModeOn', false);
          this.set('tracingReportOn', true);
        } else {
          this.set('tracingModeOn', true);
          this.set('tracingReportOn', false);
        }
      },
      focusColumn: function focusColumn(designControlType) {
        this.focusColumn(designControlType);
      },
      selectSubsystem: function selectSubsystem(subsystem) {
        this.set('selectedSubsystem', subsystem);
      },
      toggleWideColumns: function toggleWideColumns() {
        if (this.get('wideColumns')) {
          this.set('wideColumns', false);
        } else {
          this.set('wideColumns', true);
          this.set('tracingModeOn', false);
          this.set('tracingReportOn', false);
          this.set('tracingToggled', false);
        }
      }
    }
  });
});