define('frontend/models/file-upload', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      reads = Ember.computed.reads;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    product: belongsTo('product'),
    company: belongsTo('company'),
    file: attr('file'),
    fileUrl: attr('string'),
    pdfUrl: attr('string'),
    uploadable_id: attr('number'),
    uploadable_type: attr('string'),

    shortName: reads('fileName'),

    fileName: computed('fileUrl', function () {
      if (!this.get('fileUrl')) {
        return null;
      } else {
        return this.get('fileUrl').split('/').pop().split('?')[0];
      }
    })
  });
});