define('frontend/models/travisci-build', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    repositoryId: (0, _attr.default)('string'),
    commitId: (0, _attr.default)('string'),
    number: (0, _attr.default)('number'),
    vcsUrl: (0, _attr.default)('string'),
    pullRequest: (0, _attr.default)('boolean'),
    pullRequestTitle: (0, _attr.default)('string'),
    pullRequestNumber: (0, _attr.default)('number'),
    config: (0, _attr.default)(),
    state: (0, _attr.default)('string'),
    startedAt: (0, _attr.default)('date'),
    finishedAt: (0, _attr.default)('date'),
    duration: (0, _attr.default)('number'),
    jobIds: (0, _attr.default)(),

    title: Ember.computed.alias('commitId'),
    url: Ember.computed.alias('vcsUrl')
  });
});