define('frontend/models/notification', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'frontend/initializers/add-model-async-methods'], function (exports, _model, _attr, _relationships, _addModelAsyncMethods) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    user: (0, _relationships.belongsTo)('user'),
    product: (0, _relationships.belongsTo)('product'),
    text: (0, _attr.default)('string'),
    dismissedAt: (0, _attr.default)('date'),
    linkedResource: (0, _relationships.belongsTo)('routable', { polymorphic: true }),

    dismiss: (0, _addModelAsyncMethods.memberAction)({ path: 'dismiss' }),

    undismissed: Ember.computed.empty('dismissedAt'),
    dismissed: Ember.computed.not('undismissed'),

    transitionToRoute: function transitionToRoute() {
      var linkedResource = this.get('linkedResource.content');
      linkedResource && linkedResource.transitionToRoute();
    }
  });
});