define('frontend/mixins/attachable', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hasMany = _emberData.default.hasMany;
  exports.default = Ember.Mixin.create({
    attachments: hasMany('attachment', { inverse: 'attachable' })
  });
});