define('frontend/models/validation', ['exports', 'ember-data/relationships', 'ember-data/attr', 'frontend/models/design-control', 'frontend/mixins/permissions'], function (exports, _relationships, _attr, _designControl, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _designControl.default.extend(_permissions.default, {
    results: (0, _attr.default)('mobiledoc'),

    userNeedValidationDependencies: (0, _relationships.hasMany)('user-need-validation-dependency'),
    tracingDependencyObjects: Ember.computed(function () {
      return {
        userNeedValidationDependencies: 'userNeed'
      };
    }),

    designControlTypeAbbreviation: 'VAL'
  });
});