define('frontend/models/approval-request', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'frontend/mixins/approvable-actions'], function (exports, _model, _attr, _relationships, _approvableActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_approvableActions.default, {
    createdAt: (0, _attr.default)('date'),
    product: (0, _relationships.belongsTo)('product'),
    approvables: (0, _relationships.hasMany)('approvable', { inverse: 'approvalSets' }),
    isDead: Ember.computed.or('isDeleted', 'signed'),
    hasApprovables: Ember.computed.bool('approvables.length')
  });
});