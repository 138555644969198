define('frontend/components/changeset-form-for/multi-file-field', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MultiFileField = Ember.Component.extend({
    classNames: ['file-uploader'],
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    changeset: Ember.computed.reads('f.changeset'),

    company: Ember.computed.reads('session.currentUser.company'),

    getCollection: function getCollection() {
      return this.get('changeset.' + this.get('field'));
    },
    changeCollection: function changeCollection(mutator) {
      var collection = this.getCollection().slice();
      mutator(collection);
      this.set('changeset.' + this.get('field'), collection);
    },


    fileSelected: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(file) {
      var upload;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              upload = this.get('store').createRecord('file-upload', {
                file: file,
                company: this.get('company')
              });
              _context.next = 3;
              return upload.save();

            case 3:

              this.changeCollection(function (collection) {
                return collection.addObject(upload);
              });

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).drop(),

    actions: {
      removeUpload: function removeUpload(upload) {
        if (confirm('Are you sure you want to remove ' + upload.get('fileName') + '?')) {
          this.changeCollection(function (collection) {
            return collection.removeObject(upload);
          });
        }
      }
    }
  });

  MultiFileField.reopenClass({ positionalParams: ['field'] });

  exports.default = MultiFileField;
});