define('frontend/models/qms-document', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'frontend/mixins/approvable', 'frontend/mixins/attachable', 'frontend/mixins/model-meta-data', 'frontend/lib/qms-document-version'], function (exports, _model, _attr, _relationships, _approvable, _attachable, _modelMetaData, _qmsDocumentVersion) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_approvable.default, _modelMetaData.default, _attachable.default, {
    createdAt: (0, _attr.default)('date'),
    documentNumber: (0, _attr.default)('string'),
    formattedDocNumber: (0, _attr.default)('string'),
    effectiveFormattedDocNumber: (0, _attr.default)('string'),
    formNumber: (0, _attr.default)('string'),
    effectiveAt: (0, _attr.default)('date'),
    majorVersionNumber: (0, _attr.default)('string'),
    minorVersionNumber: (0, _attr.default)('number'),
    nextVersionNumber: (0, _attr.default)('string'),
    majorVersionOverride: (0, _attr.default)('string'),
    obsolete: (0, _attr.default)('boolean'),
    productionVersioning: (0, _attr.default)('boolean'),
    title: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    versionString: (0, _attr.default)('string'),
    changeDescription: (0, _attr.default)('string'),
    shortName: (0, _attr.default)('string'),
    unassignableFormNumbers: (0, _attr.default)(),

    effectiveAttributes: (0, _attr.default)(),
    currentEffectiveVersion: (0, _relationships.belongsTo)('version'),
    latestApprovedVersion: (0, _relationships.belongsTo)('version'),

    company: (0, _relationships.belongsTo)('company'),
    fileUpload: (0, _relationships.belongsTo)('file-upload'),
    processedFileUpload: (0, _relationships.belongsTo)('file-upload'),
    changesFileUpload: (0, _relationships.belongsTo)('file-upload'),
    changesPdfFileUpload: (0, _relationships.belongsTo)('file-upload'),
    historyFileUpload: (0, _relationships.belongsTo)('file-upload'),
    historyPdfFileUpload: (0, _relationships.belongsTo)('file-upload'),
    auditFileUpload: (0, _relationships.belongsTo)('file-upload'),
    auditPdfFileUpload: (0, _relationships.belongsTo)('file-upload'),
    lockedBy: (0, _relationships.belongsTo)('user'),
    qmsDocumentFolder: (0, _relationships.belongsTo)('qms-document-folder'),
    qmsDocumentType: (0, _relationships.belongsTo)('qms-document-type'),
    parentQmsDocument: (0, _relationships.belongsTo)('qms-document'),
    training: (0, _relationships.belongsTo)('training-request'),
    trainingPlan: (0, _relationships.belongsTo)('training-plan', { inverse: 'qmsDocument' }),
    updatedBy: (0, _relationships.belongsTo)('user'),
    lastTraining: (0, _relationships.belongsTo)('training'),
    trainings: (0, _relationships.hasMany)('training'),
    trainingRequests: (0, _relationships.hasMany)(),
    taggings: (0, _relationships.hasMany)('tagging'),
    tags: (0, _relationships.hasMany)('tag'),
    products: (0, _relationships.hasMany)('product'),
    docIdentifier: (0, _attr.default)('string'),

    linkedApprovalSets: (0, _relationships.hasMany)('approval-set', { inverse: 'qmsDocuments' }),

    isObsolete: Ember.computed.bool('approvedAttributes.obsolete'),
    notObsolete: Ember.computed.not('isObsolete'),

    isLocked: Ember.computed.bool('lockedBy'),
    fileUrl: Ember.computed.alias('fileUpload.fileUrl'),
    isLiveDoc: Ember.computed.and('notObsolete', 'isLive'),
    global: Ember.computed.readOnly('qmsDocumentFolder.global'),

    searchText: Ember.computed('qmsDocumentType.title', 'formattedDocNumber', 'title', 'taggings.@each', function () {
      if (this.get('taggings.length') > 0) {
        var tags = this.get('taggings').toArray();
        var taglist = '';
        if (tags.length > 0) {
          for (var i = 0; i < tags.length; ++i) {
            taglist += tags[i].get('tag.name') + ' ';
          }
        }
        return '' + this.get('qmsDocumentType.title') + this.get('formattedDocNumber') + ' ' + this.get('title') + ' ' + taglist;
      } else {
        return '' + this.get('qmsDocumentType.title') + this.get('formattedDocNumber') + ' ' + this.get('title');
      }
    }),

    isVersionSame: Ember.computed.equal('effectiveVersion.qmsDocumentVersionString', 'versionString'),

    attachmentsDiff: Ember.computed('attachments.[]', 'effectiveVersion.model.attachments.length', function () {
      var currentAttachments = this.get('attachments').mapBy('id');
      var effectiveVersion = this.get('effectiveVersion');

      if (effectiveVersion.model.content) {
        var effectiveAttachments = this.get('effectiveVersion.model.attachments').mapBy('id');
        var diff = arrDiff(currentAttachments, effectiveAttachments);

        return diff.length;
      } else {
        return currentAttachments.length;
      }
    }),

    majorVersion: Ember.computed('versionString', function () {
      return this.get('versionString').slice(-1) === 0;
    }),

    draftPresent: Ember.computed('versionString', 'effectiveAttributes.versionString', 'approvedAttributes.versionString', function () {
      return this.get('versionString') && this.get('versionString') != this.get('effectiveAttributes.versionString') && this.get('versionString') != this.get('approvedAttributes.versionString');
    }),

    tagWith: function tagWith(tagName) {
      var tag = this.store.createRecord('tagging', {
        itemType: 'QmsDocument',
        itemId: this.get('id'),
        name: tagName
      });

      return tag.saveWithNotifications().then(null, function () {
        return tag.rollbackAttributes();
      });
    },


    route: 'app.products.product.document-control.document-item',

    effectiveVersionAttachmentProxies: Ember.computed.mapBy('versions', 'attachments'),
    areAllEffectiveVersionAttachmentsLoaded: Ember.computed('effectiveVersionAttachmentProxies.@each.isFulfilled', function () {
      return this.get('effectiveVersionAttachmentProxies').map(function (attachments) {
        return attachments.get('isFulfilled');
      }).every(Boolean);
    }),

    /**
     * Maps the document versions to `QmsDocumentVersion` while filtering out the
     * ones where the version doesn't change.
     *
     * @type {Array<QmsDocumentVersion>}
     */
    effectiveVersions: Ember.computed('areAllEffectiveVersionAttachmentsLoaded', function () {
      var sortedVersions = this.get('sortedVersions');

      if (!this.get('areAllEffectiveVersionAttachmentsLoaded')) {
        return [];
      }

      var documentVersions = sortedVersions.map(this.createQmsDocumentVersion.bind(this)).filterBy('didChangeVersion');

      return documentVersions.filter(function (documentVersion, index) {
        var nextDocumentVersion = documentVersions[index - 1];
        if (!nextDocumentVersion) return true;

        var version = documentVersion;
        var nextVersion = nextDocumentVersion;

        var hasDiffFileUploadId = version.get('data.fileUploadId') !== nextVersion.get('data.fileUploadId');
        var hasDiffTitle = version.get('data.title') !== nextVersion.get('data.title');
        var hasDiffDocType = version.get('data.qmsDocumentTypeId') !== nextVersion.get('data.qmsDocumentTypeId');
        var hasDiffDocNumber = version.get('data.documentNumber') !== nextVersion.get('data.documentNumber');
        var hasDiffAttachments = arrDiff(version.get('model.attachments').mapBy('id'), nextVersion.get('model.attachments').mapBy('id').toArray()).length != 0;

        return hasDiffFileUploadId || hasDiffTitle || hasDiffAttachments || hasDiffDocType || hasDiffDocNumber;
      });
    }),

    effectiveVersion: Ember.computed('currentEffectiveVersion', function () {
      var currentEffectiveVersion = this.get('currentEffectiveVersion');

      if (currentEffectiveVersion) {
        return this.createQmsDocumentVersion(currentEffectiveVersion);
      }
    }),

    approvedVersion: Ember.computed('latestApprovedVersion', function () {
      var latestApprovedVersion = this.get('latestApprovedVersion');

      if (latestApprovedVersion) {
        return this.createQmsDocumentVersion(latestApprovedVersion);
      }
    }),

    createQmsDocumentVersion: function createQmsDocumentVersion(version) {
      var store = this.get('store');

      return _qmsDocumentVersion.default.create({ store: store, model: version });
    },
    isVersionPending: function isVersionPending(version) {
      return version.get('model.attachments.isPending');
    },


    routableTarget: 'app.products.product.document-control.document-item'
  });


  function arrDiff(a, b) {
    if (a.length > b.length) {
      return a.filter(function (i) {
        return b.indexOf(i) === -1;
      });
    }

    return b.filter(function (i) {
      return a.indexOf(i) === -1;
    });
  }
});