define('frontend/initializers/add-model-async-methods', ['exports', 'ember-data', 'ember-data/model', 'ember-api-actions'], function (exports, _emberData, _model, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  exports.memberActionWithNotifications = memberActionWithNotifications;
  exports.memberAction = memberAction;
  exports.summarizeJsonApiErrors = summarizeJsonApiErrors;
  exports.saveThenMemberAction = saveThenMemberAction;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function initialize() {
    _model.default.reopen({
      notifierService: Ember.inject.service('notify'),
      loadingSliderService: Ember.inject.service('loading-slider'),

      saveWithNotificationsAndProgress: function saveWithNotificationsAndProgress() {
        var workingMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('i18n').t('save.in_progress');
        var successMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('i18n').t('save.successful');
        var errorMessage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.get('i18n').t('save.failed');

        var message = this.get('notifierService').info(workingMessage);

        return this.saveWithNotifications(successMessage, errorMessage).finally(function () {
          return message.set('visible', false);
        });
      },
      saveWithNotifications: function saveWithNotifications() {
        var successMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('i18n').t('save.successful');
        var errorMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('i18n').t('save.failed');

        return this.promiseMethodWithNotifications('save', successMessage, errorMessage);
      },
      destroyWithNotifications: function destroyWithNotifications() {
        var _this = this;

        var successMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('i18n').t('destroy.successful');
        var errorMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('i18n').t('destroy.failed');

        return this.promiseMethodWithNotifications('destroyRecord', successMessage, errorMessage).catch(function (error) {
          // if deletion fails, the record should be rolled back so it's no
          // longer in destroyed state
          _this.rollbackAttributes();
          return Ember.RSVP.reject(error);
        });
      },
      discardWithNotifications: function discardWithNotifications() {
        var _this2 = this;

        var successMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('i18n').t('destroy.successful');
        var errorMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('i18n').t('destroy.failed');

        this.set('deletedAt', new Date());
        return this.promiseMethodWithNotifications('save', successMessage, errorMessage).catch(function (error) {
          // if deletion fails, the record should be rolled back so it's no
          // longer in destroyed state
          _this2.rollbackAttributes();
          return Ember.RSVP.reject(error);
        });
      },
      memberActionWithNotifications: function memberActionWithNotifications(memberAction) {
        var _this3 = this;

        var successMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('i18n').t('save.successful');
        var errorMessage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.get('i18n').t('save.failed');

        for (var _len = arguments.length, args = Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
          args[_key - 3] = arguments[_key];
        }

        return this.promiseMethodWithNotifications.apply(this, [memberAction, successMessage, errorMessage].concat(_toConsumableArray(args))).then(function (response) {
          return _this3.store.pushPayload(response);
        });
      },
      memberAction: function memberAction(_memberAction) {
        var _this4 = this;

        return this.promiseMethod(_memberAction).then(function (response) {
          return _this4.store.pushPayload(response);
        });
      },
      rollbackWithNotifications: function rollbackWithNotifications() {
        var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('i18n').t('save.failed');

        this.rollbackAttributes();
        this.get('loadingSliderService').startLoading();
        this.get('notifierService').error(message);
        this.get('loadingSliderService').endLoading();
      },
      promiseMethodWithNotifications: function promiseMethodWithNotifications(methodName, successMessage, errorMessage) {
        var _this5 = this;

        for (var _len2 = arguments.length, args = Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) {
          args[_key2 - 3] = arguments[_key2];
        }

        return this.promiseMethod.apply(this, [methodName].concat(_toConsumableArray(args))).then(function (record) {
          if (successMessage !== false) {
            _this5.get('notifierService').success(successMessage);
          }
          return record;
        }, function (error) {
          var errMsg = typeof errorMessage === 'function' ? errorMessage(error) : errorMessage;
          if (errMsg) {
            _this5.get('notifierService').error(errMsg);
          }
          // return a rejection so that consuming code can still handle specific failure cases
          return Ember.RSVP.reject(error);
        });
      },
      promiseMethod: function promiseMethod(methodName) {
        var _this6 = this;

        var method = typeof methodName === 'string' ? this[methodName] : methodName;
        this.get('loadingSliderService').startLoading();

        for (var _len3 = arguments.length, args = Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
          args[_key3 - 1] = arguments[_key3];
        }

        return method.apply(this, args).finally(function () {
          return _this6.get('loadingSliderService').endLoading();
        });
      }
    });

    // This allows calling model methods on DS.PromiseObjects and waiting for them
    // to resolve, instead of having to resolve everywhere the methods are called.
    // It's generally save to proxy these methods because they return promises, so
    // it's OK to wait for them to resolve
    function proxyMethod(methodName) {
      return function () {
        for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
          args[_key4] = arguments[_key4];
        }

        return this.then(function (model) {
          var method = model[methodName];

          if (typeof method === 'function') {
            return method.apply(model, args);
          } else {
            throw new Error(model.toString() + ' has no method ' + methodName);
          }
        });
      };
    }

    _emberData.default.PromiseObject.reopen({
      save: proxyMethod('save'),
      destroyRecord: proxyMethod('destroyRecord'),
      saveWithNotifications: proxyMethod('saveWithNotifications'),
      saveWithNotificationsAndProgress: proxyMethod('saveWithNotificationsAndProgress'),
      destroyWithNotifications: proxyMethod('destroyWithNotifications')
    });
  }
  exports.default = {
    name: 'add-model-async-methods',
    initialize: initialize
  };
  function memberActionWithNotifications(options) {
    return function () {
      for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
        args[_key5] = arguments[_key5];
      }

      return this.memberActionWithNotifications.apply(this, [(0, _emberApiActions.memberAction)(options)].concat(args));
    };
  }

  function memberAction(options) {
    return function () {
      for (var _len6 = arguments.length, args = Array(_len6), _key6 = 0; _key6 < _len6; _key6++) {
        args[_key6] = arguments[_key6];
      }

      return this.memberAction.apply(this, [(0, _emberApiActions.memberAction)(options)].concat(args));
    };
  }

  function summarizeJsonApiErrors(errors) {
    return errors.map(function (e) {
      var detail = e.detail;
      var field = e.source.pointer.split("/").get('lastObject');
      if (field === 'base') {
        field = "";
      } else if (field.includes(".")) {
        // dont' display errors with dot paths, copy the errors on the backend if you want direct errors in the notification
        return null;
      } else {
        field = field.replace(/_/g, " ").capitalize() + ' ';
      }
      return '' + field + detail;
    }).compact().join("\n");
  }

  function saveThenMemberAction(options) {
    var action = memberActionWithNotifications(options);
    return function () {
      var _this7 = this;

      for (var _len7 = arguments.length, args = Array(_len7), _key7 = 0; _key7 < _len7; _key7++) {
        args[_key7] = arguments[_key7];
      }

      return this.save().then(function () {
        return action.call.apply(action, [_this7, _this7.get('i18n').t('save.successful'), false].concat(args));
      }).catch(function (response) {
        _this7.get('notifierService').error('Could not save changes: ' + summarizeJsonApiErrors(response.errors));

        var store = _this7.store;
        var serializer = store.serializerFor(_this7.constructor.modelName);
        var errors = serializer.extractErrors(store, _this7.constructor, response, _this7.get('id'));

        if (!_this7.get('hasDirtyAttributes')) {
          // if the record is not dirty already, it has to be in the dirty state
          // in order to have errors added to it.
          _this7.send('becomeDirty');
        }
        store.recordWasInvalid(_this7._internalModel, errors);

        return Ember.RSVP.reject("Could not save due to invalid record");
      });
    };
  }
});