define('frontend/components/complaint-entry-form', ['exports', 'frontend/models/complaint'], function (exports, _complaint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed,
      reads = Ember.computed.reads;
  exports.default = Ember.Component.extend({
    genders: _complaint.genders,
    triageAssessments: _complaint.triageAssessments,

    session: service(),
    user: reads('session.currentUser'),
    company: reads('user.company'),

    sourceTypes: computed('company.complaintSourceTypes.@each.name', function () {
      if (this.get('company.complaintSourceTypes.length')) {
        return this.get('company.complaintSourceTypes').then(function (complaintSourceTypes) {
          return (complaintSourceTypes.mapBy('name').naturalSort() || []).concat('Other');
        });
      } else {
        return ['Distributor', 'Employee / Consultant', 'Nurse', 'Patient', 'Physician', 'Other'];
      }
    })
  });
});