define('frontend/components/user-edit-form', ['exports', 'ember-validations', 'frontend/models/user', 'frontend/validations/email'], function (exports, _emberValidations, _user, _email) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberValidations.default, {
    permissionLevels: _user.permissionLevels,
    tagName: 'form',
    classNames: ['clearfix'],
    errors: [],
    validations: {
      'user.firstName': {
        presence: true
      },
      'user.lastName': {
        presence: true
      },
      'user.email': _email.default
    },
    displayErrors: false,

    session: Ember.inject.service(),

    currentUserCompany: Ember.computed.readOnly('session.currentUser.company'),

    availableSubordinates: Ember.computed('currentUserCompany.sortedUsers', 'user.id', function () {
      return this.get('currentUserCompany.sortedUsers').rejectBy('id', this.get('user.id'));
    }),

    submit: function submit(event) {
      var _this = this;

      event.preventDefault();

      this.validate().then(function () {
        return _this.get('save')();
      }).catch(function () {
        return _this.set('displayErrors', true);
      });
    }
  });
});