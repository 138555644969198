define('frontend/utils/approved-or-current-attribute', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = approvedOrCurrentAttribute;
  var computed = Ember.computed;
  function approvedOrCurrentAttribute(approvablePath, attribute) {
    var attrPath = approvablePath + '.' + attribute,
        approvedPath = approvablePath + '.approvedAttributes.' + attribute;

    return computed('viewOptions.approvedModeOn', attrPath, approvedPath, function () {
      (false && !(!!this.get('viewOptions')) && Ember.assert('viewOptions service not found on ' + this, !!this.get('viewOptions')));


      if (this.get('viewOptions.approvedModeOn')) {
        return this.get(approvedPath);
      } else {
        return this.get(attrPath);
      }
    });
  }
});