define('frontend/controllers/app/products/product/risk-management/harms/matrix', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Controller.extend(_emberValidations.default, {
    session: inject.service(),

    isQualityOwner: computed.alias('session.currentUser.isQualityOwner'),
    displayErrors: false,
    reverseSortProbabilities: ['id:desc'],
    sortedProbabilities: computed.sort('probabilities', 'reverseSortProbabilities'),

    riskMatrix: computed('risklevels.[]', 'severities.[]', 'sortedProbabilities.[]', function () {
      var _this = this;

      return this.get('sortedProbabilities').map(function (probability) {
        return _this.get('severities').map(function (severity) {
          return _this.get('risklevels').find(function (riskLevel) {
            return riskLevel.get('probability.id') === probability.id && riskLevel.get('severity.id') === severity.id;
          });
        });
      });
    }),

    riskLevels: ['Low', 'Moderate', 'High'],

    changedRiskLevels: computed('risklevels.@each.{description}', function () {
      return this.get('risklevels').filter(function (riskLevel) {
        return riskLevel.get('hasDirtyAttributes') === true;
      });
    }),

    actions: {
      updateMatrix: function updateMatrix() {
        if (this.get('isQualityOwner')) {
          this.get('changedRiskLevels').forEach(function (riskLevel) {
            riskLevel.saveWithNotifications();
          });
          this.transitionToRoute('app.products.product.risk-management');
        }
      }
    }
  });
});