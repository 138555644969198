define('frontend/components/notification-cards/requester-details/default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['requester-details', 'gray-zone'],

    approvable: null,

    renderPopup: true,
    usePopper: false
  });
});