define('frontend/components/animated-tab', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      on = Ember.on,
      run = Ember.run,
      observer = Ember.observer;


  var animationDuration = 350;

  // Note: we can't make use of ember's classNameBindings for the class
  // manipulation in this component, as we need to add and remove the classes at
  // extremely specific times to ensure correct and smooth animation.

  exports.default = Component.extend({
    classNames: ['animated-tab', 'tab', 'anm', 'anm-3'],

    didInsertElement: function didInsertElement() {
      if (this.get('printable')) {
        this.$().addClass('tab--printable');
      }
    },


    // get a reference to the wrapper element, to easily animate it
    wrapper: function wrapper() {
      return this.$().closest('.tab-nav-wrapper');
    },


    // when inserting the tab, we don't want to animate the contents in - it's
    // probably in a container like a modal and we don't want to give the users
    // motion sickness with too many gratuitous animations!
    _insertTab: on('didInsertElement', function () {
      if (this.get('active')) {
        this.$().addClass('active-no-animation');
      }
    }),

    // Tabs are all rendered in the DOM even if their contents aren't. This makes
    // it easier to animate the tab contents in and out by just toggling classes.
    // it also prevents animations on willDestroyElement, which are only sometimes
    // desirable - if a parent of this element is removed, we probably don't want
    // to animate this element specifically
    _animateContents: observer('active', function () {
      var _this = this;

      if (this.get('active')) {
        // Active is true, so we are animating the tab in
        this.$().addClass('active');

        // once we add the active class, wait until the next runloop for it to
        // take effect in the dom
        run.next(function () {
          if (_this.get('isDestroyed')) {
            return;
          }
          // Set the wrapper height to the height of this element, so that it will
          // animate to that height
          _this.wrapper().height(_this.$().height());

          // Using absolute heights after the animation has finished does not work
          // well for dynamic content, especially nested tabs. So once the
          // animation has finished, set the height back to auto so it will be the
          // correct height no matter the contents. Unfortunately, using an event
          // here to reset the height doesn't work due to bubbling transitionend
          // events from tab children, so use a hardcoded value matched to the css
          // instead.
          run.later(function () {
            _this.wrapper().height('auto');
          }, animationDuration);
        });
      } else {
        // the wrapper should already be this height, but this will be through
        // matching the height to the contents with height: 'auto'. It must be set
        // to an explicit value for css transitions to a different explicit value
        // to work.
        this.wrapper().height(this.$().height());

        // remove both possible active classes from the element - if it's an
        // initial show then it will have the non animated class, otherwise it
        // will have been animated in and just have the active class
        this.$().removeClass('active-no-animation');
        this.$().removeClass('active');

        var clone = this.$().clone();

        // the clone won't have the active class as it was removed from the
        // element above.
        clone.addClass('active');

        // animating-out class makes the element absolutely positioned - without
        // this, the two elements that are animating in and out respectively will
        // stack on top of each other causing a jumpy effect
        clone.addClass('animating-out');

        // append the clone to the wrapper, remove the active class on the next
        // runloop to trigger the css animation, then remove the clone from the
        // dom after the animation finishes
        clone.one('transitionend', function () {
          return clone.remove();
        });
        this.wrapper().append(clone);
        run.next(function () {
          clone.removeClass('active');
        });
      }
    })
  });
});