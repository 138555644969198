define('frontend/components/product-history-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      inject = Ember.inject;
  exports.default = Component.extend({
    store: inject.service(),
    dropdownIsVisible: false,

    actions: {
      toggleDropdownIsVisible: function toggleDropdownIsVisible() {
        this.toggleProperty('dropdownIsVisible');
      },
      loadVersionPage: function loadVersionPage(page) {
        return this.get('store').query('version', {
          filter: {
            product_id: this.get('product.id'),
            approved: true,
            design_control_type: this.get('designControlType')
          },
          page: {
            number: page,
            size: 10
          }
        });
      }
    }

  });
});