define('frontend/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'frontend/mixins/approver', 'frontend/initializers/add-model-async-methods', 'frontend/helpers/permission-level-name', 'moment'], function (exports, _model, _attr, _relationships, _approver, _addModelAsyncMethods, _permissionLevelName, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.permissionLevels = undefined;
  exports.default = _model.default.extend(_approver.default, {
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    role: (0, _attr.default)('string'),
    jobTitle: (0, _attr.default)('string'),
    signature: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    passwordConfirmation: (0, _attr.default)('string'),
    currentPassword: (0, _attr.default)('string'),
    permissionLevel: (0, _attr.default)('string', { defaultValue: 'approver' }),
    websocketChannel: (0, _attr.default)('string'),
    coWebsocketChannel: (0, _attr.default)('string'),
    avatar: (0, _attr.default)('file'),
    avatarUrl: (0, _attr.default)('string'),
    removeAvatar: (0, _attr.default)('boolean'),
    pendingActionsFrequency: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    userHash: (0, _attr.default)('string'),
    pin: (0, _attr.default)('string'),
    pinConfirmation: (0, _attr.default)('string'),
    currentPin: (0, _attr.default)('string'),

    myPendingChangeRequestCount: (0, _attr.default)('number'),
    allPendingChangeRequestCount: (0, _attr.default)('number'),
    myRequestedChangeRequestCount: (0, _attr.default)('number'),
    overdueTrainingAssignmentsCount: (0, _attr.default)('number'),
    overdueTrainingUsersCount: (0, _attr.default)('number'),

    company: (0, _relationships.belongsTo)('company', { inverse: 'users' }),
    manager: (0, _relationships.belongsTo)('user', { inverse: 'subordinates' }),

    myApprovalItems: (0, _relationships.hasMany)('approvable', { polymorphic: true }),
    trainingAssignments: (0, _relationships.hasMany)('training-assignment'),
    notifications: (0, _relationships.hasMany)('notification'),
    productUserPermissionLevels: (0, _relationships.hasMany)('product-user-permission-level'),
    subordinates: (0, _relationships.hasMany)('user', { inverse: 'manager' }),

    hasSignature: Ember.computed.notEmpty('signature'),
    manages: Ember.computed.notEmpty('subordinates'),
    needsSignature: Ember.computed.or('canApprove', 'canEdit'),
    shortName: Ember.computed.alias('fullName'),
    username: Ember.computed.alias('fullName'),

    isViewer: Ember.computed.equal('permissionLevel', 'viewer'),
    isEditor: Ember.computed.equal('permissionLevel', 'editor'),
    isApprover: Ember.computed.equal('permissionLevel', 'approver'),
    isSuper: Ember.computed.equal('permissionLevel', 'super'),
    isQualityOwner: Ember.computed.equal('permissionLevel', 'quality_owner'),
    isOwner: Ember.computed.or('isQualityOwner', 'isSuper'),
    canApprove: Ember.computed.or('isApprover', 'isOwner'),
    canEdit: Ember.computed.or('isEditor', 'canApprove'),

    humanizedSignaturePresence: Ember.computed('hasSignature', function () {
      if (this.get('hasSignature')) {
        return "Yes";
      } else {
        return "No";
      }
    }),

    fullName: Ember.computed('firstName', 'lastName', function () {
      // rename to username
      return [this.get('firstName'), this.get('lastName')].compact().join(' ');
    }),

    fullNameAndEmail: Ember.computed('fullName', 'email', function () {
      return [this.get('fullName'), this.get('email')].compact().join(' - ');
    }),

    permissionLevelName: Ember.computed('permissionLevel', function () {
      var permissionLevel = this.get('permissionLevel');
      return (0, _permissionLevelName.permissionLevelName)([permissionLevel]);
    }),

    formattedUpdatedAt: Ember.computed('updatedAt', function () {
      var updatedAt = this.get('updatedAt');
      return (0, _moment.default)(updatedAt).format('YYYY-MM-DD');
    }),

    restore: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'restore' })
  });
  var permissionLevels = exports.permissionLevels = ['viewer', 'editor', 'approver', 'quality_owner'];
});