define('frontend/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    tour: Ember.inject.service(),
    dialog: Ember.inject.service(),
    authorizeAction: Ember.inject.service(),

    actions: {
      showModal: function showModal(modalName) {
        this.toggleProperty(modalName);
      },


      /**
       * This method is called by the dialog component that is currently rendered.
       * It will close the modal and resolve the dialog promise to the value passed here.
       *
       * For example, for a confirmation dialog, the result passed is a boolean indicating
       * whether the user has confirmed or cancelled.
       *
       * @param {Any} result
       */
      dialogActionCalled: function dialogActionCalled(result) {
        this.get('dialog').actionCalled(result);
      }
    }
  });
});