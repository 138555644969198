define('frontend/controllers/app/products/product/users/index', ['exports', 'ember-light-table'], function (exports, _emberLightTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),

    sortDefinition: ['id:asc'],

    columns: Ember.computed(function () {
      return [{
        label: 'First Name',
        valuePath: 'firstName'
      }, {
        label: 'Last Name',
        valuePath: 'lastName'
      }, {
        label: 'Email',
        valuePath: 'email',
        width: '300px'
      }, {
        label: 'Permissions',
        valuePath: 'permissionLevelName'
      }, {
        label: 'Signature',
        valuePath: 'humanizedSignaturePresence',
        width: '100px'
      }, {
        label: 'Last Updated',
        valuePath: 'formattedUpdatedAt'
      }, {
        label: 'Actions',
        cellComponent: 'cell-component-user-actions',
        sortable: false
      }];
    }),

    activeUsersSortKey: 'firstName',
    activeUsersSortDir: 'desc',
    activeUsersTable: Ember.computed('model.company.activeUsers', 'activeUsersSortKey', 'activeUsersSortDir', function () {
      var columns = this.get('columns');
      var model = this.get('model.company.activeUsers');
      var sortKey = this.get('activeUsersSortKey');
      var sortDir = this.get('activeUsersSortDir');
      model = model.naturalSortBy(sortKey);

      if (sortDir === 'asc') {
        model = model.reverse();
      }

      var enableSync = true;
      var options = { enableSync: enableSync };
      var table = new _emberLightTable.default(columns, model, options);

      var sortColumn = table.get('allColumns').findBy('valuePath', sortKey);

      // Setup initial sort column
      if (sortColumn) {
        sortColumn.setProperties({
          sorted: true,
          ascending: sortDir === 'asc'
        });
      }

      return table;
    }),

    inactiveUsersSortKey: 'firstName',
    inactiveUsersSortDir: 'desc',
    inactiveUsersTable: Ember.computed('model.company.inactiveUsers', 'inactiveUsersSortKey', 'inactiveUsersSortDir', function () {
      var columns = this.get('columns');
      var model = this.get('model.company.inactiveUsers');
      var sortKey = this.get('inactiveUsersSortKey');
      var sortDir = this.get('inactiveUsersSortDir');
      model = model.naturalSortBy(sortKey);

      if (sortDir === 'asc') {
        model = model.reverse();
      }

      var enableSync = true;
      var options = { enableSync: enableSync };
      var table = new _emberLightTable.default(columns, model, options);

      var sortColumn = table.get('allColumns').findBy('valuePath', sortKey);

      // Setup initial sort column
      if (sortColumn) {
        sortColumn.setProperties({
          sorted: true,
          ascending: sortDir === 'asc'
        });
      }

      return table;
    }),

    actions: {
      sortActiveUsersColumn: function sortActiveUsersColumn(_ref) {
        var sorted = _ref.sorted,
            valuePath = _ref.valuePath,
            ascending = _ref.ascending;

        if (!sorted) {
          return;
        }

        this.setProperties({
          activeUsersSortKey: valuePath,
          activeUsersSortDir: ascending ? 'asc' : 'desc'
        });
      },
      sortInactiveUsersColumn: function sortInactiveUsersColumn(_ref2) {
        var sorted = _ref2.sorted,
            valuePath = _ref2.valuePath,
            ascending = _ref2.ascending;

        if (!sorted) {
          return;
        }

        this.setProperties({
          inactiveUsersSortKey: valuePath,
          inactiveUsersSortDir: ascending ? 'asc' : 'desc'
        });
      },
      restoreUser: function restoreUser(user) {
        user.restore(user.get('fullName') + '\'s account has been restored, and their permissions have been reset to the lowest levels.');
      },
      roleDeleted: function roleDeleted(role) {
        if (this.get('newApprover') === role) {
          this.setNewApprover(this.get('product.approvers.firstObject'));
        }
      }
    }
  });
});