define('frontend/models/non-conformance', ['exports', 'ember-data', 'ember-data/relationships', 'ember-data/attr', 'frontend/mixins/approvable', 'frontend/initializers/add-model-async-methods', 'moment'], function (exports, _emberData, _relationships, _attr, _approvable, _addModelAsyncMethods, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_approvable.default, {
    session: Ember.inject.service(),

    user: (0, _relationships.belongsTo)(),
    company: (0, _relationships.belongsTo)(),
    severity: (0, _relationships.belongsTo)(),
    probability: (0, _relationships.belongsTo)(),
    source: (0, _relationships.belongsTo)('nc-capa-source'),
    defectCodes: (0, _relationships.hasMany)(),
    defectTypes: (0, _relationships.hasMany)(),
    processRequirementQmsDocument: (0, _relationships.belongsTo)('qms-document'),
    capaOwner: (0, _relationships.belongsTo)('user'),
    capa: (0, _relationships.belongsTo)(),
    cancelledAt: (0, _attr.default)('date'),
    cancellationJustification: (0, _attr.default)('mobiledoc'),

    nonConformancesProductsSummary: (0, _attr.default)(undefined, { defaultValue: function defaultValue() {
        return [];
      } }),
    processRequirement: (0, _attr.default)('string'),
    risk: (0, _attr.default)('string'),
    bugNumber: (0, _attr.default)('string'),
    areaCauseCode: (0, _attr.default)('string'),
    areaFoundCode: (0, _attr.default)('string'),
    ncType: (0, _attr.default)('string', { defaultValue: 'product' }),
    issueURL: (0, _attr.default)('string'),
    description: (0, _attr.default)('mobiledoc'),
    investigationNeeded: (0, _attr.default)('boolean', { allowNull: true }),
    noInvestigationJustification: (0, _attr.default)('mobiledoc'),
    investigationResults: (0, _attr.default)('mobiledoc'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    softwareReleaseHold: (0, _attr.default)('boolean'),
    softwareReleaseHoldJustification: (0, _attr.default)('mobiledoc'),
    escalationToCapa: (0, _attr.default)('boolean', { allowNull: true }),
    escalationToCapaJustification: (0, _attr.default)('mobiledoc'),
    riskRationale: (0, _attr.default)('mobiledoc'),
    disposition: (0, _attr.default)('string'),
    dispositionComments: (0, _attr.default)('mobiledoc'),
    evidence: (0, _attr.default)('mobiledoc'),
    unknownProduct: (0, _attr.default)('boolean'),
    latestNonConformanceDisposition: (0, _relationships.belongsTo)('non-conformance-disposition'),
    phase1DueDate: (0, _attr.default)('date-no-time'),

    routeForApproval: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'route_for_approval', type: 'post' }),
    cancel: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'cancel', type: 'post' }),

    canSubmitForApproval: Ember.computed('isDraft', 'latestNonConformanceDisposition.isPendingApproval', 'session.currentUser.canEdit', function () {
      return this.get('session.currentUser.canEdit') && this.get('isDraft') && !this.get('latestNonConformanceDisposition.isPendingApproval');
    }),

    routableTarget: 'app.products.product.non-conformances.non-conformance',

    riskForSort: Ember.computed('risk', function () {
      if (this.get('risk') === 'high') {
        return 2;
      } else if (this.get('risk') === 'low') {
        return 1;
      } else {
        return 0;
      }
    }),

    isCancelledAndApproved: Ember.computed('approvalState', 'cancelledAt', function () {
      return this.get('cancelledAt') && !['update_requested', 'create_requested'].includes(this.get('approvalState'));
    }),

    humanizedApprovalState: Ember.computed('approvalState', 'isCancelledAndApproved', function () {
      if (this.get('isCancelledAndApproved')) {
        return "Cancelled";
      }
      return {
        draft: "Open",
        create_requested: "Open",
        destroy_requested: "Open",
        update_requested: "Open",
        approved: "Closed"
      }[this.get('approvalState')];
    }),

    pendingState: Ember.computed('approvalState', 'isCancelledAndApproved', function () {
      if (this.get('isCancelledAndApproved')) {
        return 'closed';
      }
      return {
        draft: "open",
        create_requested: "pending_approval",
        destroy_requested: "pending_deletion",
        update_requested: "pending_approval",
        approved: "closed"
      }[this.get('approvalState')];
    }),

    paddedId: Ember.computed('id', function () {
      if (this.get('id')) {
        return this.get('id').padStart(4, '0');
      }
    }),

    daysOpen: Ember.computed('createdAt', function () {
      return (0, _moment.default)().diff(this.get('createdAt'), 'days');
    })
  });
});