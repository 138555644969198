define('frontend/components/changeset-form-for/power-date-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PowerDateField = Ember.Component.extend({
    tagName: '',
    hint: "YYYY-MM-DD"
  });

  exports.default = PowerDateField;


  PowerDateField.reopenClass({
    positionalParams: ['fieldName']
  });
});