define('frontend/routes/app/products/product/risk-management/hazardous-situations/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('hazards', this.modelFor('app.products.product.risk-management'));
      controller.set('editHazardousSituationDescription', model.get('description'));
      controller.set('editHazardousSituationHazard', model.get('hazard'));
    },
    resetController: function resetController(controller) {
      controller.set('isShowingPreviousApprovedVersion', false);
    }
  });
});