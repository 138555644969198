define('frontend/components/approvable-change/capa-phase/capa-elements-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    decoratedValues: Ember.computed('value.@each.{assignee_id,step,description,target_date}', function () {
      var _this = this;

      return (this.get('value') || []).map(function (value) {
        var assignee = void 0;

        if (value.assignee_id) {
          assignee = _this.get('store').find('user', value.assignee_id);
        }

        return {
          assignee: assignee,
          step: value.step,
          description: value.description,
          targetDate: value.target_date
        };
      });
    })
  });
});