define('frontend/routes/app/products/product/complaints', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('app.products.product').get('complaints');
    }
  });
});