define('frontend/controllers/app/products/product/non-conformances/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      closeForm: function closeForm() {
        if (this.get('model.isNew')) {
          this.transitionToRoute('app.products.product.non-conformances.index');
        } else {
          this.transitionToRoute('app.products.product.non-conformances.non-conformance', this.get('model'));
        }
      }
    }
  });
});