define('frontend/controllers/app/products/product/subsystems/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      saveSubsystem: function saveSubsystem() {
        var _this = this;

        this.get('model').saveWithNotifications().then(function () {
          return _this.transitionToRoute('app.products.product.subsystems');
        });
      }
    }
  });
});