define('frontend/controllers/onboarding/invite', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      inject = Ember.inject;
  exports.default = Controller.extend({
    session: inject.service(),

    actions: {
      save: function save() {
        var _this = this;

        this.get('model').saveWithNotifications().then(function () {
          _this.set('model', _this.store.createRecord('user'));
        });
      }
    }
  });
});