define('frontend/mixins/approver', ['exports', 'ember-data/attr', 'ember-data/relationships'], function (exports, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    approvesUserNeeds: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesRequirements: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesDesignOutputs: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesVerifications: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesValidations: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesRiskModels: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesProducts: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesComplaints: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesSuppliers: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesAdminCrs: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesAudits: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesNonConformances: (0, _attr.default)('string', { defaultValue: 'false' }),
    approvesCapas: (0, _attr.default)('string', { defaultValue: 'false' }),
    deletedAt: (0, _attr.default)('date'),
    isInactive: Ember.computed.bool('deletedAt'),
    isActive: Ember.computed.not('isInactive'),

    // in the UI riskManagement is used not riskModels. Aliasing this attribute
    // allows using a generic pattern in the matrix instead of having to have
    // special case handling of this one attribute
    approvesRiskManagement: Ember.computed.alias('approvesRiskModels'),

    qmsDocumentTypeApprovers: (0, _relationships.hasMany)('qms-document-type-approvers'),

    approvesUserNeedsConjunctively: Ember.computed.equal('approvesUserNeeds', 'conjunctive'),
    approvesRequirementsConjunctively: Ember.computed.equal('approvesRequirements', 'conjunctive'),
    approvesDesignOutputsConjunctively: Ember.computed.equal('approvesDesignOutputs', 'conjunctive'),
    approvesVerificationsConjunctively: Ember.computed.equal('approvesVerifications', 'conjunctive'),
    approvesValidationsConjunctively: Ember.computed.equal('approvesValidations', 'conjunctive'),
    approvesRiskModelsConjunctively: Ember.computed.equal('approvesRiskModels', 'conjunctive'),
    approvesProductsConjunctively: Ember.computed.equal('approvesProducts', 'conjunctive'),
    approvesComplaintsConjunctively: Ember.computed.equal('approvesProducts', 'conjunctive'),

    approvesUserNeedsDisjunctively: Ember.computed.equal('approvesUserNeeds', 'disjunctive'),
    approvesRequirementsDisjunctively: Ember.computed.equal('approvesRequirements', 'disjunctive'),
    approvesDesignOutputsDisjunctively: Ember.computed.equal('approvesDesignOutputs', 'disjunctive'),
    approvesVerificationsDisjunctively: Ember.computed.equal('approvesVerifications', 'disjunctive'),
    approvesValidationsDisjunctively: Ember.computed.equal('approvesValidations', 'disjunctive'),
    approvesRiskModelsDisjunctively: Ember.computed.equal('approvesRiskModels', 'disjunctive'),
    approvesProductsDisjunctively: Ember.computed.equal('approvesProducts', 'disjunctive'),
    approvesComplaintsDisjunctively: Ember.computed.equal('approvesProducts', 'disjunctive'),

    isRole: false,
    isNotRole: Ember.computed.not('isRole')
  });
});