define("frontend/components/non-conformance-entry-form", ["exports", "frontend/utils/build-mobile-doc", "frontend/utils/full-array-mutate"], function (exports, _buildMobileDoc, _fullArrayMutate) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dispositionOptions = undefined;
  var dispositionOptions = exports.dispositionOptions = ['Suspend Software Releases', 'Use As Is', 'Fix SW non-conformance'];

  exports.default = Ember.Component.extend({
    classNames: ['non-conformance-entry-form'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    user: Ember.computed.reads('session.currentUser'),
    company: Ember.computed.reads('user.company'),

    dispositionOptions: dispositionOptions,

    sourceOptions: Ember.computed('company.{complaints,audits,capas}.[]', function () {
      var _this = this;

      return ['Audits', 'Complaints', 'CAPAs'].map(function (groupName) {
        return {
          groupName: groupName,
          options: _this.get("company." + groupName.toLowerCase()).toArray().naturalSortBy('sourceDescription')
        };
      });
    }),

    currentTab: Ember.computed('f.changeset.ncType', function () {
      return this.get('f.changeset.ncType').capitalize();
    }),

    disabledTabs: Ember.computed('f.changeset.ncType', 'f.model.hasNeverBeenApproved', 'f.disabled', function () {
      var _this2 = this;

      if (this.get('f.disabled')) {
        return ['Product', 'Process'];
      }

      if (this.get('f.model.hasNeverBeenApproved')) {
        return [];
      }

      return ['Product', 'Process'].filter(function (t) {
        return t.toLowerCase() !== _this2.get('f.changeset.ncType');
      });
    }),

    disableDisposition: Ember.computed('f.model.latestNonConformanceDisposition.isApproved', 'editDisposition', function () {
      return this.get('f.model.latestNonConformanceDisposition.isApproved') && !this.get('editDisposition');
    }),

    nonConformancesProducts: Ember.computed('f.changeset.nonConformancesProductsSummary.@each.{product_id,software_version}', function () {
      var _this3 = this;

      return this.get('f.changeset.nonConformancesProductsSummary').map(function (spec) {
        return {
          product: spec.product_id && _this3.get('store').findRecord('product', spec.product_id),
          softwareVersion: spec.software_version
        };
      });
    }),

    nonCapaFieldsEntered: Ember.computed('f.changeset.{escalationToCapaJustification,investigationNeeded,noInvestigationJustification,investigationResults,disposition,dispositionComments,evidence}', function () {
      var _this4 = this;

      var mobiledocFields = ['escalationToCapaJustification', 'noInvestigationJustification', 'investigationResults', 'dispositionComments', 'evidence'];

      if (mobiledocFields.some(function (f) {
        return (0, _buildMobileDoc.isMobileDocPresent)(_this4.get("f.changeset." + f));
      })) {
        return true;
      }

      if (this.get('investigationNeeded') || this.get('investigationNeeded') === false) {
        return true;
      }

      if (this.get('disposition')) {
        return true;
      }

      return false;
    }),

    escalatedToCAPAInvestigationWarning: Ember.computed('f.changeset.ncType', 'f.changeset.escalationToCapa', function () {
      if (this.get('f.changeset.escalationToCapa') === false) {
        return null;
      }
      return "ESCALATED TO CAPA - See CAPA for complete Investigation details.";
    }),

    escalatedToCAPADispositionWarning: Ember.computed('f.changeset.ncType', 'f.changeset.escalationToCapa', function () {
      if (this.get('f.changeset.escalationToCapa') === false) {
        return null;
      }
      if (this.get('f.changeset.ncType') === 'product') {
        return "ESCALATED TO CAPA - See CAPA for complete Disposition details.";
      } else {
        return "ESCALATED TO CAPA - See CAPA for complete Resolution details.";
      }
    }),

    disableNonCapaFields: Ember.computed('nonCapaFieldsEntered', 'f.changeset.escalationToCapa', function () {
      return this.get('f.changeset.escalationToCapa') && this.get('nonCapaFieldsEntered');
    }),

    // Mutate the products data structure in a changeset-safe way
    changeNcpProducts: (0, _fullArrayMutate.default)('nonConformancesProductsSummary'),

    showRiskRationale: Ember.computed('f.changeset.riskRationale', 'riskRationaleRequested', 'f.disabled', function () {
      return this.get('f.disabled') || this.get('riskRationaleRequested') || this.get('f.changeset.riskRationale.sections.length');
    }),

    risk: Ember.computed('f.changeset.change.{ncType,severity,probability}', 'f.model.risk', 'f.changeset.severity.{isLoaded,ncProductRisklevels.@each.risk}', 'f.changeset.probability.isLoaded', function () {
      var originalRisk = this.get('f.model.risk');
      var change = this.get('f.changeset.change');

      if (change.ncType || change.severity || change.probability || !originalRisk) {
        return this.calculateRisk();
      } else {
        // show the original value if it's present and nothing has changed, because
        // the matrix might have changed since it was last updated
        return originalRisk;
      }
    }),

    calculateRisk: function calculateRisk() {
      var severity = this.get('f.changeset.severity');
      var probability = this.get('f.changeset.probability');
      var ncType = this.get('f.changeset.ncType');

      if (ncType === 'process' && severity && severity.get('isLoaded')) {
        var risk = severity.get('ncCapaRisk');

        if (risk === 'high') {
          this.set('f.changeset.escalationToCapa', true);
        }

        return risk;
      } else if (ncType === 'product' && severity && severity.get('isLoaded') && probability && probability.get('isLoaded')) {
        var risklevel = severity.get('ncProductRisklevels').findBy('probability.id', probability.get('id'));
        if (!risklevel.get('isLoaded')) {
          return null;
        }

        var _risk = risklevel.get('risk');

        if (_risk === 'high') {
          this.set('f.changeset.escalationToCapa', true);
        }

        return _risk;
      }
    },


    actions: {
      changeNcType: function changeNcType(newType) {
        var _this5 = this;

        if (this.get('f.changeset.ncType') === newType.toLowerCase()) {
          return;
        }

        var message = "Are you sure you want to change the type? This will reset the details fields to blank.";

        var changeFields = {
          description: null,
          processRequirement: null,
          processRequirementQmsDocument: null,
          product: null,
          nonConformancesProductsSummary: {
            value: [],
            setter: function setter() {
              return _this5.changeNcpProducts(function (array) {
                return array.clear();
              });
            }
          },
          defectTypes: {
            value: [],
            setter: function setter() {
              return (0, _fullArrayMutate.fullHasManyMutator)(_this5, 'defectTypes', []);
            }
          },
          severity: null,
          probability: null,
          riskRationale: null,
          unknownProduct: false
        };

        var defaultValueFor = function defaultValueFor(key) {
          var defaultValue = changeFields[key];
          if (defaultValue && defaultValue.value) {
            return defaultValue.value;
          } else {
            return defaultValue;
          }
        };
        var keys = Object.keys(changeFields);

        var hasChanged = void 0;
        if (this.get('f.model.isNew')) {
          hasChanged = keys.some(function (k) {
            return _this5.get("f.changeset.change." + k);
          });
        } else {
          hasChanged = keys.some(function (k) {
            return JSON.stringify(_this5.get("f.changeset." + k)) !== JSON.stringify(defaultValueFor(k));
          });
        }

        if (hasChanged && confirm(message)) {
          keys.forEach(function (key) {
            var defaultValue = changeFields[key];
            if (defaultValue && defaultValue.setter) {
              defaultValue.setter();
            } else {
              _this5.set("f.changeset." + key, defaultValue);
            }
          });
          this.set('f.changeset.ncType', newType.toLowerCase());
        } else if (!hasChanged) {
          this.set('f.changeset.ncType', newType.toLowerCase());
        }
      },
      addProduct: function addProduct() {
        this.changeNcpProducts(function (array) {
          return array.pushObject({ product_id: null, software_version: '' });
        });
      },
      removeProduct: function removeProduct(i) {
        this.changeNcpProducts(function (array) {
          return array.removeAt(i);
        });
      },
      changeNcpProduct: function changeNcpProduct(i, product) {
        this.changeNcpProducts(function (array) {
          return Ember.set(array.objectAt(i), 'product_id', product && Number(product.get('id')));
        });
      },
      changeNcpSoftwareVersion: function changeNcpSoftwareVersion(i, _ref) {
        var value = _ref.target.value;

        this.changeNcpProducts(function (array) {
          return Ember.set(array.objectAt(i), 'software_version', value);
        });
      }
    }
  });
});