define('frontend/utils/full-array-mutate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = fullArrayMutator;
  exports.fullHasManyMutator = fullHasManyMutator;
  // Deep copy an array then mutate it in a callback, this is required when you
  // want an array with all objects deep cloned and then to edit parts of the
  // object graph, without changing the orininal array. Used for changeset forms.

  function fullArrayMutator(propertyName) {
    return function (cb) {
      // roundtrip to json to ensure deep clone
      var array = JSON.parse(JSON.stringify(this.get('f.changeset.' + propertyName)));
      cb(array);
      var original = this.get('f.model.' + propertyName);
      if (JSON.stringify(original) === JSON.stringify(array)) {
        // arrays have same contents so set to original array so it maintains
        // object identity with orignal
        this.set('f.changeset.' + propertyName, original);
      } else {
        this.set('f.changeset.' + propertyName, array);
      }
    };
  }

  function fullHasManyMutator(object, propertyName, items) {
    var originalItems = object.get('f.model.' + propertyName);

    if (sameArrayContents(originalItems, items)) {
      // If the array has the same contents, set it back to the original array,
      // so that it's not seen as dirty - changeset only cares if a === b, not
      // if a and b have the same contents
      object.set('f.changeset.' + propertyName, originalItems);
    } else {
      object.set('f.changeset.' + propertyName, items);
    }
  }

  function sameArrayContents(a, b) {
    if (a.get('length') !== b.get('length')) {
      return false;
    }

    return a.every(function (i) {
      return b.includes(i);
    });
  }
});