define('frontend/components/md-5', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: null,
    md5: Ember.computed('value', function () {
      if (Ember.isEmpty(this.get('value'))) {
        return "";
      }
      return md5(this.get('value'));
    })
  });
});