define('frontend/utils/change-request-query-params', ['exports', 'ember-parachute', 'moment'], function (exports, _emberParachute, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var myQueryParams = new _emberParachute.default({
    productId: {
      defaultValue: null,
      replace: true,
      refresh: true,
      as: 'product'
    },
    stateValue: {
      defaultValue: null,
      replace: true,
      refresh: true,
      as: 'state',
      deserialize: function deserialize(value) {
        return value;
      }
    },
    requesterValue: {
      defaultValue: null,
      replace: true,
      refresh: true,
      as: 'requester'
    },
    createdOn: {
      defaultValue: null,
      replace: true,
      refresh: true,
      as: 'created-on',
      serialize: function serialize(date) {
        return (0, _moment.default)(date).format('YYYY-MM-DD');
      },
      deserialize: function deserialize(string) {
        return (0, _moment.default)(string, 'YYYY-MM-DD').toDate();
      }
    },
    approverValue: {
      defaultValue: null,
      replace: true,
      refresh: true,
      as: 'approver'
    },
    approvableValue: {
      defaultValue: null,
      replace: true,
      refresh: true,
      as: 'approvable'
    }
  });

  exports.default = myQueryParams;
});