define('frontend/models/training-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    questionText: attr('string'),
    createdAt: attr('date'),
    training: belongsTo('training'),
    correctAnswer: belongsTo('training-question-answer'),
    trainingQuestionAnswers: hasMany('training-question-answer')
  });
});