define('frontend/components/form-fields/search-field', ['exports', 'ember-form-for/components/form-fields/search-field'], function (exports, _searchField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _searchField.default;
    }
  });
});