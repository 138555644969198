define('frontend/models/training-assignment-attempt', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    /**
     * @type {PromiseArray<TrainingQuestionAnswer>}
     */
    trainingQuestionAnswers: hasMany('training-question-answer'),

    /**
     * @type {PromiseObject<TrainingAssignment>}
     */
    trainingAssignment: belongsTo('training-assignment')
  });
});