define('frontend/controllers/app/products/product/document-control/document-item/edit', ['exports', 'frontend/mixins/assignable-document-numbers', 'frontend/mixins/permissions'], function (exports, _assignableDocumentNumbers, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_permissions.default, _assignableDocumentNumbers.default, {
    productController: Ember.inject.controller('app/products/product'),
    session: Ember.inject.service(),
    list: Ember.inject.service(),

    product: Ember.computed.alias('productController.model'),
    company: Ember.computed.alias('product.company'),
    qmsDocument: Ember.computed.alias('model'),
    qmsDocumentType: Ember.computed.alias('model.qmsDocumentType'),

    qmsFoldersByLevel: Ember.computed.reads('list.qmsFoldersByLevel'),
    selectedFolder: Ember.computed('qmsDocumentFolder.id', 'product.qmsDocumentFoldersByLevels.@each.folder', function () {
      return this.get('product.qmsDocumentFoldersByLevels').findBy('folder.id', this.get('qmsDocumentFolder.id'));
    }),

    actions: {
      updateQmsDocument: function updateQmsDocument() {
        var _this = this;

        this.get('qmsDocument').setProperties({
          qmsDocumentFolder: this.get('qmsDocumentFolder'),
          products: this.get('products')
        });
        this.get('qmsDocument').saveWithNotifications().then(function (qmsDocument) {
          _this.transitionToRoute('app.products.product.document-control.document-item', qmsDocument);
        });
      },
      saveTag: function saveTag(tagName) {
        return this.get('qmsDocument').tagWith(tagName);
      },
      removeTagging: function removeTagging(tagging) {
        tagging.destroyWithNotifications();
      }
    }
  });
});