define('frontend/components/capa-entry-form/summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    phase: Ember.computed('f.changeset.capa.capaPhases.@each.phaseNumber', 'phaseNumber', function () {
      var phases = this.get('f.changeset.capa.capaPhases');

      return phases.findBy('phaseNumber', +this.get('phaseNumber'));
    })

  });
});