define('frontend/components/approval-item-card/field-changes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    approvalItem: null,

    isDesignReview: false,

    designReviewItemNames: Ember.computed('isDesignReview', 'approvalItem.items.@each.shortName', function () {
      if (this.get('isDesignReview')) {
        return this.get('approvalItem.items').mapBy('shortName').join(', ');
      }

      return '';
    })
  });
});