define('frontend/routes/app', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    websocket: Ember.inject.service('websocket'),
    metrics: Ember.inject.service(),
    list: Ember.inject.service(),

    model: function model() {
      return this.get('session.currentUserPromiseProxy');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      this.get('websocket').subscribeToChannel(model.get('websocketChannel'));
      this.get('websocket').subscribeToChannel(model.get('coWebsocketChannel'));
      this.get('list').warmup();
    },
    resetController: function resetController(controller) {
      this.get('websocket').unsubscribeFromChannel(controller.get('model.websocketChannel'));
      this.get('websocket').unsubscribeFromChannel(controller.get('model.coWebsocketChannel'));
    },


    actions: {
      didTransition: function didTransition() {
        //ember metrics will default to the `update` command on the Intercom adapter if already logged in
        //call again here so we get updated URL path to Intercom for each route transition.
        var email = this.get('session').lastUsedEmail;
        var userHash = this.get('session').get('session.content.authenticated.user_hash');
        this.get('metrics').identify('Intercom', { distinctId: email, user_hash: userHash });
        return true; // Bubble the didTransition event
      }
    }
  });
});