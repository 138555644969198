define('frontend/components/enzyme-power-select-collection', ['exports', 'frontend/components/enzyme-power-select'], function (exports, _enzymePowerSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _enzymePowerSelect.default.extend({
    optionsComponent: 'enzyme-power-select-collection/options',

    scrollTo: function scrollTo(highlighted, select) {
      var index = select.results.indexOf(highlighted);
      var optionsElement = document.getElementById('ember-power-select-options-' + select.uniqueId);

      var _optionsElement$getBo = optionsElement.getBoundingClientRect(),
          height = _optionsElement$getBo.height;

      var currentScrollY = optionsElement.scrollTop;
      var topOfOption = index * 28;
      var bottomOfOption = topOfOption + 28;
      if (bottomOfOption > currentScrollY + height) {
        optionsElement.scrollTop = bottomOfOption - height;
      } else if (topOfOption < currentScrollY) {
        optionsElement.scrollTop = topOfOption;
      }
    }
  });
});