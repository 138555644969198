define('frontend/tours/viewer-document-control-module', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = {
    name: 'Document Control Module (Viewer)',
    setup: function setup() {
      var allproducts = this.store.peekAll('product');
      this.realproduct = allproducts.get('firstObject');
      var allcompanies = this.store.peekAll('company');
      this.company = allcompanies.get('firstObject');
      this.product = this.store.createRecord('product', { name: "Example product", id: 'example', phase: 'product_definition', company: this.company });
      // ids are decimals to both (a) not conflict with existing records and (b) be > 0, which is
      // required to be considered live and show on the dashboard

      this.docType = this.company.get('qmsDocumentTypes').createRecord({ title: "EXAMPLE", id: 0.1, lastDocumentNumber: 1, unassignableDocumentNumbers: '' });
      this.docFolder = this.company.get('qmsDocumentFolders').createRecord({ name: "Example Folder", id: 0.1 });
      this.fileUpload1 = this.company.get('fileUploads').createRecord({ fileUrl: "example.doc", pdfUrl: "example.pdf", id: 0.1 });
      this.fileUpload2 = this.company.get('fileUploads').createRecord({ fileUrl: "example2.doc", pdfUrl: "example2.pdf", id: 0.2 });
      this.qmsDoc1 = this.company.get('qmsDocuments').createRecord({ title: "Example Global Doc", id: 0.1, fileUpload: this.fileUpload1, qmsDocumentType: this.docType, qmsDocumentFolder: this.company.get('rootGlobalFolder'), obsolete: false, versionString: '0.1', majorVersionNumber: 0, minorVersionNumber: 1, documentNumber: 1 });
      this.qmsDoc2 = this.company.get('qmsDocuments').createRecord({ title: "Example Doc", id: 0.2, fileUpload: this.fileUpload2, qmsDocumentFolder: this.docFolder, product: this.product, qmsDocumentType: this.docType, obsolete: false, versionString: '0.1', majorVersionNumber: 0, minorVersionNumber: 1, documentNumber: 2 });

      this.documentControl = [this.docType, this.docFolder, this.fileUpload1, this.fileUpload2, this.qmsDoc1, this.qmsDoc2];
    },
    teardown: function teardown() {
      this.documentControl.invoke('destroyRecord');
      this.product.destroyRecord();
    },

    steps: [{
      prepare: function prepare() {
        return this.transitionTo("app.products.product.document-control.index", this.product);
      },

      text: "This is the <b>Document Control</b> module.<br><br>Document Control is intended for <u>documents and records</u> that are part of your quality management system but <i>not otherwise captured in another module</i>.",
      selector: '#doc-page-main',
      animationRoot: '.product-liquid-outlet'
    }, {
      prepare: function prepare() {
        $('.main-nav li:contains("Process") a').trigger('mouseenter');
        return this.waitFor(function () {
          return $('.liquid-animating').length === 0;
        });
      },

      text: "<b>Document Control</b> is accessed from the navbar, here.",
      selector: '.main-navigation-dropdown a.active'
    }, {
      prepare: function prepare() {
        $('.main-nav li:contains("Process") a').trigger('mouseleave');
      },

      text: "Documents are accessed via this sidebar.<br><br>Documents are stored in <u>folders</u>. Each product can have an arbitrary amount of folders, and your company has a <b>Global Documents</b> folder that can store documents (like SOPs) that are common to all products.",
      selector: '#document-control-sidebar'
    }, {
      text: "Folders can be opened and closed by clicking on the folder header.",
      selector: '.top-level-document-folder'
    }, {
      text: "Documents are accessed by clicking on them.",
      selector: '#document-control-sidebar #accordion-menu .expanded.ember-view:first li:last'
    }, {
      prepare: function prepare() {
        return this.transitionTo("app.products.product.document-control.document-item", this.qmsDoc1);
      },

      text: "After clicking on the document, you will view the document information page for that document.",
      selector: '#doc-page-main',
      animationRoot: '.document-control-outlet'
    }, {
      prepare: function prepare() {},

      text: "This section details the information about the document, from top to bottom: <ul><li>The filename</li><li>The document title</li><li>The Document type</li><li>The document number</li><li>The native format</li><li>The effective version</li><li>When the document became effective</li><li>When the document was last approved</li><li>Any tags that have been added to the document</li></ul>",
      selector: '#doc-info-list',
      scrollTop: '200px'
    }, {
      prepare: function prepare() {},

      text: "The number of any document version will tell you if that document has been approved or is still in draft. <br><br><u>Approved documents always end in '.0'</u><br><u>Draft documents do not.</u>",
      selector: '#doc-info-list',
      scrollTop: '200px'
    }, {
      prepare: function prepare() {
        $('.tabs-nav a:first').click();
      },

      text: "For each QMS document, you can view the entire document history, every draft and approved version, by clicking <b>Versions</b>.",
      selector: '.tabs-nav a:nth-child(3)',
      scrollTop: '0px'
    }, {
      prepare: function prepare() {
        $('.tabs-nav a:contains(Version History)').click();
      },

      text: "For each version, you will see the: <ul><li>Version Number</li><li>Document Format</li><li>Last Modified Date</li><li>User who Last Updated</li><li>and Approvers, if any</li></ul>.<br><br>The download link next to 'Version' will always give the full document, including cover sheet and signatures.<br>The download link next to 'Format' will give the native version of the document.",
      selector: '.tabs-content .table',
      animationDelay: 250
    }, {
      text: "This concludes <b>Document Control</b> module training!",
      selector: 'body'
    }]
  };
});