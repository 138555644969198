define('frontend/controllers/app/products/product/audits/audit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentUser: Ember.computed.alias('session.currentUser'),
    chooseApprovers: false,
    queryParams: ['auditTab'],
    supplierTab: 'record',

    tabList: Ember.computed('model.pendingApprovalSet', function () {
      var baseTabs = ["record"];
      if (this.get('model.pendingApprovalSet')) {
        baseTabs.push('approval status');
      }
      return baseTabs;
    }),

    isReopened: false,
    disableForm: Ember.computed('model.{canNotEdit,isApproved}', 'isReopened', function () {
      return this.get('model.canNotEdit') || this.get('model.isApproved') && !this.get('isReopened');
    }),

    actions: {
      closeForm: function closeForm() {
        this.transitionToRoute('app.products.product.audits.index');
      },
      deleteAudit: function deleteAudit() {
        var _this = this;

        if (confirm("Are you sure you want to delete this audit?")) {
          this.get('model').destroyWithNotifications().then(function () {
            return _this.transitionToRoute("app.products.product.audits");
          });
        }
      },
      approverBlacklistChanged: function approverBlacklistChanged(blacklist, selectedApprovers) {
        this.set('approverBlacklist', blacklist);
        this.set('selectedApprovers', selectedApprovers);
      },
      routeForApproval: function routeForApproval() {
        this.set('chooseApprovers', false);
        var blacklist = this.get('approverBlacklist');
        return this.get('model').routeForApproval({ blacklist: blacklist });
      }
    }
  });
});