define('frontend/services/notifications', ['exports', 'frontend/utils/group-by'], function (exports, _groupBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    pendingActionItemIndex: 0,

    items: (0, _groupBy.default)('notificationsUnion', 'itemsReducer'),
    notificationsUnion: Ember.computed.union('approvalItems', 'undismissedNotificationItems'),
    approvalItems: Ember.computed.reads('session.currentUser.myApprovalItems'),
    notificationItems: Ember.computed.reads('session.currentUser.notifications'),
    undismissedNotificationItems: Ember.computed.filterBy('notificationItems', 'undismissed'),
    pendingActionItems: Ember.computed.reads('approvalItems'),

    // TODO: Simplify this
    pendingActionItemBoundedIndex: Ember.computed('pendingActionItemIndex', 'pendingActionItems.length', function () {
      // All this because javascript doesn't have a proper modulo operator
      var n = this.get('pendingActionItemIndex');
      var m = this.get('pendingActionItems.length');
      var r = n % m;

      if (r < 0) {
        r += m;
      }
    }),

    itemsReducer: function itemsReducer(accumulator, currentValue) {
      var changeRequest = currentValue.get('pendingApprovalSet');

      // If the current item has a change request
      if (changeRequest && !changeRequest.get('lite')) {

        // Go through the current array and see if a group 
        // contains the change request
        var group = accumulator.find(function (item) {
          if (!Ember.isArray(item)) {
            return false;
          }

          var itemChangeRequestId = item.get('firstObject.pendingApprovalSet.id');
          var changeRequestId = changeRequest.get('id');

          return itemChangeRequestId === changeRequestId;
        });

        // If a group does exist with the current change request
        if (group) {
          // Then push the current item into that group
          group.pushObject(currentValue);
        } else {
          // Otherwise push the current item as a group into the current array
          accumulator.pushObject([currentValue]);
        }

        return accumulator;
      }

      // Otherwise just push the current item into the array, 
      // since the  relationship does not have a 
      // change request (approval set relationship).
      accumulator.pushObject(currentValue);

      return accumulator;
    }
  });
});