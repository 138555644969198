define('frontend/components/view-options', ['exports', 'frontend/models/design-control'], function (exports, _designControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['view-options-component'],
    viewOptions: inject.service(),

    designControlTypes: Object.keys(_designControl.designControlTypes).map(function (key) {
      return {
        name: key,
        humanName: _designControl.designControlTypes[key]
      };
    }),

    selectedDesignControlType: computed('viewOptions.focusedColumn', 'designControlTypes.[]', function () {
      return this.get('designControlTypes').findBy('name', this.get('viewOptions.focusedColumn'));
    }),

    actions: {
      focusColumn: function focusColumn(type) {
        if (type) {
          this.get('viewOptions').focusColumn(type.name);
        } else {
          this.get('viewOptions').focusColumn(null);
        }
      }
    }
  });
});