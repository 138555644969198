define('frontend/controllers/app/products/product/risk-management', ['exports', 'frontend/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_permissions.default, {
    productController: Ember.inject.controller('app.products.product'),

    viewOptions: Ember.inject.service(),

    selectedSubsystem: Ember.computed.reads('viewOptions.selectedSubsystem'),
    searchText: Ember.computed.alias('productController.searchText'),
    product: Ember.computed.alias('productController.model'),
    allHazards: Ember.computed.alias('product.liveHazards'),
    isEditable: Ember.computed.alias('canEdit'),

    hazards: Ember.computed('allHazards.@each.{subsystem,isApproved}', 'selectedSubsystem', 'viewOptions.approvedModeOn', function () {
      var hazards = this.get('allHazards');

      if (this.get('selectedSubsystem')) {
        hazards = hazards.filterBy('subsystem.id', this.get('selectedSubsystem.id'));
      }

      if (this.get('viewOptions.approvedModeOn')) {
        hazards = hazards.filterBy('isApproved');
      }

      return hazards;
    })
  });
});