define('frontend/controllers/reset-password', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_emberValidations.default, {
    queryParams: ['reset_password_token'],
    reset_password_token: '',
    password: '',
    passwordConfirmation: '',
    errors: [],
    displayErrors: false,
    validations: {
      'password': {
        presence: true,
        length: { minimum: 8 },
        confirmation: { message: "Passwords do not match" }
      },
      'passwordConfirmation': {
        presence: true,
        length: { minimum: 8 }
      },
      'reset_password_token': {
        presence: true
      }
    },

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.validate().then(function () {
          if (_this.get('isValid')) {
            Ember.$.ajax({
              type: "PUT",
              url: '/api/users/password',
              contentType: "application/json",
              data: JSON.stringify({ user: { password: _this.get('password'), passwordConfirmation: _this.get('passwordConfirmation'), reset_password_token: _this.get('reset_password_token') } }),
              success: function success() {
                _this.transitionToRoute('login');
              },
              error: function error(_error) {
                _this.set('showErrorMessage', true);
                _this.set('errorMessage', JSON.parse(_error.responseText).errors.password[0]);
              }
            });
          }
        }, function () {
          _this.set('displayErrors', true);
        });
      }
    }

  });
});