define('frontend/components/tour-step', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'tour-step-container',
    tour: Ember.inject.service(),
    scroll: 0,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.$(window).on('resize', function () {
        requestAnimationFrame(function () {
          Ember.run(function () {
            if (!_this.isDestroyed) {
              _this.set('windowWidth', window.innerWidth);
              _this.set('windowHeight', window.innerHeight);
            }
          });
        });
      });
      Ember.$(window).on('scroll', function () {
        requestAnimationFrame(function () {
          Ember.run(function () {
            if (!_this.isDestroyed) {
              _this.set('scroll', window.scrollY);
            }
          });
        });
      });
    },


    spotlightBounds: Ember.computed('tour.spotlightTarget', 'windowWidth', 'windowHeight', function () {
      var containingBounds = { top: Infinity, right: 0, bottom: 0, left: Infinity };
      var $target = this.get('tour.spotlightTarget');
      if ($target) {
        $target.each(function (i, el) {
          var _Ember$$offset = Ember.$(el).offset(),
              left = _Ember$$offset.left,
              top = _Ember$$offset.top;

          var width = Ember.$(el).outerWidth();
          var height = Ember.$(el).outerHeight();
          containingBounds.left = Math.min(containingBounds.left, left);
          containingBounds.right = Math.max(containingBounds.right, left + width);
          containingBounds.top = Math.min(containingBounds.top, top);
          containingBounds.bottom = Math.max(containingBounds.bottom, top + height);
        });
      }
      var padding = 20;
      containingBounds.left -= padding;
      containingBounds.right += padding;
      containingBounds.top -= padding;
      containingBounds.bottom += padding;
      return containingBounds;
    }),

    spotlightLeftStyle: Ember.computed('spotlightBounds', function () {
      var bounds = this.get('spotlightBounds');
      return Ember.String.htmlSafe('width: ' + bounds.left + 'px');
    }),

    spotlightRightStyle: Ember.computed('spotlightBounds.right', function () {
      var bounds = this.get('spotlightBounds');
      return Ember.String.htmlSafe('left: ' + bounds.right + 'px; width: calc(100vw - ' + bounds.right + 'px)');
    }),

    spotlightTopStyle: Ember.computed('spotlightBounds.top', function () {
      var bounds = this.get('spotlightBounds');
      return Ember.String.htmlSafe('\n      height: ' + bounds.top + 'px;\n      left: ' + bounds.left + 'px;\n      width: ' + (bounds.right - bounds.left) + 'px;\n    ');
    }),

    spotlightBottomStyle: Ember.computed('spotlightBounds.bottom', function () {
      var bounds = this.get('spotlightBounds');
      return Ember.String.htmlSafe('\n      top: ' + bounds.bottom + 'px;\n      left: ' + bounds.left + 'px;\n      width: ' + (bounds.right - bounds.left) + 'px;\n    ');
    }),

    referenceElement: Ember.computed('spotlightBounds', 'scroll', function () {
      var bounds = this.get('spotlightBounds');
      var scroll = this.get('scroll');
      return {
        getBoundingClientRect: function getBoundingClientRect() {
          return Ember.assign({}, bounds, {
            x: bounds.left,
            y: bounds.top - scroll,
            width: bounds.right - bounds.left,
            height: bounds.bottom - bounds.top,
            top: bounds.top - scroll,
            bottom: bounds.bottom - scroll
          });
        },

        clientWidth: bounds.right - bounds.left,
        clientHeight: bounds.bottom - bounds.top
      };
    })

  });
});