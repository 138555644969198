define('frontend/components/qms-document-links-form', ['exports', 'frontend/utils/documents-form'], function (exports, _documentsForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    list: Ember.inject.service(),

    availableQmsDocumentsByFolder: Ember.computed('list.qmsFolders.[]', 'list.qmsDocuments.[]', 'linkedQmsDocuments.[]', function () {
      var attachedIds = this.get('linkedQmsDocuments').mapBy('id');
      var folders = this.get('list.qmsFolders').naturalSortBy('name');

      if (!folders.get('length')) {
        return folders;
      }

      var result = [];

      for (var i = 0; i < folders.get('length'); i++) {
        var folder = folders.objectAt(i);
        var folderData = (0, _documentsForm.generateOptions)(folder, attachedIds);

        if (folderData.documentsCount > 0) {
          result.push({
            groupName: folder.get('name'),
            options: folderData.options.naturalSortBy('docIdentifier')
          });
        }
      }
      return result;
    })
  });
});