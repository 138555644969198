define('frontend/components/changeset-form-for/power-select-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PowerSelectField = Ember.Component.extend({
    tagName: '',
    changeset: Ember.computed.reads('f.changeset'),
    allowClear: true,

    actions: {
      onchange: function onchange(changeset, field, value) {
        changeset.set(field, value);
        if (this.get('onchange')) {
          this.get('onchange')(value);
        }
      }
    }
  });

  PowerSelectField.reopenClass({ positionalParams: ['field'] });

  exports.default = PowerSelectField;
});