define('frontend/mixins/assignable-document-numbers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      computed = Ember.computed,
      alias = Ember.computed.alias;
  exports.default = Mixin.create({
    unassignableDocumentNumbers: alias('qmsDocumentType.unassignableDocumentNumbers'),

    assignableDocumentNumbers: computed('unassignableDocumentNumbers.[]', 'qmsDocumentType', function () {
      if (!this.get('qmsDocumentType')) {
        return ['Choose a QMS Document Type first'];
      }

      var assignable = [];

      var documentNumber = this.get('documentNumber');
      if (documentNumber) {
        assignable.push(this.get('documentNumber'));
      }

      for (var i = 1; i < 200 && assignable.length < 50; i++) {
        if (!this.get('unassignableDocumentNumbers') || !this.get('unassignableDocumentNumbers').includes(i)) {
          assignable.push(i);
        }
      }
      return assignable.uniq();
    }),

    unassignableFormNumbers: alias('parentQmsDocument.unassignableFormNumbers'),

    assignableFormNumbers: computed('unassignableFormNumbers.[]', 'parentQmsDocument', function () {
      if (!this.get('parentQmsDocument')) {
        return ['Choose a Parent QMS Document'];
      }

      var assignable = [];

      var formNumber = this.get('formNumber');
      if (formNumber) {
        assignable.push(this.get('formNumber'));
      }

      for (var i = 1; i < 200 && assignable.length < 50; i++) {
        if (!this.get('unassignableFormNumbers') || !this.get('unassignableFormNumbers').includes(i)) {
          assignable.push(i);
        }
      }
      return assignable.uniq();
    })
  });
});