define('frontend/models/capa-phase', ['exports', 'ember-data', 'ember-data/relationships', 'ember-data/attr', 'frontend/mixins/approvable', 'frontend/initializers/add-model-async-methods', 'moment'], function (exports, _emberData, _relationships, _attr, _approvable, _addModelAsyncMethods, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_approvable.default, {
    capa: (0, _relationships.belongsTo)(),
    company: (0, _relationships.belongsTo)(),
    nonConformance: (0, _relationships.belongsTo)(),
    capaElements: (0, _relationships.hasMany)(),
    rootCauseCodes: (0, _relationships.hasMany)(),
    previousPhase: (0, _relationships.belongsTo)('capa-phase', { inverse: 'nextPhase' }),
    nextPhase: (0, _relationships.belongsTo)('capa-phase', { inverse: 'previousPhase' }),
    fileUploads: (0, _relationships.hasMany)(),
    capaOwner: (0, _relationships.belongsTo)('user'),
    capaPhaseExtension: (0, _relationships.belongsTo)(),

    capaElementsSummary: (0, _attr.default)(undefined, { defaultValue: function defaultValue() {
        return [];
      } }),
    capaElementResultsSummary: (0, _attr.default)(undefined, { defaultValue: function defaultValue() {
        return [];
      } }),
    capaSolutionsSummary: (0, _attr.default)(undefined, { defaultValue: function defaultValue() {
        return [];
      } }),
    title: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    startDate: (0, _attr.default)('date-no-time'),
    dueDate: (0, _attr.default)('date-no-time'),
    phaseNumber: (0, _attr.default)('number'),
    solutionEffective: (0, _attr.default)('boolean'),
    finalClosure: (0, _attr.default)('boolean'),
    deviations: (0, _attr.default)('mobiledoc'),
    rootCauseAnalysis: (0, _attr.default)('mobiledoc'),
    proposedSolution: (0, _attr.default)('mobiledoc'),
    verificationValidationCriteria: (0, _attr.default)('mobiledoc'),
    traceability: (0, _attr.default)('mobiledoc'),
    experimentalMethod: (0, _attr.default)('mobiledoc'),
    results: (0, _attr.default)('mobiledoc'),
    conclusion: (0, _attr.default)('mobiledoc'),
    discussionRecommendations: (0, _attr.default)('mobiledoc'),
    voeCriteria: (0, _attr.default)('mobiledoc'),
    voeResults: (0, _attr.default)('mobiledoc'),
    capaTitle: (0, _attr.default)('string'),
    nextPhaseDueDate: (0, _attr.default)('date-no-time'),
    resolution: (0, _attr.default)('mobiledoc'),
    finalClosureComment: (0, _attr.default)('mobiledoc'),

    routeForApproval: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'route_for_approval', type: 'post' }),

    humanizedApprovalState: Ember.computed('approvalState', function () {
      return {
        draft: "Open",
        create_requested: "Open",
        destroy_requested: "Open",
        update_requested: "Open",
        approved: "Closed"
      }[this.get('approvalState')];
    }),

    pendingState: Ember.computed('approvalState', function () {
      return {
        draft: "open",
        create_requested: "pending_approval",
        destroy_requested: "pending_deletion",
        update_requested: "pending_approval",
        approved: "closed"
      }[this.get('approvalState')];
    }),

    cssPendingState: Ember.computed('approvalState', function () {
      return {
        draft: "draft",
        create_requested: "pending",
        destroy_requested: "pending",
        update_requested: "pending",
        approved: "approved"
      }[this.get('approvalState')];
    }),

    paddedId: Ember.computed('id', function () {
      if (this.get('id')) {
        return this.get('id').padStart(4, '0');
      }
    }),

    daysOpen: Ember.computed('createdAt', function () {
      return (0, _moment.default)().diff(this.get('createdAt'), 'days');
    }),

    lastApprovedVersion: Ember.computed('sortedVersions', function () {
      return this.get('sortedVersions').findBy('approvalState', 'approved');
    }),

    capaOwnerApproval: Ember.computed('lastApprovedVersion.approverNames.[]', 'capaOwner.fullName', function () {
      var approverNames = this.get('lastApprovedVersion.approverNames');
      var capaOwnerName = this.get('capaOwner.fullName');
      return (approverNames || []).filter(function (name) {
        return name == capaOwnerName;
      });
    }),

    otherApproval: Ember.computed('lastApprovedVersion.approverNames.[]', 'capaOwner.fullName', function () {
      var approverNames = this.get('lastApprovedVersion.approverNames');
      var capaOwnerName = this.get('capaOwner.fullName');
      return (approverNames || []).filter(function (name) {
        return name != capaOwnerName;
      });
    }),

    routableTarget: "app.products.product.capas.capa",
    routableParams: Ember.computed('capaPhase.{capa,phaseNumber}', function () {
      return [this.get('capa'), { queryParams: { currentTab: 'Phase ' + this.get('phaseNumber') } }];
    })
  });
});