define('frontend/routes/app/products/product/document-control/document-item/revise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    list: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var fileUpload = model.get('fileUpload');
      var qmsDocumentFileUpload = this.get('store').peekRecord('file-upload', fileUpload.get('id'));
      var title = model.get('title');

      controller.setProperties({
        qmsDocumentFileUpload: qmsDocumentFileUpload,
        attachmentsMarkedForRemoval: [],
        attachmentsMarkedForAddition: [],
        title: title
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        var controller = this.controllerFor(this.routeName);

        if (controller.get('changesExist')) {
          var result = confirm('You have unsaved changes. Do you want to leave anyway?');

          if (!result) {
            transition.abort();
          }
        }
      }
    }
  });
});