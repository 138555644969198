define("frontend/components/audit-entry-form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    user: Ember.computed.reads('session.currentUser'),
    company: Ember.computed.reads('user.company')
  });
});