define('frontend/models/pivotal-webhook', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    apiToken: (0, _attr.default)('string'),
    projectId: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    projectName: (0, _attr.default)('string'),
    webhookId: (0, _attr.default)('string'),
    webhookUrl: (0, _attr.default)('string'),
    product: (0, _relationships.belongsTo)('product')
  });
});