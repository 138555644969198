define('frontend/components/enzyme-power-select-multiple/group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    disabled: Ember.computed.reads('group.disabled'),
    groupName: Ember.computed.reads('group.groupName'),
    conjunctive: Ember.computed.reads('group.conjunctive')
  });
});