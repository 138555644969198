define('frontend/components/formatted-subfolder-path', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    store: service(),
    folder: computed('theFolder', function () {
      return this.get('theFolder');
    }),
    index: computed('theIndex', function () {
      return this.get('theIndex');
    }),
    oneLevelNesting: computed.equal('index', 0),
    twoLevelNesting: computed.equal('index', 1),
    threeLevelNesting: computed.equal('index', 2),
    fourLevelNesting: computed.equal('index', 3)
  });
});