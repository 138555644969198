define('frontend/routes/app/products/product/risk-management/harms/matrix', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    setupController: function setupController(controller) {
      controller.set('product', this.modelFor('app.products.product'));
      controller.set('risklevels', this.modelFor('app.products.product').get('risklevels'));
      controller.set('severities', this.modelFor('app.products.product').get('company.productSeverities'));
      controller.set('probabilities', this.modelFor('app.products.product').get('probabilities'));
    },
    resetController: function resetController() {
      this.modelFor('app.products.product').get('risklevels').forEach(function (risklevel) {
        if (risklevel) {
          // prevent training error
          if (risklevel.get('id') >= 1) {
            // filter out training
            risklevel.rollbackAttributes();
          }
        }
      });
    }
  });
});