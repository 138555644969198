define("frontend/components/extension-request", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: '',
    capaPhase: Ember.computed.alias('f.model'),
    capaPhaseExtension: Ember.computed.readOnly('capaPhase.capaPhaseExtension.content'),
    extensionTab: Ember.computed('formDisabled', function () {
      if (this.get('formDisabled')) {
        return 'Past Requests';
      } else {
        return 'New Request';
      }
    }),
    tabList: Ember.computed('formDisabled', function () {
      if (this.get('formDisabled')) {
        return ['Past Requests'];
      } else {
        return ['New Request', 'Past Requests'];
      }
    }),

    formDisabled: Ember.computed.readOnly('f.disabled'),
    showExtensionRequestButton: Ember.computed('capaPhase.dueDate', 'capaPhase.startDate', function () {
      return this.get('capaPhase.dueDate') && this.get('capaPhase.startDate');
    }),

    enableSubmit: Ember.computed('capaPhaseExtension.pendingApprovalSet', 'selectedApprovers.[]', 'capaPhase.dueDate', 'capaPhaseExtension.newDate', 'capaPhaseExtension.isSaving', function () {
      return !this.get('capaPhaseExtension.pendingApprovalSet') && !this.get('capaPhaseExtension.isSaving') && this.get('selectedApprovers.length') && this.get('capaPhaseExtension.newDate') > this.get('capaPhase.dueDate');
    }),

    disableSubmit: Ember.computed.not('enableSubmit'),

    actions: {
      approverBlacklistChanged: function approverBlacklistChanged(blacklist, selectedApprovers) {
        this.set('approverBlacklist', blacklist);
        this.set('selectedApprovers', selectedApprovers);
      },
      routeForApproval: function routeForApproval() {
        var blacklist = this.get('approverBlacklist');
        this.set('isExtensionRequest', false);
        return this.get('capaPhaseExtension').routeForApproval({ blacklist: blacklist });
      }
    }
  });
});