define('frontend/controllers/app/products/product/risk-management/hazardous-situations/new', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_emberValidations.default, {
    displayErrors: false,
    riskManagementController: Ember.inject.controller('app.products.product.risk-management'),
    hazards: Ember.computed.alias('riskManagementController.hazards'),

    validations: {
      newHazardousSituationHazard: {
        presence: true
      },

      newHazardousSituationDescription: {
        presence: true
      }
    },

    actions: {
      setNewHazardousSituationHazard: function setNewHazardousSituationHazard(hazard) {
        this.set('newHazardousSituationHazard', hazard);
      },
      createHazardousSituation: function createHazardousSituation() {
        var _this = this;

        this.validate().then(function () {
          return _this.store.createRecord('hazardous-situation', {
            description: _this.get('newHazardousSituationDescription'),
            hazard: _this.get('newHazardousSituationHazard'),
            product: _this.get('product')
          }).saveWithNotifications().then(function () {
            _this.transitionToRoute('app.products.product.risk-management');
          });
        }).catch(function () {
          _this.set('displayErrors', true);
        });
      }
    }
  });
});