define('frontend/controllers/register', ['exports', 'ember-validations', 'frontend/validations/email'], function (exports, _emberValidations, _email) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_emberValidations.default, {
    errors: [],
    displayErrors: false,
    validations: {
      'model.email': _email.default,
      'model.password': {
        presence: true,
        length: { minimum: 8 },
        confirmation: { message: "Passwords do not match" }
      },
      'model.passwordConfirmation': {
        presence: true,
        length: { minimum: 8 }
      },
      'model.name': {
        presence: true
      }
    },

    session: Ember.inject.service('session'),

    actions: {
      save: function save() {
        var _this = this;

        this.validate().then(function () {
          if (_this.get('isValid')) {
            var model = _this.get('model');

            _this.dataModel = {
              company: {
                name: model.get('name')
              },
              user: {
                first_name: model.get('firstName'),
                last_name: model.get('lastName'),
                email: model.get('email'),
                password: model.get('password')
              }
            };

            var user = _this.store.createRecord('user', model.getProperties('firstName', 'lastName', 'email', 'password'));

            var company = _this.store.createRecord('company', {
              name: model.get('name'),
              initialUser: user
            });

            company.saveWithNotifications().then(function () {
              _this.get('session').authenticate('authenticator:devise', _this.dataModel.user.email, _this.dataModel.user.password).then(function () {
                _this.transitionToRoute('onboarding.invite');
              });
            }, function (data) {
              if (data.responseJSON.errors.length > 0) {
                _this.get('model').set('errors', data.responseJSON.errors);
              }
            });
          }
        }, function () {
          _this.set('displayErrors', true);
        });

        return false;
      }
    }
  });
});