define("frontend/models/jenkins-build", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    status: attr('string'),
    description: attr('string'),
    number: attr('string'),
    displayName: attr('string'),
    url: attr('string'),
    startTime: attr('date'),

    htmlSafeDescription: Ember.computed('description', function () {
      return Ember.String.htmlSafe(this.get('description'));
    }),

    title: Ember.computed.alias('displayName')
  });
});