define('frontend/controllers/app', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    productDropdown: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),

    company: Ember.computed.alias('session.currentUser.company'),
    isPreProduction: Ember.computed.alias('company.isPreProduction'),
    isPear: Ember.computed.alias('company.isPear'),
    isQualityOwner: Ember.computed.alias('session.currentUser.isQualityOwner'),
    notQualityOwner: Ember.computed.not('isQualityOwner'),

    complaintsDisabled: Ember.computed('isPreProduction', function () {
      return this.get('isPreProduction');
    }),

    ncCapaDisabled: Ember.computed('isPreProduction', function () {
      return this.get('isPreProduction');
    }),

    designDisabled: Ember.computed('isPear', 'notQualityOwner', function () {
      return this.get('isPear') && this.get('notQualityOwner');
    }),

    isStaging: Ember.computed(function () {
      return window.location.hostname.includes("staging");
    }),

    actions: {
      showModal: function showModal(modalName) {
        this.toggleProperty(modalName);
      },
      closeBillingSettingsModal: function closeBillingSettingsModal() {
        this.set('showBillingSettingsModal', false);
      },
      reauthenticate: function reauthenticate() {
        this.get('session').recordLastAccess();
        this.get('session').get('renewExpiration').perform();
        this.get('notify').success("You have reauthenticated successfully");
      },
      touchExpiration: function touchExpiration() {
        this.get('session').touchExpiration();
      }
    }
  });
});