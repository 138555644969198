define('frontend/routes/app/products/product/design-control/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return this.store.findRecord(params.item_type, params.item_id);
    },
    setupController: function setupController(controller, model) {
      var comment = this.store.createRecord('comment');
      comment.set('itemType', model.get('itemTypeBackend'));
      comment.set('itemId', model.get('id'));
      controller.set('newComment', comment);
      controller.set('model', model);
    },
    resetController: function resetController(controller) {
      controller.get('newComment').rollbackAttributes();
      controller.set('showVerificationScreen', false);
      controller.set('editing', false);
      controller.set('isShowingPreviousApprovedVersion', false);
    },
    serialize: function serialize(model) {
      return {
        item_type: model.get('itemType'),
        item_id: model.get('id')
      };
    },


    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });
});