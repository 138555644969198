define('frontend/mixins/model-meta-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    itemType: Ember.computed('name', function () {
      return this.get('constructor.modelName').camelize();
    }),
    itemTypeBackend: Ember.computed('itemType', function () {
      var itemType = this.get('itemType');
      return itemType[0].toUpperCase() + itemType.slice(1);
    }),
    snakeCasedItemType: Ember.computed('itemTypeBackend', function () {
      var snakeCasedItemType = this.get('itemTypeBackend').replace(/\.?([A-Z])/g, function (x, y) {
        return "_" + y.toLowerCase();
      }).replace(/^_/, "");

      if (snakeCasedItemType.endsWith('y')) {
        snakeCasedItemType = snakeCasedItemType.substring(0, snakeCasedItemType.length - 1);
        snakeCasedItemType += 'ie';
      }

      return snakeCasedItemType;
    })
  });
});