define("frontend/components/pdf-js", ["exports", "ember-pdf-js/components/pdf-js"], function (exports, _pdfJs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _pdfJs.default.extend({
    isLoading: computed('percentLoaded', function () {
      var percent = this.get('percentLoaded');
      return typeof percent === 'number' && !isNaN(percent) && percent < 100;
    })
  });
});