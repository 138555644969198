define("frontend/components/craq-editor/file-upload", ["exports", "ember-uploader"], function (exports, _emberUploader) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({
    accept: "image/png,image/jpg,image/gif",
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var reader = new FileReader();
      reader.addEventListener("load", function () {
        _this.get('onUpload')(reader.result);
      }, false);
      reader.readAsDataURL(files[0]);
    }
  });
});