define('frontend/components/enzyme-power-select', ['exports', 'ember-power-select/components/power-select', 'ember-power-select/templates/components/power-select'], function (exports, _powerSelect, _powerSelect2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _powerSelect.default.extend({
    layout: _powerSelect2.default,

    tagName: 'div',
    classNames: 'enzyme-power-select',
    classNameBindings: ['isSmall:enzyme-power-select--is-small', 'disabled:enzyme-power-select--is-disabled'],
    centered: true,

    /**
     * When true, the date picker is smaller and has no margin bottom. Used for filters
     * that need to fit in the filter bar.
     *
     * @type {Boolean}
     */
    isSmall: false,
    dropdownClass: computed('centered', function () {
      var centered = this.get('centered');
      var classes = ['enzyme-power-select-dropdown', 'enzyme-power-select-dropdown--animated'];
      if (centered) {
        classes.push('enzyme-power-select-dropdown--centered');
      }
      return classes.join(' ');
    }),

    keyUp: function keyUp(e) {
      // prevent esc key closing modals with selects in if the escape should just close the select
      if (this.get('publicAPI.isOpen') && e.keyCode === 27) {
        e.stopPropagation();
      }
    }
  });
});