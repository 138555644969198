define('frontend/routes/app/products/product/document-control/document-item/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    setupController: function setupController(controller, qmsDocument) {
      this._super.apply(this, arguments);
      window.qmsDocument = qmsDocument;
      controller.set('title', qmsDocument.get('title'));
      controller.set('qmsDocumentFolder', qmsDocument.get('qmsDocumentFolder'));
      controller.set('qmsDocumentType', qmsDocument.get('qmsDocumentType'));
      controller.set('qmsDocumentGlobal', qmsDocument.get('global'));
      controller.set('fileUpload', qmsDocument.get('fileUpload'));
      controller.set('effectiveAt', qmsDocument.get('effectiveAt'));
      controller.set('documentNumber', qmsDocument.get('documentNumber'));
      controller.set('products', qmsDocument.get('products'));
    }
  });
});