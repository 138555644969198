define('frontend/components/column-selectable-table/filter', ['exports', 'frontend/helpers/humanize'], function (exports, _humanize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Filter = Ember.Component.extend({
    classNames: 'column-selectable-table-filter',
    filterSpec: Ember.computed(function () {
      return {};
    }),

    availableFilters: Ember.computed('columns.[]', function () {
      return this.get('columns').filterBy('filter').map(function (column) {
        return {
          header: column.title || (0, _humanize.humanize)(column.key),
          type: column.filter.type,
          key: column.key,
          column: column,
          options: column.filter.options
        };
      });
    }),

    selectedFilter: Ember.computed.reads('filterSpec.selectedFilter'),
    filterType: Ember.computed.reads('selectedFilter.type'),
    filterString: Ember.computed.alias('filterSpec.filterString'),
    selectedOptions: Ember.computed.alias('filterSpec.selectedOptions'),

    filterComplete: Ember.computed('selectedFilter', 'filterString', 'selectedOptions.[]', function () {
      if (!this.get('selectedFilter')) {
        return false;
      }
      switch (this.get('selectedFilter.type')) {
        case 'string':
          return !Ember.isBlank(this.get('filterString'));
        case 'list':
          return !!this.get('selectedOptions.length');
      }
    }),

    checkUpdate: function checkUpdate() {
      if (this.get('filterComplete')) {
        this.sendAction('activateFilter', this.get('filterSpec'));
      } else {
        this.sendAction('deactivateFilter', this.get('filterSpec'));
      }
    },


    actions: {
      changeFilterType: function changeFilterType(selectedFilter) {
        this.set('filterSpec.selectedFilter', selectedFilter);
        this.checkUpdate();
      },
      updateFilterString: function updateFilterString(string) {
        this.set('filterString', string);
        this.checkUpdate();
      },
      updateFilterOptions: function updateFilterOptions(newOptions) {
        this.set('selectedOptions', newOptions);
        this.checkUpdate();
      },
      remove: function remove() {
        this.sendAction('remove', this.get('filter'));
        this.sendAction('deactivateFilter', this.get('filterSpec'));
      }
    }
  });

  Filter.reopenClass({ positionalParams: ['filter'] });

  exports.default = Filter;
});