define('frontend/components/enzyme-power-select-multiple/trigger', ['exports', 'ember-power-select/components/power-select-multiple/trigger'], function (exports, _trigger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _trigger.default.extend({
    actions: {
      onKeydown: function onKeydown(e) {
        var _getProperties = this.getProperties('onKeydown', 'select'),
            onKeydown = _getProperties.onKeydown,
            select = _getProperties.select;

        if (onKeydown && onKeydown(e) === false) {
          e.stopPropagation();
          return false;
        }
        if (e.keyCode === 8) {
          e.stopPropagation();
          if (Ember.isBlank(e.target.value)) {
            if (!(select.selected && select.selected.length)) {
              return;
            }
            var lastSelection = select.selected[select.selected.length - 1];
            if (lastSelection) {
              select.actions.select(this.get('buildSelection')(lastSelection, select), e);
              if (typeof lastSelection === 'string') {
                select.actions.search(lastSelection);
              } else {
                select.actions.search('');
              }
              select.actions.open(e);
            }
          }
        } else if (e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32) {
          // Keys 0-9, a-z or SPACE
          e.stopPropagation();
        }
      }
    }
  });
});