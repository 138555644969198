define('frontend/mixins/authenticated-route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      if (!this.get('session.isAuthenticated')) {
        var url = transition.intent.url;


        if (url) {
          this.get('session').saveURLState(url);
        }
      }

      this._super.apply(this, arguments);
    }
  });
});