define('frontend/adapters/application', ['exports', 'ember-data', 'ember-data/adapters/json-api', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-inflector'], function (exports, _emberData, _jsonApi, _dataAdapterMixin, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var firstRequest = true;

  exports.default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    namespace: 'api',
    authorizer: 'authorizer:devise',
    session: Ember.inject.service(),
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    pathForType: function pathForType(type) {
      var underscored = Ember.String.underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    },
    handleResponse: function handleResponse(status, headers, responseJSON) {
      var wasFirstRequest = firstRequest;
      firstRequest = false;

      if (status === 401) {
        if (responseJSON.errors[0] === 'Expired authorization') {
          // if it's the first request, it's at page load and we can't show
          // a modal - instead we need to invalidate the session
          if (wasFirstRequest) {
            this.get('session').invalidateWithState();
          } else {
            this.set('session.expiryTime', new Date());
          }
        } else if (this.get('session.isAuthenticated')) {
          this.get('session').invalidateWithState();
        }
        return new _emberData.default.AdapterError("Request failed due to being unauthorized");
      } else {
        if (!Ember.testing) {
          // Do this separately from renewExpiration to avoid an endless loop of
          // tabs syncing with each other
          this.get('session').recordLastAccess();
          this.get('session.renewExpiration').perform();
        }
        return this._super.apply(this, arguments);
      }
    }
  });
});