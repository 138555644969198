define('frontend/components/toggle-switch/switch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['toggle-switch__switch'],
    classNameBindings: ['checked:toggle-switch__switch--checked', 'disabled:toggle-switch__switch--disabled']
  });
});