define('frontend/routes/complaint', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var _this = this;

      var product_id = _ref.product_id;

      return this.store.findRecord('product', product_id).then(function (product) {
        return _this.store.createRecord('complaint', { product: product });
      });
    }
  });
});