define('frontend/models/attachment', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    fileName: (0, _attr.default)('string'),
    fileUrl: (0, _attr.default)('string'),
    itemType: (0, _attr.default)('string'),
    itemId: (0, _attr.default)('number'),
    uploadedAt: (0, _attr.default)('date'),
    file: (0, _attr.default)('file'),
    createdAt: (0, _attr.default)('date'),
    shortName: Ember.computed.reads('fileName'),
    attachable: (0, _relationships.belongsTo)('attachable', { inverse: 'attachments' }),
    qmsDocument: (0, _relationships.belongsTo)('qms-document'),
    approvalSet: (0, _relationships.belongsTo)('approval-set'),
    trainingAssignment: (0, _relationships.belongsTo)('training-assignment'),
    training: (0, _relationships.belongsTo)('training'),

    displayName: Ember.computed.or('name', 'fileName'),

    fileType: Ember.computed('fileName', function () {
      return (this.get('fileName') || '').split('.').get('lastObject');
    }),

    markedForRemoval: Ember.computed.equal('itemType', 'remove'),

    newFileType: Ember.computed('isNew', function () {
      if (this.get('isNew')) {
        return this.get('file.name').split('.').get('lastObject');
      }
    })
  });
});