define('frontend/utils/group-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = groupBy;
  function groupBy(key, reducer) {
    return Ember.computed(key, function () {
      return this.get(key).reduce(this.get(reducer), []);
    });
  }
});