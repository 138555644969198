define('frontend/controllers/app/products/product/risk-management/harms/new', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed,
      reads = Ember.computed.reads,
      inject = Ember.inject,
      RSVP = Ember.RSVP;
  exports.default = Controller.extend(_emberValidations.default, {
    displayErrors: false,
    useExistingHarm: true,
    createNewHarm: computed.not('useExistingHarm'),
    viewOptions: inject.service(),
    selectedSubsystem: computed.reads('viewOptions.selectedSubsystem'),
    company: reads('product.company'),

    severities: computed.alias('company.productSeverities'),
    probabilities: computed.alias('product.probabilities'),
    initialProbability: '',
    mitigatedProbability: '',

    newHarmInstanceRequirements: Ember.A([]),

    selectableHarms: computed('company.harms.@each.descriptionWithSeverity', 'newHarmInstanceHazardousSituation', function () {
      var _this = this;

      return this.get('company.harms').filter(function (harm) {
        return harm === _this.get('model.harm') || !_this.get('newHarmInstanceHazardousSituation') || !_this.get('newHarmInstanceHazardousSituation.harmInstances').mapBy('harm').includes(harm);
      }).uniqBy('descriptionWithSeverity');
    }),

    selectableHazardousSituations: computed('product.liveHazardousSituations.[]', 'newHarmInstanceHazardousSituation', 'newHarmInstanceHarm', 'selectedSubsystem', function () {
      var _this2 = this;

      return this.get('product.liveHazardousSituations').filter(function (hazardousSituation) {
        return hazardousSituation === _this2.get('newHarmInstanceHazardousSituation') || (!_this2.get('selectedSubsystem') || _this2.get('selectedSubsystem') === hazardousSituation.get('hazard.subsystem')) && (!_this2.get('newHarmInstanceHarm') || !_this2.get('newHarmInstanceHarm.harmInstances').mapBy('hazardousSituation').includes(hazardousSituation));
      });
    }),

    validations: {
      newHarmInstanceHarm: {
        presence: {
          unless: 'createNewHarm'
        }
      },

      newHarmInstanceHazardousSituation: {
        presence: true
      }
    },

    actions: {
      addNewHarmInstanceRequirement: function addNewHarmInstanceRequirement(requirement) {
        this.get('newHarmInstanceRequirements').pushObject(requirement);
      },
      removeNewHarmInstanceRequirement: function removeNewHarmInstanceRequirement(requirement) {
        this.get('newHarmInstanceRequirements').removeObject(requirement);
      },
      setNewHarmInstanceInitialProbability: function setNewHarmInstanceInitialProbability(probability) {
        this.set('newHarmInstanceInitialProbability', probability);
      },
      setNewHarmInstanceMitigatedProbability: function setNewHarmInstanceMitigatedProbability(probability) {
        this.set('newHarmInstanceMitigatedProbability', probability);
      },
      setSeverity: function setSeverity(severity) {
        this.set('severity', severity);
      },
      setNewHarmInstanceHarm: function setNewHarmInstanceHarm(harm) {
        var _this3 = this;

        if (harm.get('product') !== this.get('product')) {
          harm = harm.cloneToProduct(this.get('product'));
        }
        RSVP.resolve(harm).then(function (harm) {
          return _this3.set('newHarmInstanceHarm', harm);
        });
      },
      setNewHarmInstanceHazardousSituation: function setNewHarmInstanceHazardousSituation(hazardousSituation) {
        this.set('newHarmInstanceHazardousSituation', hazardousSituation);
      },


      // These two cases (for whether the user selected an existing harm or creating one)
      // should be condensed into one
      createHarmInstance: function createHarmInstance() {
        var _this4 = this;

        this.validate().then(function () {
          if (!_this4.get('createNewHarm')) {
            _this4.store.createRecord('harm-instance', {
              harm: _this4.get('newHarmInstanceHarm'),
              hazardousSituation: _this4.get('newHarmInstanceHazardousSituation'),
              initialProbability: _this4.get('newHarmInstanceInitialProbability'),
              mitigatedProbability: _this4.get('newHarmInstanceMitigatedProbability'),
              requirements: _this4.get('newHarmInstanceRequirements')
            }).saveWithNotifications().then(function () {
              _this4.set('newHarmInstanceRequirements', Ember.A([]));
              _this4.transitionToRoute('app.products.product.risk-management');
            });
          } else {
            var newHarm = _this4.store.createRecord('harm', {
              product: _this4.get('product'),
              description: _this4.get('newHarmDescription'),
              severity: _this4.get('severity')
            });

            _this4.set('newHarm', newHarm);

            return newHarm.saveWithNotifications(false).then(function (harm) {
              // pass false so only failure is notified - success will be notified below
              _this4.store.createRecord('harm-instance', {
                harm: harm,
                hazardousSituation: _this4.get('newHarmInstanceHazardousSituation'),
                initialProbability: _this4.get('newHarmInstanceInitialProbability'),
                mitigatedProbability: _this4.get('newHarmInstanceMitigatedProbability'),
                requirements: _this4.get('newHarmInstanceRequirements')
              }).saveWithNotifications().then(function () {
                _this4.set('newHarmInstanceRequirements', Ember.A([]));
                _this4.transitionToRoute('app.products.product.risk-management');
              });
            });
          }
        }).catch(function () {
          _this4.set('displayErrors', true);
        });
      }
    }
  });
});