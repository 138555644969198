define('frontend/initializers/ember-form-for-i18n', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var getOwner = Ember.getOwner;
  function initialize(app) {
    var i18n = null;

    try {
      i18n = getOwner(app).lookup('service:i18n');
    } catch (e) {
      i18n = app.__container__ && app.__container__.lookup('service:i18n');
    }

    if (i18n) {
      app.inject('component', 'i18n', 'service:i18n');
    }
  }

  exports.default = {
    name: 'ember-form-for-i18n',
    initialize: initialize
  };
});