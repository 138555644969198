define('frontend/controllers/app/products/product/design-control', ['exports', 'ember-validations', 'frontend/macros/filter-by-subsystem'], function (exports, _emberValidations, _filterBySubsystem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_emberValidations.default, {
    errors: [],
    displayErrors: false,
    validations: {
      'newDesignControlName': {
        presence: true,
        length: { minimum: 2 }
      }
    },

    product: Ember.computed.alias('model'),
    session: Ember.inject.service('session'),
    currentUser: Ember.computed.alias('session.currentUser'),
    productController: Ember.inject.controller('app.products.product'),
    viewOptions: Ember.inject.service(),
    selectedSubsystem: Ember.computed.reads('viewOptions.selectedSubsystem'),
    showCreateNewDesignControlModal: false,
    newDesignControlModelType: null,
    newDesignControlName: null,
    newDesignControlDescription: null,

    searchText: Ember.computed.alias('productController.searchText'),
    filteredUserNeeds: (0, _filterBySubsystem.default)('product.liveUserNeeds'),
    filteredRequirements: (0, _filterBySubsystem.default)('product.liveRequirements'),
    filteredDesignOutputs: (0, _filterBySubsystem.default)('product.liveDesignOutputs'),
    filteredVerifications: (0, _filterBySubsystem.default)('product.liveVerifications'),
    filteredValidations: (0, _filterBySubsystem.default)('product.liveValidations'),

    designControlColumns: Ember.computed('filteredUserNeeds.[]', 'filteredRequirements.[]', 'filteredDesignOutputs.[]', 'filteredVerifications.[]', 'filteredValidations.[]', function () {
      return [{
        type: 'userNeed', items: this.get('filteredUserNeeds'),
        description: "are a type of Design Input that describe product safety and functionality required of the product to meet customers’ needs and intended uses.",
        example: "Example: The surgeon should be able to see the light battery status throughout the procedure under normal hospital lighting."
      }, {
        type: 'requirement', items: this.get('filteredRequirements'),
        description: "are a type of Design Input that explicitly detail technical product needs in engineering terms encompassing function, human factors, performance, business, safety, and regulatory concerns. It is an expectation of the final product.",
        example: "Example: The light source produces an equivalent of a standard 100-watt incandescent light bulb or 1,500-1,700 lumens continuously for a minimum of 6 hours on a single battery charge."
      }, {
        type: 'designOutput', items: this.get('filteredDesignOutputs'),
        description: "are the results of a design effort at each design phase and at the end of the total design effort. The finished design output is the basis for the device master record. The total finished design output consists of the device, its packaging and labeling, and the device master record.",
        example: "Example: (Architectural Design Workflow or Drawing) Enzyme Battery charge Design Architecture SAD001"
      }, {
        type: 'verification', items: this.get('filteredVerifications'),
        description: "are confirmation, through the provision of objective evidence that specified requirements have been fulfilled. A Design Verification test demonstrates the Design Output meets the corresponding (traced) Requirements.",
        example: "Example: Test of the light source performance with predefined test method against the 6 hour minimum under varying levels of light intensity"
      }, {
        type: 'validation', items: this.get('filteredValidations'),
        description: "are confirmation, through the provision of objective evidence, that the User Needs for a specific intended use or application have been fulfilled. <br><br>A Design Validation test demonstrates the Design Output meets the corresponding (traced) User Needs under defined operating conditions (actual or simulated use conditions) on initial production units, lots, batches, or software version. It includes software validation and risk analyses where appropriate.",
        example: "Example: In a simulated use setting, the laparoscopic surgeon should be able to use the light source continuously for a minimum of 6 hours in a typical Hysterectomy procedure."
      }];
    }),

    actions: {
      exportDesignControlChangeHistory: function exportDesignControlChangeHistory(designControlType) {
        this.store.createRecord('qms-export', {
          productId: this.get('product.id'),
          qmsExportableId: null, // tells the server to export the history for all exportables of this type
          qmsExportableType: Ember.String.classify(designControlType)
        }).saveAndNotify();
      },
      createNewDesignControl: function createNewDesignControl(modelType) {
        this.set('newDesignControlModelType', modelType);
        this.set('newDesignControlName', null);
        this.set('newDesignControlDescription', null);
        this.toggleProperty('showCreateNewDesignControlModal');
      },
      toggleCreateNewDesignController: function toggleCreateNewDesignController() {
        this.toggleProperty('showCreateNewDesignControlModal');
      },
      saveNewDesignControlRecord: function saveNewDesignControlRecord() {
        var _this = this;

        this.validate().then(function () {
          if (_this.get('isValid')) {
            _this.set('displayErrors', false);
            var modelType = _this.get('newDesignControlModelType');
            return _this.store.createRecord(modelType, {
              description: _this.get('newDesignControlDescription'),
              name: _this.get('newDesignControlName'),
              product: _this.get('product'),
              subsystem: _this.get('selectedSubsystem')
            }).saveWithNotifications().then(function () {
              _this.toggleProperty('showCreateNewDesignControlModal');
            });
          }
        }, function () {
          _this.set('displayErrors', true);
        });
      },
      redirectToDCDetails: function redirectToDCDetails(dc) {
        this.transitionToRoute('app.products.product.design-control.show', dc.get('itemType'), dc.get('id'));
      }
    }
  });
});