define('frontend/models/capa', ['exports', 'ember-data', 'ember-data/relationships', 'ember-data/attr', 'frontend/mixins/approvable', 'frontend/initializers/add-model-async-methods', 'moment', 'frontend/mixins/nc-capa-source'], function (exports, _emberData, _relationships, _attr, _approvable, _addModelAsyncMethods, _moment, _ncCapaSource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_approvable.default, _ncCapaSource.default, {
    owner: (0, _relationships.belongsTo)('user'),
    company: (0, _relationships.belongsTo)(),
    nonConformance: (0, _relationships.belongsTo)(),
    capaPhases: (0, _relationships.hasMany)(),
    title: (0, _attr.default)('string'),
    executiveSummary: (0, _attr.default)('mobiledoc'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    cancelledAt: (0, _attr.default)('date'),
    cancellationJustification: (0, _attr.default)('mobiledoc'),

    routableTarget: 'app.products.product.capas.capa',

    routeForApproval: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'route_for_approval', type: 'post' }),
    cancel: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'cancel', type: 'post' }),

    capa: Ember.computed('id', function () {
      return this.get('store').peekRecord('capa', this.get('id'));
    }),

    capaOwner: Ember.computed.alias('owner'),
    capaTitle: Ember.computed.alias('title'),

    humanizedApprovalState: Ember.computed('approvalState', 'cancelledAt', function () {
      if (this.get('cancelledAt')) {
        return 'Cancelled';
      }
      return {
        draft: "Open",
        create_requested: "Open",
        destroy_requested: "Open",
        update_requested: "Open",
        approved: "Closed"
      }[this.get('approvalState')];
    }),

    pendingState: Ember.computed('approvalState', 'cancelledAt', function () {
      if (this.get('cancelledAt') && this.get('approvalState') !== 'update_requested') {
        return 'closed';
      }
      return {
        draft: "open",
        create_requested: "pending_approval",
        destroy_requested: "pending_deletion",
        update_requested: "pending_approval",
        approved: "closed"
      }[this.get('approvalState')];
    }),

    shortName: Ember.computed('id', function () {
      return 'CAPA #' + this.get('id');
    }),

    shortNameForApproval: Ember.computed('shortName', function () {
      return this.get('shortName') + ' Summary and Closure';
    }),

    paddedId: Ember.computed('id', function () {
      if (this.get('id')) {
        return this.get('id').padStart(4, '0');
      }
    }),

    daysOpen: Ember.computed('createdAt', function () {
      return (0, _moment.default)().diff(this.get('createdAt'), 'days');
    }),

    lastApprovedVersion: Ember.computed('sortedVersions', function () {
      return this.get('sortedVersions').findBy('approvalState', 'approved');
    }),

    capaOwnerApproval: Ember.computed('lastApprovedVersion.approverNames.[]', 'capaOwner.fullName', function () {
      var approverNames = this.get('lastApprovedVersion.approverNames');
      var capaOwnerName = this.get('capaOwner.fullName');
      return (approverNames || []).filter(function (name) {
        return name == capaOwnerName;
      });
    }),

    otherApproval: Ember.computed('lastApprovedVersion.approverNames.[]', 'capaOwner.fullName', function () {
      var approverNames = this.get('lastApprovedVersion.approverNames');
      var capaOwnerName = this.get('capaOwner.fullName');
      return (approverNames || []).filter(function (name) {
        return name != capaOwnerName;
      });
    }),

    sourceDescription: Ember.computed.reads('shortName')
  });
});