define('frontend/routes/app/products/product/risk-management/harms/update', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    setupController: function setupController(controller) {
      controller.set('product', this.modelFor('app.products.product'));
      controller.set('harms', this.modelFor('app.products.product').get('harms'));
      controller.set('severities', this.modelFor('app.products.product').get('company.productSeverities'));
      controller.set('model', this.modelFor('app.products.product').get('harms'));
    },
    resetController: function resetController(controller) {
      var oldSeverities = controller.get('oldSeverities');
      var controllerScope = this;
      controller.get('harms').forEach(function (harm, index) {
        if (Number(harm.get('severity.id')) !== Number(oldSeverities[index])) {
          harm.set('severity', controllerScope.store.peekRecord('severity', oldSeverities[index]));
        }
      });
    },

    actions: {
      didTransition: function didTransition() {
        this.controller.get('oldSeverities'); // consume oldSeverities so it is created
      }
    }
  });
});