define('frontend/controllers/app/products/product/supplier-quality/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      closeForm: function closeForm() {
        this.transitionToRoute('app.products.product.supplier-quality.index');
      }
    }
  });
});