define('frontend/components/tour-popper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({

    classNames: ['tour-popper'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('popper', new Popper(this.get('referenceElement'), this.element, this.get('popperOptions')));
    },
    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);
      var popper = this.get('popper');
      popper.reference = this.get('referenceElement');
      popper.update();
    },


    popperOptions: computed('placement', function () {
      return {
        placement: 'auto',
        modifiers: {
          arrow: {
            element: '.tour-step-arrow'
          }
        }
      };
    })

  });
});