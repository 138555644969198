define('frontend/models/design-review', ['exports', 'ember-data', 'frontend/models/approval-request', 'frontend/mixins/attachable', 'frontend/initializers/add-model-async-methods', 'frontend/mixins/routable'], function (exports, _emberData, _approvalRequest, _attachable, _addModelAsyncMethods, _routable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _approvalRequest.default.extend(_attachable.default, _routable.default, {
    session: Ember.inject.service(),
    router: Ember.inject.service(),

    createdBy: belongsTo('user'),
    users: hasMany('users'),
    attachments: hasMany('attachment', { inverse: 'attachable' }),
    independentReviewer: belongsTo('user'),
    approvers: Ember.computed.reads('users'),

    createdAt: attr('date'),
    signed: attr('boolean'),

    items: Ember.computed.reads('approvables'),

    otherAttendees: attr('string'),
    minutes: attr('string'),
    title: attr('string'),
    phaseGate: attr('boolean'),
    state: attr('string'),

    isStateDraft: Ember.computed.equal('state', 'draft'),
    isStatePending: Ember.computed.equal('state', 'pending'),
    isStateSigned: Ember.computed.equal('state', 'signed'),

    isRequestor: Ember.computed('createdBy.id', 'session.currentUser.id', function () {
      return this.get('createdBy.id') === this.get('session.currentUser.id');
    }),

    canDelete: Ember.computed.and('session.currentUser.isQualityOwner', 'isStateDraft'),
    canEdit: Ember.computed.and('isRequestor', 'isStateDraft'),
    canPullback: Ember.computed.or('requestorCanPullback', 'qoCanPullback'),
    canResend: Ember.computed.and('isRequestor', 'isStateDraft'),

    requestorCanPullback: Ember.computed.and('isRequestor', 'isStatePending'),
    qoCanPullback: Ember.computed.and('session.currentUser.isQualityOwner', 'isStatePending'),

    pullback: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'pullback' }),
    resend: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'resend' }),

    shortName: 'Design Review',
    routableTarget: 'app.products.product.design-review',
    // intentional, because the default is to use `this` as the route param
    routableParams: [],

    approve: function approve() {
      return this.promiseMethodWithNotifications('save', 'Your attendance was signed', this._buildErrorFormatter('Could not sign your attendance')).catch(function () {});
    }
  });
});