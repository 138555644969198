define('frontend/components/company-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    user: Ember.computed.alias('session.currentUser'),
    company: Ember.computed.alias('user.company'),
    timeZones: ['Pacific Time (US & Canada)', 'Mountain Time (US & Canada)', 'Central Time (US & Canada)', 'Eastern Time (US & Canada)'],
    actions: {
      save: function save() {
        var _this = this;

        this.get('company').saveWithNotifications().then(function () {
          _this.sendAction('close');
        }, function () {});
      },
      close: function close() {
        var _this2 = this;

        this.get('company').then(function (company) {
          company.rollbackAttributes();
          _this2.sendAction('close');
        });
      }
    }
  });
});