define('frontend/controllers/app/products/product/supplier-quality/supplier', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentUser: Ember.computed.alias('session.currentUser'),
    chooseApprovers: false,
    queryParams: ['supplierTab'],
    supplierTab: 'record',

    tabList: Ember.computed('model.pendingApprovalSet', function () {
      var baseTabs = ["record"];
      if (this.get('model.pendingApprovalSet')) {
        baseTabs.push('approval status');
      }
      return baseTabs;
    }),

    supplierRecordNeedsApproval: Ember.computed('model.approvalState', 'model.state', function () {
      if (this.get('model.approvalState') == 'draft' && this.get('model.state') != 'new_supplier' && this.get('model.state') != 'auditing') {
        return true;
      }
    }),

    actions: {
      closeForm: function closeForm() {
        this.transitionToRoute('app.products.product.supplier-quality.index');
      },
      stateAction: function stateAction(actionInfo) {
        if (confirm('Are you sure you want to ' + actionInfo.confirmText)) {
          this.set('chooseApprovers', false);
          var blacklist = this.get('approverBlacklist');
          return this.get('model')[actionInfo.action.camelize()]({ blacklist: blacklist });
        }
      },
      deleteSupplier: function deleteSupplier() {
        var _this = this;

        if (confirm("Are you sure you want to delete this supplier?")) {
          this.get('model').destroyWithNotifications().then(function () {
            return _this.transitionToRoute("app.products.product.supplier-quality.index");
          });
        }
      },
      approverBlacklistChanged: function approverBlacklistChanged(blacklist, selectedApprovers) {
        this.set('approverBlacklist', blacklist);
        this.set('selectedApprovers', selectedApprovers);
      },
      routeForApproval: function routeForApproval() {
        this.set('chooseApprovers', false);
        var blacklist = this.get('approverBlacklist');
        return this.get('model').routeForApproval({ blacklist: blacklist });
      }
    }
  });
});