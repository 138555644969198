define('frontend/initializers/extend-array-prototype', ['exports', 'ember-data', 'frontend/utils/natural-sort'], function (exports, _emberData, _naturalSort3) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function initialize() {
    var EXTEND_PROTOTYPES = window.EmberENV.EXTEND_PROTOTYPES;


    if (!EXTEND_PROTOTYPES || !EXTEND_PROTOTYPES['Array']) {
      return;
    }

    function _naturalSort() {
      return (0, _naturalSort3.naturalSort)(this);
    }

    function _naturalSortBy() {
      for (var _len = arguments.length, sortKeys = Array(_len), _key = 0; _key < _len; _key++) {
        sortKeys[_key] = arguments[_key];
      }

      return _naturalSort3.naturalSortBy.apply(undefined, [this].concat(sortKeys));
    }

    // Extend Array prototype
    Array.prototype.naturalSort = _naturalSort;
    Array.prototype.naturalSortBy = _naturalSortBy;

    // Extend ArrayProxy, includes DS.ArrayPromise
    Ember.ArrayProxy.reopen({
      naturalSort: _naturalSort,
      naturalSortBy: _naturalSortBy
    });

    // Extend ManyArray
    _emberData.default.ManyArray.reopen({
      naturalSort: function naturalSort() {
        return (0, _naturalSort3.naturalSort)(this.toArray());
      },
      naturalSortBy: function naturalSortBy() {
        for (var _len2 = arguments.length, sortKeys = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          sortKeys[_key2] = arguments[_key2];
        }

        return _naturalSort3.naturalSortBy.apply(undefined, [this.toArray()].concat(_toConsumableArray(sortKeys)));
      }
    });
  }

  exports.default = {
    initialize: initialize
  };
});