define('frontend/components/change-requests/approvers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    approvalSet: Ember.computed.reads('row.content'),
    allSignersFlattened: Ember.computed.reads('approvalSet.allSignersFlattened'),
    allSignersUnique: Ember.computed('allSignersFlattened.@each.user', function () {
      return this.get('allSignersFlattened').uniqBy('user.id');
    })
  });
});