define('frontend/models/verification', ['exports', 'ember-data/relationships', 'ember-data/attr', 'frontend/models/design-control', 'frontend/mixins/permissions'], function (exports, _relationships, _attr, _designControl, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _designControl.default.extend(_permissions.default, {
    results: (0, _attr.default)('mobiledoc'),

    circleciBuild: (0, _relationships.belongsTo)('circleci-build'),
    travisciBuild: (0, _relationships.belongsTo)('travisci-build'),
    jenkinsBuild: (0, _relationships.belongsTo)('jenkins-build'),

    designOutputVerificationDependencies: (0, _relationships.hasMany)('design-output-verification-dependency'),
    requirementVerificationDependencies: (0, _relationships.hasMany)('requirement-verification-dependency'),
    tracingDependencyObjects: Ember.computed(function () {
      return {
        designOutputVerificationDependencies: 'designOutput',
        requirementVerificationDependencies: 'requirement'
      };
    }),

    designControlTypeAbbreviation: 'VER',
    canLinkToCircleciBuilds: true,
    canLinkToTravisciBuilds: true,
    canLinkToJenkinsBuilds: true
  });
});