define('frontend/controllers/app/products/product/delete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Controller.extend({
    session: inject.service(),
    product: computed.alias('model'),

    productName: computed.alias('product.name'),

    actions: {
      delete: function _delete() {
        var _this = this;

        this.get('product').requestDelete().then(function (wasDeleted) {
          if (wasDeleted) {
            _this.transitionToRoute('app.products');
          } else {
            _this.transitionToRoute('app.products.product');
          }
        });
      }
    }
  });
});