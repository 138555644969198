define('frontend/components/generic-pill', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['tag'],
    attributeBindings: ['pillColor:style'],
    actions: {
      removePill: function removePill() {
        this.sendAction('remove');
      }
    },
    pillColor: computed('color', function () {
      var color = this.get('color');
      if (color) {
        return new Ember.Handlebars.SafeString("background-color: " + color);
      }
      return null;
    })
  });
});