define('frontend/controllers/app/products/product/document-control/document-item/obsolete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    productController: Ember.inject.controller('app/products/product'),
    product: Ember.computed.alias('productController.model'),

    qmsDocument: Ember.computed.alias('model'),

    actions: {
      obsoleteQmsDocument: function obsoleteQmsDocument() {
        var _this = this;

        var qmsDocument = this.get('qmsDocument');

        qmsDocument.set('obsolete', true);

        qmsDocument.saveWithNotifications().then(function (qmsDocument) {
          _this.transitionToRoute('app.products.product.document-control.document-item', qmsDocument);
        });
      }
    }
  });
});