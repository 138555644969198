define('frontend/components/li-qms-document', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['highlight'],

    // If the document title doesn't match then some other attribute (i.e. document number)
    // must have. But since that's not visible, we'll just highlight the entire title
    searchMatchesTitle: Ember.computed.gt('searchMatchIndex', -1),

    searchMatchIndex: Ember.computed('searchText', 'qmsDocument.title', function () {
      return this.get('qmsDocument.title').indexOf(this.get('searchText'));
    }),

    beforeHighlight: Ember.computed('searchMatchesTitle', 'searchMatchIndex', 'qmsDocument.title', function () {
      if (!this.get('searchMatchesTitle')) {
        return '';
      }
      return this.get('qmsDocument.title').substring(0, Math.max(this.get('searchMatchIndex'), 0));
    }),

    highlightText: Ember.computed('searchMatchesTitle', 'searchMatchIndex', 'qmsDocument.title', function () {
      if (!this.get('searchMatchesTitle')) {
        return this.get('qmsDocument.title');
      }
      return this.get('qmsDocument.title').substring(this.get('searchMatchIndex'), this.get('searchMatchIndex') + this.get('searchText').length);
    }),

    afterHighlight: Ember.computed('searchMatchesTitle', 'searchMatchIndex', 'qmsDocument.title', function () {
      if (!this.get('searchMatchesTitle')) {
        return '';
      }
      return this.get('qmsDocument.title').substring(Math.max(this.get('searchMatchIndex'), 0) + this.get('searchText').length);
    })
  });
});