define('frontend/components/document-training', ['exports', 'frontend/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_permissions.default, {
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),

    assignByRole: false,
    classNames: ['document-training'],

    // CPs
    currentUser: null,
    subordinates: Ember.computed.reads('currentUser.subordinates'),
    questions: Ember.computed.alias('training.trainingQuestions'),
    assignedUsers: Ember.computed.alias('training.users'),
    approvalRole: Ember.computed.alias('training.approvalRole'),
    persistedUsers: Ember.computed.filterBy('users', 'id'),
    activeUsers: Ember.computed.filterBy('persistedUsers', 'isActive'),
    _unassignedUsers: Ember.computed.setDiff('activeUsers', 'assignedUsers'),

    users: Ember.computed('subordinates', 'isOwner', function () {
      if (this.get('isOwner')) {
        return this.get('store').peekAll('user');
      } else {
        return this.get('subordinates');
      }
    }),

    unassignedUsers: Ember.computed({
      get: function get() {
        return this.get('_unassignedUsers');
      },
      set: function set(_, v) {
        return v;
      }
    }),

    isQualityOwnerTraining: Ember.computed({
      get: function get() {
        return false;
      },
      set: function set(_, v) {
        if (v) {
          var _getProperties = this.getProperties('unassignedUsers', 'assignedUsers'),
              unassignedUsers = _getProperties.unassignedUsers,
              assignedUsers = _getProperties.assignedUsers;

          var filteredUnassigned = unassignedUsers.filterBy('isOwner', true);
          var filteredAssigned = assignedUsers.filterBy('isOwner', true);

          if (Ember.get(assignedUsers, 'length') !== Ember.get(filteredAssigned, 'length')) {
            this.get('notify').info('We\'ve removed the users that aren\'t Quality owners from this list since only Quality Owners can receive Quality Owners training');
          }

          this.set('unassignedUsers', filteredUnassigned);
          this.set('assignedUsers', filteredAssigned);
        } else {
          this.set('unassignedUsers', this.get('_unassignedUsers'));
        }
        return v;
      }
    }),

    // Lifecycle hooks
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      // ensure that duplicate training questions don't appear when the training is saved
      this.get('training.trainingQuestions').invoke('rollbackAttributes');
    },


    // Lifecycle hooks
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.loadApprovalRoles();
    },
    loadApprovalRoles: function loadApprovalRoles() {
      var _this = this;

      this.get('store').findAll('approval-role').then(function (roles) {
        var filteredRoles = roles.filterBy('isInactive', false);
        _this.set('approvalRoles', filteredRoles);
      });
    },


    // Actions
    actions: {
      addQuestion: function addQuestion() {
        if (this.get('questions.length') >= 5) {
          alert("A maximum of 5 questions are allowed");
          return;
        }

        var question = this.get('questions').createRecord();
        var answers = question.get('trainingQuestionAnswers');
        var firstAnswer = answers.createRecord();
        question.set('correctAnswer', firstAnswer);
        answers.createRecord(); // create a second, incorrect answer
        this.get('questions').pushObject(question);
      },
      addAnswer: function addAnswer(question) {
        question.get('trainingQuestionAnswers').createRecord();
      },
      removeQuestion: function removeQuestion(question) {
        if (confirm("Are you sure you want to remove this question?")) {
          this.get('questions').removeObject(question);
        }
      },
      makeCorrectAnswer: function makeCorrectAnswer(question, answer) {
        question.set('correctAnswer', answer);
      },
      removeAnswer: function removeAnswer(question, answer) {
        if (question.get('trainingQuestionAnswers.length') === 2) {
          alert("Can't remove this answer, at least two answers must be present");
          return;
        }

        question.get('correctAnswer').then(function (correctAnswer) {
          if (correctAnswer === answer) {
            alert("Cannot remove the correct answer, mark another answer as the correct answer first");
            return;
          }

          if (confirm("Are you sure you want to remove this answer?")) {
            question.get('trainingQuestionAnswers').removeObject(answer);
          }
        });
      },
      assignUser: function assignUser(user) {
        this.get('assignedUsers').addObject(user);
      },
      unassignUser: function unassignUser(user) {
        this.get('assignedUsers').removeObject(user);
      },
      addAllUsers: function addAllUsers() {
        if (this.get('isQualityOwnerTraining')) {
          this.get('assignedUsers').addObjects(this.get('unassignedUsers').filterBy('isOwner', true));
        } else {
          this.get('assignedUsers').addObjects(this.get('unassignedUsers'));
        }
      },
      approvalRoleChanged: function approvalRoleChanged(role) {
        this.set('approvalRole', role);
        return role;
      },
      changeAssignedByRole: function changeAssignedByRole(checked) {
        this.set('assignByRole', checked);
        if (checked) {
          this.get('assignedUsers').clear();
        } else {
          this.set('training.approvalRole', null);
        }
      }
    }
  });
});