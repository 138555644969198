define("frontend/components/slow-rendering", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    delay: 200,
    loadingMessage: "Loading…",
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.later(function () {
        return _this.set('renderNow', true);
      }, this.get('delay'));
    }
  });
});