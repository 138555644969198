define('frontend/routes/register', ['exports', 'frontend/models/user-company-view-model', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _userCompanyViewModel, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      var owner = Ember.getOwner(this),
          viewModel = _userCompanyViewModel.default.create();

      Ember.setOwner(viewModel, owner);

      return viewModel;
    }
  });
});