define('frontend/services/before-unload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      var _this = this;

      this.set('models', []);

      window.addEventListener("beforeunload", function (event) {
        if (_this.get('isDirty')) {
          var message = "You have unsaved changes, are you sure you want to leave?";
          event.returnValue = message;
          return message;
        }
      });
    },
    addObject: function addObject(object) {
      this.get('models').addObject(object);
    },
    removeObject: function removeObject(object) {
      this.get('models').removeObject(object);
    },


    isDirty: Ember.computed('models.@each.isDirty', function () {
      return !!this.get('models').findBy('isDirty');
    })
  });
});