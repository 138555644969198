define('frontend/models/supplier-type', ['exports', 'ember-data', 'ember-data/relationships', 'ember-data/attr'], function (exports, _emberData, _relationships, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    company: (0, _relationships.belongsTo)('company'),

    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    shortName: Ember.computed.alias('name')
  });
});