define('frontend/routes/app/products/product/design-review/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      closeModal: function closeModal() {
        this.transitionTo('app.products.product.design-control');
      }
    }
  });
});