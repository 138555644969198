define('frontend/locales/en/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    back: 'Back',

    save: {
      successful: 'Your changes were saved',
      failed: 'There was a problem saving your changes',
      in_progress: 'Saving your changes'
    },

    destroy: {
      successful: 'Deletion was successful',
      failed: 'Deletion failed'
    },

    approval_roles: {
      confirm_inactivate: 'Are you sure you want to inactivate this role?  It can be restored later.'
    },

    // translations for ember-form-for fields
    'ember-form-for-i18n': {
      'subsystem.name': 'Subsystem name'
    },

    'design-review': {
      'button-delete': 'Delete the Design Review',
      'button-pullback': 'Pullback the Design Review',

      'confirm-delete': 'Are you sure you want to delete this design review?',
      'confirm-pullback': 'Are you sure you want to pullback this design review?',

      'phase-gate': {
        'notify-deslect': 'Some items were deselected because draft items are not allowed in design reviews that advance the product design phase'
      }
    }
  };
});