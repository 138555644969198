define('frontend/controllers/app/products/product/integrations', ['exports', 'frontend/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_permissions.default, {
    queryParams: ['tab'],
    tab: 'GitHub',

    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    features: Ember.inject.service(),

    tabIcons: {
      GitHub: 'github',
      GitLab: 'gitlab',
      Bitbucket: 'bitbucket',
      Trello: 'trello',
      JIRA: 'yahoo',
      Pivotal: 'circle-o',
      CircleCI: 'dot-circle-o',
      TravisCI: 'travisci',
      Jenkins: 'user',
      Zendesk: 'zendesk',
      "Test Results": 'code'
    },

    // CPs
    company: Ember.computed.alias('model'),
    product: Ember.computed.alias('company.products.firstObject'),

    enabledTabs: Ember.computed(function () {
      var tabs = ['GitHub', 'GitLab', 'Bitbucket', 'Trello', 'JIRA', 'Pivotal', 'TravisCI', 'CircleCI', 'Jenkins'];
      if (this.get('features').isEnabled('zendesk-integration')) {
        tabs.push('Zendesk');
      }
      tabs.push('Test Results');
      return tabs;
    }),

    // Actions
    actions: {
      linkOauth: function linkOauth(provider, product) {
        /**
         * `product_id` doesn't matter for company auth hooks, just used for
         * callback to the application, so use company's first product by default.
         * (Github, Gitlab, Bitbucket)
         */
        if (!product) {
          product = this.get('product');
        }

        var params = {
          email: this.get('session.currentUser.email'),
          token: this.get('session.token'),
          product_id: product.get('id')
        };
        window.location.href = '/auth/' + provider + '/prepare?' + Ember.$.param(params);

        this.get('metrics').trackEvent({
          event: 'oauth-linked',
          provider: provider
        });
      },
      unlinkOauth: function unlinkOauth(provider) {
        var providerName = provider.capitalize();
        var company = this.get('company');
        if (confirm('Are you sure you want to unlink this ' + provider + ' account?')) {
          company.set('unlink' + providerName, true);
          company.saveWithNotificationsAndProgress('Unlinking ' + provider + ' account...', providerName + ' account was unlinked', 'Could not unlink ' + providerName + ' account');

          this.get('metrics').trackEvent({
            event: 'oauth-unlinked',
            provider: provider
          });
        }
      },
      trackCreateWebhook: function trackCreateWebhook(provider, productId) {
        this.get('metrics').trackEvent({
          event: 'Webhook Created',
          provider: provider,
          product_id: productId
        });
      },
      destroyWebhook: function destroyWebhook(provider, product) {
        if (confirm('Are you sure you want to unlink this ' + provider + ' account?')) {
          var webhook = product.get(provider.toLowerCase() + 'Webhook');

          webhook.destroyRecord();

          this.get('metrics').trackEvent({
            event: 'Webhook Destroyed',
            provider: provider
          });
        }
      },
      updateRepos: function updateRepos(provider, product, repos) {
        var _this = this;

        var repoIds = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

        var webhooks = product.get(provider + 'Webhooks');
        var currentRepoIds = webhooks.mapBy('repoId');
        var newRepoIds = void 0;

        if (Ember.isArray(repoIds)) {
          newRepoIds = repoIds;
        } else {
          newRepoIds = repos.mapBy('id');
        }

        var reposIdsToAdd = newRepoIds.filter(function (repoId) {
          return currentRepoIds.indexOf(repoId) === -1;
        });
        var oldWebhooks = webhooks.filter(function (hook) {
          return newRepoIds.indexOf(Ember.get(hook, 'repoId')) === -1;
        });

        var removePromises = oldWebhooks.map(function (webhook) {
          return webhook.destroyRecord();
        });

        var addPromises = reposIdsToAdd.map(function (newRepoId) {
          var webhook = _this.get('store').createRecord(provider + '-webhook', {
            product: product,
            repoId: newRepoId
          });
          return webhook.saveWithNotifications().catch(function () {
            webhook.destroyRecord();
            var errorMessage = webhook.get('errors.base.firstObject.message');
            _this.get('notify').info(errorMessage);
          });
        });

        return Ember.RSVP.all(removePromises.concat(addPromises));
      }
    }
  });
});