define('frontend/components/dc-column-header', ['exports', 'frontend/models/design-control', 'frontend/mixins/permissions'], function (exports, _designControl, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_permissions.default, {
    qmsExportsPoller: Ember.inject.service(),

    dropdownIsVisible: false,

    designControlChangeHistoryExportButtonDisabled: Ember.computed.alias('qmsExportsPoller.qmsExportInProgress'),

    popOverId: Ember.computed('designControlType', function () {
      return 'show' + this.get('designControlType') + 'ChangeHistory';
    }),

    title: Ember.computed('headerTitle', function () {
      return "Add a new " + this.get('headerTitle').substring(0, this.get('headerTitle').length - 1);
    }),

    historyTitle: Ember.computed('headerTitle', function () {
      return "View the change history of " + this.get('headerTitle');
    }),

    exportTitle: Ember.computed('headerTitle', function () {
      return "Export the change history of " + this.get('headerTitle');
    }),

    headerTitle: Ember.computed('designControlType', function () {
      return _designControl.designControlTypes[this.get('designControlType')];
    }),

    actions: {
      toggleDropdownIsVisible: function toggleDropdownIsVisible() {
        this.toggleProperty('dropdownIsVisible');
      },
      createNewDesignControl: function createNewDesignControl() {
        this.sendAction('action', this.get('designControlType'));
      },
      exportDesignControlChangeHistory: function exportDesignControlChangeHistory() {
        this.sendAction('exportDesignControlChangeHistory', this.get('designControlType'));
      }
    }
  });
});