define('frontend/controllers/app/products/product/risk-management/hazards/edit', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed;
  exports.default = Controller.extend(_emberValidations.default, {
    hazard: computed.alias('model'),
    displayErrors: false,

    validations: {
      editHazardDescription: {
        presence: true
      }
    },

    actions: {
      updateHazard: function updateHazard() {
        var _this = this;

        this.validate().then(function () {
          _this.get('hazard').setProperties({
            'description': _this.get('editHazardDescription'),
            'approvalState': 'draft'
          });
          return _this.get('hazard').saveWithNotifications().then(function () {
            _this.transitionToRoute('app.products.product.risk-management');
          });
        }).catch(function () {
          _this.set('displayErrors', true);
        });
      },
      revertToPrevious: function revertToPrevious() {
        this.set('isShowingPreviousApprovedVersion', false);
        this.set('editHazardDescription', this.get('model.description'));
      },
      subsystemChanged: function subsystemChanged(selectedSubsystem) {
        this.get('hazard').set('subsystem', selectedSubsystem);
        this.get('hazard').saveWithNotifications();
      }
    }
  });
});