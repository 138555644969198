define('frontend/models/circleci-build', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    status: attr('string'),
    committerName: attr('string'),
    committerEmail: attr('string'),
    vcsType: attr('string'),
    vcsRevision: attr('string'),
    vcsUrl: attr('string'),
    reponame: attr('string'),
    username: attr('string'),
    buildNum: attr('string'),
    buildUrl: attr('string'),
    startTime: attr('date'),

    title: Ember.computed.alias('vcsRevision'),
    number: Ember.computed.alias('buildNum'),
    url: Ember.computed.alias('buildUrl')
  });
});