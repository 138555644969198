define('frontend/models/design-control-item-qms-document-link', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    createdAt: attr('date'),

    designControlItem: belongsTo('design-control', { polymorphic: true }),
    qmsDocument: belongsTo('qms-document')
  });
});