define('frontend/helpers/risk-score-class', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.riskScoreClass = riskScoreClass;
  function riskScoreClass(params /*, hash*/) {
    var riskLabel = params[0];

    return {
      Low: 'green',
      Moderate: 'orange',
      High: 'red'
    }[riskLabel];
  }

  exports.default = Ember.Helper.helper(riskScoreClass);
});