define('frontend/routes/onboarding/product/subsystem', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    setupController: function setupController(controller, context) {
      this._super(controller, context);
      controller.createNewSubsystem();
    }
  });
});