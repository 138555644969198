define('frontend/models/training-assignment', ['exports', 'ember-data', 'moment', 'frontend/mixins/routable', 'frontend/initializers/add-model-async-methods'], function (exports, _emberData, _moment, _routable, _addModelAsyncMethods) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend(_routable.default, {
    remainingAttempts: attr('number'),
    completedAt: attr('date'),
    createdAt: attr('date'),
    failedAt: attr('date'),
    training: belongsTo('training'),
    user: belongsTo('user'),
    aasmState: attr('string'),
    attachments: hasMany('attachment', { inverse: 'attachable' }),

    // Properties for segmenting assignments in the matrix
    isInApp: Ember.computed.reads('training.isInApp'),
    isDoc: Ember.computed.reads('training.isDoc'),
    isTask: Ember.computed.reads('training.isTask'),
    description: Ember.computed.reads('training.description'),
    tourName: Ember.computed.reads('training.tourName'),
    qmsDocument: Ember.computed.reads('training.qmsDocument'),
    documentType: Ember.computed.reads('training.qmsDocument.qmsDocumentType.title'),
    documentNumber: Ember.computed.reads('training.qmsDocument.formattedDocNumber'),

    isOverdue: Ember.computed('isInProgress', 'isFailed', 'training.dueDate', function () {
      return (this.get('isInProgress') || this.get('isFailed')) && (0, _moment.default)(this.get('training.dueDate')) < (0, _moment.default)().startOf('day');
    }),

    isObsoleted: Ember.computed.equal('aasmState', 'obsoleted'),
    isInProgress: Ember.computed.equal('aasmState', 'in_progress'),
    isFailed: Ember.computed.equal('aasmState', 'failed'),
    isCompleted: Ember.computed.equal('aasmState', 'completed'),
    isPending: Ember.computed.equal('aasmState', 'pending'),
    isOutstanding: Ember.computed('isCompleted', 'isOverdue', 'isPending', 'isFailed', function () {
      return !this.get('isCompleted') && !this.get('isOverdue') && !this.get('isPending') && !this.get('isFailed');
    }),
    //  computes assignment CSS class string
    status: Ember.computed('isOverdue', 'isCompleted', 'isFailed', 'isPending', function () {
      if (this.get('isCompleted')) {
        return 'completed';
      }
      if (this.get('isFailed')) {
        return 'failed';
      }
      if (this.get('isOverdue')) {
        return 'overdue';
      }
      if (this.get('isPending')) {
        return 'pending';
      }
      if (this.get('isObsoleted')) {
        return 'obsoleted';
      }
      return 'outstanding';
    }),

    filterStatus: Ember.computed('isOverdue', 'isCompleted', function () {
      if (this.get('isCompleted')) {
        return 'completed';
      }
      if (this.get('isOverdue')) {
        return 'overdue';
      }
      return 'outstanding';
    }),

    /**
     * Submits to the `incomplete` custom API endpoint. Used by quality owners
     * to un-complete the assignment.
     *
     * @return {Promise<TrainingAssignment>}
     */
    markAsIncomplete: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'incomplete' }),

    /**
     * Completes the assignment by saving the custom `complete` API endpoint.
     *
     * @param  {String} successMessage
     * @param  {String} errorMessage
     * @return {Promise<TrainingAssignment>}
     */
    markAsComplete: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'complete' }),

    /**
     * Resets the assignment when it is failed. Called by the quality owner.
     *
     * @return {Promise<trainingAssignment>}
     */
    markAsReset: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'reset' }),

    /**
     * Removes the training assignment. Called by the quality owner.
     *
     * @return {Promise<trainingAssignment>}
     */
    removeAssignment: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'remove' }),

    routableTarget: Ember.computed('qmsDocument.id', function () {
      if (this.get('qmsDocument.id')) {
        return 'app.products.product.document-control.document-item';
      } else {
        return 'app.products.product.training';
      }
    }),

    routableParams: Ember.computed('qmsDocument.id', function () {
      if (this.get('qmsDocument.id')) {
        return [this.get('qmsDocument')];
      }
    })
  });
});