define('frontend/routes/app/products/product/change-requests/change-request', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var approval_set_id = _ref.approval_set_id;

      this.transitionTo('app.products.product.change-requests.change-request', { queryParams: { change: approval_set_id } });
    }
  });
});