define('frontend/models/capa-phase-extension', ['exports', 'ember-data', 'ember-data/relationships', 'ember-data/attr', 'frontend/mixins/approvable', 'frontend/initializers/add-model-async-methods'], function (exports, _emberData, _relationships, _attr, _approvable, _addModelAsyncMethods) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PromiseArray = _emberData.default.PromiseArray;
  exports.default = _emberData.default.Model.extend(_approvable.default, {
    company: (0, _relationships.belongsTo)(),
    capaPhase: (0, _relationships.belongsTo)(),
    newDate: (0, _attr.default)('date-no-time'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    justification: (0, _attr.default)('mobiledoc'),

    routeForApproval: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'route_for_approval', type: 'post' }),

    routableTarget: "app.products.product.capas.capa",
    routableParams: Ember.computed('capaPhase.{capa,phaseNumber}', function () {
      var promise = this.get('capaPhase').then(function (phase) {
        return [phase.get('capa'), { queryParams: { currentTab: 'Phase ' + phase.get('phaseNumber') } }];
      });
      return PromiseArray.create({ promise: promise });
    }),

    wasPushed: function wasPushed() {
      this.get('versions').reload();
    }
  });
});