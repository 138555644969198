define('frontend/controllers/app/products/product/risk-management/harms/update', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed;
  exports.default = Controller.extend(_emberValidations.default, {
    oldSeverities: computed('harms', function () {
      return this.get('harms').mapBy('severity.id');
    }),

    displayErrors: false,

    changedHarms: computed('harms.@each.{description,severity}', 'oldSeverities', function () {
      var oldSeverities = this.get('oldSeverities');
      return this.get('harms').filter(function (harm, index) {
        return harm.get('hasDirtyAttributes') === true || Number(harm.get('severity.id')) !== Number(oldSeverities[index]);
      });
    }),

    actions: {
      updateHarms: function updateHarms() {
        this.get('changedHarms').forEach(function (harm) {
          harm.set('approvalState', 'draft');
          harm.saveWithNotifications();
        });
        this.transitionToRoute('app.products.product.risk-management');
      },
      deleteHarm: function deleteHarm(harm) {
        harm.requestDelete();
      }
    }
  });
});