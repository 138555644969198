define('frontend/components/column-selectable-table/td', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      alias = Ember.computed.alias;
  exports.default = Ember.Component.extend({
    tagName: 'td',
    classNameBindings: ['column.grow:should-grow:should-not-grow'],

    trampoline: computed('row', 'column.key', function () {
      return Ember.Object.extend({
        value: alias('row.' + this.get('column.key'))
      }).create({ row: this.get('row') });
    }),

    value: computed('trampoline.value', 'column.formatter', function () {
      var value = this.get('trampoline.value');

      if (this.get('column.formatter')) {
        return this.get('column.formatter')(value);
      }

      return value;
    })

  });
});