define('frontend/tours/introduction-dashboard-training', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = {
    name: 'Introduction, Dashboard, Training Module',

    setup: function setup() {
      var allproducts = this.store.peekAll('product');
      this.realproduct = allproducts.get('firstObject');
      var allcompanies = this.store.peekAll('company');
      this.company = allcompanies.get('firstObject');
      this.product = this.store.createRecord('product', { name: "Example product", id: 'example', phase: 'product_definition', company: this.company });
    },
    teardown: function teardown() {
      this.product.unloadRecord();
    },


    steps: [{
      prepare: function prepare() {
        return this.transitionTo("app.products.product.dashboard", this.product);
      },

      text: "Welcome to Enzyme QMS.<br><br>This training will cover some of functions of the QMS that are common to all modules. <br><br>Your Quality Owner will assign you additional training as appropriate.",
      selector: '.dashboard--status',
      animationRoot: '.product-liquid-outlet'
    }, {
      text: "The purpose of a QMS, or Quality Management System, is to organize and store data about your products to ensure the following things: <ul><li>Making sure you have a 'single source of truth' - a place that reflects the current state of the design that everyone in the company can turn to</li><li>Making sure you build what the customer needs</li><li>Making sure you build what you spec'd</li><li>Preventing defects before they ship to customers</li><li>Fixing problems that arise once your product has shipped to customers.</li></ul>",
      selector: '.dashboard--status'
    }, {
      text: "To that end, data is structured in a number of <u>modules</u> that you interact with on a regular basis: <ul><li><b>Design Control</b>, to store data about your design</li><li><b>Risk Management</b>, to capture and track your product's risk and risk controls</li><li><b>Document Control</b>, to store documents and records that don't fit in Design Control</li><li><b>Training</b>, to ensure you understand the workings of the QMS</li></ul><br><br>All changes to the content of the Quality System are <u>tracked and logged</u>, and <u>most changes require approval</u> from at least one other person in the company to be implemented.",
      selector: '.main-nav'
    }, {
      prepare: function prepare() {
        $($('.tabs-nav a')[0]).click();
        return this.waitFor('.tab-nav-wrapper');
      },

      text: "This module is called <b>Dashboard</b> and is designed to provide you with an at-a-glance status of your actions in the QMS.<br><br>You can see here on this page:<ul><li>The number of Change Requests that require your approval</li><li>The number of Change Requests you've sent that are still pending (i.e. neither denied nor approved)</li><li>and your Outstanding Training</li></ul>",
      selector: '.tabs-nav'
    }, {
      prepare: function prepare() {},

      text: "Next, we look at the Navbar, which allows you to transition to the different modules of the QMS. <br><br>Each of these modules are covered in separate training courses except for Training, which we will cover briefly now.",
      selector: '.main-nav'
    }, {
      prepare: function prepare() {
        return this.transitionTo("app.products.product.training", this.product);
      },

      text: "You are probably familiar with this page because you needed to come here in order to see this! <br><br>From here you can see all of your outstanding and overdue training assignments, and take any assignment in any order.",
      selector: '.training-section-container',
      animationRoot: '.product-liquid-outlet'
    }, {
      prepare: function prepare() {
        $('.user-dropdown').removeClass('active');
        return this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "hidden";
        });
      },

      text: "Next, we move to the User menu.",
      selector: '.ph-zone.f-right'
    }, {
      prepare: function prepare() {
        if ($('.popup-header .fa-close').length > 0) {
          $('.popup-header .fa-close').click();
        }
        $('.user-dropdown').addClass('active');
        return this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "visible";
        });
      },

      text: "Two options in the user menu are available to all users. First is the User Profile.",
      selector: '.user-dropdown .clear-ul li.user-profile',
      animationDelay: 250
    }, {
      prepare: function prepare() {
        $($('.user-dropdown .clear-ul a')[0]).click();
        $('.user-dropdown').removeClass('active');
        return this.waitFor('.ember-modal-dialog');
      },

      text: "Here, you can change your: <ul><li>Name</li><li>Email</li><li>Password</li><li>Avatar (user icon)</li><li>Signature</li><li>Email Reminder Frequency</li></ul><br>Setting a signature is <u>extremely important</u> if you will be approving things and you should do so immediately.",
      selector: '.ember-modal-dialog',
      animationDelay: 250
    }, {
      prepare: function prepare() {
        if ($('.popup-header .fa-close').length > 0) {
          $('.popup-header .fa-close').click();
        }
        $('.user-dropdown').addClass('active');
        return this.waitFor(function () {
          return $('.user-dropdown').css('visibility') === "visible";
        });
      },

      text: "Lastly, integrations, where you can integrate other applications your company might use with the QMS.",
      selector: '.user-dropdown .clear-ul li.integrations',
      animationDelay: 250
    }, {
      prepare: function prepare() {
        $('.user-dropdown').removeClass('active');
        this.transitionTo("app.products.product.integrations", this.product);
        return this.waitFor('.api-integration');
      },

      text: "Here you can link to a variety of web services. Instructions for each are located on each tab.",
      selector: '.api-integration'
    }, {
      text: "This concludes the Introduction to the QMS!",
      selector: 'body'
    }]
  };
});