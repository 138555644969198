define('frontend/helpers/humanize-permission-level', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizePermissionLevel = humanizePermissionLevel;
  function humanizePermissionLevel(params /*, hash*/) {
    var permissionLevel = params[0];

    return {
      'viewer': "Viewer",
      'editor': "Editor",
      'approver': "Approver",
      'quality_owner': "Quality Owner"
    }[permissionLevel];
  }

  exports.default = Ember.Helper.helper(humanizePermissionLevel);
});