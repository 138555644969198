define('frontend/components/user-drop-down', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    user: Ember.computed.oneWay('session.currentUser'),
    company: Ember.computed.readOnly('user.company'),
    isShowingDropdown: false,

    avatarUrl: Ember.computed('user.avatarUrl', function () {
      return this.get('user.avatarUrl') || _environment.default.assetUrlPrepend + '/imgs/user.jpg';
    }),

    avatarStyle: Ember.computed('avatarUrl', function () {
      return Ember.String.htmlSafe('background-image: url(\'' + this.get('avatarUrl') + '\')');
    }),

    logoStyle: Ember.computed('company.logoUrl', function () {
      var style = void 0;

      if (this.get('company.logoUrl')) {
        style = 'background-image: url(' + this.get('company.logoUrl') + ')';
      } else {
        style = '';
      }

      return style.htmlSafe();
    }),

    actions: {
      showModal: function showModal(modalName) {
        this.sendAction('showModal', modalName);
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('isShowingDropdown');
      }
    }
  });
});