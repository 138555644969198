define('frontend/components/craq-editor/mobiledoc-editor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['craq-mobiledoc-editor'],
    click: function click() {
      this.set('isEditing', true);
    },

    actions: {
      save: function save() {
        this.get('update')(this.get('newMobiledoc'));
        this.set('isEditing', false);
      }
    }
  });
});