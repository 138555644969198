define('frontend/components/change-requests/approvables', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['change-request-approvables'],

    approvablesByProduct: Ember.computed('value.@each.product', function () {
      return this.get('value').naturalSortBy('product.name', 'shortName').reduce(function (memo, value) {
        var productName = value.get('product.approvedAttributes.name') || value.get('product.name');
        if (!productName) {
          productName = "No Product";
        }
        if (!memo[productName]) {
          memo[productName] = [];
        }
        memo[productName].push(value);
        return memo;
      }, {});
    })
  });
});