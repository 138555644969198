define('frontend/helpers/hilight-query-result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hilightQueryResult = hilightQueryResult;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var isBlank = Ember.isBlank;


  // Helper to hilight a query result in a search string.

  // This helper returns the results in a convenient form for iterating over in a
  // hbs template. For example if you are searching for "bar" in text "foobar",
  // the result returned will be:
  // [{text: "foo", hilighted: false}, {text: "bar", hilighted: true}]

  // This approach sidesteps issues of escaping that would be encountered if
  // you were to build an html string in the helper, and allows simple
  // case-insensitive searching and searching for regexp characters that would be
  // more difficult using a RegExp#exec based approach.


  function hilightQueryResult(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        query = _ref2[0],
        text = _ref2[1];

    if (isBlank(query) || isBlank(text)) {
      return [{ text: text, hilighted: false }];
    }

    var results = [],
        i = 0;

    while (i < text.length) {
      var foundIndex = text.toLowerCase().indexOf(query, i);
      if (~foundIndex) {
        var beforeString = text.substring(i, foundIndex);
        if (beforeString.length) {
          results.push({ text: beforeString, hilighted: false });
        }

        var foundString = text.substr(foundIndex, query.length);

        results.push({ text: foundString, hilighted: true });
        i = foundIndex + query.length;
      } else {
        var restOfString = text.substring(i);
        results.push({ text: restOfString, hilighted: false });
        i += restOfString.length;
      }
    }

    return results;
  }

  exports.default = Ember.Helper.helper(hilightQueryResult);
});