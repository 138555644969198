define('frontend/components/authorize-action/input-pin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MASK_CHAR = '*';

  exports.default = Ember.Component.extend({
    tagName: '',

    autoFocus: false,
    idx: 0,

    onPinEntered: function onPinEntered() {},
    onPinDeleted: function onPinDeleted() {},

    actions: {
      onInput: function onInput(_ref) {
        var target = _ref.target,
            data = _ref.data;

        if (data) {
          var nextElementSibling = target.nextElementSibling;

          var idx = this.get('idx');

          target.value = data;

          this.onPinEntered(idx, data);

          if (nextElementSibling) {
            nextElementSibling.focus();
          }

          Ember.run.later(function () {
            target.value = MASK_CHAR;
          }, 175);
        }
      },
      onKeyUp: function onKeyUp(_ref2) {
        var target = _ref2.target,
            key = _ref2.key;

        if (key == "Backspace") {
          var previousElementSibling = target.previousElementSibling;

          if (previousElementSibling) {
            this.onPinDeleted();
            if (!target.nextElementSibling && target.value) {
              // last PIN
              target.value = '';
              target.focus();
            } else {
              previousElementSibling.value = '';
              previousElementSibling.focus();
            }
          }
        } else if (key === "Escape") {
          this.sendAction('cancel');
        } else if (key === "Enter" && !target.nextElementSibling) {
          this.sendAction('done');
        }
      }
    }
  });
});