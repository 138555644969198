define('frontend/models/harm', ['exports', 'ember-data', 'frontend/mixins/approvable', 'frontend/mixins/model-meta-data'], function (exports, _emberData, _approvable, _modelMetaData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  var computed = Ember.computed;
  var alias = computed.alias,
      collect = computed.collect;
  exports.default = Model.extend(_approvable.default, _modelMetaData.default, {
    product: belongsTo('product'),
    description: attr('string'),
    severity: belongsTo('severity'),
    harmInstances: hasMany('harm-instances'),
    company: belongsTo(),

    shortName: alias('description'),
    severityLabel: alias('severity.name'),

    descriptionWithSeverity: computed('description', 'severityLabel', function () {
      return this.get('description') + ': ' + this.get('severityLabel');
    }),

    cloneToProduct: function cloneToProduct(product) {
      var harm = product.get('liveHarms').findBy('descriptionWithSeverity', this.get('descriptionWithSeverity'));
      if (harm) {
        return harm;
      }

      return this.store.createRecord('harm', {
        product: product,
        description: this.get('description'),
        severity: this.get('severity')
      }).save();
    },


    routableTarget: 'app.products.product.risk-management',
    routableParams: collect('product'),

    transitionToRoute: function transitionToRoute() {
      var product = this.get('product');
      this.get('router').transitionTo('app.products.product.risk-management', product);
    }
  });
});