define('frontend/components/enzyme-power-select-multiple/selected-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['tag', 'doc-tag', 'max'],
    classNameBindings: ['isNewlyAdded'],

    isNewlyAdded: Ember.computed('select.selectComponent.{formModel,newlyAdded.[]}', 'option', function () {
      if (!this.get('select.selectComponent.formModel')) {
        return false;
      }

      return (this.get('select.selectComponent.newlyAdded') || []).includes(this.get('option'));
    })
  });
});