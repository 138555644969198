define('frontend/services/dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * @type {Boolean}
     */
    isOpen: false,

    /**
     * @param  {String} component A component containing a modal
     * @param  {Any} model This value will be passed to the component
     * @return {Promise<Any>} Resolves when the modal is closed, and resolves to the value the modal returned.
     */
    open: function open(component, model) {
      this.setProperties({
        component: component,
        model: model,
        isOpen: true
      });
      this.defer = Ember.RSVP.defer();
      return this.defer.promise;
    },


    /**
     * This action is called by the dialog modal to close the modal.
     *
     * @param {Any} result
     */
    actionCalled: function actionCalled(result) {
      this.defer.resolve(result);
      this.defer = null;
      this.setProperties({
        isOpen: false,
        component: null,
        model: null
      });
    },


    /**
     * Call this to confirm anything with the user. You can customize the
     * confirmation dialog's title, text, and buttons.
     *
     * @param {Object} options
     * @return {Promise<Boolean>}
     */
    confirm: function confirm() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var opts = Ember.merge({
        title: 'Confirmation',
        text: 'Are you sure?',
        submitText: 'Yes',
        cancelText: 'Cancel'
      }, options);
      return this.open('dialog-confirm', opts);
    }
  });
});