define('frontend/mixins/permissions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),

    user: Ember.computed.alias('session.currentUser'),
    hasProductPermissions: Ember.computed.alias('user.company.enableProductUserPermissionLevels'),
    productsCount: Ember.computed.alias('products.length'),
    hasManyProducts: Ember.computed.gt('productsCount', 0),

    singleProductUserPermissionLevel: Ember.computed('product.id', 'user.productUserPermissionLevels.@each.{product,permissionLevel}', function () {
      return this.get('user.productUserPermissionLevels').findBy('product.id', this.get('product.id'));
    }),

    manyProductUserPermissionLevel: Ember.computed('products.@each.id', 'user.productUserPermissionLevels.@each.{product,permissionLevel}', 'permissionWeights', function () {
      return this.get('applicableProductUserPermissionLevels').sortBy('permissionWeight').get('firstObject');
    }),

    applicableProductUserPermissionLevels: Ember.computed('products.@each.id', 'user.productUserPermissionLevels.@each.product', function () {
      var productIds = this.get('products').mapBy('id');

      return this.get('user.productUserPermissionLevels').filter(function (p) {
        return productIds.includes(p.get('product.id'));
      });
    }),

    productUserPermissionLevel: Ember.computed('hasManyProducts', 'singleProductUserPermissionLevel', 'manyProductUserPermissionLevel', function () {
      if (this.get('hasManyProducts')) {
        return this.get('manyProductUserPermissionLevel');
      }
      return this.get('singleProductUserPermissionLevel');
    }),

    permissionLevel: Ember.computed('hasProductPermissions', 'productUserPermissionLevel.permissionLevel', function () {
      return this.get('hasProductPermissions') && this.get('productUserPermissionLevel.permissionLevel') || this.get('user.permissionLevel');
    }),

    isViewer: Ember.computed.equal('permissionLevel', 'viewer'),
    isEditor: Ember.computed.equal('permissionLevel', 'editor'),
    isApprover: Ember.computed.equal('permissionLevel', 'approver'),
    isSuper: Ember.computed.equal('permissionLevel', 'super'),
    isQualityOwner: Ember.computed.equal('permissionLevel', 'quality_owner'),
    isOwner: Ember.computed.or('isQualityOwner', 'isSuper'),
    canApprove: Ember.computed.or('isApprover', 'isOwner'),
    canEdit: Ember.computed.or('isEditor', 'canApprove')
  });
});