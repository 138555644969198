define('frontend/components/avatar-selector', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      RSVP = Ember.RSVP;
  var alias = computed.alias;
  var Promise = RSVP.Promise;
  var PromiseObject = _emberData.default.PromiseObject;
  exports.default = Component.extend({
    removeField: computed('field', function () {
      return 'remove' + this.get('field').capitalize();
    }),

    urlField: computed('field', function () {
      return this.get('field') + 'Url';
    }),

    // trampoline lets you have computed property with computed dependent key
    // https://spin.atomicobject.com/2015/08/03/ember-computed-properties/
    trampoline: computed('model', 'field', 'removeField', 'urlField', function () {
      return Ember.Object.extend({
        fieldValue: alias('model.' + this.get('field')),
        removeFieldValue: alias('model.' + this.get('removeField')),
        urlFieldValue: alias('model.' + this.get('urlField'))
      }).create({ model: this.get('model') });
    }),

    // Figures out the correct url for an image to display when editing the avatar,
    // based on the current state the form is in, reading a file for a data-uri if
    // a new file has been uploaded
    avatarDisplayer: computed('trampoline.{fieldValue,removeFieldValue,urlFieldValue}', function () {
      var _this = this;

      if (this.get('trampoline.removeFieldValue')) {
        return null;
      }

      if (this.get('trampoline.fieldValue')) {
        var promise = new Promise(function (resolve) {
          var reader = new FileReader();

          reader.addEventListener("load", function () {
            resolve({ url: reader.result });
          });

          reader.readAsDataURL(_this.get('trampoline.fieldValue'));
        });

        return PromiseObject.create({ promise: promise });
      }

      return { url: this.get('trampoline.urlFieldValue') };
    }),

    displayUrl: computed.reads('avatarDisplayer.url'),

    setField: function setField(fieldName, value) {
      this.get('model').set(this.get(fieldName), value);
    },


    actions: {
      removeAvatar: function removeAvatar() {
        this.setField('field', null);
        this.setField('removeField', true);
        this.$('input[type=file]').val(null);
      },
      changeAvatar: function changeAvatar(file) {
        this.setField('field', file);
        this.setField('removeField', false);
      }
    }
  });
});