define('frontend/components/attachments-qms-documents-form', ['exports', 'frontend/utils/documents-form'], function (exports, _documentsForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    list: Ember.inject.service(),

    attachedQmsDocuments: Ember.computed.mapBy('model.designControlItemQmsDocumentLinks', 'qmsDocument'),

    qmsDocuments: Ember.computed(function () {
      return this.get('store').peekAll('qms-document').toArray();
    }),

    availableQmsDocumentsByFolder: Ember.computed('list.qmsFolders.[]', 'list.qmsDocuments.[]', 'attachedQmsDocuments.[]', function () {
      var attachedIds = this.get('attachedQmsDocuments').mapBy('id');
      var folders = this.get('list.qmsFolders').naturalSortBy('name');

      if (!folders.get('length')) {
        return folders;
      }

      var result = [];

      for (var i = 0; i < folders.get('length'); i++) {
        var folder = folders.objectAt(i);
        var folderData = (0, _documentsForm.generateOptions)(folder, attachedIds);

        if (folderData.documentsCount > 0) {
          result.push({
            groupName: folder.get('name'),
            options: folderData.options.naturalSortBy('docIdentifier')
          });
        }
      }
      return result;
    }),

    actions: {
      linkDocument: function linkDocument(qmsDocument) {
        var link = this.createLink(qmsDocument);

        link.saveWithNotifications().then(this._onSaveSuccessful.bind(this)).catch(function () {
          return link.rollbackAttributes();
        });
      },
      unlinkDocument: function unlinkDocument(qmsDocument) {
        var link = this.findLink(qmsDocument);

        link.destroyWithNotifications();
      }
    },

    createLink: function createLink(qmsDocument) {
      var store = this.get('store');
      var designControlItem = this.get('model');
      var attributes = {
        designControlItem: designControlItem,
        qmsDocument: qmsDocument
      };
      var modelName = 'design-control-item-qms-document-link';

      return store.createRecord(modelName, attributes);
    },
    findLink: function findLink(qmsDocument) {
      var qmsDocumentLinks = this.get('model.designControlItemQmsDocumentLinks');
      var qmsDocumentLinkId = Ember.get(qmsDocument, 'id');

      return qmsDocumentLinks.find(function (link) {
        return Ember.get(link, 'qmsDocument.id') === qmsDocumentLinkId;
      });
    },
    _onSaveSuccessful: function _onSaveSuccessful() {
      this.get('model').set('approvalState', 'draft');
      this.get('model').save();
    }
  });
});