define('frontend/components/toggle-switch/label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['toggle-switch__label'],
    attributeBindings: ['for']
  });
});