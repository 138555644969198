define('frontend/components/click-outside', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    clickOutside: function clickOutside(e) {
      var isOutside = Ember.$(e.target).closest(this.get('element')).length !== 1;
      if (isOutside) {
        this.sendAction();
      }
    },


    boundClickHandler: Ember.computed(function () {
      return Ember.run.bind(this, this.clickOutside);
    }),

    _attachClickOutsideHandler: Ember.on('didInsertElement', function () {
      var _this = this;

      // attach the handler in the next runloop, so any click event currently
      // happening will not interfere
      Ember.run.next(function () {
        Ember.$(window).on('click', _this.get('boundClickHandler'));
      });
    }),

    _removeClickOutsideHandler: Ember.on('willDestroyElement', function () {
      Ember.$(window).off('click', this.get('boundClickHandler'));
    })

  });
});