define('frontend/components/approvable-change', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var typeMap = {
    initialProbabilityId: 'probability',
    mitigatedProbabilityId: 'probability',
    capaOwnerId: 'user',
    ownerId: 'user',
    processRequirementQmsDocumentId: 'qms-document'
  };

  var unknownValue = { shortName: "[cannot display value]" };

  exports.default = Ember.Component.extend({
    classNames: ['approvable-change'],
    classNameBindings: ['field'],
    isComponent: Ember.inject.service(),
    store: Ember.inject.service(),

    previousValue: Ember.computed('values.previous', 'field', function () {
      return this.decorateValue(this.get('values.previous'));
    }),

    currentValue: Ember.computed('values.current', 'field', function () {
      return this.decorateValue(this.get('values.current'));
    }),

    resolvedCurrentValue: Ember.computed('currentValue.{isPending,shortName}', function () {
      return this.resolveValue(this.get('currentValue'));
    }),

    resolvedPreviousValue: Ember.computed('previousValue.{isPending,shortName}', function () {
      return this.resolveValue(this.get('previousValue'));
    }),

    fieldName: Ember.computed('field', function () {
      return this.get('field').replace(/Id$/, '');
    }),

    componentName: Ember.computed('approvable.constructor.modelName', 'field', function () {
      var modelName = this.get('approvable.constructor.modelName');
      var specificComponent = 'approvable-change/' + modelName + '/' + this.get('field').dasherize();

      if (this.get('isComponent').test(specificComponent)) {
        return specificComponent;
      }
    }),

    decorateValue: function decorateValue(value) {
      var field = this.get('field');

      if (typeMap[field]) {
        return this.findBelongsTo(typeMap[field], value);
      } else {
        var match = field.match(/^(.+)Id$/);
        if (match) {
          return this.findBelongsTo(match[1], value);
        } else {
          match = field.match(/Summary$/);
          if (match) {
            return this.findSummary(value);
          } else if (typeof value == "boolean") {
            return value ? "Yes" : "No";
          } else {
            return value;
          }
        }
      }
    },

    findBelongsTo: function findBelongsTo(type, value) {
      try {
        if (value) {
          return this.get('store').findRecord(type, value);
        }
      } catch (e) {
        // if we can't figure a way to load the data and there's also a custom
        // renderer, then just return the actual value so the custom renderer can
        // do whatever it wants.
        if (this.get('componentName')) {
          return value;
        } else {
          return unknownValue;
        }
      }
    },
    findSummary: function findSummary(value) {
      var _this = this;

      var findPromises = void 0;

      if (value && !Ember.isArray(value)) {
        findPromises = [unknownValue];
      } else {
        findPromises = (value || []).compact().map(function (summary) {
          if (summary && summary.type && summary.id) {
            return _this.get('store').findRecord(summary.type, summary.id);
          } else {
            return unknownValue;
          }
        });
      }

      // if we can't figure a way to load the data and there's also a custom
      // renderer, then just return the actual value so the custom renderer can
      // do whatever it wants.
      if (findPromises.every(function (p) {
        return p === unknownValue;
      }) && this.get('componentName')) {
        return value;
      }

      var promise = Ember.RSVP.all(findPromises).then(function (records) {
        return {
          shortName: records.mapBy('shortName').join(", ") || ' '
        };
      });

      return _emberData.default.PromiseObject.create({ promise: promise });
    },
    resolveValue: function resolveValue(value) {
      if (value && Ember.get(value, 'isPending')) {
        return null;
      }

      return value && Ember.get(value, 'shortName') || value;
    }
  });
});