define('frontend/components/tracing-relationship-line', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      compare = Ember.compare,
      observer = Ember.observer,
      on = Ember.on,
      run = Ember.run,
      htmlSafe = Ember.String.htmlSafe;


  var orderCards = function orderCards(orderBy) {
    return computed('from', 'to', function () {
      return [this.get('from'), this.get('to')].sort(function (a, b) {
        return compare(a.$().offset()[orderBy], b.$().offset()[orderBy]);
      });
    });
  };

  exports.default = Component.extend({
    tagName: ['canvas'],
    classNames: ['tracing-relationship-line'],
    attributeBindings: ['style', 'width', 'height'],
    cardsOrderedByLeft: orderCards('left'),
    cardsOrderedByTop: orderCards('top'),

    topCard: computed.alias('cardsOrderedByTop.firstObject'),
    bottomCard: computed.alias('cardsOrderedByTop.lastObject'),
    leftCard: computed.alias('cardsOrderedByLeft.firstObject'),
    rightCard: computed.alias('cardsOrderedByLeft.lastObject'),

    offsetY: computed('topCard', function () {
      return this.get('topCard').$().offsetParent().offset().top;
    }),

    startX: computed('leftCard', function () {
      return this.get('leftCard').$().offset().left + this.get('leftCard').$().width();
    }),

    endX: computed('rightCard', function () {
      return this.get('rightCard').$().offset().left;
    }),

    startY: computed('topCard', 'offsetY', function () {
      return this.get('topCard').$().offset().top - this.get('offsetY');
    }),

    endY: computed('bottomCard', 'offsetY', function () {
      return this.get('bottomCard').$().offset().top + this.get('bottomCard').$().height() - this.get('offsetY');
    }),

    width: computed('startX', 'endX', function () {
      return this.get('endX') - this.get('startX');
    }),

    height: computed('startY', 'endY', function () {
      return this.get('endY') - this.get('startY');
    }),

    style: computed('startX', 'startY', 'width', 'height', function () {
      return htmlSafe('\n      top: ' + this.get('startY') + 'px;\n      left: ' + this.get('startX') + 'px;\n      width: ' + this.get('width') + 'px;\n      height: ' + this.get('height') + 'px;\n    ');
    }),

    _triggerRedraw: on('didInsertElement', observer('topCard', 'bottomCard', 'leftCard', 'rightCard', 'height', 'width', function () {
      run.once(this, 'drawLine');
    })),

    drawLine: function drawLine() {
      var context = this.get('element').getContext('2d'),
          x1 = 0,
          x2 = this.get('width'),
          y1 = void 0,
          y2 = void 0;

      if (this.get('topCard') === this.get('leftCard')) {
        y1 = this.get('topCard').$().height() / 2;
        y2 = this.get('height') - this.get('bottomCard').$().height() / 2;
      } else {
        y1 = this.get('height') - this.get('leftCard').$().height() / 2;
        y2 = this.get('rightCard').$().height() / 2;
      }

      var controlX = this.get('width') / 2,
          controlY = 0;

      context.beginPath();
      context.moveTo(x1, y1);
      context.quadraticCurveTo(controlX, controlY, x2, y2);
      context.lineTo(x2, y2);
      context.lineWidth = 2;
      context.strokeStyle = '#55cd9a';
      context.stroke();

      // uncomment this code to draw lines to the control points, which helps
      // adjusting the line curvature. See http://www.html5canvastutorials.com/tutorials/html5-canvas-quadratic-curves/#explanation
      // for illustration of how control points work

      // context.beginPath();
      // context.moveTo(x1, y1);
      // context.lineTo(controlX, controlY);
      // context.lineTo(x2, y2);
      // context.lineWidth = 1;
      // context.strokeStyle = '#ff0000';
      // context.stroke();

    }
  });
});