define('frontend/components/tracing-dependencies-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    selected: null,

    dependencyObject: computed('dependency', function () {
      return this.get('dc.tracingDependencyObjects')[this.get('dependency')];
    }),

    currentTracingDependencies: computed('dependency', 'dc.designOutputRequirementDependencies.[]', 'dc.designOutputVerificationDependencies.[]', 'dc.requirementUserNeedDependencies.[]', 'dc.requirementVerificationDependencies.[]', 'dc.userNeedValidationDependencies.[]', function () {
      return this.get('dc.' + this.get('dependency')).naturalSortBy('id');
    }),

    availableDependencies: computed('dependency', 'dc.designOutputRequirementDependencies.[]', 'dc.designOutputVerificationDependencies.[]', 'dc.requirementUserNeedDependencies.[]', 'dc.requirementVerificationDependencies.[]', 'dc.userNeedValidationDependencies.[]', function () {
      var availableDependencies = [];
      var dependencies = this.get('dc.' + this.get('dependency')).mapBy(this.get('dependencyObject'));
      var dependencyIds = dependencies.mapBy('idDescon');

      this.get('dc.product.' + this.get('dependencyObject') + 's').forEach(function (currentDependency) {
        if (!dependencyIds.includes(currentDependency.get('idDescon')) && currentDependency.get('isLive')) {
          availableDependencies.pushObject(currentDependency);
        }
      });

      return availableDependencies.naturalSortBy('id');
    }),

    actions: {
      selectionChanged: function selectionChanged(selectedDependency) {
        this.sendAction('action', selectedDependency, this.get('dependency'), this.get('dependencyObject'));
      },
      removeTracingDependency: function removeTracingDependency(tracingDependency, dependencyType) {
        this.sendAction('removeTracingDependencyAction', tracingDependency, dependencyType);
      }
    }
  });
});