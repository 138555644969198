define('frontend/models/approval-set-approvable', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    approvable: belongsTo('approvable'),
    approvalSet: belongsTo('approval-set')
  });
});