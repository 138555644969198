define('frontend/components/user-permission-matrix', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var roleTypes = ['viewer', 'editor', 'approver', 'quality_owner'];

  exports.default = Ember.Component.extend({
    roleTypes: roleTypes,

    filteredUsers: Ember.computed('users.@each.isNew', function () {
      return this.get('users').filter(function (user) {
        return !user.get('isNew');
      });
    })
  });
});