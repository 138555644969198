define('frontend/models/approval-set', ['exports', 'ember-data/attr', 'ember-data/relationships', 'frontend/models/approval-request', 'frontend/initializers/add-model-async-methods', 'frontend/mixins/routable'], function (exports, _attr, _relationships, _approvalRequest, _addModelAsyncMethods, _routable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _approvalRequest.default.extend(_routable.default, {
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),

    state: (0, _attr.default)('string'),
    sequenceNumber: (0, _attr.default)('number'),
    requester: (0, _relationships.belongsTo)('user'),
    approvalSetApprovers: (0, _relationships.hasMany)('approval-set-approvers'),
    approvalSetApprovables: (0, _relationships.hasMany)('approval-set-approvable'),
    product: (0, _relationships.belongsTo)('product', { inverse: 'ownedApprovalSets' }),
    approvers: Ember.computed.mapBy('approvalSetApprovers', 'approver'),
    craqAnswers: (0, _attr.default)(),
    craqVersion: (0, _attr.default)('number'),
    comment: (0, _attr.default)('string'),
    craqQuestionSet: (0, _relationships.belongsTo)(),
    lite: (0, _attr.default)('boolean'),
    isLiteApprovalSet: Ember.computed.alias('lite'),
    pendingJustification: (0, _attr.default)('string'),
    attachments: (0, _relationships.hasMany)('attachment', { inverse: 'attachable' }),
    qmsDocuments: (0, _relationships.hasMany)('qms-document', { inverse: 'approvalSets' }),
    blacklistPresent: (0, _attr.default)('boolean'),
    company: (0, _relationships.belongsTo)(),
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    isAdminCr: (0, _attr.default)('boolean'),
    reportPdfFileUpload: (0, _relationships.belongsTo)('file-upload'),
    blacklist: (0, _attr.default)(),
    submittedAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),

    submittedOrCreatedAt: Ember.computed.or('submittedAt', 'createdAt'),

    /**
     * The property name is `isStatePending` instead of `isPending` is to avoid
     * conflicts with the promise object's `isPending` property.
     *
     * @type {Boolean}
     */
    isStatePending: Ember.computed.equal('state', 'pending'),

    isStateDraft: Ember.computed.equal('state', 'draft'),
    isStateApproved: Ember.computed.equal('state', 'approved'),
    isStateDenied: Ember.computed.equal('state', 'denied'),
    isUnresolved: Ember.computed.or('isStatePending', 'isStateDraft'),

    canEdit: Ember.computed.and('canCancel', 'isStateDraft'),

    canCancel: Ember.computed('requester.id', 'session.currentUser.isQualityOwner', 'isUnresolved', function () {
      if (!this.get('isUnresolved')) {
        return false;
      }
      if (this.get('session.currentUser.isQualityOwner')) {
        return true;
      }
      var requesterId = this.get('requester.id');
      return requesterId && requesterId === this.get('session.currentUser.id');
    }),

    canPullback: Ember.computed.and('canCancel', 'isStatePending'),

    pullback: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'pullback' }),

    allApprovalSetSigners: Ember.computed.mapBy('approvalSetApprovers', 'approvalSetApproverSigners'),

    canApprove: Ember.computed('isStatePending', 'requester', 'session.currentUser.id', 'approvers.@each.id', 'approvalSetApprovers.@each.pendingApproval', 'allApprovalSetSigners.@each.{status}', function () {
      var _this = this;

      if (!this.get('isStatePending')) {
        return false;
      }

      var currentUser = this.get('session.currentUser');
      var pendingAsas = this.get('approvalSetApprovers').filterBy('pendingApproval', true);

      var approvalSetApproverByUser = pendingAsas.findBy('approver.content', currentUser);
      if (approvalSetApproverByUser) {
        return true;
      }

      var filteredAsas = pendingAsas.filter(function (asa) {
        return asa.get('approvalSetApproverSigners').find(function (signer) {
          return (
            // signer has to be the user and signer has to still be pending
            signer.get('user.id') === _this.get('session.currentUser.id') && signer.get('status') === 'pending'
          );
        });
      });

      var rolesThatCanApprove = filteredAsas.mapBy('approver.content').filter(function (a) {
        if (!a) return false;
        return a.constructor.modelName === 'approval-role';
      });

      return rolesThatCanApprove.some(function (role) {
        return role.get('users').toArray().some(function (user) {
          return user.get('id') === currentUser.get('id');
        });
      });
    }),

    canDeny: Ember.computed.readOnly('canApprove'),

    stateColor: Ember.computed('state', function () {
      return {
        draft: 'grey',
        pending: 'orange',
        denied: 'red',
        approved: 'green'
      }[this.get('state')];
    }),

    allSigners: Ember.computed.mapBy('approvalSetApprovers', 'approvalSetApproverSigners'),
    allSignersFlattened: Ember.computed('allSigners.@each.[]', function () {
      return this.get('allSigners').flatten();
    }),

    allApproversApprovedByRole: Ember.computed('approvalSetApprovers.@each.{approved,approver,roleRepresentative,approvalSetApproverSigners}', 'allSignersFlattened.@each.user', function () {
      return this.get('approvalSetApprovers').naturalSortBy('approver.isRole', 'approver.fullName').map(function (asa) {
        var signers = asa.get('approvalSetApproverSigners');

        if (signers.length) {
          return {
            asa: asa,
            approver: asa.get('approver'),
            isRole: asa.get('approver.isRole'),
            users: signers,
            status: asa.get('status')
          };
        }
      }).compact();
    }),

    /**
     * @param {String} action Can be `approve` or `deny`
     * @return {Promise<Object>} Resolves to the ajax response
     */
    approvalAction: function approvalAction(action, role) {
      var _this2 = this;

      var data = {
        id: this.get('id'),
        approval_action: action
      };
      if (role) {
        data.approval_role_id = role.get('id');
      }
      return this.get('ajax').request('/api/approvals', { type: 'POST', data: data }).then(function (response) {
        return _this2.get('approvables').then(function (approvables) {
          approvables.forEach(function (approvable) {
            var approvableId = approvable.get('id');
            var approvableType = approvable.constructor.modelName;
            var eventName = 'Approval ' + (action === 'approve' ? 'accepted' : 'denied');
            _this2.get('metrics').trackEvent({ event: eventName, approvableType: approvableType, approvableId: approvableId });
          });
          var eventName = 'Change Request ' + (action === 'approve' ? 'approved' : 'denied');
          _this2.get('metrics').trackEvent({ event: eventName, approvalSetId: _this2.get('id') });
          return response;
        });
      });
    },


    routableTarget: 'app.products.product.change-requests.change-request',

    wasPushed: function wasPushed() {
      // Hacky (although I actually like this pattern, it's not best practice due to the private api)
      // Send this to the change requests route to refresh the table when new records are opushed
      this.get('router._router').send('changeRequestSaved');
    }
  });
});