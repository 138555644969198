define('frontend/components/form-field', ['exports', 'ember-form-for/components/form-field', 'ember-changeset/utils/is-changeset'], function (exports, _formField, _isChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formField.default.extend({
    // Changeset and ember-data have a slightly different error path. You can
    // configure this globally for ember-form-for, but there are currently forms
    // that use both. This will automatically detect if the form is for a changeset
    // and use the correct error path
    errorsPath: function errorsPath(propertyName) {
      if ((0, _isChangeset.default)(this.get('object'))) {
        return 'error.' + propertyName + '.validation';
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});