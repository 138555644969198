define('frontend/components/change-request/questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['change-request-questions'],

    answers: Ember.computed(function () {
      return {};
    }),

    allOptions: Ember.computed('questions.@each.options', function () {
      return this.get('questions').mapBy('options');
    }),

    firstCompleteQuestion: Ember.computed('answers', 'allOptions.@each.[]', function () {
      var _this = this;

      var answers = this.get('answers');

      return this.get('questions').find(function (question) {
        var questionIndex = _this.get('questions').indexOf(question);
        var answerCode = 'q' + questionIndex;
        var optionIndex = Ember.get(answers, answerCode);
        var selectedOption = question.options[optionIndex];
        return selectedOption && selectedOption.complete_if_selected;
      });
    }),

    firstCompleteQuestionIndex: Ember.computed('firstCompleteQuestion', 'questions.[]', 'answers', function () {
      // returning Infinity allows simple > comparison to find if questions should be disabled
      if (!this.get('firstCompleteQuestion')) {
        return Infinity;
      }

      return this.get('questions').indexOf(this.get('firstCompleteQuestion'));
    }),

    // action stubs, these are optionally passed in
    commentChanged: function commentChanged() {},


    actions: {
      selectOption: function selectOption(question, option) {
        // Create new object so dependent keys update
        var answers = Ember.assign({}, this.get('answers'));

        var questionIndex = this.get('questions').indexOf(question);
        var answerCode = 'q' + questionIndex;
        var answerConfirmationKey = answerCode + '_confirmations';
        var optionIndex = Ember.get(question, 'options').indexOf(option);

        // reset any confirmations when the option changes
        var oldValue = Ember.get(answers, answerCode);
        if (oldValue !== optionIndex) {
          Ember.set(answers, answerConfirmationKey, null);
        }

        Ember.set(answers, answerCode, optionIndex);

        this.set('answers', answers);

        if (this.get('firstCompleteQuestion')) {
          for (var i = this.get('firstCompleteQuestionIndex') + 1; i < this.get('questions.length'); i++) {
            var _question = this.get('questions')[i];
            var _questionIndex = this.get('questions').indexOf(_question);
            var _answerCode = 'q' + _questionIndex;
            var _answerConfirmationKey = _answerCode + '_confirmations';
            Ember.set(answers, _answerCode, null);
            Ember.set(answers, _answerConfirmationKey, null);
          }
        }
      },
      toggleConfirm: function toggleConfirm(question, option, confirmation) {
        // Create new object so dependent keys update
        var answers = Ember.assign({}, this.get('answers'));
        var questionIndex = this.get('questions').indexOf(question);
        var key = 'q' + questionIndex + '_confirmations';

        var confirmations = (Ember.get(answers, key) || []).slice();

        if (confirmations.includes(confirmation)) {
          confirmations.removeObject(confirmation);
        } else {
          confirmations.addObject(confirmation);
        }

        Ember.set(answers, key, confirmations);

        this.set('answers', answers);
      }
    }

  });
});