define('frontend/models/training-plan', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'frontend/mixins/attachable'], function (exports, _model, _attr, _relationships, _attachable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_attachable.default, {
    approvalRoles: (0, _relationships.hasMany)('approval-roles'),
    requester: (0, _relationships.belongsTo)('user'),
    qmsDocument: (0, _relationships.belongsTo)('qms-document', { inverse: 'trainingPlan' }),
    qmsDocumentType: (0, _relationships.belongsTo)('qms-document-type', { inverse: 'trainingPlan' }),
    daysUntilDue: (0, _attr.default)('number'),
    initialDaysUntilDue: (0, _attr.default)('number'),
    daysUntilRepeat: (0, _attr.default)('number'),
    initialDueDate: (0, _attr.default)('date-no-time'),
    trainingQuestions: (0, _relationships.hasMany)('training-question'),
    taskDescription: (0, _attr.default)('string'),
    dateCalculation: (0, _attr.default)('string'),
    overrideDocumentPlans: (0, _attr.default)('boolean'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),

    isDoc: Ember.computed.bool('qmsDocument.id'),
    isTask: Ember.computed.bool('taskDescription'),
    isDocType: Ember.computed.bool('qmsDocumentType.id'),

    approvalRoleIds: Ember.computed.mapBy('approvalRoles', 'id'),

    description: Ember.computed('qmsDocument.title', 'taskDescription', function () {
      if (this.get('qmsDocument.title')) {
        var effectiveDocTitle = this.get('qmsDocument.effectiveAttributes.title') || this.get('qmsDocument.title');
        return this.get('qmsDocument.docIdentifier') + ' ' + effectiveDocTitle;
      } else if (this.get('taskDescription')) {
        return this.get('taskDescription');
      }
    })
  });
});