define('frontend/controllers/app/products/product/supplier-quality', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    qmsExportsPoller: Ember.inject.service(),
    productController: Ember.inject.controller('app.products.product'),
    currentCompany: Ember.computed.reads('session.currentUser.company'),
    exportSupplierDataButtonIsDisabled: Ember.computed.alias('qmsExportsPoller.qmsExportInProgress'),

    searchText: Ember.computed.reads('productController.searchText'),

    columns: Ember.computed(function () {
      var _this = this;

      return [{
        title: 'ID',
        key: 'id',
        sortBy: 'id',
        filter: { type: 'number' }
      }, {
        title: "Supplier Status",
        component: 'suppliers/column-state',
        sortBy: 'humanizedEffectiveState',
        key: 'humanizedEffectiveState',
        filter: { type: 'list', options: ['Unapproved', 'Conditionally approved', 'Approved', 'Audit in progress', 'Disqualified', 'Discontinued'] }
      }, {
        title: "Risk",
        key: 'supplierRisk.name',
        sortBy: 'supplierRisk.name',
        filter: { type: 'string' }
      }, {
        title: 'Type',
        key: 'supplierType.name',
        sortBy: 'supplierType.name',
        filter: { type: 'string' }
      }, {
        title: 'Rating',
        key: 'supplierRating.name',
        sortBy: 'supplierRating.name',
        filter: { type: 'string' }
      }, {
        title: 'Name',
        key: 'name',
        sortBy: 'name',
        filter: { type: 'string' }
      }, {
        title: 'Description',
        key: 'description',
        sortBy: 'description',
        filter: { type: 'string' }
      }, {
        title: 'Attachments',
        component: 'suppliers/column-attachments',
        sortBy: 'numberOfAttachments',
        filter: { type: 'number' }
      }, {
        type: 'actions',
        actions: [{
          name: "View",
          action: function action(supplier) {
            return _this.transitionToRoute('app.products.product.supplier-quality.supplier', supplier);
          }
        }]
      }, {
        title: "Record Status",
        component: 'suppliers/column-approval-state',
        sortBy: 'humanizedApprovalState',
        key: 'humanizedApprovalState',
        filter: { type: 'string' }
      }];
    }),

    persistedSuppliers: Ember.computed.filterBy('model', 'isNew', false),

    // TODO: Define keys to compute against
    filteredSuppliers: Ember.computed('persistedSuppliers.[]', 'searchText', function () {
      var searchText = this.get('searchText');
      var suppliers = this.get('persistedSuppliers');

      if (Ember.isBlank(searchText)) {
        return suppliers;
      }

      // TODO: Define search criteria
      // return suppliers.filter( complaint =>
      //   "region.name sourceName sourceType hospital stage".w().some(field =>
      //     (complaint.get(field) || "").toLowerCase().includes(q)
      //   )
      // );
    }),

    actions: {
      createSupplierExport: function createSupplierExport() {
        if (!this.get('qmsExportsPoller.qmsExportInProgress')) {
          this.store.createRecord('qms-export', {
            companyId: this.get('currentCompany.id'),
            qmsExportableType: 'Supplier'
          }).saveAndNotify();
        }
      }
    }
  });
});