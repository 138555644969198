define("frontend/utils/build-mobile-doc", ["exports", "ember-mobiledoc-text-renderer"], function (exports, _emberMobiledocTextRenderer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = buildMobileDoc;
  exports.isMobileDoc = isMobileDoc;
  exports.isMobileDocPresent = isMobileDocPresent;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function buildMobileDoc(text) {
    return { "version": "0.3.0", "markups": [], "atoms": [], "cards": [], "sections": [[1, "p", [[0, [], 0, text]]]] };
  }

  function isMobileDoc(value) {
    return value && (typeof value === "undefined" ? "undefined" : _typeof(value)) === 'object' && value.version && value.sections;
  }

  function isMobileDocPresent(maybeMobiledoc) {
    if (!isMobileDoc(maybeMobiledoc)) {
      return false;
    }

    var renderer = new _emberMobiledocTextRenderer.default();

    return !!renderer.render(maybeMobiledoc).result.length;
  }
});