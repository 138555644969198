define('frontend/initializers/extend-form-for-fields', ['exports', 'ember-form-for/components/form-fields/checkbox-field', 'ember-form-for/components/form-fields/checkbox-group', 'ember-form-for/components/form-fields/color-field', 'ember-form-for/components/form-fields/custom-field', 'ember-form-for/components/form-fields/date-field', 'ember-form-for/components/form-fields/datetime-local-field', 'ember-form-for/components/form-fields/email-field', 'ember-form-for/components/form-fields/file-field', 'ember-form-for/components/form-fields/hidden-field', 'ember-form-for/components/form-fields/month-field', 'ember-form-for/components/form-fields/number-field', 'ember-form-for/components/form-fields/password-field', 'ember-form-for/components/form-fields/radio-field', 'ember-form-for/components/form-fields/radio-group', 'ember-form-for/components/form-fields/range-field', 'ember-form-for/components/form-fields/search-field', 'ember-form-for/components/form-fields/select-field', 'ember-form-for/components/form-fields/tel-field', 'ember-form-for/components/form-fields/text-field', 'ember-form-for/components/form-fields/textarea-field', 'ember-form-for/components/form-fields/time-field', 'ember-form-for/components/form-fields/url-field', 'ember-form-for/components/form-fields/week-field'], function (exports, _checkboxField, _checkboxGroup, _colorField, _customField, _dateField, _datetimeLocalField, _emailField, _fileField, _hiddenField, _monthField, _numberField, _passwordField, _radioField, _radioGroup, _rangeField, _searchField, _selectField, _telField, _textField, _textareaField, _timeField, _urlField, _weekField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  var Fields = [_checkboxField.default, _checkboxGroup.default, _colorField.default, _customField.default, _dateField.default, _datetimeLocalField.default, _emailField.default, _fileField.default, _hiddenField.default, _monthField.default, _numberField.default, _passwordField.default, _radioField.default, _radioGroup.default, _rangeField.default, _searchField.default, _selectField.default, _telField.default, _textField.default, _textareaField.default, _timeField.default, _urlField.default, _weekField.default];

  function initialize() {
    Fields.forEach(function (Field) {
      Field.reopen({
        _disabled: false,

        didInsertElement: function didInsertElement() {
          this._super.apply(this, arguments);

          this.notifyPropertyChange('formComponent');
        },


        formComponent: Ember.computed('form', function () {
          if (!this.get('form')) {
            return;
          }

          var registry = Ember.getOwner(this).lookup('-view-registry:main');

          return registry[this.get('form').replace(/-form$/, '')];
        }),

        disabled: Ember.computed('formComponent.disabled', {
          get: function get() {
            return this.get('formComponent.disabled') || this.get('_disabled');
          },
          set: function set(key, value) {
            this.set('_disabled', value);
          }
        })

      });
    });
  }

  exports.default = {
    initialize: initialize
  };
});