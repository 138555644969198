define('frontend/serializers/application', ['exports', 'ember-data/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    // Error keys need to be camelized instead of underscored
    keyForAttribute: function keyForAttribute(attr) {
      return attr.underscore();
    },

    keyForRelationship: function keyForRelationship(rawKey) {
      return rawKey.underscore();
    },

    normalizeErrors: function normalizeErrors(typeClass, hash) {
      this._super(typeClass, hash);

      for (var key in hash) {
        if (~key.indexOf('_')) {
          var camelizedKey = key.camelize();
          hash[camelizedKey] = hash[key];
          delete hash[key];
        }
      }
    }
  });
});