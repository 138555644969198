define('frontend/components/select-approvables', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PromiseArray = _emberData.default.PromiseArray;
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),

    // This could just be a computed.reads but making it a promise makes power select handle loading state
    options: Ember.computed('approvables', function () {
      return PromiseArray.create({ promise: this.get('approvables').then(function (a) {
          return a.options;
        }) });
    }),

    approvableDetails: Ember.computed.reads('approvables.details'),

    actions: {
      searchOptions: function searchOptions(spec, query) {
        var details = this.get('approvableDetails').findBy('spec', spec);

        var result = Object.keys(details).some(function (k) {
          return (details[k] || "").toString().toLowerCase().replace(/-/g, '').includes(query.toLowerCase().replace(/-/g, ''));
        });
        return result ? 0 : -1;
      }
    }
  });
});