define('frontend/controllers/app/products/product/document-control/actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.qmsDocumentFileUploadChanged = qmsDocumentFileUploadChanged;
  var NOTIFICATIONS = {
    ON_UPLOADED: 'File was uploaded',
    ON_FAILURE: 'File could not be uploaded'
  };

  /**
   * Creates and saves a new file-upload record.
   * 
   * If you plan on using this actions, you'll need to implement
   * the onFileUploadSuccess hook. This hooks takes one parameter and that
   * is the uploaded file.
   * 
   * Example:
   * 
   *   onFileUploadSuccess(fileUpload) {
   *     this.set('fileUpload', fileUpload);
   *   }
   * 
   * @function
   * @param {File} file The file to be uploaded.
   * 
   */
  function qmsDocumentFileUploadChanged(file) {
    var product = this.get('product');
    var company = this.get('company');
    var store = this.get('store');
    var attrs = { file: file, product: product, company: company };
    var fileUpload = store.createRecord('file-upload', attrs);

    this.set('qmsDocumentFileUpload', fileUpload);

    fileUpload.saveWithNotifications(NOTIFICATIONS.ON_UPLOADED, NOTIFICATIONS.ON_FAILURE).then(this.onFileUploadSuccess.bind(this));
  }
});