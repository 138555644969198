define('frontend/mixins/routable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create({
    router: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      (false && !(this.get('routableTarget')) && Ember.assert('No route target specified on the ' + this.get('constructor.modelName') + ' model - if you include the Routable Mixin, you must define a routableTarget property with the name of the route to transition to', this.get('routableTarget')));
    },


    paramsForLinkTo: Ember.computed('routableTarget', 'routableParams.{[],isPending}', function () {
      var params = [this.get('routableTarget')];
      if (this.get('routableParams.isPending')) {
        return [];
      }

      if (Ember.isArray(this.get('routableParams'))) {
        var routableParams = this.get('routableParams').slice();

        if (routableParams.get('lastObject.queryParams')) {
          var values = routableParams.pop();
          routableParams.push({ isQueryParams: true, values: values.queryParams });
        }
        return params.concat(routableParams);
      } else {
        return params.concat([this]);
      }
    }),

    transitionToRoute: function transitionToRoute() {
      var _this = this;

      Ember.RSVP.resolve(this.get('routableParams')).then(function (params) {
        var _get;

        if (!Ember.isArray(params)) {
          params = [_this];
        }

        (_get = _this.get('router')).transitionTo.apply(_get, [_this.get('routableTarget')].concat(_toConsumableArray(params.toArray())));
      });
    }
  });
});