define('frontend/routes/app/products/product/risk-management/hazardous-situations/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var product = this.modelFor('app.products.product');

      controller.setProperties({
        product: product,
        newHazardousSituationHazard: null,
        newHazardousSituationDescription: '',
        displayErrors: false
      });
    }
  });
});