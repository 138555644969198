define('frontend/components/complaints/column-approval-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['column-label'],
    classNameBindings: ['row.approvalState'],

    displayApprovalState: computed('row.hasDraftApprovalSet', 'row.isPendingApproval', 'row.approvalState', function () {
      if (this.get('row.hasDraftApprovalSet')) {
        return "In a Draft Change Request";
      } else if (this.get('row.isPendingApproval')) {
        return "Pending Approval";
      } else {
        return this.get('row.approvalState');
      }
    })
  });
});