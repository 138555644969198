define('frontend/components/qms-integrations/bitbucket/repo-select', ['exports', 'frontend/utils/find-selected-repos', 'frontend/utils/grouped-repos'], function (exports, _findSelectedRepos, _groupedRepos) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'dd',

    selectedBitbucketRepos: (0, _findSelectedRepos.default)('bitbucket'),
    groupedBitbucketRepos: (0, _groupedRepos.default)('bitbucket'),

    bitbucketWebhooks: Ember.computed.alias('product.bitbucketWebhooks')
  });
});