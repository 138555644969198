define('frontend/models/approval-role', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'frontend/mixins/approver'], function (exports, _model, _attr, _relationships, _approver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_approver.default, {
    name: (0, _attr.default)('string'),
    company: (0, _relationships.belongsTo)('company'),
    users: (0, _relationships.hasMany)('user'),
    approvers: Ember.computed.filterBy("users", "canApprove"),

    isRole: true,

    fullName: Ember.computed.alias('name')
  });
});