define('frontend/controllers/onboarding/product/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      save: function save() {
        var _this = this;

        this.get('model').saveWithNotifications().then(function (product) {
          _this.transitionToRoute('onboarding.product.subsystem', product.get('id'));
        });
      }
    }
  });
});