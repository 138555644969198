define('frontend/components/notification-cards/training-assignment-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['training-assignment-card'],

    item: null,
    pendingActionItems: [],
    pendingActionItemsIndex: 0,

    assignment: Ember.computed.alias('item')
  });
});