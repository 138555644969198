define('frontend/models/jenkins-webhook', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    apiToken: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    username: (0, _attr.default)('string'),
    product: (0, _relationships.belongsTo)('product')
  });
});