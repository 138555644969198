define('frontend/models/product', ['exports', 'ember-data', 'frontend/initializers/add-model-async-methods', 'frontend/mixins/approvable', 'frontend/mixins/model-meta-data'], function (exports, _emberData, _addModelAsyncMethods, _approvable, _modelMetaData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend(_approvable.default, _modelMetaData.default, {
    company: belongsTo('company'),
    name: attr('string'),
    shortCode: attr('string'),
    phase: attr('string', { defaultValue: 'product_definition' }),
    websocketChannel: attr('string'),
    killSwitchState: attr('string', { defaultValue: 'active' }),
    testResultsToken: attr('string'),
    jiraWebhookUrl: attr('string'),
    trelloBoards: hasMany('trello-board'),
    jenkinsBuilds: hasMany('jenkins-build'),
    circleciBuilds: hasMany('circleci-build'),
    subsystems: hasMany('subsystem'),
    userNeeds: hasMany('user-need'),
    requirements: hasMany('requirement'),
    validations: hasMany('validation'),
    verifications: hasMany('verification'),
    designOutputs: hasMany('design-outputs'),
    qmsDocuments: hasMany('qms-documents'),
    topLevelFolders: hasMany('qms-document-folders'),
    qmsDocumentTypes: hasMany('qms-document-types'),
    hazards: hasMany('hazards'),
    harms: hasMany('harms'),
    hazardousSituations: hasMany('hazardous-situations'),
    harmInstances: hasMany('harm-instances'),
    designReviews: hasMany('design-reviews'),
    severities: hasMany('severities'),
    risklevels: hasMany('risklevels'),
    probabilities: hasMany('probabilities'),
    approvalRoles: hasMany('approval-role'),
    complaints: hasMany(),
    fileUploads: hasMany(),

    ownedImpactSets: hasMany('impact-sets', { inverse: 'product' }),
    ownedApprovalSets: hasMany('approval-sets', { inverse: 'product' }),

    bitbucketWebhooks: hasMany('bitbucket-webhook'),
    githubWebhooks: hasMany('github-webhook'),
    gitlabWebhooks: hasMany('gitlab-webhook'),
    trelloWebhook: belongsTo('trello-webhook'),
    travisciWebhook: belongsTo('travisci-webhook'),
    circleciWebhook: belongsTo('circleci-webhook'),
    jenkinsWebhook: belongsTo('jenkins-webhook'),
    jiraWebhook: belongsTo('jira-webhook'),
    pivotalWebhook: belongsTo('pivotal-webhook'),
    zendeskWebhook: belongsTo('zendesk-webhook'),

    approvedName: Ember.computed.reads('currentApprovedVersion.versionData.name'),

    duplicate: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'duplicate', type: 'post' }),

    // Live versions
    liveUserNeeds: Ember.computed.filterBy('userNeeds', 'isLiveDc'),
    liveRequirements: Ember.computed.filterBy('requirements', 'isLiveDc'),
    liveDesignOutputs: Ember.computed.filterBy('designOutputs', 'isLiveDc'),
    liveVerifications: Ember.computed.filterBy('verifications', 'isLiveDc'),
    liveValidations: Ember.computed.filterBy('validations', 'isLiveDc'),
    liveQmsDocuments: Ember.computed.filterBy('accessibleAndNonObsoleteQmsDocuments', 'isLiveDoc'),
    liveHazards: Ember.computed.filterBy('hazards', 'isLive'),
    liveHazardousSituations: Ember.computed.filterBy('hazardousSituations', 'isLive'),
    liveHarms: Ember.computed.filterBy('harms', 'isLive'),
    liveHarmInstances: Ember.computed.filterBy('harmInstances', 'isLive'),
    liveComplaints: Ember.computed.filterBy('complaints', 'isLive'),

    // Design phase computed properties
    isInProductDefinitionPhase: Ember.computed.equal('phase', 'product_definition'),
    isInVerificationAndValidationPhase: Ember.computed.equal('phase', 'verification_and_validation'),
    isInDesignTransferPhase: Ember.computed.equal('phase', 'design_transfer'),
    isNotInDesignTransferPhase: Ember.computed.not('isInDesignTransferPhase'),

    // Approvables computed properties
    nonemptyImpactSets: Ember.computed.filterBy('impactSets', 'isEmpty', false),

    // prefer approved name to display but display unapproved name is that's all
    // that's available
    displayName: Ember.computed.or('approvedAttributes.name', 'name'),
    displayPhase: Ember.computed.or('approvedAttributes.phase', 'phase'),

    approvables: Ember.computed.union('liveUserNeeds', 'liveRequirements', 'liveValidations', 'liveVerifications', 'liveDesignOutputs', 'liveQmsDocuments', 'liveHazards', 'liveHazardousSituations', 'liveHarms', 'liveHarmInstances'),

    approvers: Ember.computed.alias('company.approvers'),

    defaultSubsystem: Ember.computed('subsystems.[]', function () {
      return this.get('subsystems').findBy('name', 'System');
    }),

    qmsDocumentFolders: Ember.computed.reads('topLevelFolders'),

    unsortedUnionOfQmsDocumentFolders: Ember.computed.union('allQmsDocumentFolders', 'company.qmsDocumentFolders'),
    unionOfQmsDocumentFolders: Ember.computed('unsortedUnionOfQmsDocumentFolders', function () {
      return this.get('unsortedUnionOfQmsDocumentFolders').naturalSortBy('fullPath');
    }),
    shortName: Ember.computed.reads('name'),
    qmsDocumentFoldersByLevels: Ember.computed('qmsDocumentFolders.[]', function () {
      var result = [];

      function walk(folders, level) {
        folders.forEach(function (folder) {
          result.push({ level: level, folder: folder });
          var subfolders = folder.get('subfolders').toArray();
          if (subfolders.length > 0) {
            walk(subfolders, level + 1);
          }
        });
      }
      walk(this.get('qmsDocumentFolders'), 0);
      return result;
    }),

    routableTarget: 'app.products.product'

  });
});