define('frontend/components/notification-cards/approval-card-form', ['exports', 'frontend/mixins/approval-role-selection'], function (exports, _approvalRoleSelection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_approvalRoleSelection.default, {
    tagName: 'form',
    classNames: ['approval-card-form'],

    approveText: 'Approve Changes',
    approvingText: 'Approving...',
    canDeny: true,
    approvable: undefined,

    isPending: Ember.computed.or('approve.isRunning', 'deny.isRunning'),
    approvalSet: Ember.computed.readOnly('approvable.unresolvedApprovalSet'),

    submit: function submit(e) {
      e.preventDefault();

      this.send('approve');
    },


    actions: {
      deny: function deny() {
        this.get('deny').perform();
      },
      approve: function approve() {
        this.get('approve').perform();
      }
    }
  });
});