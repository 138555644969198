define('frontend/helpers/natural-sort-by', ['exports', 'frontend/macros/natural-sort'], function (exports, _naturalSort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      params = params.slice();

      Ember.set(this, 'array', params.pop());
      Ember.set(this, 'sortProps', params.compact());

      return Ember.get(this, 'content');
    },


    sortPropsDidChange: Ember.observer('sortProps', function () {
      var sortProps = Ember.get(this, 'sortProps');

      if (Ember.isEmpty(sortProps)) {
        Ember.defineProperty(this, 'content', []);
      }

      Ember.defineProperty(this, 'content', (0, _naturalSort.default)('array', 'sortProps'));
    }),

    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });
});