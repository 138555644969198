define('frontend/components/change-requests/state-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    /**
     * @type {Array<String>}
     */
    classNames: ['cr-table__state'],

    /**
     * @type {Array<String>}
     */
    classNameBindings: ['color'],

    /**
     * This value is passed by the ember-light-table addon, contains the approval set state.
     *
     * @type {String}
     */
    value: null,

    /**
     * - approved: green
     * - denied: red
     * - pending: orange
     * - draft: grey
     *
     * @type {String}
     */
    color: computed('value', function () {
      return 'cr-table__state_value_' + this.get('value');
    })
  });
});