define("frontend/mixins/form-data-adapter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Mixin.create({
    // Overwrite to change the request types on which Form Data is sent
    formDataTypes: ['POST', 'PUT', 'PATCH'],

    ajaxOptions: function ajaxOptions(url, type, options) {
      var data;

      if (options && 'data' in options) {
        data = options.data;
      }

      var hash = this._super.apply(this, arguments);

      if (typeof FormData !== 'undefined' && data && this.formDataTypes.indexOf(type) >= 0) {
        hash.processData = false;
        hash.contentType = false;
        hash.data = this._getFormData(data);
      }

      return hash;
    },
    _getFormData: function _getFormData(data) {
      var formData = new FormData();

      var recursiveSerialize = function recursiveSerialize(key, value) {
        if (Ember.isArray(value)) {
          value.forEach(function (arrayElement) {
            return recursiveSerialize(key + "[]", arrayElement);
          });
        } else if ((typeof value === "undefined" ? "undefined" : _typeof(value)) === 'object' && value && value.constructor !== File) {
          Object.keys(value).forEach(function (subKey) {
            return recursiveSerialize(key + "[" + subKey + "]", value[subKey]);
          });
        } else if (!Ember.isNone(value)) {
          formData.append(key, value);
        }
      };

      recursiveSerialize('data', data.data);

      return formData;
    }
  });
});