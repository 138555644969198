define('frontend/components/column-selectable-table/column-toggle', ['exports', 'frontend/helpers/humanize'], function (exports, _humanize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    header: computed('column.title', 'column.key', 'column.type', function () {
      if (this.get('column.type') === 'actions') {
        return "Actions";
      }
      if (this.get('column.title')) {
        return this.get('column.title');
      }
      return (0, _humanize.humanize)(this.get('column.key'));
    }),

    isSelected: computed('hiddenColumns.[]', 'column', function () {
      return !this.get('hiddenColumns').includes(this.get('column'));
    }),

    actions: {
      change: function change(value) {
        if (value) {
          this.get('hiddenColumns').removeObject(this.get('column'));
        } else {
          this.get('hiddenColumns').addObject(this.get('column'));
        }
      }
    }
  });
});