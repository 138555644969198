define('frontend/components/complaint-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Component.extend({
    session: inject.service('session'),
    user: computed.alias('session.currentUser'),
    company: computed.alias('user.company'),

    asReportedCodes: computed.reads('company.asReportedCodes'),
    asAnalyzedCodes: computed.reads('company.asAnalyzedCodes'),
    complaintSourceTypes: computed.reads('company.complaintSourceTypes'),

    actions: {
      close: function close() {
        this.sendAction('close');
      }
    }
  });
});