define('frontend/models/tagging', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    itemType: attr('string'),
    itemId: attr('string'),
    tag: belongsTo('tag'),
    name: attr('string'),
    taggable: belongsTo('approvable')
  });
});