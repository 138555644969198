define('frontend/components/notifications-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;
  var filterBy = computed.filterBy,
      empty = computed.empty,
      reads = computed.reads,
      and = computed.and,
      union = computed.union;
  exports.default = Component.extend({
    // defaults
    session: inject.service('session'),
    notificationsDropdownIsVisible: false,
    notTraining: true,
    notificationItemIndex: 0,
    pendingActionItemIndex: 0,
    notificationsDropdownLastOpenedAt: undefined,

    // CPs
    notificationsDropdownIsVisibleNotTraining: and('notificationsDropdownIsVisible', 'notTraining'),
    approvalItems: reads('session.currentUser.myApprovalItems'),
    notificationItems: reads('session.currentUser.notifications'),
    undismissedNotificationItems: filterBy('notificationItems', 'undismissed'),
    notifications: union('approvalItems', 'undismissedNotificationItems'),
    pendingActionItems: reads('approvalItems'),
    notificationsEmpty: empty('notifications'),
    notificationItemBoundedIndex: computed('notificationItemIndex', 'notifications.length', function () {
      // All this because javascript doesn't have a proper modulo operator
      var n = this.get('notificationItemIndex');
      var m = this.get('notifications.length');
      var r = n % m;
      if (r < 0) {
        r += m;
      }

      return r;
    }),
    pendingActionItemBoundedIndex: computed('pendingActionItemIndex', 'pendingActionItems.length', function () {
      // All this because javascript doesn't have a proper modulo operator
      var n = this.get('pendingActionItemIndex');
      var m = this.get('pendingActionItems.length');
      var r = n % m;
      if (r < 0) {
        r += m;
      }

      return r;
    }),

    notificationItem: computed('notificationItemBoundedIndex', 'notifications.[]', function () {
      return this.get('notifications').objectAt(this.get('notificationItemBoundedIndex'));
    }),

    hasNewItemsSinceLastVisited: computed('notifications.@each.createdAt', 'notificationsDropdownLastOpenedAt', function () {
      var _getProperties = this.getProperties('notifications', 'notificationsDropdownLastOpenedAt'),
          notifications = _getProperties.notifications,
          notificationsDropdownLastOpenedAt = _getProperties.notificationsDropdownLastOpenedAt;

      if (notifications.get('length') === 0) {
        return false;
      }
      if (!notificationsDropdownLastOpenedAt) {
        return true;
      }
      return +notificationsDropdownLastOpenedAt < Math.max.apply(Math, _toConsumableArray(notifications.mapBy('createdAt')));
    }),

    // Actions
    actions: {
      toggleNotificationsDropdownIsVisible: function toggleNotificationsDropdownIsVisible() {
        this.toggleProperty('notificationsDropdownIsVisible');
        this.set('notificationsDropdownLastOpenedAt', new Date());
      },
      notificationsDropdownTraining: function notificationsDropdownTraining() {
        this.set('notificationsDropdownIsVisible', true);
        this.set('notTraining', false);
      },
      undoNotificationsDropdownTraining: function undoNotificationsDropdownTraining() {
        this.set('notificationsDropdownIsVisible', false);
        this.set('notTraining', true);
      },
      incrementNotificationItemIndex: function incrementNotificationItemIndex() {
        this.incrementProperty('notificationItemIndex');
        if (this.get('notificationItem')) {
          var modelName = this.get('notificationItem.constructor.modelName');
          if (modelName != "notification") {
            this.incrementProperty('pendingActionItemIndex');
          }
        }
      },
      decrementNotificationItemIndex: function decrementNotificationItemIndex() {
        this.decrementProperty('notificationItemIndex');
        if (this.get('notificationItem')) {
          var modelName = this.get('notificationItem.constructor.modelName');
          if (modelName != "notification") {
            this.decrementProperty('pendingActionItemIndex');
          }
        }
      }
    }
  });
});