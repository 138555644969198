define('frontend/components/change-request-modal/details-for', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    details: Ember.computed('approvableDetails.@each.spec', 'item', function () {
      return this.findDetails(this.get('item'));
    }),

    impactedDetails: Ember.computed('approvableDetails.@each.spec', 'details.impacting.[]', function () {
      var _this = this;

      return (this.get('details.impacting') || []).map(function (spec) {
        return _this.findDetails(spec);
      });
    }),

    findDetails: function findDetails(spec) {
      if (!this.get('approvableDetails.length')) {
        return null;
      }

      return this.get('approvableDetails').findBy('spec', spec);
    }
  });
});