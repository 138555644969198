define('frontend/utils/natural-sort', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.naturalSort = naturalSort;
  exports.naturalSortBy = naturalSortBy;
  exports.naturalCompare = naturalCompare;


  // Returns array sorted:
  // - As per default for non-strings
  // - Naturally for strings, i.e. 'a9' > 'a10'
  // - Uppercase comes first as per native `sort()`
  function naturalSort(arr) {
    return Ember.isArray(arr) ? arr.slice().sort(naturalCompare) : arr;
  }

  // Copied from https://github.com/emberjs/ember.js/blob/v3.7.2/packages/%40ember/-internals/runtime/lib/mixins/array.js
  // Modified to be a pure function
  function naturalSortBy(arr) {
    for (var _len = arguments.length, sortKeys = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      sortKeys[_key - 1] = arguments[_key];
    }

    if (!Ember.isArray(arr)) {
      return arr;
    }

    return arr.slice().sort(function (a, b) {
      for (var i = 0; i < sortKeys.length; i++) {
        var key = sortKeys[i];
        var propA = Ember.get(a, key);
        var propB = Ember.get(b, key);
        // MODIFIED: Use `naturalCompare` instead of `compare`
        var compareValue = naturalCompare(propA, propB);

        if (compareValue) {
          return compareValue;
        }
      }
      return 0;
    });
  }

  function naturalCompare(a, b) {
    return typeof a === 'string' && typeof b === 'string' ?
    // Case-insensitive, natural sort if `a` is a string
    a.localeCompare(b, undefined, { numeric: true, caseFirst: 'upper' }) :
    // Default sort otherwise
    Ember.compare(a, b);
  }
});