define('frontend/components/simple-dropdown-wrapper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'span',
    classNames: 'simple-dropdown-wrapper',
    isShowingDropdown: false,

    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('isShowingDropdown');
      }
    }
  });
});