define("frontend/helpers/lookup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lookup = lookup;
  function lookup(params) {
    if (params[2] === "direct") {
      return params[0][params[1]];
    } else {
      return params[0].get(params[1]);
    }
  }

  exports.default = Ember.Helper.helper(lookup);
});