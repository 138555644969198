define('frontend/models/non-conformance-disposition', ['exports', 'ember-data', 'ember-data/relationships', 'ember-data/attr', 'frontend/mixins/approvable'], function (exports, _emberData, _relationships, _attr, _approvable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_approvable.default, {
    nonConformance: (0, _relationships.belongsTo)(),
    company: (0, _relationships.belongsTo)(),
    disposition: (0, _attr.default)('string'),
    dispositionComments: (0, _attr.default)('mobiledoc'),

    routableTarget: 'app.products.product.non-conformances.non-conformance',
    routableParams: Ember.computed.collect('nonConformance')
  });
});