define("frontend/components/enzyme-power-select-multiple", ["exports", "ember-power-select/components/power-select-multiple"], function (exports, _powerSelectMultiple) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _powerSelectMultiple.default.extend({
    tagName: 'div',
    classNames: ['enzyme-power-select', 'enzyme-power-select-multiple'],
    centered: true,
    triggerComponent: "enzyme-power-select-multiple/trigger",
    triggerClass: 'enzyme-power-select-multiple-trigger',
    closeOnSelect: false,

    dropdownClass: Ember.computed('centered', 'extraDropdownClass', function () {
      var centered = this.get('centered');
      var classes = ['enzyme-power-select-dropdown', 'enzyme-power-select-dropdown--animated'];

      if (centered) {
        classes.push('enzyme-power-select-dropdown--centered');
      }

      if (this.get('extraDropdownClass')) {
        classes.push(this.get('extraDropdownClass'));
      }

      return classes.join(' ');
    }),

    onkeydown: function onkeydown(select, e) {
      // selecting a selected item with enter should deselect that item, not
      // close the select
      if (e.keyCode === 13 && select.isOpen && select.selected && select.selected.includes(select.highlighted)) {
        select.actions.choose(select.highlighted, e);
        return false;
      }
    },
    onopen: function onopen(powerSelect) {
      // sometimes opens in wrong position in modal.
      // sometimes next run will work but sometimes not.
      // try both to minimise visual jank
      Ember.run.next(function () {
        return powerSelect.actions.reposition();
      });
      Ember.run.later(function () {
        return powerSelect.actions.reposition();
      }, 200);
    },
    init: function init() {
      this.setProperties({
        // use function binding to ensure the action is called with the right context
        onchange: onchange.bind(this),
        newlyAdded: []
      });

      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('formModel')) {
        Ember.RSVP.resolve(this.get('formModel')).then(function (model) {
          if (model.on) {
            model.on('didUpdate', _this, 'resetNewlyAdded');
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      if (this.get('formModel')) {
        Ember.RSVP.resolve(this.get('formModel')).then(function (model) {
          if (model.off) {
            model.off('didUpdate', _this2, 'resetNewlyAdded');
          }
        });
      }

      this._super.apply(this, arguments);
    },
    resetNewlyAdded: function resetNewlyAdded() {
      this.set('newlyAdded', []);
    },
    keyUp: function keyUp(e) {
      // prevent esc key closing modals with selects in
      if (e.keyCode === 27) {
        e.stopPropagation();
      }
    },


    actions: {
      buildSelection: function buildSelection(option, select) {
        var oldSelection = (select.selected || []).slice();
        var newSelection = this._super(option, select);

        if (newSelection.includes(option) && !oldSelection.includes(option)) {
          this.get('newlyAdded').addObject(option);

          if (this.get('onAdd')) {
            this.get('onAdd')(option, select);
          }
        } else if (!newSelection.includes(option) && oldSelection.includes(option)) {
          this.get('newlyAdded').removeObject(option);

          if (this.get('onRemove')) {
            this.get('onRemove')(option, select);
          }
        }

        if (this.get('onReplace')) {
          this.get('onReplace')(newSelection, select);
        }

        // Pass this so the selected-item can access it
        Ember.set(select, 'selectComponent', this);

        return newSelection;
      }
    }
  });


  function onchange(newSelection, powerSelect) {
    if (this.isDestroyed) {
      return;
    }

    // Choosing an option makes the trigger grow, so reposition after the
    // trigger changes render
    Ember.run.schedule('afterRender', function () {
      return powerSelect.actions.reposition();
    });

    if (powerSelect.results.length === 1 && (!powerSelect.results[0].groupName || powerSelect.results[0].options.length === 1)) {
      // There is only one option showing and it just got selected.
      // We should clear the search and show the whole list now as the query
      // is no longer relevent
      powerSelect.actions.search("");
    }

    // After selecting something, always focus the search again
    this.get('element').querySelector(".ember-power-select-trigger-multiple-input").focus();
  }
});