define('frontend/controllers/app/products/product/subsystems/edit', ['exports', 'ember-changeset'], function (exports, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Controller.extend({
    viewOptions: service(),

    changeset: computed('model', function () {
      return new _emberChangeset.default(this.get('model'));
    }),

    actions: {
      saveSubsystem: function saveSubsystem(changeset) {
        var _this = this;

        changeset.execute();

        this.get('model').saveWithNotifications().then(function () {
          return _this.transitionToRoute('app.products.product.subsystems');
        });
      },
      deleteSubsystem: function deleteSubsystem() {
        var _this2 = this;

        this.get('model').destroyWithNotifications().then(function () {
          if (_this2.get('viewOptions.selectedSubsystem') === _this2.get('model')) {
            _this2.get('viewOptions').set('selectedSubsystem', null);
          }
          _this2.transitionToRoute('app.products.product.subsystems');
        });
      }
    }
  });
});