define('frontend/components/approvable-change/non-conformance/source-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    source: Ember.computed('value.{type,id}', function () {
      var type = this.get('value.type'),
          id = this.get('value.id');
      if (type && id) {
        return this.get('store').find(type, id);
      }
    })
  });
});