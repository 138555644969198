define("frontend/components/craq-editor/option", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['change-request-option-edit'],

    atTop: Ember.computed.equal('index', 0),
    atBottom: Ember.computed('index', 'options.length', function () {
      return this.get('index') === this.get('options.length') - 1;
    }),

    actions: {
      addConfirmation: function addConfirmation() {
        var confirmation = prompt("Enter confirmation text");
        if (!Ember.isBlank(confirmation)) {
          this.get('addConfirmation')(confirmation);
        }
      }
    }
  });
});