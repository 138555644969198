define('frontend/models/zendesk-ticket', ['exports', 'ember-data/attr', 'ember-data/model'], function (exports, _attr, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    url: (0, _attr.default)('string'),
    ticketType: (0, _attr.default)('string'),
    subject: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    priority: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    requesterId: (0, _attr.default)('number'),
    submitterId: (0, _attr.default)('number'),
    assigneeId: (0, _attr.default)('number'),
    groupId: (0, _attr.default)('number'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    tags: (0, _attr.default)()
  });
});