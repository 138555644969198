define('frontend/utils/object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.camelizeKeys = camelizeKeys;
  exports.replaceKeys = replaceKeys;
  var keys = Object.keys;
  var camelize = Ember.String.camelize,
      typeOf = Ember.typeOf;


  /**
   * Takes an object and returns a clone with keys turned into camelized format.
   *
   * @method camelizeKeys
   * @param {Object} obj
   * @return {Object} obj with camelizeKeys properties
   */
  function camelizeKeys(obj) {
    return replaceKeys(obj, camelize);
  }

  /**
   * Pass any transform function to perform transformations on an object's keys.
   * Used mostly in serialization/deserialization of API requests/responses.
   * The keys will be transformed recursively (even in arrays).
   *
   * Example:
   * ```js
   * response = replaceKeys(response, key => underscore(key));
   * ```
   *
   * @param  {Object|Array} obj
   * @param  {Function} transform The transform function (Example: `underscore`)
   * @return {Object|Array}
   */
  function replaceKeys(obj, transform) {
    var type = typeOf(obj);
    if (type !== 'object' && type !== 'array') {
      return obj;
    }
    if (type === 'array') {
      return obj.map(function (item) {
        return replaceKeys(item, transform);
      });
    }
    return keys(obj).reduce(function (prev, key) {
      var item = obj[key];
      prev[transform(key)] = replaceKeys(item, transform);
      return prev;
    }, {});
  }
});