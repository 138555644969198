define('frontend/components/current-dependency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['one-third', 'top-align'],
    isApproved: computed.equal('tracingDependency.approvalState', 'approved'),
    isPendingCreation: computed.equal('tracingDependency.approvalState', 'create_requested'),
    isPendingDeletion: computed.equal('tracingDependency.approvalState', 'destroy_requested'),
    disableButton: computed.or('isPendingCreation', 'isPendingDeletion', 'disabled'),

    title: computed('isApproved', 'isPendingCreation', 'isPendingDeletion', function () {
      if (this.get('isApproved')) {
        return 'Send a delete request for this dependency';
      }

      if (this.get('isPendingCreation')) {
        return 'This item is pending approval';
      }

      if (this.get('isPendingDeletion')) {
        return 'This dependency is pending approval to be deleted';
      }
    }),

    resolvedTracingDependencyObject: computed('tracingDependency', 'dependencyObject', function () {
      return this.get('tracingDependency').get(this.get('dependencyObject'));
    }),
    name: computed.alias('resolvedTracingDependencyObject.name'),
    idDescon: computed.alias('resolvedTracingDependencyObject.idDescon'),

    status: computed('isPendingCreation', 'isPendingDeletion', function () {
      if (this.get('isPendingCreation')) {
        return 'pending';
      }

      if (this.get('isPendingDeletion')) {
        return 'pending delete';
      }
    }),

    actions: {
      removeTracingDependency: function removeTracingDependency() {
        this.sendAction('removeTracingDependencyAction', this.get('tracingDependency'), this.get('dependency'));
      }
    }
  });
});