define('frontend/routes/app/products/product/complaints/complaint/link-zendesk', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var product_id = this.modelFor('app.products.product.complaints.complaint').get('product.id');
      return this.get('store').query('zendesk-ticket', { product_id: product_id, page: 1 });
    }
  });
});