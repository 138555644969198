define('frontend/models/comment', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    body: (0, _attr.default)('string'),
    itemType: (0, _attr.default)('string'),
    itemId: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),

    user: (0, _relationships.belongsTo)('user'),
    commentable: (0, _relationships.belongsTo)('design-control', { inverse: 'comments' })
  });
});