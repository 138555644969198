define('frontend/controllers/app/products/product/document-control/document-item/index', ['exports', 'frontend/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_permissions.default, {
    queryParams: ['tab'],
    tab: 'Effective Version',
    app: Ember.inject.controller(),
    session: Ember.inject.service(),

    showAttachmentsModal: false,
    versionForAttachmentsModal: null,

    qmsDocument: Ember.computed.alias('model'),
    products: Ember.computed.alias('model.products'),
    currentUser: Ember.computed.alias('app.model'),
    unlocked: Ember.computed.equal('qmsDocument.lockedBy.id', undefined),

    qualityOwnerCanUnlock: Ember.computed('unlocked', 'lockedByCurrentUser', 'isQualityOwner', function () {
      return !this.get('unlocked') && !this.get('lockedByCurrentUser') && this.get('isQualityOwner');
    }),
    canUnlock: Ember.computed.or('lockedByCurrentUser', 'qualityOwnerCanUnlock'),
    isApprovedAndObsolete: Ember.computed.and('qmsDocument.obsolete', 'qmsDocument.isApproved'),
    isNotApprovedAndObsolete: Ember.computed.and('qmsDocument.obsolete', 'qmsDocument.isNotApproved'),

    availableTabs: Ember.computed('qmsDocument.effectiveVersion.processedFileUpload.fileUrl', 'isViewer', function () {
      var hasPDF = !Ember.isBlank(this.get('qmsDocument.effectiveVersion.processedFileUpload.fileUrl'));
      var hasChangesPDF = !Ember.isBlank(this.get('qmsDocument.effectiveVersion.changesPdfFileUpload.fileUrl'));
      var isViewer = this.get('isViewer');
      var tabArray = ['Effective Version'];
      if (!isViewer) {
        tabArray.push('Draft Version');
      }
      tabArray.push('Version History');
      if (hasPDF) {
        tabArray.push('Preview');
      }
      if (hasChangesPDF && !isViewer) {
        tabArray.push('Redlines');
      }
      return tabArray;
    }),

    /**
     * @type {Array<QmsDocumentVersion>}
     */
    versions: Ember.computed.reads('model.effectiveVersions'),
    firstVersion: Ember.computed.reads('versions.firstObject'),
    olderVersions: Ember.computed('firstVersion', 'versions.[]', function () {
      var _this = this;

      return this.get('versions').filter(function (v) {
        return v != _this.get('firstVersion');
      });
    }),
    olderApprovedVersions: Ember.computed('olderVersions.@each.qmsDocumentVersionString', 'model.approvedAttributes.versionString', 'model.effectiveAttributes.versionString', function () {
      var _this2 = this;

      return this.get('olderVersions').filter(function (v) {
        return v.get('qmsDocumentVersionString').split(".").pop() == "0" && v.get('qmsDocumentVersionString') != _this2.get('model.approvedAttributes.versionString') && v.get('qmsDocumentVersionString') != _this2.get('model.effectiveAttributes.versionString');
      });
    }),
    olderDraftVersions: Ember.computed('olderVersions.@each.qmsDocumentVersionString', function () {
      return this.get('olderVersions').filter(function (v) {
        return v.get('qmsDocumentVersionString').split(".").pop() != "0";
      });
    }),
    lockedByCurrentUser: Ember.computed('qmsDocument.lockedBy', function () {
      return this.get('qmsDocument.lockedBy.id') === this.get('currentUser.id');
    }),

    effectiveDocumentType: Ember.computed('qmsDocument.effectiveAttributes.qmsDocumentTypeId', function () {
      var id = this.get('qmsDocument.effectiveAttributes.qmsDocumentTypeId');
      if (id) {
        return this.store.findRecord('qms-document-type', id);
      }
    }),

    actionable: Ember.computed('qmsDocument.lockedBy', 'canEdit', function () {
      window.qmsDocument = this.get('qmsDocument');
      return this.get('canEdit') && (this.get('unlocked') || this.get('lockedByCurrentUser') || this.get('currentUser.isQualityOwner'));
    }),

    approverNames: Ember.computed('model.approvedAttributes.approverNames.[]', function () {
      return this.get('model.approvedAttributes.approverNames').join(", ");
    }),

    onAttachmentSaveSuccess: function onAttachmentSaveSuccess(attachment) {
      var model = this.get('model');

      model.get('attachments').pushObject(attachment);
    },


    actions: {
      onViewAttachment: function onViewAttachment(version) {
        this.set('showAttachmentsModal', true);
        this.set('versionForAttachmentsModal', version);
      },
      obsolete: function obsolete() {
        this.get('qmsDocument').set('obsolete', true);
        this.get('qmsDocument').saveWithNotifications();
      },
      undoObsolete: function undoObsolete() {
        this.get('qmsDocument').set('obsolete', false);
        this.get('qmsDocument').saveWithNotifications();
      },
      checkOut: function checkOut() {
        this.get('qmsDocument').set('lockedBy', this.get('currentUser'));
        this.get('qmsDocument').saveWithNotifications();
      },
      checkIn: function checkIn() {
        this.get('qmsDocument').set('lockedBy', null);
        this.get('qmsDocument').saveWithNotifications();
      },
      tabChanged: function tabChanged(newTab) {
        this.set('tab', newTab);
        if (newTab == 'Version History') {
          this.get('model.versions').reload();
        }
      },
      addAttachment: function addAttachment(file, component) {
        var model = this.get('model');
        var itemId = model.get('id');
        var itemType = model.get('itemTypeBackend');

        var attachmentAttrs = { file: file, itemId: itemId, itemType: itemType };
        var attachment = this.store.createRecord('attachment', attachmentAttrs);

        attachment.saveWithNotifications().then(this.onAttachmentSaveSuccess.bind(this)).finally(function () {
          return component.set('isUploading', false);
        });
      }
    }
  });
});