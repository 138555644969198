define('frontend/controllers/app/products/product/users/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    usersIndexController: Ember.inject.controller('app.products.product.users.index'),

    actions: {
      saveUser: function saveUser() {
        var _this = this;

        this.get('model').saveWithNotifications().then(function () {
          return _this.transitionToRoute('app.products.product.users');
        });
      },
      resetPassword: function resetPassword() {
        var _this2 = this;

        var user = this.get('model');

        if (confirm("are you sure you want to reset this user's password?")) {
          this.get('session').authorizedAjaxRequest('/api/users/' + user.get('id') + '/reset_password', {
            type: 'POST'
          }).then(function () {
            return _this2.get('notify').success("The password was reset");
          }, function () {
            return _this2.get('notify').error("Could not reset password");
          });
        }
      },
      deleteUser: function deleteUser() {
        var _this3 = this;

        if (confirm("Are you sure you want to delete this user?")) {
          this.get('model').destroyWithNotifications().then(function () {
            return _this3.get('usersIndexController.model.company.content').reload();
          }).then(function () {
            _this3.transitionToRoute('app.products.product.users');
          });
        }
      }
    }
  });
});