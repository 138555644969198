define('frontend/controllers/app/products/product/risk-management/hazardous-situations/edit', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed;
  exports.default = Controller.extend(_emberValidations.default, {
    hazardousSituation: computed.alias('model'),
    displayErrors: false,

    validations: {
      editHazardousSituationHazard: {
        presence: true
      },

      editHazardousSituationDescription: {
        presence: true
      }
    },

    actions: {
      setEditHazardousSituationHazard: function setEditHazardousSituationHazard(hazard) {
        this.set('editHazardousSituationHazard', hazard);
      },
      updateHazardousSituation: function updateHazardousSituation() {
        var _this = this;

        this.validate().then(function () {
          _this.get('hazardousSituation').setProperties({
            description: _this.get('editHazardousSituationDescription'),
            hazard: _this.get('editHazardousSituationHazard'),
            approvalState: 'draft'
          });
          return _this.get('hazardousSituation').saveWithNotifications().then(function () {
            _this.transitionToRoute('app.products.product.risk-management');
          });
        }).catch(function () {
          _this.set('displayErrors', true);
        });
      },
      revertToPrevious: function revertToPrevious() {
        this.set('isShowingPreviousApprovedVersion', false);
        this.set('editHazardousSituationDescription', this.get('model.description'));
        this.set('editHazardousSituationHazard', this.get('model.hazard'));
      }
    }
  });
});