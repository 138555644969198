define('frontend/models/version', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    itemId: (0, _attr.default)('number'),
    itemType: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    event: (0, _attr.default)('string'),
    versionData: (0, _attr.default)('simple-object'),
    versionChangeset: (0, _attr.default)('simple-object'),
    approvalState: (0, _attr.default)('string'),
    approvalSetId: (0, _attr.default)('number'),
    qmsDocumentVersionString: (0, _attr.default)('string'),
    changesSincePreviousApprovedVersion: (0, _attr.default)(),
    approverNames: (0, _attr.default)(),
    editorName: (0, _attr.default)('string'),
    requesterName: (0, _attr.default)('string'),
    requestDate: (0, _attr.default)('date'),
    pendingJustification: (0, _attr.default)('string'),

    currentApprovable: (0, _relationships.belongsTo)('approvable', { polymorphic: true, inverse: 'versions' }),
    whodunnit: (0, _relationships.belongsTo)('user'),
    product: (0, _relationships.belongsTo)('product'),
    attachments: (0, _relationships.hasMany)('attachment'),

    isCreateEvent: Ember.computed.equal('event', 'create'),
    isUpdateEvent: Ember.computed.equal('event', 'update'),
    isDestroyEvent: Ember.computed.equal('event', 'destroy'),
    isApproved: Ember.computed.equal('approvalState', 'approved'),
    isNotApproved: Ember.computed.not('isApproved'),
    isDraft: Ember.computed.equal('approvalState', 'draft'),
    isNotDraft: Ember.computed.not('isDraft'),
    isDoc: Ember.computed.equal('itemType', 'QmsDocument'),
    isPendingCreate: Ember.computed.equal('approvalState', 'create_requested'),
    isPendingUpdate: Ember.computed.equal('approvalState', 'update_requested'),
    isPendingDestroy: Ember.computed.equal('approvalState', 'destroy_requested'),
    isPendingApproval: Ember.computed.or('isPendingCreate', 'isPendingUpdate', 'isPendingDestroy'),
    isNotPending: Ember.computed.not('isPendingApproval'),
    initialProbabilityLabel: Ember.computed.alias('initialProbability.name'),
    mitigatedProbabilityLabel: Ember.computed.alias('mitigatedProbability.name'),
    effectiveAt: Ember.computed.reads('versionData.effective_at'),
    shortName: Ember.computed.reads('currentApprovable.shortName'),

    normalizedChangeset: Ember.computed('versionChangeset', function () {
      var changeset = this.get('versionChangeset');

      return changeset && Object.keys(changeset).reduce(function (obj, key) {
        obj[key] = changeset[key][1];
        return obj;
      }, {});
    }),
    completeVersionData: Ember.computed('versionData', 'normalizedChangeset', function () {
      return Ember.assign({}, this.get('versionData'), this.get('normalizedChangeset'));
    }),

    updatedAt: Ember.computed('versionData.updated_at', function () {
      if (this.get('versionData.updated_at')) {
        return new Date(this.get('versionData.updated_at'));
      }
    }),

    approvalSet: Ember.computed('approvalSetId', function () {
      if (this.get('approvalSetId')) {
        return this.store.findRecord('approval-set', this.get('approvalSetId'));
      }
    })
  });
});