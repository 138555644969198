define('frontend/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    actions: {
      authenticated: function authenticated() {
        var redirect = this.get('session').retrieveURLState();

        this.transitionToRoute(redirect || 'app.products');
      }
    }
  });
});