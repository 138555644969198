define('frontend/controllers/app/products/product/complaints/complaint/link-zendesk', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    complaintController: Ember.inject.controller('app/products/product/complaints/complaint'),
    complaint: Ember.computed.alias('complaintController.model'),

    actions: {
      goToComplaint: function goToComplaint() {
        this.transitionToRoute('app.products.product.complaints.complaint');
      },
      stopPropagation: function stopPropagation(e) {
        e.stopPropagation();
      },
      linkTicket: function linkTicket(ticket) {
        var _this = this;

        var complaint = this.get('complaint');
        complaint.set('zendeskTicket', ticket);
        complaint.saveWithNotifications("The ticket was linked", "The ticket could not be linked").then(function () {
          return _this.transitionToRoute('app.products.product.complaints.complaint');
        });
      }
    }
  });
});