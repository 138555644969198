define('frontend/components/attachments-uploads-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isV2: true,

    actions: {
      addAttachmentToStore: function addAttachmentToStore(file) {
        this.set('isUploading', true);
        this.sendAction('addAttachmentToStore', file, this);
      }
    }
  });
});