define('frontend/routes/app/products/product/dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });
});