define('frontend/utils/i18n/missing-message', ['exports', 'frontend/helpers/humanize'], function (exports, _humanize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (locale, key) /* context */{
    if (key.match(/^ember-form-for-i18n\./)) {
      return (0, _humanize.humanize)(key.split('.').get('lastObject')).capitalize();
    }
    throw new Error('Missing translation ' + key + ' in locale ' + locale);
  };
});