define('frontend/models/design-output-requirement-dependency', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'frontend/mixins/model-meta-data'], function (exports, _model, _attr, _relationships, _modelMetaData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_modelMetaData.default, {
    state: (0, _attr.default)('string'),
    designOutput: (0, _relationships.belongsTo)('design-output'),
    requirement: (0, _relationships.belongsTo)('requirement')
  });
});