define('frontend/components/settings-crud-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'settings-crud-form',

    rows: Ember.computed.filterBy('models', 'isDeleted', false),

    newModel: function newModel() {
      return this.get('models').createRecord();
    },


    actions: {
      new: function _new() {
        this.set('editingModel', this.newModel());
      },
      edit: function edit(model) {
        this.set('editingModel', model);
      },
      delete: function _delete(model) {
        if (confirm('Are you sure?')) {
          model.destroyWithNotifications();
        }
      },
      doneEditing: function doneEditing() {
        if (this.get('editingModel.isNew')) {
          this.get('editingModel').deleteRecord();
        }
        this.set('editingModel', null);
      }
    }
  });
});