define('frontend/components/column-selectable-table', ['exports', 'frontend/macros/natural-sort'], function (exports, _naturalSort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var reverseDirections = {
    desc: 'asc',
    asc: 'desc'
  };

  exports.default = Ember.Component.extend({
    showColumnSelector: true,
    showFilters: true,
    filters: computed(function () {
      return [];
    }),
    activeFilters: computed(function () {
      return [];
    }),
    classNames: ['column-selectable-table'],
    classNameBindings: ['hasGrowColumns'],
    hiddenColumns: computed(function () {
      return [];
    }),
    visibleColumns: computed('columns.[]', 'hiddenColumns.[]', function () {
      var _this = this;

      return this.get('columns').filter(function (column) {
        return !_this.get('hiddenColumns').includes(column);
      });
    }),

    hasGrowColumns: computed('visibleColumns.@each.grow', function () {
      return this.get('visibleColumns').isAny('grow');
    }),

    sortColumn: computed.oneWay('visibleColumns.firstObject'),
    sortDirection: 'asc',
    filteredRows: computed('rows.[]', 'activeFilters.[]', function () {
      var _this2 = this;

      var rows = this.get('rows');
      var activeFilters = this.get('activeFilters');

      if (activeFilters.length === 0) {
        return rows;
      }
      return rows.filter(function (row) {
        return activeFilters.every(function (filter) {
          return _this2.matchesFilter(row, filter);
        });
      });
    }),

    sortedRows: (0, _naturalSort.default)('filteredRows', 'rowSorting'),
    rowSorting: computed('sortColumn.{key,sortBy}', 'sortDirection', function () {
      var key = this.get('sortColumn.sortBy') || this.get('sortColumn.key');
      var direction = this.get('sortDirection') === 'desc' ? ':desc' : '';
      return ['' + key + direction];
    }),

    matchesFilter: function matchesFilter(row, filter) {
      var selectedFilter = filter.selectedFilter;
      var rowValue = row.get(selectedFilter.key);
      if (selectedFilter.column.formatter) {
        rowValue = selectedFilter.column.formatter(rowValue);
      }

      switch (selectedFilter.type) {
        case 'string':
          return (rowValue || "").toLowerCase().includes(filter.filterString.toLowerCase());
        case 'list':
          return !filter.selectedOptions.length || filter.selectedOptions.includes(rowValue);
      }
    },
    canSort: function canSort(column) {
      return column.key || column.sortBy;
    },


    actions: {
      setSort: function setSort(column) {
        if (!this.canSort(column)) {
          return;
        }
        if (this.get('sortColumn') === column) {
          this.set('sortDirection', reverseDirections[this.get('sortDirection')]);
        } else {
          this.set('sortColumn', column);
          this.set('sortDirection', 'asc');
        }
      },
      addFilter: function addFilter() {
        this.get('filters').pushObject({});
      },
      removeFilter: function removeFilter(filter) {
        this.get('filters').removeObject(filter);
      },
      activateFilter: function activateFilter(filterSpec) {
        this.get('activeFilters').addObject(filterSpec);
        this.set('activeFilters', this.get('activeFilters').slice()); // force update
      },
      deactivateFilter: function deactivateFilter(filterSpec) {
        this.get('activeFilters').removeObject(filterSpec);
        this.set('activeFilters', this.get('activeFilters').slice()); // force update
      },
      handleClickRow: function handleClickRow(row, event) {
        if (event.target.tagName === 'A') {
          return;
        }
        if (this.get('clickRow')) {
          this.get('clickRow')(row);
        }
      }
    }
  });
});