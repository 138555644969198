define('frontend/components/changeset-form-for/linked-qms-documents-field', ['exports', 'frontend/utils/full-array-mutate'], function (exports, _fullArrayMutate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PowerSelectMultipleField = Ember.Component.extend({
    tagName: '',

    changeset: Ember.computed.reads('f.changeset'),

    trampoline: Ember.computed('changeset', 'field', function () {
      return Ember.Object.extend({
        value: Ember.computed.reads('changeset.' + this.get('field'))
      }).create({ changeset: this.get('changeset') });
    }),

    linkedQmsDocuments: Ember.computed.reads('trampoline.value'),

    getCurrentItems: function getCurrentItems() {
      return this.get('changeset.' + this.get('field'));
    },
    setItems: function setItems(items) {
      (0, _fullArrayMutate.fullHasManyMutator)(this, this.get('field'), items);
    },


    actions: {
      addItem: function addItem(item) {
        var items = this.getCurrentItems().slice();
        items.pushObject(item);
        this.setItems(items);
      },
      removeItem: function removeItem(item) {
        var items = this.getCurrentItems().filter(function (c) {
          return item !== c;
        });
        this.setItems(items);
      }
    }
  });

  PowerSelectMultipleField.reopenClass({ positionalParams: ['field'] });

  exports.default = PowerSelectMultipleField;
});