define('frontend/routes/app/products/product/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    notify: Ember.inject.service(),

    model: function model() {
      return this.modelFor('app.products.product');
    },
    redirect: function redirect() {
      if (!this.get('session.currentUser.isOwner')) {
        this.get('notify').error("Only quality owners are allowed to edit products");
        this.replaceWith('app.products.product');
      }
    },


    actions: {
      willTransition: function willTransition() {
        this.modelFor(this.routeName).rollbackAttributes();
      }
    }
  });
});