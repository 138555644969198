define('frontend/models/requirement', ['exports', 'ember-data/relationships', 'ember-data/attr', 'frontend/models/design-control', 'frontend/mixins/permissions'], function (exports, _relationships, _attr, _designControl, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _designControl.default.extend(_permissions.default, {
    jiraIssueUrl: (0, _attr.default)('string'),
    pivotalStoryUrl: (0, _attr.default)('string'),

    designOutputRequirementDependencies: (0, _relationships.hasMany)('design-output-requirement-dependency'),
    requirementUserNeedDependencies: (0, _relationships.hasMany)('requirement-user-need-dependency'),
    requirementVerificationDependencies: (0, _relationships.hasMany)('requirement-verification-dependency'),
    tracingDependencyObjects: Ember.computed(function () {
      return {
        designOutputRequirementDependencies: 'designOutput',
        requirementUserNeedDependencies: 'userNeed',
        requirementVerificationDependencies: 'verification'
      };
    }),

    designControlTypeAbbreviation: 'REQ'
  });
});