define('frontend/services/pdf-js', ['exports', 'ember-pdf-js/services/pdf-js'], function (exports, _pdfJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pdfJs.default;
    }
  });
});