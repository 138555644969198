define('frontend/macros/join', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = join;
  function join(arrayKey) {
    var seperator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ", ";

    return Ember.computed(arrayKey + '.[]', function () {
      var array = this.get(arrayKey);

      if (!Ember.isArray(array)) {
        return null;
      }

      return array.join(seperator);
    });
  }
});