define('frontend/models/hazard', ['exports', 'ember-data', 'frontend/mixins/approvable', 'frontend/mixins/model-meta-data'], function (exports, _emberData, _approvable, _modelMetaData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  var computed = Ember.computed;
  var alias = computed.alias,
      filterBy = computed.filterBy,
      collect = computed.collect;
  exports.default = Model.extend(_approvable.default, _modelMetaData.default, {

    product: belongsTo('product'),
    hazardousSituations: hasMany('hazardous-situation'),
    description: attr('string'),
    harmInstances: hasMany('harm-instances'),
    subsystem: belongsTo('subsystem'),

    shortName: alias('description'),

    liveHazardousSituations: filterBy('hazardousSituations', 'isLive'),
    liveHarmInstances: filterBy('harmInstances', 'isLive'),

    routableTarget: 'app.products.product.risk-management',
    routableParams: collect('product')
  });
});