define('frontend/macros/natural-sort', ['exports', 'frontend/utils/natural-sort'], function (exports, _naturalSort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = naturalSortComputed;
  exports.sortByNormalizedSortProperties = sortByNormalizedSortProperties;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // Similar to `Ember.computed.sort`:
  // - Uses `naturalCompare` for strings instead of `Ember.compare`
  // - Only accepts a sort properties array as sorting definition (no functions)
  function naturalSortComputed(itemsKey, sortPropertiesKey) {
    (false && !(arguments.length === 2) && Ember.assert('`naturalSort` requires two arguments: an array key to sort and a sort properties key', arguments.length === 2));


    return propertySort(itemsKey, sortPropertiesKey);
  }

  // Below functions copied from: https://github.com/emberjs/ember.js/blob/v3.7.2/packages/%40ember/object/lib/computed/reduce_computed_macros.js
  // Modification marked with comment

  function propertySort(itemsKey, sortPropertiesKey) {
    var cp = new Ember.ComputedProperty(function (key) {
      var _this = this;

      var sortProperties = Ember.get(this, sortPropertiesKey);

      (false && !(Ember.isArray(sortProperties) && sortProperties.every(function (s) {
        return typeof s === 'string';
      })) && Ember.assert('The sort definition for \'' + key + '\' on ' + this + ' must be a function or an array of strings', Ember.isArray(sortProperties) && sortProperties.every(function (s) {
        return typeof s === 'string';
      })));


      // Add/remove property observers as required.
      var activeObserversMap = cp._activeObserverMap || (cp._activeObserverMap = new WeakMap());
      var activeObservers = activeObserversMap.get(this);

      var sortPropertyDidChangeMap = cp._sortPropertyDidChangeMap || (cp._sortPropertyDidChangeMap = new WeakMap());

      if (!sortPropertyDidChangeMap.has(this)) {
        sortPropertyDidChangeMap.set(this, function () {
          this.notifyPropertyChange(key);
        });
      }

      var sortPropertyDidChange = sortPropertyDidChangeMap.get(this);

      if (activeObservers !== undefined) {
        activeObservers.forEach(function (path) {
          return Ember.removeObserver(_this, path, sortPropertyDidChange);
        });
      }

      var itemsKeyIsAtThis = itemsKey === '@this';
      var normalizedSortProperties = normalizeSortProperties(sortProperties);
      if (normalizedSortProperties.length === 0) {
        var path = itemsKeyIsAtThis ? '[]' : itemsKey + '.[]';
        Ember.addObserver(this, path, sortPropertyDidChange);
        activeObservers = [path];
      } else {
        activeObservers = normalizedSortProperties.map(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 1),
              prop = _ref2[0];

          var path = itemsKeyIsAtThis ? '@each.' + prop : itemsKey + '.@each.' + prop;
          Ember.addObserver(_this, path, sortPropertyDidChange);
          return path;
        });
      }

      activeObserversMap.set(this, activeObservers);

      var items = itemsKeyIsAtThis ? this : Ember.get(this, itemsKey);
      if (!Ember.isArray(items)) {
        return Ember.A();
      }

      if (normalizedSortProperties.length === 0) {
        return Ember.A(items.slice());
      } else {
        return sortByNormalizedSortProperties(items, normalizedSortProperties);
      }
    }, { dependentKeys: [sortPropertiesKey + '.[]'], readOnly: true });

    cp._activeObserverMap = undefined;
    cp._sortPropertyDidChangeMap = undefined;

    return cp;
  }

  function normalizeSortProperties(sortProperties) {
    return sortProperties.map(function (p) {
      var _p$split = p.split(':'),
          _p$split2 = _slicedToArray(_p$split, 2),
          prop = _p$split2[0],
          direction = _p$split2[1];

      direction = direction || 'asc';

      return [prop, direction];
    });
  }

  function sortByNormalizedSortProperties(items, normalizedSortProperties) {
    return Ember.A(items.slice().sort(function (itemA, itemB) {
      for (var i = 0; i < normalizedSortProperties.length; i++) {
        var _normalizedSortProper = _slicedToArray(normalizedSortProperties[i], 2),
            prop = _normalizedSortProper[0],
            direction = _normalizedSortProper[1];

        // MODIFIED: Use `naturalCompare` instead of `compare`
        var result = (0, _naturalSort.naturalCompare)(Ember.get(itemA, prop), Ember.get(itemB, prop));
        if (result !== 0) {
          return direction === 'desc' ? -1 * result : result;
        }
      }
      return 0;
    }));
  }
});