define('frontend/controllers/app/products/product/complaints/complaint/index', ['exports', 'frontend/models/complaint'], function (exports, _complaint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      computed = Ember.computed,
      reads = Ember.computed.reads;
  exports.default = Ember.Controller.extend({
    queryParams: ['complaintTab'],
    complaintTab: 'entry',
    rootCauseCategories: _complaint.rootCauseCategories,
    triageAssessments: _complaint.triageAssessments,
    chooseApprovers: false,

    session: service(),

    // CPs
    user: reads('session.currentUser'),
    company: reads('user.company'),

    tabList: computed('model.willInvestigate', 'model.pendingApprovalSet', function () {
      var baseTabs = ["entry", "reportability"];
      if (this.get('model.willInvestigate')) {
        baseTabs.push('investigation');
      }
      baseTabs.push('history');
      if (this.get('model.pendingApprovalSet')) {
        baseTabs.push('approval status');
      }
      return baseTabs;
    }),

    actions: {
      closeForm: function closeForm() {
        this.transitionToRoute('app.products.product.complaints');
      },
      stageAction: function stageAction(actionInfo) {
        if (confirm('Are you sure you want to ' + actionInfo.confirmText)) {
          return this.get('model')[actionInfo.action.camelize()]();
        }
      },
      unlinkZendeskTicket: function unlinkZendeskTicket() {
        var complaint = this.get('model');
        complaint.set('zendeskTicket', null);
        complaint.saveWithNotifications("The ticket was unlinked", "Could not unlink ticket");
      },
      approverBlacklistChanged: function approverBlacklistChanged(blacklist, selectedApprovers) {
        this.set('approverBlacklist', blacklist);
        this.set('selectedApprovers', selectedApprovers);
      },
      routeForApproval: function routeForApproval() {
        this.set('chooseApprovers', false);
        var blacklist = this.get('approverBlacklist');
        return this.get('model').submitForApprovalAndClosure({ blacklist: blacklist });
      }
    }
  });
});