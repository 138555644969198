define('frontend/mixins/approvable', ['exports', 'frontend/mixins/approvable-actions', 'ember-data/attr', 'ember-data/relationships', 'frontend/initializers/add-model-async-methods', 'frontend/mixins/routable'], function (exports, _approvableActions, _attr, _relationships, _addModelAsyncMethods, _routable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_approvableActions.default, _routable.default, {
    session: Ember.inject.service(),
    loadingSlider: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),

    versions: (0, _relationships.hasMany)('version', { inverse: 'currentApprovable' }),
    impactSet: (0, _relationships.belongsTo)('impact-set'),
    unresolvedApprovalSet: (0, _relationships.belongsTo)('approval-set'),
    approvalSets: (0, _relationships.hasMany)('approval-request', { inverse: 'approvables' }),
    approvalState: (0, _attr.default)('string', { defaultValue: 'draft' }),
    approvalCount: (0, _attr.default)('number', { defaultValue: 0 }),
    destroyRequested: (0, _attr.default)('boolean'),
    updatedAt: (0, _attr.default)('date'),
    createdAt: (0, _attr.default)('date'),
    approvedAttributes: (0, _attr.default)(),
    currentUser: Ember.computed.readOnly('session.currentUser'),
    lastApprovedApprovalSet: (0, _relationships.belongsTo)('approval-set'),
    shortName: (0, _attr.default)('string'),

    /**
     * When the approvable has an unresolved approval and it's in pending state.
     *
     * @type {PromiseObject<ApprovalSet>}
     */
    pendingApprovalSet: Ember.computed('unresolvedApprovalSet.isStatePending', function () {
      if (this.get('unresolvedApprovalSet.isStatePending')) {
        return this.get('unresolvedApprovalSet');
      }
      return null;
    }),

    /**
     * When the approvable has an unresolved approval and it's in draft state.
     *
     * @type {PromiseObject<ApprovalSet>}
     */
    draftApprovalSet: Ember.computed('unresolvedApprovalSet.isStateDraft', function () {
      if (this.get('unresolvedApprovalSet.isStateDraft')) {
        return this.get('unresolvedApprovalSet');
      }
      return null;
    }),

    /**
     * @type {Boolean}
     */
    hasUnresolvedApprovalSet: Ember.computed.bool('unresolvedApprovalSet.content'),
    isApproved: Ember.computed.equal('approvalState', 'approved'),
    approved: Ember.computed.alias('isApproved'), // remove during refactor
    isNotApproved: Ember.computed.not('isApproved'),
    isDraft: Ember.computed.equal('approvalState', 'draft'),
    isPendingCreate: Ember.computed.equal('approvalState', 'create_requested'),
    isPendingUpdate: Ember.computed.equal('approvalState', 'update_requested'),
    isPendingDestroy: Ember.computed.equal('approvalState', 'destroy_requested'),
    isPendingApproval: Ember.computed.or('isPendingCreate', 'isPendingUpdate', 'isPendingDestroy'),
    /**
     * Useful to know if a draft approvable already belongs to a draft approval set.
     *
     * @type {Boolean}
     */
    hasDraftApprovalSet: Ember.computed.bool('draftApprovalSet.content'),
    isNotPending: Ember.computed.not('isPendingApproval'),

    isPendingApprovalOrDestroyRequested: Ember.computed.or('isPendingApproval', 'destroyRequested'),
    canNotEdit: Ember.computed.or('isPendingApprovalOrDestroyRequested', 'currentUser.isViewer'),
    isChangeRequestable: Ember.computed('destroyRequested', 'isDraft', 'hasDraftApprovalSet', 'isApproved', function () {
      return this.get('isDraft') && !this.get('hasDraftApprovalSet') || this.get('destroyRequested') && this.get('isApproved');
    }),

    // impacted and linked items
    impactingApprovables: Ember.computed('impactSet.approvables', function () {
      return (this.get('impactSet.approvables') || []).without(this);
    }),

    hasNeverBeenApproved: Ember.computed.equal('approvalCount', 0),
    hasBeenApproved: Ember.computed.not('hasNeverBeenApproved'),

    // version computed properties
    isLive: Ember.computed.gt('id', 0),
    // TODO Remove this, and isLive, when we move to Ember 2.12LTS
    // Added to fix this bug https://github.com/emberjs/data/issues/2666
    isLiveDc: Ember.computed('isDeleted', 'id', function () {
      return this.get('id') > 0 && !this.get('isDeleted');
    }),

    /**
     * @type {Boolean}
     */
    canEditApprovalSet: Ember.computed.and('hasUnresolvedApprovalSet', 'unresolvedApprovalSet.canEdit'),

    /**
     * @type {Boolean}
     */
    canCancelApprovalSet: Ember.computed.and('hasUnresolvedApprovalSet', 'unresolvedApprovalSet.canCancel'),

    versionsSorting: ['createdAt:desc'],
    sortedVersions: Ember.computed.sort('versions', 'versionsSorting'),
    history: Ember.computed.alias('sortedVersions'),

    revert: (0, _addModelAsyncMethods.memberActionWithNotifications)({ path: 'revert' }),

    approvalStateColor: Ember.computed('approvalState', function () {
      return {
        draft: 'grey',
        create_requested: 'orange',
        update_requested: 'orange',
        destroy_requested: 'orange',
        approved: 'green'
      }[this.get('approvalState')];
    }),

    approvalAction: function approvalAction(action, role) {
      return this.get('unresolvedApprovalSet.content').approvalAction(action, role);
    },
    saveDraft: function saveDraft() {
      if (this.changedAttributes().length > 0) {
        this.set('approvalState', 'draft');
        return this.saveWithNotifications();
      } else {
        return Ember.RSVP.resolve();
      }
    },
    requestDelete: function requestDelete() {
      if (this.get('hasNeverBeenApproved')) {
        return this.destroyWithNotifications("Deletion Successful").then(function () {
          return true;
        });
      } else {
        this.set('destroyRequested', true);
        return this.saveWithNotifications("Deletion Requested").then(function () {
          return false;
        });
      }
    },
    cancelRequestDelete: function cancelRequestDelete() {
      this.set('destroyRequested', false);
      return this.saveWithNotifications();
    }
  });
});