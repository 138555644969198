define('frontend/components/change-requests/filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    /**
     * We need the `submit` event.
     *
     * @type {String}
     */
    tagName: 'form',

    /**
     * @type {Array<String>}
     */
    classNames: ['cr-table-filters'],

    /**
     * The list of products to select from for filtering.
     * Passed through the template.
     *
     * @type {Array<Product>}
     */
    products: null,

    /**
     * The list of states to select from for filtering.
     * Passed through the template.
     *
     * @type {Array<Object>}
     *  - {String} value (possible values: `draft`, `pending`, `approved`, `denied`)
     *  - {String} name
     */
    stateOptions: null,

    /**
     * The currently selected product. Passed via the template.
     *
     * @type {Product}
     */
    product: null,

    /**
     * The currently selected state. Passed via the template.
     *
     * @type {Object}
     *  - {String} value (possible values: `draft`, `pending`, `approved`, `denied`)
     *  - {String} name
     */
    state: null,

    /**
     * The content in the requester text field. This filter is not activated until the
     * form is submitted.
     *
     * @type {String}
     */
    requester: null,

    /**
     * The content in the approver text field. This filter is not activated until the
     * form is submitted.
     *
     * @type {String}
     */
    approver: null,

    /**
     * The content in the approvable text field. This filter is not activated until the
     * form is submitted.
     *
     * @type {String}
     */
    approvable: null,

    /**
     * The currently selected "Created On" date filter. This value is passed throug the template.
     *
     * @type {Date}
     */
    createdOn: null,

    /**
     * Action triggered when a product is selected. Refreshes the filter.
     *
     * @type {Function}
     * @param {Product} product
     */
    'on-select-product': null,

    /**
     * Action triggered when a state is selected. Refreshes the filter.
     *
     * @type {Function}
     * @param {Object} state
     */
    'on-select-state': null,

    /**
     * Action triggered when a createdOn date is selected. Refreshes the filter.
     *
     * @type {Function}
     * @param {Date} date
     */
    'on-select-created-on': null,

    /**
     * Action triggered when a the form is submitted. Refreshes the filter.
     *
     * @type {Function}
     */
    'on-update-filters': null,

    /**
     * Updates the filter when the form is submitted. Specifically the text filters.
     *
     * @return {Boolean} false to prevent default behavior.
     */
    submit: function submit() {
      this.get('on-update-filters')();
      return false;
    },


    actions: {
      resetAll: function resetAll() {
        this.get('reset-all')();
        return false;
      }
    }
  });
});