define('frontend/models/design-output', ['exports', 'ember-data/relationships', 'frontend/models/design-control', 'frontend/mixins/permissions'], function (exports, _relationships, _designControl, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _designControl.default.extend(_permissions.default, {
    designOutputRequirementDependencies: (0, _relationships.hasMany)('design-output-requirement-dependency'),
    designOutputVerificationDependencies: (0, _relationships.hasMany)('design-output-verification-dependency'),
    tracingDependencyObjects: Ember.computed(function () {
      return {
        designOutputRequirementDependencies: 'requirement',
        designOutputVerificationDependencies: 'verification'
      };
    }),

    designControlTypeAbbreviation: 'DO',
    canLinkToGithubPullRequests: true,
    canLinkToGitlabPullRequests: true,
    canLinkToBitbucketPullRequests: true
  });
});