define('frontend/models/harm-instance', ['exports', 'ember-data', 'frontend/mixins/approvable', 'frontend/mixins/model-meta-data', 'frontend/macros/join'], function (exports, _emberData, _approvable, _modelMetaData, _join) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  var computed = Ember.computed;
  var alias = computed.alias,
      collect = computed.collect,
      mapBy = computed.mapBy,
      reads = computed.reads;
  exports.default = Model.extend(_approvable.default, _modelMetaData.default, {

    hazardousSituation: belongsTo('hazardous-situation'),
    harm: belongsTo('harm'),
    requirements: hasMany('requirements'),
    initialRiskLevel: belongsTo('risklevel'),
    mitigatedRiskLevel: belongsTo('risklevel'),
    initialProbability: belongsTo('probability'),
    mitigatedProbability: belongsTo('probability'),

    initialRiskLabel: alias('initialRiskLevel.description'),
    mitigatedRiskLabel: alias('mitigatedRiskLevel.description'),
    initialProbabilityLabel: alias('initialProbability.name'),
    mitigatedProbabilityLabel: alias('mitigatedProbability.name'),
    severityLabel: alias('harm.severityLabel'),

    initialRiskScore: computed('severity', 'initialProbability', function () {
      return this.get('severity.id') + this.get('initialProbability.id');
    }),
    mitigatedRiskScore: computed('severity', 'mitigatedProbability', function () {
      return this.get('severity.id') + this.get('mitigatedProbability.id');
    }),

    hazard: alias('hazardousSituation.hazard'),
    severity: alias('harm.severity'),
    product: alias('hazardousSituation.product'),

    shortName: computed('harm.description', 'hazardousSituation.description', function () {
      return this.get('harm.description') + ' from ' + this.get('hazardousSituation.description');
    }),

    description: reads('harm.description'),

    requirementShortNames: mapBy('requirements', 'shortName'),
    requirementList: (0, _join.default)('requirementShortNames'),

    searchTerms: collect('hazard.description', 'hazardousSituation.description', 'harm.description', 'severityLabel', 'initialRiskLabel', 'mitigatedRiskLabel', 'initialProbabilityLabel', 'mitigatedProbabilityLabel'),

    routableTarget: 'app.products.product.risk-management',
    routableParams: collect('product')
  });
});