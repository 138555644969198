define('frontend/validations/email', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    presence: true,
    format: {
      with: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i,
      message: 'Not a valid e-mail address'
    },
    length: { minimum: 6 }
  };
});