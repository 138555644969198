define('frontend/transforms/simple-object', ['exports', 'ember-data', 'frontend/utils/object'], function (exports, _emberData, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var underscore = Ember.String.underscore,
      typeOf = Ember.typeOf;
  var Transform = _emberData.default.Transform;
  exports.default = Transform.extend({
    /**
     * @param {Object} serialized
     * @return {Object} new object with camelize keys
     */
    deserialize: function deserialize(serialized) {
      if (typeOf(serialized) !== 'object') {
        return null;
      }
      return (0, _object.camelizeKeys)(serialized);
    },


    /**
     * @param {Object} deserialized
     * @return {Object} new object with underscored keys
     */
    serialize: function serialize(deserialized) {
      if (typeOf(deserialized) !== 'object') {
        return null;
      }

      return (0, _object.replaceKeys)(deserialized, underscore);
    }
  });
});