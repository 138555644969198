define('frontend/components/enzyme-render-mobiledoc', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Renderer = Ember.Component.extend({
    tagName: ''
  });

  Renderer.reopenClass({
    positionalParams: ['mobiledoc']
  });

  exports.default = Renderer;
});