define('frontend/components/user-settings', ['exports', 'ember-validations', 'ember-data'], function (exports, _emberValidations, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PromiseObject = _emberData.default.PromiseObject;
  exports.default = Ember.Component.extend(_emberValidations.default, {
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    errors: [],

    pendingActionsFrequencies: ["Never", "24 hrs", "48 hrs", "Weekly"],

    // CPs
    user: Ember.computed.alias('session.currentUser'),

    // Tasks
    userSignature: Ember.computed('user.signature', 'session.data.authenticated.token', function () {
      var user = this.get('user');
      var token = this.get('session.data.authenticated.token');
      if (!user.get('signature')) {
        return null;
      }
      var promise = this.get('ajax').request('/signatures/' + user.id + '?token=' + token);
      return PromiseObject.create({ promise: promise });
    }),

    // Actions
    actions: {
      changeSignature: function changeSignature() {
        var id = this.get('user').id;
        this.get('ajax').request('/api/users/send_signature_email', {
          type: 'POST',
          data: { id: id }
        });
        window.open('/signatures/' + id + '?token=' + this.get('session.data.authenticated.token'), '_blank');
      },
      resetPin: function resetPin() {
        var _this = this;

        var user = this.get('user');

        if (confirm("Are you sure you want to reset your PIN?")) {
          this.get('session').authorizedAjaxRequest('/api/users/' + user.get('id') + '/reset_pin', {
            type: 'POST'
          }).then(function () {
            return _this.get('notify').success("Your PIN has been reset. Check your email.");
          }, function () {
            return _this.get('notify').error("Could not reset PIN");
          });
        }
      }
    }

  });
});