define('frontend/initializers/array-proxy-flatten', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    Ember.ArrayProxy.prototype.flatten = Array.prototype.flatten = function () {
      var r = [];
      this.forEach(function (el) {
        r.push.apply(r, Ember.isArray(el) ? el.flatten() : [el]);
      });
      return r;
    };
  }

  exports.default = {
    name: 'array-proxy-flatten',
    initialize: initialize
  };
});