define('frontend/components/notification-cards/requester-details/notification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['requester-details', 'notification', 'gray-zone'],

    approvable: null,
    usePopper: false,

    actions: {
      transitionToLiteApprovable: function transitionToLiteApprovable() {
        Ember.RSVP.resolve(this.get('approvable.linkedResource')).then(function (approvalSet) {
          return Ember.RSVP.resolve(approvalSet.get('approvables.firstObject')).then(function (approvable) {
            return approvable.transitionToRoute();
          });
        });
      }
    }
  });
});