define('frontend/components/training-assignment/quiz-mode', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['training-assignment__quiz-mode'],
    questions: computed(function () {
      return [];
    }),
    retryQuizMode: null,
    onChooseAnswer: function onChooseAnswer() {
      (false && !('onChooseAnswer needs to be provided') && Ember.assert(false, 'onChooseAnswer needs to be provided'));
    },
    onGradeQuiz: function onGradeQuiz() {
      (false && !('onGradeQuiz needs to be provided') && Ember.assert(false, 'onGradeQuiz needs to be provided'));
    },
    onRetryQuiz: function onRetryQuiz() {
      (false && !('onRetryQuiz needs to be provided') && Ember.assert(false, 'onRetryQuiz needs to be provided'));
    }
  });
});