define('frontend/components/capa-entry-form/approval', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'fieldset',

    model: Ember.computed('f.model.capa.capaPhases.@each.phaseNumber', 'phaseNumber', function () {
      // 'Final' phase has capa as the model, not the capa-phase
      if (!this.get('phaseNumber')) {
        return this.get('f.model');
      }

      var phases = this.get('f.model.capa.capaPhases');

      return phases.findBy('phaseNumber', +this.get('phaseNumber'));
    }),

    nextPhaseLabel: Ember.computed('phaseNumber', function () {
      return 'Phase ' + (+this.get('phaseNumber') + 1) + ' Due Date';
    })

  });
});