define('frontend/models/design-control', ['exports', 'ember-data', 'frontend/mixins/model-meta-data', 'frontend/mixins/approvable', 'frontend/mixins/tracable', 'ember-mobiledoc-text-renderer', 'frontend/mixins/attachable'], function (exports, _emberData, _modelMetaData, _approvable, _tracable, _emberMobiledocTextRenderer, _attachable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.designControlTypes = undefined;
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  var designControlTypes = exports.designControlTypes = {
    userNeed: 'User Needs',
    requirement: 'Requirements',
    designOutput: 'Design Outputs',
    verification: 'Verifications',
    validation: 'Validations'
  };

  exports.default = Model.extend(_approvable.default, _modelMetaData.default, _tracable.default, _attachable.default, {
    name: attr('string'),
    description: attr('mobiledoc'),
    comments: hasMany('comment', { inverse: 'commentable' }),
    designControlItemQmsDocumentLinks: hasMany('design-control-item-qms-document-link'),
    product: belongsTo('product'),
    subsystem: belongsTo('subsystem'),
    taggings: hasMany('tagging'),
    tags: hasMany('tag'),
    commentCount: attr('number'),
    sequentialId: attr('number'),
    githubPullRequestUrl: attr('string'),
    gitlabPullRequestUrl: attr('string'),
    bitbucketPullRequestUrl: attr('string'),
    attachmentSummary: attr(),
    attachmentCount: attr('number'),
    attachedDocumentSummary: attr(),

    designControlTypeAbbreviation: 'DC',
    shortName: computed('designControlTypeAbbreviation', 'sequentialId', function () {
      return this.get('designControlTypeAbbreviation') + '-' + this.get('sequentialId');
    }),
    idDescon: computed.alias('shortName'), // deprecated; use shortName

    descriptionText: computed('description', function () {
      if (this.get('description.version')) {
        var renderer = new _emberMobiledocTextRenderer.default();
        return renderer.render(this.get('description')).result;
      } else {
        return "";
      }
    }),

    hasDescriptionText: true, // if the text is blank, we still want to render that

    designControlTags: computed('taggings.[]', function () {
      return this.get('taggings').naturalSortBy('tag.name');
    }),

    designControlTagNames: computed('designControlTags', function () {
      return this.get('designControlTags').mapBy('tag.name');
    }),

    availableTags: computed.setDiff('company.companyTags', 'designControlTagNames'),

    commentsCount: computed.or('comments.length', 'commentCount'),

    designControlDependencies: ['designOutputRequirementDependencies', 'designOutputVerificationDependencies', 'requirementUserNeedDependencies', 'requirementVerificationDependencies', 'userNeedValidationDependencies'],
    tracingDependencies: computed('designOutputRequirementDependencies.[]', 'designOutputVerificationDependencies.[]', 'requirementUserNeedDependencies.[]', 'requirementVerificationDependencies.[]', 'userNeedValidationDependencies.[]', function () {
      var _this = this;

      var tracingDependencies = [];

      this.eachRelationship(function (name, descriptor) {
        if (descriptor.kind === 'hasMany' && _this.get('designControlDependencies').includes(name)) {
          tracingDependencies.pushObject(name);
        }
      });

      return tracingDependencies;
    }),

    tracingDependentCards: computed('tracingDependencies.[]', 'tracingDependencyObjects', 'designOutputRequirementDependencies.[]', 'designOutputVerificationDependencies.[]', 'requirementUserNeedDependencies.[]', 'requirementVerificationDependencies.[]', 'userNeedValidationDependencies.[]', function () {
      var _this2 = this;

      return this.get('tracingDependencies').map(function (dependencyName) {
        return _this2.get(dependencyName).map(function (dependency) {
          return dependency.get(_this2.get('tracingDependencyObjects')[dependencyName]);
        });
      }).flatten();
    }),

    isRelatedTo: function isRelatedTo(otherDesignControl) {
      return this.isRelatedByImpactSetTo(otherDesignControl) || this.isRelatedByTracingDependencyTo(otherDesignControl);
    },
    isRelatedByImpactSetTo: function isRelatedByImpactSetTo(otherDesignControl) {
      return eitherPermutation(this, otherDesignControl, function (a, b) {
        return a.get('impactingApprovables').mapBy('shortName').includes(b.get('shortName'));
      });
    },
    isRelatedByTracingDependencyTo: function isRelatedByTracingDependencyTo(otherDesignControl) {
      return eitherPermutation(this, otherDesignControl, function (a, b) {
        return a.get('tracingDependentCards').mapBy('shortName').includes(b.get('shortName'));
      });
    },


    routableTarget: 'app.products.product.design-control.show'

  });


  var eitherPermutation = function eitherPermutation(a, b, callback) {
    return callback(a, b) || callback(b, a);
  };
});