define('frontend/controllers/app/products/product/non-conformances', ['exports', 'frontend/components/non-conformance-entry-form'], function (exports, _nonConformanceEntryForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    productController: Ember.inject.controller('app.products.product'),
    currentCompany: Ember.computed.reads('session.currentUser.company'),
    exportSupplierDataButtonIsDisabled: Ember.computed.alias('qmsExportsPoller.qmsExportInProgress'),
    queryParams: ['showCancelled'],
    showCancelled: false,

    searchText: Ember.computed.reads('productController.searchText'),

    columns: Ember.computed(function () {
      return [{
        component: "non-conformances/status-and-number-column",
        title: 'Status',
        sortBy: 'id',
        key: 'humanizedApprovalState',
        filter: {
          type: 'list',
          options: ['Open', 'Closed', 'Cancelled']
        }
      }, {
        component: "non-conformances/description-column",
        title: 'Description'
      }, {
        component: "non-conformances/metadata-column",
        title: 'Initiated By/On',
        sortBy: 'user.fullName'
      }, {
        component: "non-conformances/links-column",
        title: 'Links'
      }, {
        component: "non-conformances/risk-column",
        title: 'Risk',
        sortBy: 'riskForSort',
        key: 'risk',
        filter: {
          type: 'list',
          options: ['high', 'low']
        }
      }, {
        key: 'disposition',
        filter: {
          type: 'list',
          options: _nonConformanceEntryForm.dispositionOptions
        }
      }];
    }),

    persistedNonConformances: Ember.computed('model.@each.{isNew,isCancelledAndApproved}', 'showCancelled', function () {
      var _this = this;

      return this.get('model').filter(function (nc) {
        return !nc.get('isNew') && (_this.get('showCancelled') || !nc.get('isCancelledAndApproved'));
      });
    }),

    actions: {
      viewNonConformance: function viewNonConformance(nonConformance) {
        this.transitionToRoute('app.products.product.non-conformances.non-conformance', nonConformance);
      }
    }

  });
});