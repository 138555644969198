define("frontend/tours/risk-management-module", ["exports", "frontend/utils/build-mobile-doc"], function (exports, _buildMobileDoc) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = {
    name: 'Risk Management Module',

    setup: function setup() {
      var allproducts = this.store.peekAll('product');
      this.realproduct = allproducts.get('firstObject');
      var allcompanies = this.store.peekAll('company');
      this.company = allcompanies.get('firstObject');

      this.negligible = this.company.get('severities').createRecord({ name: "Negligible", description: "Inconvenience or temporary discomfort", id: 0.1 });
      this.minor = this.company.get('severities').createRecord({ name: "Minor", description: "Results in temporary injury or impairment not requiring professional medical intervention", id: 0.2 });
      this.serious = this.company.get('severities').createRecord({ name: "Serious", description: "Results in injury or impairment requiring professional medical intervention", id: 0.3 });
      this.critical = this.company.get('severities').createRecord({ name: "Critical", description: "Results in permanent impairment or life-threatening injury", id: 0.4 });
      this.catastrophic = this.company.get('severities').createRecord({ name: "Catastrophic", description: "Results in patient death", id: 0.5 });
      this.severities = [this.negligible, this.minor, this.serious, this.critical, this.catastrophic];

      this.remote = this.company.get('probabilities').createRecord({ name: "Remote", description: "< 10⁻⁶ (Less than 1 in 1,000,000). Extremely unlikely. No history of occurrence.", id: 0.1 });
      this.improbable = this.company.get('probabilities').createRecord({ name: "Improbable", description: "≤ 10⁻⁵ and ≥ 10⁻⁶ (Between 1 in 100,000 and 1 in 1,000,000). Could occur at some time. Isolated incidents observed.", id: 0.2 });
      this.occasional = this.company.get('probabilities').createRecord({ name: "Occasional", description: "≤ 10⁻⁴ and ≥ 10⁻⁵ (Between 1 in 10,000 and 1 in 100,000). Probable at some time and has been observed.", id: 0.3 });
      this.probable = this.company.get('probabilities').createRecord({ name: "Probable", description: "≤ 10⁻³ and ≥ 10⁻⁴ (Between 1 and 1,000 and 1 in 10,000). Likely and will occur in most circumstances. Repeated occurrences observed.", id: 0.4 });
      this.frequent = this.company.get('probabilities').createRecord({ name: "Frequent", description: "> 10⁻³ (More than 1 in 1,000). Almost inevitable.", id: 0.5 });
      this.probabilities = [this.remote, this.improbable, this.occasional, this.probable, this.frequent];

      this.rl_nf = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.negligible, probability: this.frequent, id: 0.1 });
      this.rl_np = this.company.get('risklevels').createRecord({ description: "Low", severity: this.negligible, probability: this.probable, id: 0.2 });
      this.rl_no = this.company.get('risklevels').createRecord({ description: "Low", severity: this.negligible, probability: this.occasional, id: 0.3 });
      this.rl_ni = this.company.get('risklevels').createRecord({ description: "Low", severity: this.negligible, probability: this.improbable, id: 0.4 });
      this.rl_nr = this.company.get('risklevels').createRecord({ description: "Low", severity: this.negligible, probability: this.remote, id: 0.5 });
      this.rl_mf = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.minor, probability: this.frequent, id: 0.6 });
      this.rl_mp = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.minor, probability: this.probable, id: 0.7 });
      this.rl_mo = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.minor, probability: this.occasional, id: 0.8 });
      this.rl_mi = this.company.get('risklevels').createRecord({ description: "Low", severity: this.minor, probability: this.improbable, id: 0.9 });
      this.rl_mr = this.company.get('risklevels').createRecord({ description: "Low", severity: this.minor, probability: this.remote, id: '0.10' });
      this.rl_sf = this.company.get('risklevels').createRecord({ description: "High", severity: this.serious, probability: this.frequent, id: 0.11 });
      this.rl_sp = this.company.get('risklevels').createRecord({ description: "High", severity: this.serious, probability: this.probable, id: 0.12 });
      this.rl_so = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.serious, probability: this.occasional, id: 0.13 });
      this.rl_si = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.serious, probability: this.improbable, id: 0.14 });
      this.rl_sr = this.company.get('risklevels').createRecord({ description: "Low", severity: this.serious, probability: this.remote, id: 0.15 });
      this.rl_cf = this.company.get('risklevels').createRecord({ description: "High", severity: this.critical, probability: this.frequent, id: 0.16 });
      this.rl_cp = this.company.get('risklevels').createRecord({ description: "High", severity: this.critical, probability: this.probable, id: 0.17 });
      this.rl_co = this.company.get('risklevels').createRecord({ description: "High", severity: this.critical, probability: this.occasional, id: 0.18 });
      this.rl_ci = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.critical, probability: this.improbable, id: 0.19 });
      this.rl_cr = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.critical, probability: this.remote, id: '0.20' });
      this.rl_caf = this.company.get('risklevels').createRecord({ description: "High", severity: this.catastrophic, probability: this.frequent, id: 0.21 });
      this.rl_cap = this.company.get('risklevels').createRecord({ description: "High", severity: this.catastrophic, probability: this.probable, id: 0.22 });
      this.rl_cao = this.company.get('risklevels').createRecord({ description: "High", severity: this.catastrophic, probability: this.occasional, id: 0.23 });
      this.rl_cai = this.company.get('risklevels').createRecord({ description: "High", severity: this.catastrophic, probability: this.improbable, id: 0.24 });
      this.rl_car = this.company.get('risklevels').createRecord({ description: "Moderate", severity: this.catastrophic, probability: this.remote, id: 0.25 });
      this.risklevels = [this.rl_nf, this.rl_np, this.rl_no, this.rl_ni, this.rl_nr, this.rl_mf, this.rl_mp, this.rl_mo, this.rl_mi, this.rl_mr, this.rl_sf, this.rl_sp, this.rl_so, this.rl_si, this.rl_sr, this.rl_cf, this.rl_cp, this.rl_co, this.rl_ci, this.rl_cr, this.rl_caf, this.rl_cap, this.rl_cao, this.rl_cai, this.rl_car];

      this.product = this.store.createRecord('product', { name: "Example product", id: 'example', phase: 'product_definition', company: this.company, severities: this.severities, probabilities: this.probabilities, risklevels: this.risklevels });
      this.req1 = this.product.get('requirements').createRecord({ name: "Fixes all", sequentialId: 1, id: 0.1, description: (0, _buildMobileDoc.default)("This is an example of a requirement.") });

      this.hazard2 = this.product.get('hazards').createRecord({ description: 'Connected to mains', id: 0.1 });
      this.hazard1 = this.product.get('hazards').createRecord({ description: 'Sharp surface', id: 0.2 });
      this.hazardousSituation1 = this.product.get('hazardousSituations').createRecord({ description: 'Device is grasped incorrectly', hazard: this.hazard1, id: 0.1 });
      this.harm1 = this.product.get('harms').createRecord({ description: 'Tissue damage', severity: this.minor, id: 0.1 });
      this.harmInstance1 = this.product.get('harmInstances').createRecord({ harm: this.harm1, hazardousSituation: this.hazardousSituation1, initialProbability: this.occasional, mitigatedProbability: this.improbable, initialRiskLevel: this.rl_mo, mitigatedRiskLevel: this.rl_mi, requirements: [this.req1], id: 0.1 });
      this.hazardousSituation2 = this.product.get('hazardousSituations').createRecord({ description: 'Main voltage discharged in patient', hazard: this.hazard2, id: 0.2 });
      this.harm2 = this.product.get('harms').createRecord({ description: 'Burn', severity: this.minor, id: 0.2 });
      this.harm3 = this.product.get('harms').createRecord({ description: 'Cardiac arrest', severity: this.catastrophic, id: 0.3 });
      this.harmInstance2 = this.product.get('harmInstances').createRecord({ harm: this.harm2, hazardousSituation: this.hazardousSituation2, initialProbability: this.occasional, mitigatedProbability: this.improbable, initialRiskLevel: this.rl_mo, mitigatedRiskLevel: this.rl_mi, requirements: [this.req1], id: 0.2 });
      this.harmInstance3 = this.product.get('harmInstances').createRecord({ harm: this.harm3, hazardousSituation: this.hazardousSituation2, initialProbability: this.improbable, mitigatedProbability: this.remote, initialRiskLevel: this.rl_cai, mitigatedRiskLevel: this.rl_car, requirements: [this.req1], id: 0.3 });

      this.riskManagement = [this.negligible, this.minor, this.serious, this.critical, this.catastrophic, this.remote, this.improbable, this.occasional, this.probable, this.frequent, this.rl_nf, this.rl_np, this.rl_no, this.rl_ni, this.rl_nr, this.rl_mf, this.rl_mp, this.rl_mo, this.rl_mi, this.rl_mr, this.rl_sf, this.rl_sp, this.rl_so, this.rl_si, this.rl_sr, this.rl_cf, this.rl_cp, this.rl_co, this.rl_ci, this.rl_cr, this.rl_caf, this.rl_cap, this.rl_cao, this.rl_cai, this.rl_car, this.req1, this.hazard1, this.hazard2, this.hazardousSituation1, this.harm1, this.harmInstance1, this.hazardousSituation2, this.harm2, this.harm3, this.harmInstance2, this.harmInstance3];
    },
    teardown: function teardown() {
      this.riskManagement.invoke('unloadRecord');
      this.product.unloadRecord();
    },


    steps: [{
      prepare: function prepare() {
        return this.transitionTo("app.products.product.risk-management.index", this.product);
      },

      text: "This is the <b>Risk Management</b> module.<br><br>Risk Management is a critical and required part of product design of medical devices.<br><br>Every regulatory submission you ever make will have to account for product risk, and you can expect that the majority of time you receive questions from a regulator, they will concern risk.<br><br>This module implements a Hazard Analysis, and is the minimum required activity needed to consider a medical device ready for submission to a regulatory authority.",
      selector: '.product-liquid-outlet',
      animationRoot: '.product-liquid-outlet'
    }, {
      text: "A Hazard Analysis looks at product risk from the perspective of a <b>Hazard</b>, some aspect of your design that introduces risk to your users.<br><br>Here we see two sample Hazards, a sharp edge, and a connection to a wall outlet.",
      selector: '.table-3-header p.risk-hazard'
    }, {
      text: "Next, Hazard Analysis looks at <b>Hazardous Situations</b> that arise from the Hazard being present. Here we see that, in the case of being connected to the wall outlet, there is a possibility of a circuit connecting to the patient (for example, a child playing with a plug).<br><br>A Hazard may have more than one Hazardous Situation.",
      selector: '.table-3-header p.risk-situations'
    }, {
      text: "Next, Hazard Analysis looks at <b>Harms</b> that arise from the Hazardous Situation. In this case, cardiac arrest can occur (although unlikely).<br><br>A Hazardous Situation may (and usually does) have more than one Harm.",
      selector: '.table-3-header p.risk-harm'
    }, {
      text: "The combination of the <b>Severity</b> of the Harm and the <b>Probability</b> that the Harm arises from the Hazardous Situation produces a Risk score.<br><br>We will see where to set Severities and Probabilities in a moment.",
      selector: '.table-3-header p.risk-initial'
    }, {
      text: "When you perform Hazard Analysis, you do so assuming design features that reduce risk have not been implemented. <br><br>(This is much easier to do if you evalute risk at the beginning of the design and not the end).<br><br>Design features that reduce Risk are <b>Risk Controls</b>, and should be captured in the form of Requirements.",
      selector: '.table-3-header p.risk-control'
    }, {
      text: "Risk Controls reduce the Probability of a Harm occurring, though not its Severity (cardiac arrest is just as bad no matter how unlikely it is). <br><br>By reducing Probability, you reduce Risk.<br><br>You generally want to reduce risk as much as possible, and you need to have a very good explanation to regulators if you ship a product with any <b>High</b> risk.",
      selector: '.table-3-header p.risk-mitigated'
    }, {
      prepare: function prepare() {
        if ($($('.container .table-3-body')[0]).hasClass('expanded')) {
          $('.container .expand-row').click();
        }
      },

      text: "By default, the view of the Hazard Analysis table collapses different Harms under the same Hazard, showing you only the most risky Harm. To see all Harms, click this button.",
      selector: '.container .expand-row'
    }, {
      prepare: function prepare() {
        $($('.table-3-row .tiny-dropdown')[0]).css('visibility', 'hidden');
        $($('.table-3-row .tiny-dropdown')[0]).css('opacity', 0);
        $('.container .expand-row').click();
      },

      text: "Here you now see the expanded set of Harms for this Hazard.",
      selector: '.hazard-group.expanded',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        $($('.table-3-row .tiny-dropdown')[0]).css('visibility', 'visible');
        $($('.table-3-row .tiny-dropdown')[0]).css('opacity', 1);
      },

      text: "You'll notice a grey shading here. Risk data that is not approved is shaded in gray to indicate Draft status. <br><br>To edit a Hazard, hover over it and click the 'Pencil' icon.<br><br>To delete, click the 'trash can' icon, and to see the history of this item, click the counter-clockwise arrow.",
      selector: '.table-3-row'
    }, {
      prepare: function prepare() {
        $($('.table-3-row .tiny-dropdown')[0]).css('visibility', 'hidden');
        $($('.table-3-row .tiny-dropdown')[0]).css('opacity', 0);
        $($('.table-3-row .tiny-dropdown')[1]).css('visibility', 'hidden');
        $($('.table-3-row .tiny-dropdown')[1]).css('opacity', 0);
        return this.transitionTo("app.products.product.risk-management.hazards.edit", this.product, this.hazard1);
      },

      text: "Here you can edit the name of the Hazard and the subsystem it falls under, for filtering purposes.",
      selector: '.ember-modal-dialog',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        this.transitionTo("app.products.product.risk-management.index", this.product);
        $($('.table-3-row .tiny-dropdown')[1]).css('visibility', 'visible');
        $($('.table-3-row .tiny-dropdown')[1]).css('opacity', 1);
      },

      text: "The same principle is true for editing Hazardous Situations, hover and click the pencil icon.",
      selector: '.table-3-row'
    }, {
      prepare: function prepare() {
        $($('.table-3-row .tiny-dropdown')[1]).css('visibility', 'hidden');
        $($('.table-3-row .tiny-dropdown')[1]).css('opacity', 0);
        return this.transitionTo("app.products.product.risk-management.hazardous-situations.edit", this.product, this.hazardousSituation2);
      },

      text: "Here you can edit the name of the Hazardous Situation and the Hazard it belongs to",
      selector: '.ember-modal-dialog',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        this.transitionTo("app.products.product.risk-management.index", this.product);
        $($('.table-3-row .tiny-dropdown')[2]).css('visibility', 'visible');
        $($('.table-3-row .tiny-dropdown')[2]).css('opacity', 1);
      },

      text: "And last, to edit the Harms, the same behavior.",
      selector: '.table-3-row'
    }, {
      prepare: function prepare() {
        $($('.table-3-row .tiny-dropdown')[2]).css('visibility', 'hidden');
        $($('.table-3-row .tiny-dropdown')[2]).css('opacity', 0);
        return this.transitionTo("app.products.product.risk-management.harms.edit", this.product, this.harmInstance3);
      },

      text: "Here you see the make up of each Harm. You choose the: <ul><li>Hazardous Situation</li><li>Harm (the master list of Harms is the same for all Hazardous Situations)</li><li>Initial Probability</li><li>Mitigated Probability</li><li>Risk Controls</li><br><br>Changing the master list of harms, the probabilities, severities and risk scores is a function available only to Quality Owners in your company.",
      selector: '.ember-modal-dialog',
      animationDelay: 300
    }, {
      prepare: function prepare() {
        return this.transitionTo("app.products.product.risk-management.index", this.product);
      },

      text: "This concludes Risk Management training!",
      selector: 'body'
    }]
  };
});