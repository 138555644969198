define('frontend/routes/app/products/product/change-requests', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    websocket: Ember.inject.service(),

    deactivate: function deactivate() {
      this.get('websocket').unregisterListener(this.controller);
      return this._super.apply(this, arguments);
    },


    /**
     * We reset the model to an empty array.
     * The model is the populated within the controller after the route
     * is entered.
     *
     * @return {Array<ApprovalSet>} An empty array
     */
    model: function model() {
      return [];
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.get('websocket').registerListener(controller);
    },


    actions: {
      changeRequestSaved: function changeRequestSaved() {
        this.controller.get('fetchModelTask').perform();
      }
    }
  });
});