define('frontend/components/current-attachments', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    session: Ember.inject.service('session'),

    classNameBindings: ['attachment.markedForRemoval:is-marked-for-removal', 'attachment.isNew:is-marked-for-addition'],

    attachment: null,

    actionable: true,
    canEdit: true,
    canDelete: true,
    isEditing: false,
    isV2: true,

    name: Ember.computed.oneWay('attachment.name'),
    description: Ember.computed.oneWay('attachment.description'),

    actions: {
      removeAttachment: function removeAttachment() {
        var attachment = this.get('attachment');

        attachment.set('itemType', 'remove');
        attachment.saveWithNotifications();
      },
      editAttachment: function editAttachment() {
        this.set('isEditing', true);
      },
      saveAttachment: function saveAttachment(opts) {
        var save = opts.save;


        var attachment = this.get('attachment');

        var _getProperties = this.getProperties('name', 'description'),
            name = _getProperties.name,
            description = _getProperties.description;

        attachment.setProperties({ name: name, description: description });

        if (save) {
          return attachment.save().then(this.onSaveSuccess.bind(this));
        }

        this.onSaveSuccess();
      }
    },

    onSaveSuccess: function onSaveSuccess() {
      this.set('isEditing', false);
    }
  });
});