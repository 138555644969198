define("frontend/components/column-selectable-table/th", ["exports", "frontend/helpers/humanize"], function (exports, _humanize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'th',
    classNameBindings: ['sortIcon:is-sort-column', 'column.grow:should-grow:should-not-grow', 'canSort:can-sort'],

    canSort: Ember.computed.or('column.key', 'column.sortBy'),

    header: Ember.computed('column.title', 'column.key', 'column.type', function () {
      if (this.get('column.type') === 'actions' && !Object.keys(this.get('column')).includes('title')) {
        return "Actions";
      }
      if (this.get('column.title')) {
        return this.get('column.title');
      }
      return (0, _humanize.humanize)(this.get('column.key') || '');
    }),

    sortIcon: Ember.computed('column', 'sortColumn', 'sortDirection', 'canSort', function () {
      if (this.get('column') === this.get('sortColumn')) {
        return "sort-" + this.get('sortDirection');
      } else if (this.get('canSort')) {
        return "sort";
      }
    })
  });
});