define('frontend/routes/app/products/product/training', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.modelFor('app').get('trainingAssignments');
    },
    resetController: function resetController(controller) {
      controller.set('assignmentId', null);
    }
  });
});