define('frontend/models/impact-set', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    company: belongsTo('company'),
    product: belongsTo('product'),
    approvables: hasMany('approvable'),
    global: attr('boolean')
  });
});