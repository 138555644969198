define('frontend/controllers/app/products/product/risk-management/hazards/new', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_emberValidations.default, {
    displayErrors: false,

    validations: {
      newHazardDescription: {
        presence: true
      }
    },

    actions: {
      createHazard: function createHazard() {
        var _this = this;

        this.validate().then(function () {
          return _this.store.createRecord('hazard', {
            description: _this.get('newHazardDescription'),
            product: _this.get('product'),
            subsystem: _this.get('subsystem')
          }).saveWithNotifications().then(function () {
            _this.transitionToRoute('app.products.product.risk-management');
          });
        }).catch(function () {
          _this.set('displayErrors', true);
        });
      }
    }
  });
});