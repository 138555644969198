define('frontend/initializers/typekit', ['exports', 'ember-inject-script', 'frontend/config/environment'], function (exports, _emberInjectScript, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'typekit',
    initialize: function initialize() {
      if (_environment.default.environment === 'test') {
        return;
      }
      var url = "//use.typekit.net/" + _environment.default.typekitID + ".js";
      (0, _emberInjectScript.default)(url).then(function () {
        Typekit.load();
      });
    }
  };
});