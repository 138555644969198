define('frontend/models/qms-export', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      computed = Ember.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    qmsExportsPoller: inject.service(),

    productId: attr('number'),
    companyId: attr('number'),
    qmsExportableId: attr('number'),
    qmsExportableType: attr('string'),
    status: attr('string', { defaultValue: 'pending' }),
    fileUrl: attr('string'),
    createdAt: attr('date'),
    filters: attr(),

    isProcessed: computed.equal('status', 'succeeded'),

    saveAndNotify: function saveAndNotify() {
      this.get('qmsExportsPoller').handleSave(this);
    },
    wasPushed: function wasPushed() {
      this.get('qmsExportsPoller').handlePush(this);
    }
  });
});