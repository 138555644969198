define('frontend/components/hazards-table/cell', ['exports', 'frontend/mixins/permissions'], function (exports, _permissions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_permissions.default, {
    session: Ember.inject.service(),

    classNames: ['hazards-table-cell', 'hazards-table-cell--approvable'],
    classNameBindings: ['isDraftOrHasDraftChangeRequest:risk-draft', 'approvable.isPendingApproval:risk-pending'],
    attributeBindings: ['statusLabel:data-status-label'],

    product: Ember.computed.alias('approvable.product'),

    /**
     * @type {Boolean}
     */
    isDraftOrHasDraftChangeRequest: Ember.computed.or('approvable.isChangeRequestable', 'approvable.hasDraftApprovalSet'),

    // CPs
    editable: Ember.computed.and('canEdit', 'approvable.isNotPending'),

    statusLabel: Ember.computed('approvable.{isDraft,isPendingUpdate,isPendingCreate,isPendingDestroy,destroyRequested,hasDraftApprovalSet}', function () {
      var approvable = this.get('approvable');

      if (approvable.get('hasDraftApprovalSet')) {
        return 'In a Draft Change Request';
      } else if (approvable.get('isDraft')) {
        return 'Draft';
      } else if (approvable.get('isPendingCreate')) {
        return 'Pending approval';
      } else if (approvable.get('isPendingUpdate')) {
        return 'Pending approval';
      } else if (approvable.get('isPendingDestroy')) {
        return 'Pending deletion';
      } else if (approvable.get('destroyRequested')) {
        return 'Deletion requested';
      }
    }),

    // Actions
    actions: {
      destroyApprovable: function destroyApprovable() {
        this.get('approvable').requestDelete();
      }
    }
  });
});