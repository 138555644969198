define('frontend/utils/propagate-errors-to-relationships', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = propagateErrorsToRelationships;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var camelize = Ember.String.camelize;
  function propagateErrorsToRelationships(rootModel) {
    var rootErrors = rootModel.get('errors');
    var errorsToPropagate = rootErrors.toArray().filter(function (e) {
      return e.attribute.indexOf('/') > -1;
    });
    errorsToPropagate.forEach(function (e) {
      var _e$attribute$split = e.attribute.split('/'),
          _e$attribute$split2 = _slicedToArray(_e$attribute$split, 2),
          relName = _e$attribute$split2[0],
          attrName = _e$attribute$split2[1];

      var relationshipErrors = rootModel.get(relName + '.errors');
      if (!relationshipErrors) {
        return;
      }
      relationshipErrors.add(camelize(attrName), e.message);
      rootErrors.remove(e.attribute);
    });
  }
});