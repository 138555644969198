define('frontend/components/process-severities-crud-form', ['exports', 'frontend/components/settings-crud-form', 'frontend/templates/components/settings-crud-form'], function (exports, _settingsCrudForm, _settingsCrudForm2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _settingsCrudForm.default.extend({
    layout: _settingsCrudForm2.default,

    session: Ember.inject.service(),
    company: Ember.computed.readOnly('session.currentUser.company'),

    rows: Ember.computed.filterBy('models', 'processRisk'),

    newModel: function newModel() {
      return this.get('models').createRecord({
        company: this.get('company'),
        processRisk: true,
        ncCapaRisk: 'low'
      });
    }
  });
});