define('frontend/components/audits/column-approval-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['column-label'],
    classNameBindings: ['row.approvalState']
  });
});