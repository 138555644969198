define('frontend/models/product-user-permission-level', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Permission Weights are used when multiple products for a single object
   * exist ({Model.QmsDocument}) to determine which permission wins. The lowest
   * weighted permission wins.
   *
   * For example, if QmsDocument belongs to Product 1 and Product 2, and User
   * has `productUserPermissionLevel.permissionLevel == 'viewer'` for Product 1
   * and `productUserPermissionLevel.permissionLevel == 'quality_owner'` for
   * Product 2, then the `permissionLevel` determined by the `PermissionsMixin`
   * would be based on this weight and would evaluate to `viewer`.
   *
   * See {Mixins.Permissions} for related logic
   */
  var permissionWeights = {
    viewer: 1,
    editor: 2,
    approver: 3,
    quality_owner: 4
  };

  exports.default = _model.default.extend({
    permissionLevel: (0, _attr.default)('string'),

    user: (0, _relationships.belongsTo)('user'),
    product: (0, _relationships.belongsTo)('product'),

    permissionWeight: Ember.computed('permissionLevel', function () {
      return permissionWeights[this.get('permissionLevel')];
    })
  });
});