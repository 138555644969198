define('frontend/components/dc-card', ['exports', 'frontend/utils/approved-or-current-attribute'], function (exports, _approvedOrCurrentAttribute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['card'],
    classNameBindings: ['dc.isPendingApproval:pending', 'isTracingModeAndMissingDependencies:tracing-red', 'isTracingModeAndHasAllDependencies:tracing-green', 'isTracingCard:tracing-card'],

    viewOptions: Ember.inject.service(),

    // CPs
    approvedModeOn: Ember.computed.reads('dcContainer.approvedModeOn'),
    tracingModeOn: Ember.computed.reads('dcContainer.tracingModeOn'),
    hasAllDependencies: Ember.computed.not('dc.hasMissingDependencies'),

    name: (0, _approvedOrCurrentAttribute.default)('dc', 'name'),

    isTracingModeAndMissingDependencies: Ember.computed.and('dc.hasMissingDependencies', 'tracingModeOn'),

    isTracingModeAndHasAllDependencies: Ember.computed.and('hasAllDependencies', 'tracingModeOn'),

    isTracingCard: Ember.computed('dcContainer.tracingCards.[]', function () {
      return this.get('dcContainer.tracingCards').includes(this);
    }),

    isRelatedToFocusedCardByImpactSet: Ember.computed('focusedCard.impactingApprovables.[]', 'dc.impactingApprovables.[]', function () {
      if (!this.get('focusedCard')) {
        return false;
      }
      return this.get('focusedCard').isRelatedByImpactSetTo(this.get('dc'));
    }),

    isRelatedToFocusedCardByTracingDependency: Ember.computed('focusedCard.tracingDependentCards.[]', 'dc.tracingDependentCards.[]', function () {
      if (!this.get('focusedCard')) {
        return false;
      }
      return this.get('focusedCard').isRelatedByTracingDependencyTo(this.get('dc'));
    }),

    subsystemColor: Ember.computed('color', function () {
      var color = this.get('dc.subsystem.color');
      return Ember.String.htmlSafe("background-color: " + color);
    }),

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      Ember.run.schedule('actions', this.get('dcContainer'), 'registerCard', this);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      Ember.run.schedule('actions', this.get('dcContainer'), 'unregisterCard', this);
    },


    // Events
    click: function click() {
      this.sendAction('action', this.get('dc'));
    },
    mouseEnter: function mouseEnter() {
      if (this.get('tracingModeOn')) {
        this.get('dcContainer').hoverCard(this);
      }
    },
    mouseLeave: function mouseLeave() {
      if (this.get('tracingModeOn')) {
        this.get('dcContainer').unhoverCard(this);
      }
    },


    actions: {
      focus: function focus(event) {
        event.stopPropagation();
        this.sendAction('setFocusedCard', this.get('dc'));
      },
      unfocus: function unfocus(event) {
        event.stopPropagation();
        this.sendAction('setFocusedCard', null);
      }
    }
  });
});