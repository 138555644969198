define('frontend/mixins/embedded-jsonapi-records', ['exports', 'ember-data/serializers/json', 'ember-data/serializers/json-api', 'ember-inflector'], function (exports, _json, _jsonApi, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // add json-api compliant serialization type
    hasSerializeIdsAndTypeOption: function hasSerializeIdsAndTypeOption(attr) {
      var option = this.attrsOption(attr);
      return option && option.serialize === 'ids-and-type';
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var _this = this;

      var attr = relationship.key;
      if (this.noSerializeOptionSpecified(attr)) {
        this._super(snapshot, json, relationship);
        return;
      }
      var includeIds = this.hasSerializeIdsOption(attr);
      var includeIdsAndType = this.hasSerializeIdsAndTypeOption(attr);
      var includeRecords = this.hasSerializeRecordsOption(attr);
      if (includeIdsAndType || includeRecords) {
        var serializedKey = this.keyForRelationship(attr, relationship.kind, 'serialize'),
            serializedRecords = snapshot.hasMany(attr).map(function (_ref) {
          var id = _ref.id,
              modelName = _ref.modelName;
          return { id: id, type: (0, _emberInflector.pluralize)(modelName) };
        });
        if (!json['relationships']) {
          json['relationships'] = {};
        }
        json['relationships'][serializedKey] = { data: serializedRecords };

        if (includeRecords) {
          var key = this.keyForAttribute(attr, 'serialize');
          var hasMany = snapshot.hasMany(attr);

          (false && Ember.warn("The embedded relationship '" + key + "' is undefined for '" + snapshot.modelName + "' with id '" + snapshot.id + "'. Please include it in your original payload.", Ember.typeOf(hasMany) !== 'undefined', { id: 'ds.serializer.embedded-relationship-undefined' }));

          if (!json['included']) {
            json['included'] = [];
          }

          hasMany.forEach(function (embeddedSnapshot) {
            var embeddedJson = embeddedSnapshot.record.serialize({ includeId: true });
            _this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
            json['included'].push(embeddedJson.data);
          });
        }
      } else if (includeIds) {
        var _serializedKey = this.keyForRelationship(attr, relationship.kind, 'serialize');
        json[_serializedKey] = snapshot.hasMany(attr, { ids: true });
      }
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var attr = relationship.key;
      if (this.hasSerializeRecordsOption(attr)) {
        var key = this.keyForAttribute(attr, 'serialize');
        if (!json['relationships']) {
          json['relationships'] = {};
        }
        var relationshipData = snapshot.belongsTo(attr);
        if (relationshipData) {
          json['relationships'][key] = relationshipData.record.serialize();
        }
      } else {
        this._super(snapshot, json, relationship);
      }
    }
  });


  // Redefine the willMergeMixin hook to remove the warning that the embedded
  // records mixin won't work with json-api
  // https://github.com/emberjs/data/blob/90d529342e0204a7ca01c8273dad0f0f4644bfa8/addon/serializers/json-api.js#L749-L751
  _jsonApi.default.reopen({
    willMergeMixin: function willMergeMixin(props) {
      var constructor = this.constructor;
      (false && Ember.warn('You\'ve defined \'extractMeta\' in ' + constructor.toString() + ' which is not used for serializers extending JSONAPISerializer. Read more at http://emberjs.com/api/data/classes/DS.JSONAPISerializer.html#toc_customizing-meta on how to customize meta when using JSON API.', Ember.isNone(props.extractMeta) || props.extractMeta === _json.default.prototype.extractMeta, {
        id: 'ds.serializer.json-api.extractMeta'
      }));
    }
  });
});