define("frontend/transforms/date-no-time", ["exports", "ember-data/transform", "moment"], function (exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      var type = typeof serialized === "undefined" ? "undefined" : _typeof(serialized);

      if (type === "string") {
        return (0, _moment.default)(serialized).toDate();
      } else {
        return null;
      }
    },
    serialize: function serialize(date) {
      if (date instanceof Date && !isNaN(date)) {
        return (0, _moment.default)(date).format("YYYY-MM-DD");
      } else {
        return null;
      }
    }
  });
});