define('frontend/components/show-previous-approved-version', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: 'show-previous-approved-version',
    actions: {
      revert: function revert() {
        var _this = this;

        if (confirm("Are you sure you want to revert to the previous approved version?")) {
          this.get('approvable').revert().then(function () {
            _this.sendAction('reverted');
          });
        }
      }
    }
  });
});