define('frontend/components/info-popup/content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    color: 'default',

    attachment: 'top right',
    targetAttachment: 'bottom left',
    targetOffset: '10px 35px',
    constraints: [{ to: 'window', pin: true }],

    popupContentClasses: ''
  });
});