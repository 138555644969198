define('frontend/utils/grouped-repos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (provider) {
    return Ember.computed(function () {
      return this.get(provider + 'Repos').then(function (repos) {
        return repos.mapBy('ownerLogin').uniq().naturalSort().map(function (login) {
          return {
            groupName: login,
            options: repos.filterBy('ownerLogin', login).naturalSortBy('name')
          };
        });
      });
    });
  };
});