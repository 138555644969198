define('frontend/services/ajax', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),

    headers: Ember.computed('session.data.authenticated.{email,token}', function () {
      return {
        Authorization: 'Token token="' + this.get('session.data.authenticated.token') + '", email="' + this.get('session.data.authenticated.email') + '"'
      };
    })
  });
});