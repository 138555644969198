define('frontend/controllers/forgot-password', ['exports', 'ember-validations', 'frontend/validations/email'], function (exports, _emberValidations, _email) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_emberValidations.default, {
    email: '',

    errors: [],
    displayErrors: false,

    validations: {
      'email': _email.default
    },

    actions: {
      requestNewPassword: function requestNewPassword() {
        var _this = this;

        this.validate().then(function () {
          if (_this.get('isValid')) {
            Ember.$.ajax({
              type: "POST",
              url: '/api/users/password',
              contentType: "application/json",
              data: JSON.stringify({ user: { email: _this.get('email') } }),
              success: function success() {
                _this.set('showSuccessMessage', true);
                _this.set('showErrorMessage', false);
              },
              error: function error(_error) {
                _this.set('errorMessage', 'Email ' + JSON.parse(_error.responseText).errors.email[0]);
                _this.set('showSuccessMessage', false);
                _this.set('showErrorMessage', true);
              }
            });
          }
        }, function () {
          _this.set('displayErrors', true);
        });
      }
    }
  });
});