define('frontend/services/tour', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TourStep = Ember.Object.extend({
    tour: Ember.inject.service(),

    type: 'raw',
    allowInteraction: false,

    activate: function activate() {
      var _this = this;

      var scrollTarget = this.get('scrollTop');
      if (scrollTarget !== undefined) {
        Ember.$('html, body').animate({ scrollTop: scrollTarget }, { duration: 100 });
      }

      var $element = Ember.$(this.get('selector'));
      Ember.assert('Unable to find element for next step of test: ' + this.get('selector'), $element.length > 0);

      this.set('tour.spotlightTarget', $element);

      if (this.get('advanceOnClickTarget')) {
        $element.first().one('click', function (event) {
          event.stopPropagation();
          event.preventDefault();
          _this.get('tour').advance();
        });
      }
    },
    raiseError: function raiseError(message) {
      throw new Error('The following step has failed: ' + JSON.stringify(this.get('_step')) + ' - failed waiting for ' + message);
    }
  });

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    nextIndex: null,
    data: null,

    isSpotlightOn: false,
    spotlightTarget: null,

    trainingTour: function trainingTour(assignment) {
      var _this2 = this;

      var data = assignment.get('training.tourData');
      this.set('data', data);

      this.tourContext = {
        store: this.get('store'),
        transitionTo: function transitionTo() {
          var _get;

          return (_get = _this2.get('router')).transitionTo.apply(_get, arguments);
        },
        waitFor: waitFor
      };

      var owner = Ember.getOwner(this);
      this.set('steps', data.steps.map(function (step) {
        var tourStep = TourStep.create(Ember.assign({}, step, { _step: step }));
        Ember.setOwner(tourStep, owner);
        return tourStep;
      }));

      if (typeof data.setup === 'function') {
        data.setup.call(this.tourContext);
      }

      this.set('nextIndex', 0);
      this.set('isSpotlightOn', true);
      this.set('trainingAssignment', assignment);

      this.advance();
    },
    advance: function advance() {
      var _this3 = this;

      var step = this.get('nextStep');
      if (!step) {
        this.finish();
        return;
      }
      this.set('isTourTransitioning', true);

      var promise = Ember.RSVP.resolve();

      if (typeof step.get('prepare') === 'function') {
        promise = promise.then(function () {
          return step.get('prepare').call(_this3.tourContext);
        });
      }

      promise.then(function () {
        return step.get('animationRoot') && waitForAnimation(step.get('animationRoot'));
      }).then(function () {
        return step.get('animationDelay') && waitForDelay(step.get('animationDelay'));
      }).then(function () {
        return step.get('selector') && waitFor(step.get('selector'), 0, step);
      }).then(function () {
        return step.activate && step.activate();
      }).then(function () {
        _this3.set('isTourTransitioning', false);
        Ember.run.scheduleOnce('afterRender', function () {
          if (!_this3.get('isLastStep')) {
            _this3.incrementProperty('nextIndex');
          }
          step.activate();
        });
      });
    },
    retreat: function retreat() {
      var _this4 = this;

      var currentStep = this.get('currentStep');
      var prevStep = this.get('prevStep');
      if (!prevStep) {
        this.finish();
        return;
      }

      this.set('isTourTransitioning', true);

      var promise = Ember.RSVP.resolve();

      if (typeof prevStep.get('prepare') === 'function') {
        promise = promise.then(function () {
          return prevStep.get('prepare').call(_this4.tourContext);
        });
      }

      promise.then(function () {
        return currentStep.get('animationRoot') && waitForAnimation(currentStep.get('animationRoot'));
      }).then(function () {
        return currentStep.get('animationDelay') && waitForDelay(currentStep.get('animationDelay'));
      }).then(function () {
        _this4.set('isTourTransitioning', false);
        Ember.run.scheduleOnce('afterRender', function () {
          if (!_this4.get('isFirstStep')) {
            _this4.decrementProperty('nextIndex');
          }
          prevStep.activate();
        });
      });
    },
    finish: function finish() {
      var _this5 = this;

      var promise = Ember.RSVP.resolve();
      var teardown = this.get('data.teardown');

      this.set('nextIndex', null);
      this.set('isSpotlightOn', false);

      if (typeof teardown === 'function') {
        promise = promise.then(function () {
          return teardown.call(_this5.tourContext);
        });
      }

      promise.then(function () {
        if (_this5.get('trainingAssignment')) {
          var product = _this5.get('store').peekAll('product').get('firstObject');
          var queryParams = { assignmentId: _this5.get('trainingAssignment.id') };
          _this5.get('router').transitionTo('app.products.product.training', product, { queryParams: queryParams });
        }
      });
    },


    nextStep: Ember.computed('steps.[]', 'nextIndex', function () {
      return this.get('steps')[this.get('nextIndex')];
    }),

    // Get currentStep from nextIndex, allowing the next step to be 0
    // to kick off the tour
    currentStep: Ember.computed('steps.[]', 'nextIndex', function () {
      if (this.get('nextIndex')) {
        return this.get('steps')[this.get('nextIndex') - 1];
      }
    }),

    isActive: Ember.computed.bool('currentStep'),

    isFirstStep: Ember.computed.equal('currentIndex', 0),

    isLastStep: Ember.computed('steps.[]', 'nextIndex', function () {
      return this.get('nextIndex') === this.get('steps.length');
    }),

    prevStep: Ember.computed('steps.[]', 'nextIndex', function () {
      if (this.get('nextIndex')) {
        if (this.get('nextIndex') === 1) {
          return false;
        } else {
          return this.get('steps')[this.get('nextIndex') - 2];
        }
      }
    }),

    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    })
  });


  function waitFor(selectorOrCondition) {
    var attempts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var step = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    return new Ember.RSVP.Promise(function (resolve) {
      Ember.run.later(function () {
        var condition = selectorOrCondition;
        if (typeof selectorOrCondition !== 'function') {
          condition = function condition() {
            return Ember.$(selectorOrCondition).length;
          };
        }

        var readyToContinue = condition();
        if (!readyToContinue) {
          if (attempts > 50) {
            if (step.raiseError) {
              step.raiseError(toString(condition));
            } else {
              throw new Error('Timed out waiting for ' + selectorOrCondition + ' - there is a problem with the tour. The following condition was never satisfied:\n\n' + toString(condition));
            }
          }
          return waitFor(condition, attempts + 1, step).then(resolve);
        }
        resolve();
      }, 100);
    });
  }

  function waitForAnimation(selector) {
    var firstSeen = false;
    return waitFor(function () {
      if (!firstSeen) {
        if (Ember.$(selector).length > 0) {
          firstSeen = true;
        }
        return false;
      }
      return !Ember.$(selector).hasClass('liquid-animating');
    });
  }

  function waitForDelay(time) {
    return new Ember.RSVP.Promise(function (resolve) {
      setTimeout(resolve, time);
    });
  }
});