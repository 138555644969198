define('frontend/models/company', ['exports', 'ember-data', 'frontend/macros/natural-sort'], function (exports, _emberData, _naturalSort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    name: attr("string"),
    users: hasMany("user"),
    approvalRoles: hasMany("approval-role"),
    billing_name: attr("string"),
    billing_address: attr("string"),
    billing_city: attr("string"),
    billing_state: attr("string"),
    billing_zip: attr("string"),
    billing_country: attr("string"),
    card_data: attr("string"),
    timeZone: attr("string"),
    quizAttemptsLimit: attr("number"),
    initialUser: belongsTo("user"),
    qmsDocumentFolders: hasMany("qms-document-folder"),
    impactSets: hasMany("impact-sets"),
    qmsDocumentTypes: hasMany("qms-document-types"),
    qmsDocuments: hasMany("qms-documents"),
    severities: hasMany("severities"),
    processSeverities: Ember.computed.filterBy('severities', 'processRisk'),
    productSeverities: Ember.computed.filterBy('severities', 'productRisk'),
    risklevels: hasMany("risklevels"),
    probabilities: hasMany("probabilities"),
    fileUploads: hasMany("file-uploads"),
    products: hasMany(),
    currentCraqQuestionSet: belongsTo("craq-question-set"),
    craqQuestionSets: hasMany(),
    supplierTypes: hasMany("supplier-types"),
    supplierRisks: hasMany("supplier-risks"),
    supplierRatings: hasMany("supplier-ratings"),
    suppliers: hasMany('supplier'),
    customerPlan: attr("string"),
    sessionTimeout: attr('number'),
    strictSessionTimeouts: attr('boolean', { defaultValue: false }),
    tags: hasMany('tags'),
    enhancedPart11: attr('boolean'),
    enableProductUserPermissionLevels: attr('boolean'),
    requireCrTitleAndDescription: attr('boolean'),
    authType: attr('string'),
    appVersion: Ember.computed(function () {
      return document.querySelector("meta[name='app_version']").content;
    }),
    releaseDate: Ember.computed(function () {
      return document.querySelector("meta[name='release_date']").content;
    }),

    asReportedCodes: hasMany(),
    asAnalyzedCodes: hasMany(),
    defectCodes: hasMany(),
    defectTypes: hasMany(),
    rootCauseCodes: hasMany(),
    liveDefectCodes: Ember.computed.filterBy('defectCodes', 'isDeleted', false),
    liveDefectTypes: Ember.computed.filterBy('defectTypes', 'isDeleted', false),
    liveRootCauseCodes: Ember.computed.filterBy('rootCauseCodes', 'isDeleted', false),
    complaints: hasMany(),
    audits: hasMany(),
    nonConformances: hasMany(),
    capas: hasMany(),
    complaintSourceTypes: hasMany("complaint-source-types"),

    harms: hasMany("harm"),
    zendeskWebhook: belongsTo('zendesk-webhook'),

    activeUsers: Ember.computed.filterBy("users", "deletedAt", null),
    inactiveUsers: Ember.computed.filterBy("users", "deletedAt"),

    approvers: Ember.computed.filterBy("activeUsers", "canApprove"),
    qualityOwners: Ember.computed.filterBy("activeUsers", "isQualityOwner"),

    sortUsersDefinition: ['fullName:asc'],
    sortedUsers: (0, _naturalSort.default)('users', 'sortUsersDefinition'),

    // Github integration attributes
    githubEmail: attr("string"),
    githubNickname: attr("string"),
    githubName: attr("string"),
    githubAvatar: attr("string"),
    unlinkGithub: attr("boolean"),

    isGithubLinked: Ember.computed.bool("githubNickname"),

    // Gitlab integration attributes
    gitlabApiEndpoint: attr("string"),
    gitlabEmail: attr("string"),
    gitlabNickname: attr("string"),
    gitlabName: attr("string"),
    gitlabAvatar: attr("string"),
    unlinkGitlab: attr("boolean"),

    isGitlabLinked: Ember.computed.bool("gitlabNickname"),

    // Bitbucket integration attributes
    bitbucketEmail: attr("string"),
    bitbucketUsername: attr("string"),
    bitbucketName: attr("string"),
    bitbucketAvatar: attr("string"),
    unlinkBitbucket: attr("boolean"),

    isBitbucketLinked: Ember.computed.bool("bitbucketUsername"),

    // Pivotal integration options
    pivotalStoriesOnlyAccepted: attr("boolean"),
    pivotalProvidesDesignControlId: attr("boolean"),

    // Verification JSON integration options
    verificationJsonSetsSequentialId: attr("boolean"),

    // Logo
    logo: attr("file"),
    logoUrl: attr("string"),
    removeLogo: attr("boolean"),

    // Other settings
    fillGapsInSequentialIds: attr("boolean"),
    autolinkRequirementsFromBitbucket: attr("boolean"),
    autolinkRequirementsFromGithub: attr("boolean"),
    autolinkRequirementsFromGitlab: attr("boolean"),
    enableAdminCrs: attr("boolean"),
    enableVersionOverrides: attr("boolean"),
    immediateTrainingEmail: attr('boolean'),
    outstandingTrainingEmailDays: attr('string'),
    overdueTrainingEmails: attr('boolean'),
    overdueTrainingEmailsCcManager: attr('boolean'),

    individualApprovers: Ember.computed.filterBy('users', 'canApprove'),

    rootGlobalFolder: Ember.computed("qmsDocumentFolders.@each.parentFolder", function () {
      return this.get("qmsDocumentFolders").findBy("parentFolder.id", undefined);
    }),

    isPreProduction: Ember.computed.equal('customerPlan', 'pre-production'),
    isPear: Ember.computed('name', function () {
      return this.get('name').includes("Pear Therapeutics") && !window.location.hostname.includes("staging");
    }),

    rolesSorting: ['name'],
    sortedApprovalRoles: (0, _naturalSort.default)('unsortedApprovalRoles', 'rolesSorting'),
    unsortedApprovalRoles: Ember.computed.filter('approvalRoles.@each.{isNew,deletedAt}', function (role) {
      return !role.get('isNew') && !role.get('deletedAt');
    }),
    deletedApprovalRoles: (0, _naturalSort.default)('unsortedDeletedApprovalRoles', 'rolesSorting'),
    unsortedDeletedApprovalRoles: Ember.computed.filter('approvalRoles.@each.{isNew,deletedAt}', function (role) {
      return !role.get('isNew') && role.get('deletedAt');
    }),

    companyTags: Ember.computed.mapBy('tags', 'name')
  });
});