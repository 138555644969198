define('frontend/routes/app/products/product/risk-management/hazards/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('editHazardDescription', model.get('description'));
    },
    resetController: function resetController(controller) {
      controller.set('isShowingPreviousApprovedVersion', false);
    }
  });
});