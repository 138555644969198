define('frontend/routes/app/products/product', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    websocket: Ember.inject.service(),
    notify: Ember.inject.service(),
    viewOptions: Ember.inject.service(),

    queryParams: {
      searchText: {
        replace: true
      }
    },

    model: function model(params) {
      var _this = this;

      return this.store.findRecord('product', params.product_id, { reload: true }).catch(function () {
        return _this.transitionTo('/app/products');
      });
    },
    setupController: function setupController(_controller, model) {
      this._super.apply(this, arguments);

      this.set('viewOptions.selectedSubsystem', null);

      var websocketChannel = model.get('websocketChannel');

      this.get('websocket').subscribeToChannel(websocketChannel);
    },
    resetController: function resetController(controller) {
      var websocketChannel = controller.get('model.websocketChannel');

      this.get('websocket').unsubscribeFromChannel(websocketChannel);
    },


    actions: {
      closeModal: function closeModal() {
        this.transitionTo('app.products.product.design-control');
      },
      recordDeletedViaPush: function recordDeletedViaPush(record) {
        var product = this.modelFor(this.routeName);

        if (record === product) {
          this.get('notify').info("The product you were viewing has been deleted.");
          this.transitionTo('app.products');
        }

        if (record === this.get('viewOptions.selectedSubsystem')) {
          this.get('viewOptions').set('selectedSubsystem', null);
        }
      }
    }
  });
});