define('frontend/components/complaints/column-stage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['column-label'],
    classNameBindings: ['row.stage']
  });
});