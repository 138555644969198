define("frontend/components/nc-capa-settings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    user: Ember.computed.alias('session.currentUser'),
    company: Ember.computed.alias('user.company')
  });
});