define('frontend/routes/test/approvable-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return this.get('ajax').request("/api/test/sample_approvables").then(function (response) {
        _this.store.pushPayload(response);
        return response.data.map(function (_ref) {
          var type = _ref.type,
              id = _ref.id;
          return _this.store.peekRecord(type, id);
        });
      });
    }
  });
});