define('frontend/controllers/app/products/product/document-control/document-item/delete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Controller.extend({
    productController: inject.controller('app/products/product'),
    product: computed.alias('productController.model'),

    qmsDocument: computed.alias('model'),

    actions: {
      deleteQmsDocument: function deleteQmsDocument() {
        var _this = this;

        if (this.get('qmsDocument.hasNeverBeenApproved')) {
          this.get('qmsDocument').destroyWithNotifications().then(function () {
            _this.transitionToRoute('app.products.product.document-control.index');
          });
        } else {
          // TODO: handle error
        }
      }
    }
  });
});