define('frontend/utils/date-formatter', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = dateFormatter;
  function dateFormatter(date) {
    if (Ember.isBlank(date)) {
      return "";
    }

    return (0, _moment.default)(date).format("YYYY-MM-DD");
  }
});