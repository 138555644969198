define('frontend/routes/app/products/product/delete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.modelFor('app.products.product');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      controller.set('deleteProductName', model.get('name'));
    }
  });
});