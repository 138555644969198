define('frontend/helpers/humanize-index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeIndex = humanizeIndex;


  // Computers index from zero; humans index from one
  function humanizeIndex(params /*, hash*/) {
    return parseInt(params) + 1;
  }

  exports.default = Ember.Helper.helper(humanizeIndex);
});