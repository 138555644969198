define('frontend/components/maybe-mobiledoc', ['exports', 'frontend/utils/build-mobile-doc'], function (exports, _buildMobileDoc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;


  var MaybeMobiledoc = Component.extend({
    isMobiledoc: computed('value.{version,sections}', function () {
      return (0, _buildMobileDoc.isMobileDoc)(this.get('value'));
    })
  });

  MaybeMobiledoc.reopenClass({
    positionalParams: ['value']
  });

  exports.default = MaybeMobiledoc;
});