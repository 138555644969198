define('frontend/routes/app/products/product/document-control/document-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('store').query('qms-document-type', {
        page: {
          number: 1,
          size: 10
        }
      });
    }
  });
});