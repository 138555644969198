define('frontend/components/changeset-form-for/enzyme-radio-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RadioField = Ember.Component.extend({});

  RadioField.reopenClass({
    positionalParams: ['fieldName']
  });

  exports.default = RadioField;
});