define('frontend/routes/app/products/product/document-control/document-type/list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('app.products.product.document-control.document-type');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('modelTotalCount', model.get('meta.total_count'));
    },


    actions: {
      willTransition: function willTransition() {
        this.get('controller').set('displayErrors', false);
      }
    }
  });
});