define('frontend/components/top-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    /**
     * Tagless component. The goal is positioning using ember-wormhole, nothing more.
     *
     * @type {String}
     */
    tagName: ''
  });
});