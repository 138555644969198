define('frontend/models/trello-webhook', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    boardColor: (0, _attr.default)('string'),
    boardName: (0, _attr.default)('string'),
    board: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    nickname: (0, _attr.default)('string'),
    webhookId: (0, _attr.default)('string'),
    product: (0, _relationships.belongsTo)('product')
  });
});