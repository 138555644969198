define('frontend/models/approval-set-approver-signer', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr', 'moment'], function (exports, _model, _relationships, _attr, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    user: (0, _relationships.belongsTo)('user'),
    approvalSetApprover: (0, _relationships.belongsTo)('approval-set-approver'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    approvedAt: (0, _attr.default)('date'),
    deniedAt: (0, _attr.default)('date'),

    status: Ember.computed('approvedAt', 'deniedAt', 'approvalSetApprover.isDraft', function () {
      if (this.get('approvedAt')) {
        return 'approved';
      } else if (this.get('deniedAt')) {
        return 'denied';
      } else if (this.get('approvalSetApprover.isDraft')) {
        return 'draft';
      } else {
        return 'pending';
      }
    }),

    details: Ember.computed('approvedAt', 'deniedAt', function () {
      if (this.get('approvedAt')) {
        return 'Approved on ' + (0, _moment.default)(this.get('approvedAt')).format('YYYY-MM-DD HH:mm:ss');
      } else if (this.get('deniedAt')) {
        return 'Denied on on ' + (0, _moment.default)(this.get('deniedAt')).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return 'Still pending';
      }
    })
  });
});