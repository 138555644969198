define('frontend/components/answer-change-request-modal', ['exports', 'frontend/mixins/approval-role-selection'], function (exports, _approvalRoleSelection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend(_approvalRoleSelection.default, {
    // CPs
    approvalSet: computed('model.approvalSet', function () {
      return Ember.RSVP.resolve(this.get('model.approvalSet'));
    }),

    // Actions
    actions: {
      submit: function submit(e) {
        e.preventDefault();
        this.get('actionCalled')({ approvalRole: this.get('chosenRole') });
      }
    }
  });
});