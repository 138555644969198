define('frontend/controllers/app/products/product/users/permissions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),

    productTab: Ember.computed.reads('products.firstObject.name'),

    products: Ember.computed(function () {
      return this.get('store').findAll('product');
    })
  });
});