define('frontend/mixins/approvable-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    authorizeAction: Ember.inject.service(),
    session: Ember.inject.service(),

    approve: function approve(role) {
      var _this = this;

      return this.authorize(function () {
        return _this._approveOrSave('approve', role);
      });
    },
    deny: function deny(role) {
      var _this2 = this;

      return this.authorize(function () {
        return _this2._approveOrSave('deny', role);
      });
    },
    authorize: function authorize(cb) {
      if (this.get('session.currentUser.company.enhancedPart11')) {
        return this.get('authorizeAction').showConfirmation().then(function (authorized) {
          return authorized && cb();
        });
      } else {
        return cb();
      }
    },
    _approveOrSave: function _approveOrSave(actionName, role) {
      var _this3 = this;

      return this.promiseMethodWithNotifications('approvalAction', actionName === 'approve' ? 'The changes were approved' : 'The changes were denied', this._buildErrorFormatter('Could not ' + actionName + ' changes'), actionName, role).then(function (response) {
        return _this3.get('store').pushPayload(response);
      }).catch(function () {});
    },
    _buildErrorFormatter: function _buildErrorFormatter(defaultMsg) {
      return function (error) {
        var errors = Ember.get(error, 'errors.length') ? error.errors : Ember.get(error, 'payload.errors.length') ? error.payload.errors : false;

        return errors ? errors.join('\n') : defaultMsg;
      };
    }
  });
});