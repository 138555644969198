define('frontend/components/design-review/form', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberValidations.default, {
    store: Ember.inject.service(),
    notify: Ember.inject.service('notify'),

    tagName: 'form',

    selectedDraftItems: Ember.computed.filterBy('designReview.items', 'isDraft'),

    filteredItems: Ember.computed('designReview.product.approvables.@each.isDraft', 'designReview.phaseGate', function () {
      if (this.get('designReview.phaseGate')) {
        return this.get('designReview.product.approvables').filterBy('isDraft', false);
      } else {
        return this.get('designReview.product.approvables');
      }
    }),

    actions: {
      addAttendee: function addAttendee(attendee) {
        this.get('designReview.users').pushObject(attendee);
      },
      removeAttendee: function removeAttendee(attendee) {
        this.get('designReview.users').removeObject(attendee);

        // independent reviewer must be an attendee
        if (this.get('designReview.independentReviewer') === attendee) {
          this.set('designReview.independentReviewer', null);
        }
      },
      addItem: function addItem(item) {
        this.get('designReview').get('approvables').addObject(item);
      },
      removeItem: function removeItem(item) {
        this.get('designReview').get('approvables').removeObject(item);
      },
      updatePhaseGate: function updatePhaseGate(value) {
        var _this = this;

        this.set('designReview.phaseGate', value);
        if (this.get('selectedDraftItems.length')) {
          this.get('notify').info(this.get('i18n').t('design-review.phase-gate.notify-deselect'));
          // use slice as each iteration mutates the array
          this.get('selectedDraftItems').slice().forEach(function (item) {
            return _this.send('removeItem', item);
          });
        }
      }
    }
  });
});