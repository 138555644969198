define('frontend/services/qms-exports-poller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    handleSave: function handleSave(qmsExport) {
      var _this = this;

      return qmsExport.save().then(function () {
        var notification = _this.get('notify').info('Your request is being processed. You will be notified in-app when product data has been exported and you will also receive an email with the download link');
        _this.set('notification', notification);
        _this.set('qmsExportId', qmsExport.get('id'));
      }).catch(function () {
        _this.set('qmsExportId', null);
        _this.get('notify').alert('Ooops.. Something went wrong! Please try to export the product data again.');
      });
    },
    handlePush: function handlePush(qmsExport) {
      if (this.get('qmsExportId') && this.get('qmsExportId') !== qmsExport.get('id') || !this.get('qmsExportId')) {
        // the push is not relevant to the current watched export
        return;
      }

      if (qmsExport.get('status') === 'pending') {
        return;
      }

      if (this.get('notification') && !this.get('notification.isDestroyed')) {
        this.set('notification.visible', false);
      }

      if (qmsExport.get('status') === 'succeeded') {
        this.get('notify').success({ html: 'A download link to the exported data has been sent to your email and will be valid for 24 hours. Alternatively, you can download it <a href="' + qmsExport.get('fileUrl') + '">here.</a></div>' });
      } else if (qmsExport.get('status') === 'failed') {
        this.get('notify').error("Enzyme QMS could not create your export. Make sure you approve at least one item before trying to export. Contact Enzyme Support if you have approved items and you see this message.");
      }
      this.set('qmsExportId', null);
    },


    qmsExportId: Ember.computed({
      get: function get() {
        return localStorage.getItem('qmsExportId');
      },
      set: function set(key, value) {
        if (value) {
          localStorage.setItem('qmsExportId', value);
        } else {
          localStorage.removeItem('qmsExportId');
        }
        return value;
      }
    }),

    qmsExportPromiseObject: Ember.computed('qmsExportId', function () {
      if (!this.get('qmsExportId')) {
        return null;
      }
      return this.get('store').findRecord('qms-export', this.get('qmsExportId'));
    }),

    qmsExport: Ember.computed.reads('qmsExportPromiseObject.content'),

    qmsExportInProgress: Ember.computed('qmsExportId', 'qmsExport.status', function () {
      if (!this.get('qmsExportId')) {
        return false;
      }

      var status = this.get('qmsExport.status');
      if (!status || status === 'pending' || status === 'processing') {
        return true;
      }
    })
  });
});