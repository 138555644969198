define('frontend/models/audit', ['exports', 'ember-data', 'ember-data/relationships', 'ember-data/attr', 'frontend/mixins/approvable', 'frontend/initializers/add-model-async-methods', 'moment', 'frontend/mixins/nc-capa-source'], function (exports, _emberData, _relationships, _attr, _approvable, _addModelAsyncMethods, _moment, _ncCapaSource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_approvable.default, _ncCapaSource.default, {
    company: (0, _relationships.belongsTo)(),
    supplier: (0, _relationships.belongsTo)(),

    auditPlanFileUploads: (0, _relationships.hasMany)('file-upload'),
    auditReportFileUploads: (0, _relationships.hasMany)('file-upload'),
    miscellaneousFileUploads: (0, _relationships.hasMany)('file-upload'),
    qmsDocuments: (0, _relationships.hasMany)('qms-document'),

    totalFileUploadCount: Ember.computed('auditPlanFileUploads.[]', 'auditReportFileUploads.[]', 'miscellaneousFileUploads.[]', function () {
      return this.get('auditPlanFileUploads.length') + this.get('auditReportFileUploads.length') + this.get('miscellaneousFileUploads.length');
    }),

    routeForApproval: (0, _addModelAsyncMethods.saveThenMemberAction)({ path: 'route_for_approval', type: 'post' }),

    majorNonConformances: (0, _attr.default)('number'),
    minorNonConformances: (0, _attr.default)('number'),
    areasOfImprovement: (0, _attr.default)('number'),

    auditType: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    startDate: (0, _attr.default)('date-no-time'),
    endDate: (0, _attr.default)('date-no-time'),
    closureDate: (0, _attr.default)('date-no-time'),
    startDateString: Ember.computed('startDate', function () {
      return String((0, _moment.default)(this.get('startDate')).format('YYYY-MM-DD'));
    }),
    endDateString: Ember.computed('endDate', function () {
      return String((0, _moment.default)(this.get('endDate')).format('YYYY-MM-DD'));
    }),
    closureDateString: Ember.computed('closureDate', function () {
      return String((0, _moment.default)(this.get('closureDate')).format('YYYY-MM-DD'));
    }),

    comments: (0, _attr.default)('string'),
    areasAudited: (0, _attr.default)('string'),
    requirementsApplied: (0, _attr.default)('string'),

    paddedId: Ember.computed('id', function () {
      if (this.get('id')) {
        return this.get('id').padStart(4, '0');
      }
    }),

    shortName: Ember.computed('paddedId', function () {
      return 'Audit #' + this.get('paddedId');
    }),

    sourceDescription: Ember.computed('paddedId', 'title', function () {
      return 'Audit #' + this.get('paddedId') + ': ' + this.get('title');
    }),

    routableTarget: 'app.products.product.audits.audit',

    humanizedApprovalState: Ember.computed('approvalState', function () {
      return {
        draft: "Draft",
        create_requested: "Pending Approval",
        destroy_requested: "Pending Approval",
        update_requested: "Pending Approval",
        approved: "Closed"
      }[this.get('approvalState')];
    })
  });
});