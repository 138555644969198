define('frontend/routes/oauth', ['exports', 'frontend/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, {
    session: Ember.inject.service(),

    afterModel: function afterModel() {
      var redirect = this.get('session').retrieveURLState();

      this.transitionTo(redirect || 'app.products');
    }
  });
});