define('frontend/components/info-bubble', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: ['span'],
    classNames: ['info-bubble'],
    constraints: Ember.computed(function () {
      return [{ to: 'window', pin: true }];
    }),

    tooltipSelector: Ember.computed('elementId', function () {
      return '#' + this.get('elementId') + ' i.user-icon';
    })
  });
});