define('frontend/services/product-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var showProductDropdownRoutes = ['app.products.product.design-control', 'app.products.product.design-control.index', 'app.products.product.risk-management.index'];

  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),

    doShow: Ember.computed('router.currentRouteName', 'disabled', function () {
      var doShow = showProductDropdownRoutes.indexOf(this.get('router.currentRouteName')) > -1;
      return !!doShow && !this.get('disabled');
    }),

    disabled: Ember.computed('isPear', 'notQualityOwner', function () {
      return this.get('isPear') && this.get('notQualityOwner');
    }),

    actions: {
      onProductCreated: function onProductCreated(product) {
        this.get('router').transitionTo('app.products.product', product);
      }
    }
  });
});