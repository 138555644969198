define('frontend/components/approvable-change/non-conformance/non-conformances-products-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    decoratedValues: Ember.computed('value.@each.{product_id,software_version}', function () {
      var _this = this;

      return (this.get('value') || []).map(function (value) {
        var product = void 0;

        if (value.product_id) {
          product = _this.get('store').find('product', value.product_id);
        }

        return {
          product: product,
          softwareVersion: value.software_version
        };
      });
    })
  });
});