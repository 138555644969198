define('frontend/controllers/app/products/product/risk-management/harms/edit', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_emberValidations.default, {
    displayErrors: false,
    useExistingHarm: true,

    createNewHarm: Ember.computed.not('useExistingHarm'),
    harmInstance: Ember.computed.alias('model'),
    editHarmInstanceHarm: Ember.computed.alias('harmInstance.harm'),
    editHarmInstanceHazardousSituation: Ember.computed.alias('harmInstance.hazardousSituation'),
    editHarmInstanceInitialProbability: Ember.computed.alias('harmInstance.initialProbability'),
    editHarmInstanceMitigatedProbability: Ember.computed.alias('harmInstance.mitigatedProbability'),
    company: Ember.computed.reads('product.company'),
    severities: Ember.computed.alias('harmInstance.product.company.productSeverities'),
    probabilities: Ember.computed.alias('harmInstance.product.probabilities'),
    initialProbability: Ember.computed.alias('harmInstance.initialProbability'),
    mitigatedProbability: Ember.computed.alias('harmInstance.mitigatedProbability'),

    editHarmInstanceRequirements: Ember.computed('harmInstance.requirements.[]', function () {
      return this.get('harmInstance.requirements').naturalSortBy('requirement.id');
    }),

    validations: {
      editHarmInstanceHarm: {
        presence: true
      },
      editHarmInstanceHazardousSituation: {
        presence: true
      }
    },

    selectableHarms: Ember.computed('company.harms.@each.descriptionWithSeverity', 'newHarmInstanceHazardousSituation', function () {
      var _this = this;

      return this.get('company.harms').filter(function (harm) {
        return harm === _this.get('model.harm') || !_this.get('newHarmInstanceHazardousSituation') || !_this.get('newHarmInstanceHazardousSituation.harmInstances').mapBy('harm').includes(harm);
      }).uniqBy('descriptionWithSeverity');
    }),

    selectableHazardousSituations: Ember.computed('product.liveHazardousSituations.[]', 'model.hazardousSituation', 'editHarmInstanceHarm', function () {
      var _this2 = this;

      return this.get('product.liveHazardousSituations').filter(function (hazardousSituation) {
        return hazardousSituation === _this2.get('model.hazardousSituation') || !_this2.get('editHarmInstanceHarm.harmInstances').mapBy('hazardousSituation').includes(hazardousSituation);
      });
    }),

    actions: {
      addEditHarmInstanceRequirement: function addEditHarmInstanceRequirement(requirement) {
        this.get('editHarmInstanceRequirements').pushObject(requirement);
      },
      removeEditHarmInstanceRequirement: function removeEditHarmInstanceRequirement(requirement) {
        this.get('editHarmInstanceRequirements').removeObject(requirement);
      },
      setEditHarmInstanceInitialProbability: function setEditHarmInstanceInitialProbability(probability) {
        this.set('editHarmInstanceInitialProbability', probability);
      },
      setEditHarmInstanceMitigatedProbability: function setEditHarmInstanceMitigatedProbability(probability) {
        this.set('editHarmInstanceMitigatedProbability', probability);
      },
      setSeverity: function setSeverity(severity) {
        this.set('severity', severity);
      },
      setEditHarmInstanceHarm: function setEditHarmInstanceHarm(harm) {
        var _this3 = this;

        if (harm.get('product') !== this.get('product')) {
          harm = harm.cloneToProduct(this.get('product'));
        }
        Ember.RSVP.resolve(harm).then(function (harm) {
          return _this3.set('editHarmInstanceHarm', harm);
        });
      },
      setEditHarmInstanceHazardousSituation: function setEditHarmInstanceHazardousSituation(hazardousSituation) {
        this.set('editHarmInstanceHazardousSituation', hazardousSituation);
      },


      // These two cases (for whether the user selected an existing harm or creating one)
      // should be condensed into one
      updateHarmInstance: function updateHarmInstance() {
        var _this4 = this;

        this.validate().then(function () {
          if (!_this4.get('createNewHarm')) {
            _this4.get('model').setProperties({
              product: _this4.get('product'),
              approvalState: 'draft',
              harm: _this4.get('editHarmInstanceHarm'),
              hazardousSituation: _this4.get('editHarmInstanceHazardousSituation'),
              initialProbability: _this4.get('editHarmInstanceInitialProbability'),
              mitigatedProbability: _this4.get('editHarmInstanceMitigatedProbability')
            });
            var requirements = _this4.get('editHarmInstanceRequirements');
            _this4.get('model.requirements').clear();
            requirements.forEach(function (requirement) {
              return _this4.get('model.requirements').pushObject(requirement);
            });
            _this4.get('model').saveWithNotifications().then(function () {
              _this4.transitionToRoute('app.products.product.risk-management');
            });
          } else {
            _this4.store.createRecord('harm', {
              product: _this4.get('product'),
              description: _this4.get('editHarmDescription'),
              severity: _this4.get('severity')
            }).saveWithNotifications(false).then(function (harm) {
              // pass false so only failure is notified - success will be notified below
              _this4.get('model').setProperties({
                product: _this4.get('product'),
                approvalState: 'draft',
                harm: harm,
                hazardousSituation: _this4.get('editHarmInstanceHazardousSituation'),
                initialProbability: _this4.get('editHarmInstanceInitialProbability'),
                mitigatedProbability: _this4.get('editHarmInstanceMitigatedProbability')
              });
              var requirements = _this4.get('editHarmInstanceRequirements');
              _this4.get('model.requirements').clear();
              requirements.forEach(function (requirement) {
                return _this4.get('model.requirements').pushObject(requirement);
              });
              _this4.get('model').saveWithNotifications().then(function () {
                _this4.transitionToRoute('app.products.product.risk-management');
              });
            });
          }
        }).catch(function () {
          _this4.set('displayErrors', true);
        });
      },
      revertToPrevious: function revertToPrevious() {
        this.set('isShowingPreviousApprovedVersion', false);
        this.set('editHarmInstanceHazardousSituation', this.get('model.hazardousSituation'));
        this.set('editHarmInstanceHarm', this.get('model.harm'));
        this.set('editHarmInstanceInitialProbability', this.get('model.initialProbability'));
        this.set('editHarmInstanceMitigatedProbability', this.get('model.mitigatedProbability'));
        var reqs = this.get('model.requirements').naturalSortBy('requirement.id');
        this.set('editHarmInstanceRequirements', reqs);
      }
    }
  });
});