define('frontend/components/changeset-form-for/enzyme-radio-field/radio-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RadioButton = Ember.Component.extend({
    classNames: ['form-field', 'radio-field']
  });

  RadioButton.reopenClass({
    positionalParams: ['label']
  });

  exports.default = RadioButton;
});