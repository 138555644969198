define('frontend/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _this = this;

    this.transition(this.childOf('.tab-nav-wrapper'), this.use('scaleFade'), this.reverse('scaleFade'));

    this.transition(this.hasClass('document-control-outlet'), this.toRoute('app.products.product.document-control.new'), this.use('scaleFade'), this.reverse('scaleFade'));

    var mainRoutes = ['app.products.product.dashboard', 'app.products.product.index', 'app.products.product.risk-management', 'app.products.product.document-control', 'app.products.product.change-requests', 'app.products.product.training', 'app.products.product.supplier-quality', 'app.products.product.audits', 'app.products.product.complaints', 'app.products.product.non-conformances'];

    mainRoutes.slice(0, -1).forEach(function (route, i) {
      var routesToRight = mainRoutes.slice(i + 1);
      _this.transition.apply(_this, [_this.fromRoute(route)].concat(_toConsumableArray(routesToRight.map(function (r) {
        return _this.toRoute(r);
      })), [_this.use('toLeft'), _this.reverse('toRight')]));
    });

    this.transition(this.hasClass('design-control-drawer-wrapper'), this.toValue(true), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.hasClass('liquid-tooltip'), this.use('scaleFade'), this.reverse('scaleFade'));

    this.transition(this.hasClass('info-popup-content'), this.use('scaleFade'), this.reverse('scaleFade'));

    this.transition(this.hasClass('main-navigation-dropdown'), this.use('scaleFade'), this.reverse('scaleFade'));

    this.transition(this.hasClass('liquid-carousel'), this.toValue(function (to, from) {
      return to.index > from.index;
    }), this.use('toLeft'));

    this.transition(this.hasClass('liquid-carousel'), this.toValue(function (to, from) {
      return to.index < from.index;
    }), this.use('toRight'));
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }
});