define('frontend/models/subsystem', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  var computed = Ember.computed,
      reads = Ember.computed.reads;
  var equal = computed.equal,
      union = computed.union;
  exports.default = Model.extend({
    product: belongsTo('product'),
    name: attr('string'),
    color: attr('string'),
    userNeeds: hasMany('user-need'),
    requirements: hasMany('requirement'),
    designOutputs: hasMany('design-outputs'),
    validations: hasMany('validation'),
    verifications: hasMany('verification'),
    version: belongsTo('version'),

    shortName: reads('name'),

    designControls: union('userNeeds', 'requirements', 'designOutputs', 'validations', 'verifications'),

    isDefaultSubsystem: equal('name', 'System')
  });
});