define('frontend/components/mobiledoc-cards/atoms/file-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: ['span'],
    classNames: ['file-upload-atom'],
    store: Ember.inject.service(),

    fileUpload: Ember.computed('payload.fileUploadId', function () {
      return this.get('store').findRecord('file-upload', this.get('payload.file_upload_id'));
    })
  });
});