define('frontend/authenticators/devise', ['exports', 'ember-simple-auth/authenticators/devise'], function (exports, _devise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _devise.default.extend({
    metrics: Ember.inject.service(),
    serverTokenEndpoint: '/api/users/sign_in',

    authenticate: function authenticate() {
      var _this = this;

      return this._super.apply(this, arguments).then(function (data) {
        _this.get('metrics').identify('Intercom', { distinctId: data.email, user_hash: data.user_hash });
        return data;
      });
    },
    restore: function restore() {
      var _this2 = this;

      return this._super.apply(this, arguments).then(function (data) {
        var user_hash = data.user_hash || "pre_deploy_session";
        _this2.get('metrics').identify('Intercom', { distinctId: data.email, user_hash: user_hash });
        return data;
      });
    }
  });
});